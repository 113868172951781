import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Table from "react-bootstrap/Table";
import TemplateAddModal from "../../components/modals/TemplateAddModal";
import TemplateEditModal from "../../components/modals/TemplateEditModal";
import Theme from "theme/theme";
import { Box, Button as MuiButton, Divider, IconButton, Menu, MenuItem, TextField, Typography, useMediaQuery } from "@mui/material";
import { useInputStyles } from "components/StyledInputFields";
import { makeStyles } from "@mui/styles";
import RowSelector from "components/RowSelector";
import { DeleteOutlined, EditOutlined, Block as BlockIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ActionBar from "components/ActionBar";
import downloadCSV from "utils/downloadCsv";

const SearchBox = styled.div`
    float: right;
`;

const openInit = {
    addTemplate: false,
    editTemplate: false
}

const actionButtonProps = {
    marginRight: 10,
    width: 166,
    borderWidth: 2
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    table: {
        style: {
            border: `1px solid grey`,
            borderRadius: 10,
            overflow: "hidden"
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#ffedee"
        }
    }
}

const ExtendedItem = ({ title, value, style, valueComponent, defaultValue }) => {
    const classes = useStyles();
    let haveValue = value || valueComponent || defaultValue;
    if (haveValue) {
        return (
            <div style={style?.itemStyle} className={classes.ex_item}>
                {title && <div style={style?.titleStyle} className={classes.ex_title}>{title}</div>}
                {valueComponent || (value && <div style={style?.valueStyle} className={classes.ex_value}>{value}</div>) || defaultValue}
            </div>
        )
    } else {
        return null;
    }
}

export const SmsTemplates = (props) => {
    const classes = useStyles();
    const [partnersData, setPartnersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([])
    const [isOpen, setIsOpen] = useState(openInit);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [anchorAction, setAnchorAction] = useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = partnersData.filter(
        item =>    (
                            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
                            (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) ||
                            (item.message && item.message.toLowerCase().includes(filterText.toLowerCase()))
                        )
    );

    const handleAddTemplate = () => setIsOpen({ ...openInit, addTemplate: true });
    const handleEditTemplate = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, editTemplate: true })
        }
    };
    const handleDeactivateTemplate = async(data) =>{
        if (tableData.length === 1) {

            function dataURItoBlob(dataURI) {
                // convert base64/URLEncoded data component to raw binary data held in a string
                var byteString;
                if (dataURI.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(dataURI.split(',')[1]);
                else
                    byteString = unescape(dataURI.split(',')[1]);
                // separate out the mime component
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
                // write the bytes of the string to a typed array
                var ia = new Uint8Array(byteString.length);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                return new Blob([ia], {type:mimeString});
            }

            const TemplateFormData = new FormData();
            TemplateFormData.append("id", tableData[0].id);
            TemplateFormData.append("name", tableData[0].name);
            TemplateFormData.append("description", tableData[0].description);
            TemplateFormData.append("message", tableData[0].message);
            TemplateFormData.append("disabled", data?0:1);
            TemplateFormData.append("media_file", dataURItoBlob(tableData[0].media_url));

            try {
                const response = await axios.post('/internal/sms_template/update', TemplateFormData, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY
                    },
                });
            } catch (error) {
                console.log(error)
            }
            setTableData([])
            loadEnrollments();
            handleActionClose();
        }
    }
    const handleClose = () => {
        setIsOpen(openInit);
        // setTableData([]);
    };

    const ExpandedComponent = ({ data }) => {
        return (
            <Box className={classes.ec_main}>
                <Box className={classes.ec_container}>
                    <ExtendedItem title="Id" value={data.id} />
                    <ExtendedItem title="Template Name" value={data.name} />
                    <ExtendedItem title="Active" value={data.disabled ? "No" : "Yes"} />
                    <ExtendedItem title="Description" value={data.description} />
                    <ExtendedItem title="Message" value={data.message} 
                        style={{
                            itemStyle: { gridColumn: 'span 2' },
                        }}
                    />
                    <ExtendedItem
                        title="Media Attached"
                        valueComponent={<img src={data.media_url} className={classes.logo} />}
                        style={{
                            itemStyle: { gridColumn: 'span 2' },
                        }}
                    />
                </Box>
            </Box>
        )
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const exportOpsHeaders = [
        { label: 'Account', key: 'account_number' },
        { label: 'Signup Date', key: 'signup_date' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'ESIID', key: 'esiid' },
        { label: 'IsMove', key: 'moving_switching' },
        { label: 'Requested Date', key: 'requested_date' },
        { label: 'End Date', key: 'end_date' },
        { label: 'Plan Name', key: 'plan_name' },
        { label: 'Address', key: 'Address1' },
        { label: 'Status', key: 'SPStatus' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'IpAddress', key: 'EnrollmentIpAddress' },
        { label: 'Tdu', key: 'TduName' },
        { label: 'Promo code', key: 'promo_code' },
        { label: 'UTM_Source', key: 'utm_source' },
        { label: 'UTM_Content', key: 'utm_content' },
    ];

    const openAction = Boolean(anchorAction);

    const handleExport = () =>{
        downloadCSV(partnersData, "sms_templates")
    }

    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };

    useEffect(() => {
        setColumns([
            {
                name: '',
                selector: row => <RowSelector row={row} tableData={tableData} setTableData={setTableData} />,
                sortable: false,
                right: true,
                width: "60px",
            },
            {
                name: 'Template Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'Description',
                selector: row => row.description,
                sortable: true,
            },
            {
                name: 'Message',
                selector: row => row.message,
                sortable: true,
                right: true,
            },
            {
                name: 'Active',
                selector: row => row.disabled ? 'No' : 'Yes',
                sortable: true,
            }
        ]);
    }, [tableData])


    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };

    const onFilter = (e) => {
        setFilterText(e.target.value);
        e.target.focus();
    };

    const actionProps = {
        title: "SMS Templates",
        addTitle: "Add Template",
        filterTitle: "Filter Templates",
        tableData,
        openAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportOpsHeaders,
        handleExportAction: handleExport,
        handleActionClick,
        handleAddAction: handleAddTemplate
    };

    const actionsMemo = React.useMemo(() =>
        <>
            <ActionBar actionProps={actionProps} />
        </>
        , [tableData, filterText, anchorAction, partnersData]);


    // load all templates here
    const loadEnrollments = async () => {
        let response = await axios.get('/internal/sms_template/get/all',
            {
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
            }
        );
        let sorted = response.data.data;
        function compare(a, b) {
            if (Number(a.disabled) < Number(b.disabled)) {
                return -1;
            }
            if (Number(a.disabled) > Number(b.disabled)) {
                return 1;
            }
            return 0;
        }

        sorted.sort(compare);
        await setPartnersData(sorted);

    }
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                // console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    
    return (
        <>
            {
                (loading || !partnersData || partnersData.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>

                    <div id="portal"></div>
                    <TemplateAddModal open={isOpen.addTemplate} onClose={handleClose} loadEnrollments={loadEnrollments}></TemplateAddModal>
                    {tableData?.length === 1 && isOpen.editTemplate && (
                        <TemplateEditModal open={isOpen.editTemplate}
                            onClose={handleClose} tableData={tableData[0]} setTableData={setTableData} partnersData={partnersData} loadEnrollments={loadEnrollments}
                        />
                    )}
                    <Box className={classes.tableContainer}>
                        <Box className={classes.table}>
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="1000px"
                                pagination
                                paginationPerPage={30}
                                paginationComponentOptions={paginationComponentOptions}
                                striped
                                dense
                                subHeader
                                actions={actionsMemo}
                                customStyles={customStyleForTable}
                            >
                            </DataTable>
                        </Box>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorAction}
                        open={openAction}
                        onClose={handleActionClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        style={{ margin: 0, padding: 0 }}
                    >
                        <MenuItem disabled={tableData?.length !== 1} onClick={handleEditTemplate}>
                            <div className={classes.menuItem}>
                                <EditOutlined color="primary" sx={{ mr: 1 }} />
                                Edit
                            </div>
                        </MenuItem>
                        <Divider style={{ margin: 0, padding: 0 }} />
                        <MenuItem disabled={tableData?.length !== 1} onClick={()=>handleDeactivateTemplate(tableData[0].disabled)}>
                            <div className={classes.menuItem}>
                                {(tableData?.length === 1 && tableData[0].disabled) ? <CheckCircleOutlineIcon  color="primary" sx={{ mr: 1 }}/>: <BlockIcon color="primary" sx={{ mr: 1 }} /> }
                                {(tableData?.length === 1 && tableData[0].disabled) ? "Activate" : "Deactivate"} 
                            </div>
                        </MenuItem>
                        <style>{`
                            ul.MuiList-root.MuiList-padding {
                                padding: 0;
                            }
                        `}</style>
                    </Menu>
                </>
                )
            }

        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    table: {
        // border: "1px solid black"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    },
    logo: {
        maxHeight: 100,
        // border: "1px dashed black",
        padding: 15,
        borderRadius: 6,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)"
    }
}))
