import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import DataTable from 'react-data-table-component';
import Table from 'react-bootstrap/Table';

const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;

const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
      float: right;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

export const Active =  (props) => {
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([])
    //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    const today = new Date();
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <th>Id</th><td>{data.id}</td>
            <th>Created</th><td>{data.created_date}</td>
        </tr>
        <tr>
            <th>FirstName</th><td>{data.first_name}</td>
            <th>LastName</th><td>{data.last_name}</td>
        </tr>
        <tr>
            <th>Email</th><td>{data.email}</td>
            <th>PromoCode</th><td>{data.promo_code}</td>
        </tr>
        <tr>
            <th>Address</th><td>{data.address}</td>
            <th>City</th><td>{data.city}</td>
        </tr>
        <tr>
            <th>ZipCode</th><td>{data.zip_code}</td>
            <th>Esiid</th><td>{data.esiid}</td>
        </tr>
        <tr>
            <th>PetType</th><td>{data.pet_type}</td>
            <th>PetBreed</th><td>{data.pet_breed}</td>
        </tr>
        <tr>
            <th>UTMSources</th><td>{data.utmlookup_code}</td>
            <th></th><td></td>
        </tr>
    </Table>
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = enrollmentsData.filter(
        item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton>
        </>
    );
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const loadEnrollments = async () => {
        let response = await axios.get('/internal/active',
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        await setColumns([
            {
                name: 'Id',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Date',
                selector: row => row.created_date,
                sortable: true,
                right: true,
            },
            {
                name: 'FirstName',
                selector: row => row.first_name,
                sortable: true,
                right: true,
            },
            {
                name: 'LastName',
                selector: row => row.last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'ZipCode',
                selector: row => row.zip_code,
                sortable: true,
                right: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
                right: true,
            },
            {
                name: 'Plan',
                selector: row => row.plan_name,
                sortable: true,
                right: true,
            },
            {
                name: 'LastPage',
                selector: row => row.screen_path,
                sortable: true,
                right: true,
            },
            {
                name: 'Promocode',
                selector: row => row.promo_code,
                sortable: true,
                right: true,
            },
            {
                name: 'UtmSources',
                selector: row => row.utmlookup_code,
                sortable: true,
                right: true,
            }
        ]);
    }
    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <SearchBox>
                            <TextField
                                id="search"
                                type="text"
                                placeholder="Filter By First Name"
                                aria-label="Search Input"
                                value={filterText}
                                onInput={onFilter}
                                autoFocus
                            />
                            <ClearButton type="button" onClick={onClear}>
                                X
                            </ClearButton>
                        </SearchBox>
                        <DataTable
                            title="Enrollments"
                            columns={columns}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="800px"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            striped
                            subHeader
                            //subHeaderComponent={subHeaderComponentMemo}
                        >
                        </DataTable>
                    </>
                )
            }

        </>
    );
}
