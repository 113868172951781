import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import DataTable from 'react-data-table-component';
import PromoCodeAddModal  from '../../components/modals/PromoCodeAddModal'
import Table from "react-bootstrap/Table";
import { CSVLink, CSVDownload } from "react-csv";

const BUTTON_WRAPPER_STYLES = {
    position: 'relative',
    zIndex: 1
}

export const Products =  (props) => {
    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copyStatus, setCopyStatus] = useState({ copySuccess: '' });
    const [columns, setColumns] =  useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [tduName, setTduName] = useState('CENTERPOINT');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = productsData.filter(
        item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()),
    );
    const tduNames = {
        CENTERPOINT: 'CPE',
        AEP_CENTRAL: 'AEPCPL',
        AEP_NORTH: 'AEPWTU',
        TNMP: 'TNMP',
        ONCOR: 'ONCOR'
    }
    const resetCopyStatus = (e) => {
        setCopyStatus({ copySuccess: '' });
    }
    const lightBase = 'https://enrollment.goodcharlie.com';
    const ptcUtmCodes = 'utm_campaign=Corporate&utm_source=comparison-site&' +
        'utm_medium=third-party&utm_content=power-to-choose'
    ;

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(e.target.value);

        e.target.focus();
        setCopyStatus({ copySuccess: 'Copied!' });
        setTimeout(resetCopyStatus, 3000);
    };
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tbody>
        <tr>
            <th>productID</th><td>{data.productID}</td>
            <th>Name</th><td>{data.name}</td>
            <th>Term</th><td>{data.contractPeriodNumValue} {data.contractPeriodName}</td>
        </tr>
        <tr>
            <th>PTC Link</th><td colSpan={"5"}>{lightBase}?product_id={data.productID}&utility_code={tduNames[tduName]}&{ptcUtmCodes} &nbsp;
            <button value={lightBase + '?product_id=' + data.productID + '&utility_code=' + tduNames[tduName] + '&' +ptcUtmCodes}
                    onClick={copyToClipboard}>Copy to clipboard</button><br/>
            <span> {copyStatus.copySuccess}</span></td>
        </tr>
        </tbody>
    </Table>;
    const addPromoCode = () =>{
        setIsOpen(true);
    }
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        { label: 'ProductID', key: 'productID'},
        { label: 'Name', key: 'name'},
        { label: 'TDU', key: 'tduName'},
        { label: 'RateType', key: 'rateType'},
        { label: 'TermType', key: 'contractPeriodName'},
        { label: 'TermAmount', key: 'contractPeriodNumValue'},
        { label: 'PTCLink', key: 'ptcLink'},


    ];
    const handleSetTduName = async (e) => {
         await setTduName(e.target.name);
         await loadProducts(e.target.name);
    }
    const handleOnClose = async () =>{
        setIsOpen(false);
        await loadProducts(tduName);
    }
    const actionsMemo = React.useMemo(() =>
        <>
            <Button
                className="btn btn-primary"
                onClick={handleSetTduName}
                name="CENTERPOINT"
            >Centerpoint
            </Button>
            <Button
                className="btn btn-primary"
                onClick={handleSetTduName}
                name="AEP_CENTRAL"
            >AEP Central
            </Button>
            <Button
                className="btn btn-primary"
                onClick={handleSetTduName}
                name="AEP_NORTH"
            >AEP North
            </Button>
            <Button
                className="btn btn-primary"
                onClick={handleSetTduName}
                name="TNMP"
            >TNMP
            </Button>
            <Button
                className="btn btn-primary"
                onClick={handleSetTduName}
                name="ONCOR"
            >Oncor
            </Button>
            <CSVLink
                data={productsData}
                headers={exportHeaders}
                filename={"goodcharlie_ptc_products.csv"}
                className="btn btn-secondary"
            >
                Export
            </CSVLink>
        </>,[]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

    }, [filterText, resetPaginationToggle]);
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const loadProducts = async (tdu) => {
        let response = await axios.get(`/internal/products/${tdu}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setProductsData(response.data.data.webProducts);

        await setColumns([
            {
                name: 'productID',
                selector: row => row.productID,
                sortable: true,
            },
            {
                name: 'Name',
                selector: row => row.name,
                sortable: true,
            },
            {
                name: 'PTC Link',
                selector: row => `${lightBase}?product_id=${row.productID}&utility_code=${tduNames[tdu]}&${ptcUtmCodes}`,
                sortable: true,
                right: false,
                grow: 6
            },
            {
                name: 'Term',
                selector: row => row.contractPeriodNumValue + ' ' + row.contractPeriodName,
                sortable: true,
                right: true,
            },
            {
                name: 'Rate Type',
                selector: row => row.rateType,
                sortable: true,
                right: true,
            },
            {
                name: 'Benefits',
                selector: row => row.includesBenefits ? 'Yes' : 'No',
                sortable: true,
                right: true,
            }            ,
            {
                name: 'Status',
                selector: row => row.active ? 'Active' : 'Inactive',
                sortable: true,
                right: true,
            }
        ]);
    }
    useEffect(() => {
        const init = async () => {
            await loadProducts(tduName);
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            <span>{tduName}</span>
            {
                (loading || ! productsData || productsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):( <>

                        <DataTable
                            title="Products"
                            columns={columns}
                            data={productsData}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="800px"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                            striped
                            responsive
                            dense
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            actions={actionsMemo}
                        >
                        </DataTable>
                    </>
                )
            }
        </>
    );
}


