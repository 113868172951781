import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Box, Grid } from '@mui/material';

export const RenewalNotice = () => {
    const [data, setData] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [filter, setFilter] = useState('30_days'); // Default filter value
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10); // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    const fetchData = async (perPage = 10, page = 1, loadAll = false) => {
        setLoading(true);
        try {
            const response = await axios.get('/internal/renewal_notice', {
                params: {
                    filter: filter,
                    per_page: loadAll ? totalRows : perPage,
                    page: loadAll ? 1 : page
                },
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
            });
            if (loadAll) {
                setAllItems(response.data.data);
            } else {
                setData(response.data.data);
                setTotalRows(response.data.total);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(perPage, currentPage);
    }, [filter, perPage, currentPage]);

    const handleFilter = (filterValue) => {
        setFilter(filterValue);
        setCurrentPage(1); // Reset to first page when filter changes
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const loadAllItems = async () => {
        await fetchData(perPage, currentPage, true);
    };

    const columns = [
        { name: "Account Number", selector: "AccountNumber", sortable: true },
        { name: "Distribution Service Provider ID", selector: "DistributionServiceProviderID", sortable: true },
        { name: "Distribution Service Provider Name", selector: "DistributionServiceProviderName", sortable: true },
        { name: "Service Point Status ID", selector: "ServicePointStatusID", sortable: true },
        { name: "Service Point Status Name", selector: "ServicePointStatusName", sortable: true },
        // { name: "Notification Template ID", selector: "NotificationTemplateID", sortable: true },
        // { name: "Campaign ID", selector: "CampaignID", sortable: true },
        // { name: "Campaign Name", selector: "CampaignName", sortable: true },
        // { name: "Transmit Date", selector: "TransmitDate", sortable: true },
        // { name: "Account Communication ID", selector: "AccountCommunicationID", sortable: true },
        // { name: "Message Status", selector: "MessageStatus", sortable: true },
        // { name: "Notification Template Name", selector: "NotificationTemplateName", sortable: true },
        // { name: "Communication Date", selector: "CommunicationDate", sortable: true },
        { name: "Service Agreement ID", selector: "ServiceAgreementID", sortable: true },
        { name: "End Date", selector: "EndDate", sortable: true },
    ];

    const exportHeaders = columns.map(col => ({ label: col.name, key: col.selector }));

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <h1>Renewal Notice</h1>
                </Grid>
                <Grid item>
                    {allItems.length === 0 ? (
                        <Button onClick={loadAllItems} variant="contained" color="primary">
                            Load all for download
                        </Button>
                    ) : (
                        <CSVLink
                            data={allItems}
                            headers={exportHeaders}
                            filename={"renewal_notice_data.csv"}
                            className="btn btn-primary"
                        >
                            Download CSV
                        </CSVLink>
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2} style={{ marginTop: 16 }}>
                <Grid item>
                    {(filter === 'all') ?
                        <Button onClick={() => handleFilter('30_days')} variant="contained" color="primary">
                            Filter 30 Days
                        </Button> :
                        <Button onClick={() => handleFilter('all')} variant="contained" color="primary">
                            All
                        </Button>
                    }
                </Grid>
            </Grid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        responsive
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={setPerPage}
                    />
                    <div>
                        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
                        <span>{currentPage}</span>
                        <Button onClick={() => handlePageChange(currentPage + 1)} disabled={data.length < perPage}>Next</Button>
                    </div>
                </div>
            )}
        </div>
    );
};
