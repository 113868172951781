import axios from "axios";

const axiosInternal = axios.create({
    baseURL: process.env.REACT_APP_PROD_INTERNAL_API_ENDPOINT
});

export const axiosSignup = axios.create({
    baseURL: process.env.REACT_APP_PROD_SIGNUP_API_ENDPOINT,
})

export default axiosInternal;
