import ReactDom from "react-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {ThemedButton} from "../GCButtons";
import DataTable from "react-data-table-component";
import useCommonStyles from 'components/CommonStyleClasses';
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const WidgetModal = ({ open, onClose, widget }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const handleClose = (e) => {
        onClose();
    }
    if (!open || !widget) {
         console.log('not opened...');
        return null
    }
    console.log(widget);
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <h2>{widget.title}</h2>
                <form>
                    <div className={classes.dataTable}>
                        <img
                            src={`data:image/png;base64,${widget.object.Image}`}
                        ></img>
                        <ThemedButton
                            onClick={handleClose}
                            outlined={"true"}
                        >
                            Close
                        </ThemedButton>
                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    );
};
export default WidgetModal;
const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        zIndex: 1000,
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        /*padding: '1px 8vw',*/
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    dataTable: {

        overflow: "remove",
    },
    forCheckButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'none',
        padding: '0px 0px 20px 0px',
        cursor: 'pointer',
        position: 'absolute',
    },
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))