import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from 'react-data-table-component';
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import { CSVLink, CSVDownload } from "react-csv";
import {useMsal} from "@azure/msal-react";
import styled from 'styled-components';
const Pre = styled.pre`
      display: block;
      padding: 0px 0px;
      margin-left: 33%;
      overflow: hidden;
      text-align: initial;
    `;
export const FraudReview =  (props) => {
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [allItems, setAllItems] =  useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tbody>
        <tr>
            <td><b>Decision</b><br/><b><big>{data.gc_decision}</big></b></td>
            <td><b>Accoun Number</b><br/>{data.account_number}</td>
            <td><b>FirstName</b><br/>{data.first_name}</td>
            <td><b>LastName</b><br/>{data.last_name}</td>
        </tr>
        <tr>
            <td><b>Email</b><br/>{data.email}</td>
            <td><b>Created</b><br/>{data.created_at}</td>
            <td><b>SN Trust</b><br/>{data.SSNTrust}</td>
            <td><b>SN Reason</b><br/>{data.SSNInsightsReason}</td>
        </tr>
        <tr>
            <td><b>Address Affiliation</b><br/>{data.addressAffiliation}</td>
            <td><b>Reason</b><br/>{data.addressAffiliationReason}</td>
            <td><b>DOB Trust</b><br/>{data.dobTrust}</td>
            <td><b>DOB Reason</b><br/>{data.dobVerificationReason}</td>
        </tr>
        <tr>
            <td><b>Email Trust</b><br/>{data.emailTrust}</td>
            <td><b>Email reason</b><br/>{data.emailVerificationReason}</td>
            <td><b>Phone Trust</b><br/>{data.phoneTrust}</td>
            <td><b>Phone Reason</b><br/>{data.phoneVerificationReason}</td>
        </tr>
        <tr>
            <td><b>Identity Trust</b><br/>{data.identityTrust}</td>
            <td><b>Identity Reason</b><br/>{data.identityVerificationReason}</td>
            <td><b>Identity Risk</b><br/>{data.identityRisk}</td>
            <td><b>Reason</b><br/>{data.identityRiskReason}</td>
        </tr>
        <tr>
            <td><b>Credit Score</b><br/>{data.credit_score}</td>
            <td><b>Broker/UTM-content</b><br/>{data.broker_name}/{data.utm_content}</td>
            <td><b>MIS SO 6 Months</b><br/>{data.ercot_so6m === null ? 'n/a' : data.ercot_so6m}</td>
            <td><b>MIS SO 12 Months</b><br/>{data.ercot_so12m === null ? 'n/a' : data.ercot_so12m}</td>
        </tr>
        <tr>
            <td>{(data.ivrId)
                ? <>
                    <b>Id Validation Status: </b><br/>{data.ivrResponse.status} : {data.ivrResponse.match_type === 'DevAPIAddressID'
                    ? 'TPS Address'
                    : (data.ivrResponse.match_type === 'DevAPICallerID'
                        ? 'TPS Phone'
                        : ( data.ivrResponse.match_type === 'LandGlide' ? 'LandGlide' : '(no matches)') )}
                </>
                : <>
                    <b>Id Validation: </b>
                    <button id={"runidv_" + data.pr_id} onClick={RunIdVerification} prid={data.pr_id}
                            className={"btn-primary"}> Run ID Verification
                    </button>
                </>}
            </td>
            <td>
                {(data.ivrId)
                    ? <>
                        <b>Id Validation Type: </b><br/>
                        {data.ivrResponse.match_type_first_name
                            ? data.ivrResponse.match_type_first_name
                            : data.ivrResponse.match_type_name ? data.ivrResponse.match_type_name : 'n/a'}
                    </>
                    : <>
                    </>}
            </td>
            <td>
                {(data.ivrId)
                    ? <>
                        <b>Id Validation Address: </b><br/>
                        {data.ivrResponse.match_address ?? 'n/a'}
                    </>
                    : <>
                    </>}
            </td>
            <td>
                {(data.ivrId)
                    ? <>
                        <b>Id Validation Name: </b><br/>
                        {data.ivrResponse.match_name ?? 'n/a'}
                    </>
                    : <>
                    </>}
            </td>
        </tr>
        </tbody>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const {instance, accounts} = useMsal();
    const disableDownload = !accounts.some(
        (v) => v.username.match(/(weilbacher|hernandez|helders)/i)
    );
    const getAllItems = async (e) => {
        console.log(e);
        setLoading(true);
        const response = await axios.get(`/internal/idt_review_cc?page=1&per_page=0`,
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        setLoading(false);
        setAllItems(response.data.data);

    }
    const RunIdVerification = async (e) => {
        console.log(e);
        console.log(e.target);

        const bt = e.target;
        console.log(bt);
        console.log(bt.button);
        bt.innerText = '*';
        bt.classList.add("spinner-grow");
        const pridpar = e.target.attributes.prid.value;
        console.log(pridpar);
        const payload = {
            prospect_id : pridpar
        }
        //setLoading(true);
        const response =  axios.post(`/internal/verifyid`, payload,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        response.then((result) => {
            loadEnrollments(page);
        })
        //setLoading(false);
    }
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        { label: 'id', key: 'gc_id'},
        { label: 'email', key: 'email'},
        { label: 'credit_score', key: 'credit_score'},
        { label: 'account_number', key: 'account_number'},
        { label: 'account_status', key: 'ACStatus'},
        { label: 'enrollment_date', key: 'created_at'},
        { label: 'pushed_to_market', key: 'pushed_to_market'},
        { label: 'plan_name', key: 'plan_name'},
        { label: 'gc_decision', key: 'gc_decision'},
        { label: 'sn_trust', key: 'SSNTrust'},
        { label: 'sn_reason', key: 'SSNInsightsReason'},
        { label: 'address_affiliation', key: 'addressAffiliation'},
        { label: 'address_reason', key: 'addressAffiliationReason'},
        { label: 'dob_trust', key: 'dobTrust'},
        { label: 'dob_reason', key: 'dobVerificationReason'},
        { label: 'email_trust', key: 'emailTrust'},
        { label: 'email_reason', key: 'emailVerificationReason'},
        { label: 'phone_trust', key: 'phoneTrust'},
        { label: 'phone_reason', key: 'phoneVerificationReason'},
        { label: 'identity_trust', key: 'identityTrust'},
        { label: 'identity_trust_reason', key: 'identityVerificationReason'},
        { label: 'identity_risk', key: 'identityRisk'},
        { label: 'identity_risk_reason', key: 'identityRiskReason'},
        { label: 'service_order_6m', key: 'ercot_so6m'},
        { label: 'service_order_12m', key: 'ercot_so12m'},
        { label: 'broker_name', key: 'broker_name'},
    ];
    const conditionalRowStyles = [
        {
            when: row => row.gc_decision === 'Review',
            style: {
                backgroundColor: 'orange',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.gc_decision === 'Decline',
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.gc_decision === 'Deny',
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }
    ];
    const actionsMemo = React.useMemo(() =>
            <>
                {! disableDownload && allItems.length <= 0 ?<Button onClick={getAllItems}>Load Credit Scores</Button>: undefined}
                {! disableDownload && allItems.length > 0 ?
                    <CSVLink
                        data={allItems}
                        headers={exportHeaders}
                        filename={"goodcharlie_confidential.csv"}
                        className="btn btn-primary"
                    >
                        Export Credit Scores
                    </CSVLink>
                    : undefined}

            </>,
        [allItems]
    );
    const loadEnrollments = async (page) => {
        let response = await axios.get(`/internal/idt_review?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        await setColumns([
            {
                name: 'Enrollment Create Date',
                selector: row => row.created_at,
                sortable: true,
                right: false,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
                right: true,
            },
            {
                name: 'First Name',
                selector: row => row.first_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Last Name',
                selector: row => row.last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Prospect',
                selector: row => row.prospectId,
                sortable: true,
                right: true,
            },
            {
                name: 'AccountNumber',
                selector: row => row.account_number,
                sortable: true,
                right: true,
            },
            {
                name: 'Plan Name (Id)',
                selector: row => row.plan_name + ' (' + row.plan_id + ')',
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'Switch/Move',
                selector: row => row.moving_switching == 1 ? 'Moving' : 'Switching',
                sortable: true,
                right: true,
            },
            {
                name: 'AccountStatus',
                selector: row => row.ACStatus,
                sortable: true,
                right: true,
            },
            {
                name: 'GC Decision',
                selector: row => row.gc_decision,
                sortable: true,
                right: true,
            },
            {
                name: 'IDVerification',
                selector: row => ( row.ivrResponse &&  row.ivrResponse.status) ? row.ivrResponse.status : '',
                sortable: true,
                right: true,
            }
        ]);
        setTotalRows(response.data.total);
        setLoading(false);
    }
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        setPage(page);
        loadEnrollments(page);

    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/idt_review?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        const init = async () => {
            await loadEnrollments(1);
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <DataTable
                        title="Fraud Review"
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        data={enrollmentsData}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        fixedHeader
                        fixedHeaderScrollHeight="900px"
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={perPage}
                        paginationDefaultPage={page}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={loading}
                        striped
                        responsive
                        dense
                        actions={actionsMemo}
                    >
                    </DataTable>
                )
            }

        </>
    );
}
