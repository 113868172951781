import { Box, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import Theme from "theme/theme";
import { useInputStyles } from "./StyledInputFields";
import {
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material/'
import { useRef, useState } from "react";


const SearchBox = styled.div`
        float: right;
    `;

const actionButtonProps = {
    marginRight: 10,
    width: 166,
    borderWidth: 2
}

const ActionBar = ({ actionProps }) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();
    const anchorRef = useRef(null);

    const [ openPopover, setOpenPopover ] = useState(false)
    const {
        title,
        addTitle,
        filterTitle,
        tableData,
        openAction,
        pgOpenAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportOpsHeaders,
        handleExportAction,
        handleExportActionAll,
        handleBulkAction,
        handleActionClick,
        bulkActionIds,
        handleAddAction,
        //for pricing group page --\/
        filterByBroker,
        filterByPricingGroup,
        pricingGroupMapping,
        viewPricingGroup,
        hideSearch=false,
        disableprop
    } = actionProps;
    return (
        <Box className={classes.flexBoxTop}>            
            <Box className={classes.flexBox}>
                <Typography variant="h5" alignSelf="center" >{title}</Typography>
                <Box className={classes.actionMemo}>
                    <Box style={{ display: "flex" }}>
                        {handleBulkAction &&
                            <>
                                <Button
                                    style={actionButtonProps}
                                    className={classes.actionButtons}
                                    variant="outlined"
                                    disabled={bulkActionIds?.length === 0}
                                    ref={anchorRef}
                                    // onClick={handleBulkAction} 0 deny 2 approve
                                    onClick={()=>setOpenPopover(!openPopover)}
                                >
                                    Approval Status
                                    {openPopover ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </Button>
                                <Popper
                                    open={openPopover}
                                    role={undefined}
                                    anchorEl={anchorRef.current}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                    style={{width: anchorRef.current?.clientWidth}}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={()=>setOpenPopover(false)}>
                                                <MenuList
                                                    autoFocusItem={openPopover}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    // onKeyDown={handleListKeyDown}
                                                >
                                                    <MenuItem onClick={()=>{setOpenPopover(false), handleBulkAction(2)}}>Approved</MenuItem>
                                                    <MenuItem onClick={()=>{setOpenPopover(false), handleBulkAction(0)}}>Denied</MenuItem>
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </>
                        }
                        {handleExportAction && <Button
                            style={actionButtonProps}
                            headers={exportOpsHeaders}
                            className={classes.actionButtons}
                            variant="outlined"
                            onClick={handleExportAction}
                            disabled={disableprop}
                        >
                            Export
                        </Button>}
                        {handleExportActionAll && <Button
                            style={actionButtonProps}
                            headers={exportOpsHeaders}
                            className={classes.actionButtons}
                            variant="outlined"
                            onClick={handleExportActionAll}
                            disabled={disableprop}
                        >
                            Export All
                        </Button>}
                        {filterByBroker && <Button
                            headers={exportOpsHeaders}
                            style={actionButtonProps}
                            className={classes.actionButtons}
                            variant="outlined"
                            id="action-button"
                            aria-controls={openAction ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openAction ? 'true' : undefined}
                            onClick={filterByBroker}
                        >
                            Broker
                            {Boolean(anchorAction) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>}
                        {filterByPricingGroup && <Button
                            headers={exportOpsHeaders}
                            style={actionButtonProps}
                            className={classes.actionButtons}
                            variant="outlined"
                            id="action-button"
                            aria-controls={pgOpenAction ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={pgOpenAction ? 'true' : undefined}
                            onClick={filterByPricingGroup}
                        >
                            Pricing Group
                            {Boolean(pgOpenAction) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>}
                        {handleActionClick && <Button
                            disabled={!tableData?.length > 0}
                            headers={exportOpsHeaders}
                            style={actionButtonProps}
                            className={classes.actionButtons}
                            variant="outlined"
                            id="action-button"
                            aria-controls={openAction ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openAction ? 'true' : undefined}
                            onClick={handleActionClick}
                        >
                            Actions
                            {Boolean(anchorAction) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </Button>}
                        {handleAddAction && <Button
                            style={actionButtonProps}
                            headers={exportOpsHeaders}
                            className={classes.actionButtons}
                            variant="outlined"
                            onClick={handleAddAction}
                        >
                            {addTitle}
                        </Button>}
                    </Box>
                    {!hideSearch && <SearchBox>
                        <TextField
                            id="search"
                            type="text"
                            InputProps={{
                                // style: { border: "1px solid red" },
                                classes: {
                                    root: `${classes.searchInputProps} searchInput`,
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: { marginTop: -6 },
                                classes: { focused: inputClasses.focused }
                            }}
                            label={filterTitle}
                            aria-label="Search Input"
                            value={filterText}
                            onInput={onFilter}
                        />
                        <style>{`
                                .${classes.searchInputProps} input[type="text"]{
                                    height: 50px !important;
                                    border-color: ${Theme.palette.secondary.main}
                                }
                            `}</style>
                        <IconButton
                            type="button"
                            onClick={onClear}
                            className={classes.cancelIcon}
                            style={{ color: "#fff", backgroundColor: Theme.palette.primary.main }}
                        >
                            X
                        </IconButton>
                    </SearchBox>}
                </Box>
            </Box>
            {pricingGroupMapping && viewPricingGroup && <Box className={classes.productsModalBox}>
                <Typography marginRight={10} className={classes.modalTitleBox} onClick={pricingGroupMapping}>Product / Pricing Group Mappings</Typography>
                <Typography className={classes.modalTitleBox} onClick={viewPricingGroup}>View Pricing Groups</Typography>
            </Box>}
        </Box>
    )
}

export default ActionBar;

const useStyles = makeStyles(theme => ({
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "red !important"
    },
    flexBoxTop: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "center",
        }
    },
    flexBox: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "center",
        }
    },
    actionMemo: {
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse"
        }
    },
    searchInputProps: {
        height: "40px",
        width: "250px",
        borderRadius: "3px",
        // border: "1px solid red !important",
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
        borderTopRightRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
        padding: "0 8px 0 8px",
        marginBottom: 0,
        [theme.breakpoints.down("md")]: {
            marginBottom: 10,
            width: "300px",
        }
    },
    cancelIcon: {
        borderTopLeftRadius: "0 !important",
        borderBottomLeftRadius: "0 !important",
        borderTopRightRadius: "5px !important",
        borderBottomRightRadius: "5px !important",
        borderWidth: "1px",
        height: "40px",
        width: "40px",
        textAlign: "center",
        display: "flex",
        float: "right",
        margin: "0px 10px 0px 0px !important",
        alignItems: "center",
        justifyContent: "center"
    },
    productsModalBox:{
        display: 'flex',
        justifyContent: 'right',
        marginTop: '20px',
        marginRight: '10px'
    },
    modalTitleBox:{
        cursor: 'pointer',
        "&:hover":{
                "text-decoration": 'underline'
        },
        color: "#ff8d8a"
    }
}))