import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import DataTable from "react-data-table-component";
import { CSVLink, CSVDownload } from "react-csv";

const SearchBox = styled.div`
        float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;       
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const VideoVetChatlog =  (props) => {
    const [chatlogData, setChatlogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const filteredItems = chatlogData.filter(
        item => item.account_number && item.account_number.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <th>Date</th><td>{data.posted_date}</td>
            <th>Account</th><td>{data.account_number}</td>
            <th>UserId</th><td>{data.user_id}</td>
        </tr>
        <tr>
            <th>FirstName</th><td>{data.first_name}</td>
            <th>LastName</th><td>{data.last_name}</td>
            <th>Email</th><td>{data.email}</td>
        </tr>
        <tr>
            <th>Pet Type</th><td>{data.pet_type}</td>
            <th>Pet Name</th><td>{data.pet_name}</td>
            <th>Added on</th><td>{data.pet_added_on}</td>
        </tr>
        <tr>
            <th>Type</th><td>{data.channel_type}</td>
            <th>Reason</th><td>{data.reason}</td>
            <th>Vet Declared Emergency</th><td>{data.is_vet_emergency > 0 ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
            <th>Channel Id</th><td>{data.channel_sid}</td>
            <th>Message</th><td>{data.response_message}</td>
            <th>Channel Vet</th><td>{data.room_vet} {data.channel_vet}</td>
        </tr>
        {data.channel_type === 'chat' && data.channel_vet_pic !== 'null' ?
            <tr>
                <td colspan={2}></td>
                <th>Vet</th>
                <td><img src={data.channel_vet_pic} width="100px"/></td>
            </tr> : ''
        }
        <tr>
            <th>Vet</th><td>{data.vet_first_name} {data.vet_last_name}</td>
            <th>Feedback Date</th><td>{data.feedback_date}</td>
            <th>Recommendation</th><td>{data.recommendation}</td>
        </tr>
        <tr>
            <th>Customer Rating</th><td>{data.rating_stars} {data.rating_stars ? 'stars' : ''} </td>
            <th>Topic</th><td>{data.rating_topic}</td>
            <th>Review</th><td>{data.rating_review}</td>
        </tr>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        { label: 'PostedDate', key: 'posted_date'},
        { label: 'Account', key: 'channel_sid'},
        { label: 'GCId', key: 'user_id'},
        { label: 'FirstName', key: 'first_name'},
        { label: 'LastName', key: 'last_name'},
        { label: 'Email', key: 'email'},
        { label: 'PetType', key: 'pet_type'},
        { label: 'PetName', key: 'pet_name'},
        { label: 'PetAddedOn', key: 'pet_added_on'},
        { label: 'ChannelType', key: 'channel_type'},
        { label: 'Reason', key: 'reason'},
        { label: 'IsEmergency', key: 'data.is_emergency === 1 ? \'Yes\' : \'No\''},
        { label: 'ChannelId', key: 'channel_sid'},
        { label: 'Message', key: 'response_message'},
        { label: 'Reason', key: 'reason'},
        { label: 'ChannelId', key: 'channel_sid'},
        { label: 'ChannelVet', key: 'channel_vet'},
        { label: 'RoomVet', key: 'room_vet'},
        { label: 'VetFirstName', key: 'vet_first_name'},
        { label: 'VetLastName', key: 'vet_last_name'},
        { label: 'FeedbackDate', key: 'feedback_date'},
        { label: 'Recommendation', key: 'recommendation'},
    ];
    const actionsMemo = React.useMemo(() =>
            <>
                <CSVLink
                    data={filteredItems}
                    headers={exportHeaders}
                    filename={"goodcharlie_chat_logs.csv"}
                    className="btn btn-primary"
                >
                    Export
                </CSVLink>
            </>,
        [filteredItems]
    );
    const onClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const conditionalRowStyles = [
        {
            when: row => row.is_vet_emergency > 0,
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        // You can also pass a callback to style for additional customization
       // {
       //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const fetchUsers = async page => {
        setLoading(true);
        const response = await axios.get(`/internal/chatlogs?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setChatlogData(response.data.data);
        await setColumns([
            {
                name: 'Account',
                selector: row => row.account_number,
                sortable: true,
            },
            {
                name: 'Channel ID',
                selector: row => row.channel_sid,
                sortable: true,
            },
            {
                name: 'Date',
                selector: row => row.posted_date,
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'FirstName',
                selector: row => row.first_name,
                sortable: true,
                right: true,
            },
            {
                name: 'LastName',
                selector: row => row.last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Call Type',
                selector: row => row.channel_type,
                sortable: true,
                right: true,
            },
            {
                name: 'Is Emergency',
                selector: row => (row.is_vet_emergency > 0 ? 'Yes' : 'No'),
                sortable: true,
                right: true,
            },
            {
                name: 'Reason',
                selector: row => row.reason,
                sortable: true,
                right: true,
            },
            {
                name: 'Recommendation',
                selector: row => row.recommendation,
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'Vet',
                selector: row => row.vet_last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Rating',
                selector: row =>  '' ,
                sortable: true,
                right: true,
                cell: row => (row.rating_stars) ? <img width="25px" src={'/rating/' + row.rating_stars + '_stars.jpeg'}/> : ''
            }
        ]);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        fetchUsers(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/chatlogs?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setChatlogData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        const init = async () => {
            await fetchUsers(1);
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! chatlogData || chatlogData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(<>
                    <SearchBox>
                        <TextField
                            id="search"
                            type="text"
                            placeholder="Filter By Account"
                            aria-label="Search Input"
                            value={filterText}
                            onInput={onFilter}
                            autoFocus
                        />
                        <ClearButton type="button" onClick={onClear}>
                            X
                        </ClearButton>
                    </SearchBox>
                    <DataTable
                        title="Vet Video and Chat Requests"
                        columns={columns}
                        conditionalRowStyles={conditionalRowStyles}
                        data={filteredItems}
                        defaultSortAsc={false}
                        defaultSortFieldId={3}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        fixedHeader
                        fixedHeaderScrollHeight="1000px"
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={perPage}
                        paginationDefaultPage={page}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={loading}
                        striped
                        responsive
                        dense
                        subHeader
                        actions={actionsMemo}
                    >
                    </DataTable>
                    </>
                )
            }
        </>
    );
}
