import moment from "moment"

const getLabelForDailySales = (month, year) =>{
    let result = []
    let numberOfDays = new Date(year, month, 0).getDate()
    for(let i=1; i<=numberOfDays; i++){
        let day = getDayOfWeek(`${year}-${month}-${i}`)
        result.push(
            {
                date: `${month}/${i}`,
                day: day
            }
        )
    }
    return result
}

function getDayOfWeek(dateString) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(dateString);
    const dayOfWeek = daysOfWeek[date.getDay()];
    return dayOfWeek;
}

export const formatForAPIFilter = (month, year, value)=>{
    if(value===1){
        let result = [`${year}-${month}-01`]
        var nextMonth = new Date(year, month, 1);
        var lastDayOfMonth = new Date(nextMonth.getTime() - 1);
        result.push(`${year}-${month}-${lastDayOfMonth.getDate()}`)
        return result
    }
    else{
        // if (year=== new Date().getFullYear()){
        //     return [`${year}-01-01`,moment(new Date()).format("YYYY-MM-DD")]
        // }
        return [`${year}-01-01`,`${year}-12-31`]
    }
}

export default getLabelForDailySales;