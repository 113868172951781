import React, { useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";

import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText } from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const ModalCheckBox = styled.input`
        width: 35px;
        height: 40px
      `;
const ModalInput = styled.input`
        min-width: 350px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 350px;
      `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}
const AssoProductsAddModal = ({ open, onClose, allProductsData, formValue, setFormValue }) => {

    const commonClasses = useCommonStyles();
    const classes = useStyles();
    let products = formValue.products ? JSON.parse(formValue.products) : {}

    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {

        // const brokerFormData = new FormData();
        // brokerFormData.append("broker_api_enabled", formValue.broker_api_enabled ? 1 : 0);
        // brokerFormData.append("broker_name", formValue.broker_name);
        // brokerFormData.append("utm_campaign", formValue.utm_campaign);
        // brokerFormData.append("utm_content", formValue.utm_content);
        // brokerFormData.append("utm_medium", formValue.utm_medium);
        // brokerFormData.append("utm_source", formValue.utm_source);
        // brokerFormData.append("sg_agent_id", formValue.sg_agent_id);
        // brokerFormData.append("sg_user_id", formValue.sg_user_id);
        // brokerFormData.append("submit_deposit_required", formValue.submit_deposit_required);

        // try {
        //     const response = await axios.post('/internal/partners', partnerFormData, {
        //         headers: {
        //             'x-api-key': process.env.REACT_APP_API_KEY
        //         },
        //     });
        // } catch (error) {
        //     console.log(error)
        // }

        setFormValue({
            ...formValue,
            products: JSON.stringify(products)
        })
        onClose();
    }

    const handleChange = (e) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
        });
    }

    if (!open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Add Associated Products for Broker</Typography>

                        {
                            allProductsData ? allProductsData.map((item) => {
                                const addRemoveProducts = (data) => {
                                    data.target.checked ? products = { ...products, [data.target.name]: item.name } : delete products[data.target.name]
                                }
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CheckBox
                                            type="checkbox"
                                            name={item.productID}
                                            defaultChecked={(formValue.products !== '' && typeof (formValue.products) !== 'undefined') ? JSON.parse(formValue.products).hasOwnProperty(item.productID) : false}
                                            onChange={addRemoveProducts}
                                        />
                                        <Typography variant="h6" sx={{ ml: '10px', mb: '10px', alignSelf: "center" }} >{item.productID} : {item.name}</Typography>
                                    </div>
                                )
                            }) : "No Products Available"
                        }
                        <br />

                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Done
                            </ThemedButton>
                        </InputRow>

                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default AssoProductsAddModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
}))