import { createTheme } from "@mui/material/styles";
import Button from "react-bootstrap/Button";

const Theme = createTheme({
    typography: {
        allVariants: {
            textTransform: "none",
            fontFamily: "Nunito",
        },
        h4: {
            fontFamily: "Nunito",
            color: "#3A3A3A",
            fontWeight: "bold",
            lineHeight: "45px",
            textAlign: "center",
        },
        h5: {
            color: "#3A3A3A",
            fontWeight: "bold",
            lineHeight: "42px",
            textAlign: "center",
        }
    },
    palette: {
        primary: {
            main: "#FF5B6A",
        },
        secondary: {
            main: "#0BCEBB",
        },
        disableBtn: {
            main: "#E5E5E5",
        },
    },

    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { component: Button, variant: "contained" },
                    style: {
                        backgroundColor: "#FF5B6A",
                        borderRadius: 20,
                        textTransform: "none",
                        "&:hover": {
                            color: "#fff",
                        },
                    },
                },
            ],
        },
    },
});

export default Theme;
