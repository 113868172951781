export const commissionProductFormatter = (rawArray, broker_id, tableFlags) =>{
    let targetArray = [];
    rawArray?.map(item=>{
        let obj = {
            "id": item?.id,
            "broker_id": broker_id,
            "sg_product_id": item?.sg_product_id,
            "sg_product_name": item?.sg_product_name,
        }
        if(tableFlags?.acqComp && tableFlags?.acqUpfront){
            obj={
                ...obj,
                "acq_comp_upfront": item?.acq_comp_upfront
            }
        }
        if(tableFlags?.acqComp && tableFlags?.acqResidual){
            obj={
                ...obj,
                "acq_comp_residual": item?.acq_comp_residual,
            }
        }
        if(tableFlags?.renComp && tableFlags?.renUpfront){
            obj={
                ...obj,
                "renewal_comp_upfront": item?.renewal_comp_upfront
            }
        }
        if(tableFlags?.renComp && tableFlags?.renResidual){
            obj={
                ...obj,
                "renewal_comp_residual": item?.renewal_comp_residual
            }
        }
        targetArray.push(obj)
    })
    return targetArray;
}


export const tableFlagFormatter = (tableFlags) =>{
    return {
        acq_comp: tableFlags?.acqComp,
        acq_comp_upfront: tableFlags?.acqUpfront,
        acq_comp_residual: tableFlags?.acqResidual,
        renewal_comp: tableFlags?.renComp,
        renewal_comp_upfront: tableFlags?.renUpfront,
        renewal_comp_residual: tableFlags?.renResidual
    }
}

export const commissionDataFormatter = (data) =>{
    return {
        payment_frequency : data?.frequency,
        clawback_period : data?.clawback,
        effective_date : data?.date,
        commision_status : data?.status == "Active"?1:data?.status == "Inactive"?0:2,
        converting_utm_content: data?.utmContent
    }
}