import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";

import {
    CheckBox,
    InputRow,
    StyledCheckBox,
    StyledInput,
    StyledTextarea,
    TextArea,
    MuiText,
    Switch,
    MuiAutocomplete
} from 'components/RenewalStyledFields';
import { Checkbox as CBX } from '@mui/material';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const ModalCheckBox = styled.input`
        width: 35px;
        height: 40px
      `;
const ModalInput = styled.input`
        min-width: 350px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 350px;
      `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}
const RenewalEligibleEditModal = ({ open, onClose, tableData, loadEnrollment }) => {

    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [dataToBeUpdated, setDataToBeUpdated] = useState({})
    const [forBtnCB, setForBtnCB] = useState({
        assoProds: false,
        assoToken: false
    })
    const statusOptions = [
        { title: 'Renewal - Sale', year: 1},
        { title: 'Renewal – Left VM', year: 2},
        { title: 'Renewal - Callback', year: 3},
        { title: 'Renewal - Invalid Number', year: 4},
        { title: 'Renewal - Rate', year: 5},
        { title: 'Renewal - Term', year: 6},
        { title: 'Renewal - Shopping', year: 7},
        { title: 'Renewal - Call dropped', year: 8},
        { title: 'Renewal - Enrolled W. Competitor', year: 9},
        { title: 'Eligible', year: 10},
    ];

    const [formValue, setFormValue] = React.useState({
        AccountID : tableData.AccountID,
        AccountNumber : tableData.AccountNumber,
        EndDate : tableData.EndDate,
        FirstName : tableData.FirstName,
        LastName : tableData.LastName,
        PhoneNumber : tableData.PhoneNumber,
        PhoneType : tableData.PhoneType,
        ProductName : tableData.ProductName,
        UtilityName : tableData.UtilityName,
        AccountServicePointID:tableData.ServicePointID,
        GCRenewNotes: tableData.GCRenewNotes,
        GCRenewStatus:tableData.GCRenewStatus,
        SAGId: tableData.SAGId
    });

    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {
        //console.log(formValue);
        let modFormValue = { ...formValue};
        //modFormValue = {
        //    ...modFormValue,
        //}
        console.log(modFormValue);
        try {
            const response = await axios.put('/internal/renew_eligible/update', modFormValue, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            //loadEnrollments();
            loadEnrollment(tableData,modFormValue);
            onClose();
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e) => {
        //console.log(e);

            setFormValue({
                ...formValue,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
            });

    }
    if (!open) {
        // console.log('not opened...');
        return null
    }

    useEffect(() => {


    }, [])

    useEffect(() => {
        (formValue.api_token !== '' || dataToBeUpdated.api_token !== '') ? setForBtnCB({ ...forBtnCB, assoToken: true }) : setForBtnCB({ ...forBtnCB, assoToken: false })
    }, [formValue.api_token, dataToBeUpdated])


    useEffect(() => {
        (formValue.products !== '' && formValue.products !== '{}') ? setForBtnCB({ ...forBtnCB, assoProds: true }) : setForBtnCB({ ...forBtnCB, assoProds: false })
    }, [formValue.products])

    return ReactDom.createPortal(
        <>
            <div key="OS1" style={OVERLAY_STYLES} />
            <div key="FM1" className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Update Renewal Prospect Here.</Typography>

                        <InputRow>
                            <MuiText
                                label="Account Number"
                                name="AccountNumber"
                                defaultValue={formValue.AccountNumber}
                                onChange={handleChange}
                                key="AccountNumber"
                            />
                            <MuiText
                                label="Agreement End Date"
                                name="EndDate"
                                defaultValue={formValue.EndDate}
                                onChange={handleChange}
                                key="EndDate"
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                // style={{ width: "98%" }}
                                label="First Name"
                                name="FirstName"
                                defaultValue={formValue.FirstName}
                                onChange={handleChange}
                                key="FirstName"
                            />
                            <MuiText
                                // style={{ width: "98%" }}
                                label="Last Name"
                                name="LastName"
                                defaultValue={formValue.LastName}
                                onChange={handleChange}
                                key="LastName"
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                label="Phone Number"
                                name="PhoneNumber"
                                defaultValue={formValue.PhoneNumber}
                                onChange={handleChange}
                                key="PhoneNumber"
                            />
                            <MuiText
                                label="Phone Type"
                                name="PhoneType"
                                defaultValue={formValue.PhoneType}
                                onChange={handleChange}
                                key="PhoneType"
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                label="Product Name"
                                name="ProductName"
                                defaultValue={formValue.ProductName}
                                onChange={handleChange}
                                key="ProductName"
                            />
                            <MuiText
                                label="Utility Name"
                                name="UtilityName"
                                defaultValue={formValue.UtilityName}
                                onChange={handleChange}
                                key="UtilityName"
                            />
                        </InputRow>
                        <br />
                            <MuiAutocomplete
                                label="Disposition"
                                type="select"
                                name="GCRenewStatus"
                                options={statusOptions}
                                formValue={formValue}
                                //defaultValue={formValue.GCRenewStatus}
                                value={formValue.GCRenewStatus}
                                setFormValue={setFormValue}
                                onChange={handleChange}
                                key="GCRenewStatus"
                            />
                        <br />
                        <MuiText
                            label="disposition Notes"
                            name="GCRenewNotes"
                            defaultValue={formValue.GCRenewNotes}
                            multiline={true}
                            row={5}
                            onChange={handleChange}
                            key="GCRenewNotes"
                        />
                        <br />
                        <br />
                        <br />

                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={'true'}
                                key = "b1"
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={'true'}
                                onClick={handleSubmit}
                                key = "b2"
                            >
                                Save
                            </ThemedButton>
                        </InputRow>

                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default RenewalEligibleEditModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    forCheckButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'none',
        padding: '0px 0px 20px 0px',
        cursor: 'pointer',
        position: 'absolute',
    },
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))