import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App.jsx";
import { ThemeProvider } from "@mui/material/styles";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import Theme from "theme/theme";
import { SnackbarProvider, useSnackbar } from 'notistack'

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
    <SnackbarProvider>
        <React.StrictMode>
            <ThemeProvider theme={Theme}>
                <MsalProvider instance={msalInstance}>
                    <Router>
                        <App />
                    </Router>
                </MsalProvider>
            </ThemeProvider>
        </React.StrictMode>
    </SnackbarProvider>,
    document.getElementById("root")
);
