import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import {TextField} from "@material-ui/core"
import CancelIcon from '@mui/icons-material/Cancel';
import { netPlanSourceFormatter, responseParserNetPlan } from 'utils/apiDataFormatterSalesReport'
//Data
import netPlanSourceData from './dummydata/netplaneditsourcedata.json';
import { useEffect } from 'react';
import { getSalesTarget, uploadSalesTarget } from './salesApis';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
  
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const mappingDataMonth=["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

export default function NetPlanModal({open, handleOpen, year, targetData, setTargetData, pageRefresh, setPageRefresh}) {
    const [data, setData] = useState(targetData);
    const [editingId, setEditingId] = useState(null);
    
    const handleFieldChange = (event, field, id) => {
        if (/^\d*$/.test(event.target.value)) { // Only allow numeric values
            const newData = data.map(item => {
                if (item.id === id) {
                    return { ...item, [field]: parseInt(event.target.value) };
                }
                return item;
            });
            setData(newData);
        }
        if (event.target.value === "" || event.target.value === null) { // Only allow numeric values
            const newData = data.map(item => {
                if (item.id === id) {
                    return { ...item, [field]: 0 };
                }
                return item;
            });
            setData(newData);
        }
    };

    const enableEditing = (id) => {
        setEditingId(id);
    };
    
    const saveEdits = (id) => {
        setEditingId(null);
        console.log('Data in data ==>', data);
    };

    const handleModalSubmit = async() =>{
        const payload = responseParserNetPlan(data, year)
        let res = await uploadSalesTarget(payload)
        if(res){
            setTargetData(data)
            setPageRefresh(!pageRefresh)
            handleOpen(false)
        }else{
            handleOpen(true)
        }
    }

    return (
        <Modal
            open={open}
            onClose={()=>handleOpen(false)}
        >
            <Box sx={style}>
                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", padding: 20}}>
                    <p>EDIT &nbsp;<b>SALES - TARGET</b>&nbsp;  (YEAR - {year})</p>
                    <CancelIcon onClick={()=>{handleOpen(false); setData(targetData)}} style={{cursor: "pointer"}}/>
                </Box>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center", minWidth: "188px"}} >SALES - TARGET</StyledTableCell>
                                {
                                    months.map(item=><StyledTableCell style={{fontWeight: "bold"}}>{item}</StyledTableCell>)
                                }
                                <StyledTableCell style={{fontWeight: "bold"}}>EDIT</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data.map(row => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {
                                        row.name
                                    }
                                </TableCell>
                                {mappingDataMonth.map((item)=><TableCell>
                                    {editingId === row.id ? (
                                    <TextField
                                        value={row[item]}
                                        onChange={event => handleFieldChange(event, item, row.id)}
                                    />
                                    ) : (
                                    row[item]
                                    )}
                                </TableCell>)}
                                <TableCell>
                                    {editingId === row.id ? (
                                        <SaveIcon onClick={() => saveEdits(row.id)} style={{cursor:"pointer"}}/>
                                    ) : (
                                        <EditIcon onClick={() => enableEditing(row.id)} style={{cursor:"pointer"}}/>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box style={{marginTop: 20, display: "flex", justifyContent: "right"}}>
                    <Button variant='contained' onClick={handleModalSubmit}>Submit</Button>
                </Box>
            </Box>
        </Modal>
    );
}