import React, { useState, useEffect } from 'react'
import ReactDom from "react-dom";
import {makeStyles} from "@mui/styles";
import useCommonStyles from "../CommonStyleClasses";
import {CircularProgress} from "@mui/material";

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const AdverseLetterSendModal = ({ open, onClose, tableData, isOpen }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    if (!open) {
        // console.log('not opened...');
        return null
    } else {
        console.log('isOpen', isOpen);
        console.log('tableData', tableData);
        console.log(document.getElementById('portal'));
    }
    return ReactDom.createPortal(
        <>
            <div key="OS1" style={OVERLAY_STYLES} />
            <div key="FM1" className={classes.modal}>
                <form>
                    <CircularProgress />
                </form>
            </div>
        </>
        ,
        document.getElementById('portal')
    )
}
export default AdverseLetterSendModal;
const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width:
            "55%",
        height:
            "96vh",
        overflowX:
            "hidden",
        top:
            '50%',
        left:
            '50%',
        transform:
            'translate(-50%, -50%)',
        backgroundColor:
            '#FFF',
        borderRadius:
            10,
        padding:
            '60px 8vw',
        zIndex:
            1000,
        [theme.breakpoints.down("lg")]
            :
            {
                width: "70%",
            }
        ,
        [theme.breakpoints.down("md")]
            :
            {
                width: "90%",
            }
    }
    ,
    forCheckButton: {
        display: 'flex',
        flexDirection
            :
            'row',
        justifyContent
            :
            'flex-start',
        alignItems
            :
            'center',
        backgroundColor
            :
            'none',
        padding
            :
            '0px 0px 20px 0px',
        cursor
            :
            'pointer',
        position
            :
            'absolute',
    }
    ,
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))