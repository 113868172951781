import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Chip, Tooltip } from '@material-ui/core';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  // const [left, setLeft] = React.useState(props?.left);
  // const [right, setRight] = React.useState(props?.right);

  const { left, setLeft, right, setRight, checked, setChecked } = props
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  function convertValues(input) {
    try{
      const parts = input.split('=>');
      return parts[0];
    }catch(err){
      return input;
    }
  }
  
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => {
    return(
      <Card style={{borderRadius:"10px"}}>
        <div style={{  background:"#FF7676",color:"white",fontWeight:"bold",display:"flex",alignItems:"center",padding:"6px 16px 6px 16px"}}>
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={title === "Available UTM Content"?(numberOfChecked(items) === items.length && items.length !== 0):
              !(numberOfChecked(items) === items.length && items.length !== 0)
            }
              indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
              style={{color:"white",padding:"0px"}}
            />
            <div style={{paddingLeft:"20px"}}>{title + ` (${numberOfChecked(items)}/${items.length} selected) `}</div>
        </div>
        <Divider />
        <Grid container >
          <Grid item lg={12} md={12} sm={12} xs={12} >
          <List
              sx={{
              width: "100%",
              height: 230,
              bgcolor: 'background.paper',
              overflow: 'auto',
              padding:"0px"
              }}
              dense
              component="div"
              role="list"
          >
              {items.map((value,index) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                  <Tooltip title={(value.includes("=>1")) ? "This is a manually added UTM content, it will be removed if transferred to available UTMs." : ""} placement='right' arrow>
                    <ListItem
                      key={index}
                      role="listitem"
                      button
                      onClick={handleToggle(value)}
                      style={{
                          backgroundColor: index % 2 === 0 ? '#FFF1F1' : 'white',
                          padding:"6px 16px 6px 16px"
                      }}
                    >
                    <ListItemIcon>
                        <Checkbox
                          checked={title === "Available UTM Content"?checked.indexOf(value) !== -1:!(checked.indexOf(value) !== -1)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                              'aria-labelledby': labelId,
                          }}
                          style={{color:"#FF7676",padding:"0px"}}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={convertValues(value)} />
                    <div>{(value.includes("=>1")) ?<Chip style={{backgroundColor:"#00D2BB",fontSize:"x-small",fontWeight:600,height:"20px",color:"white"}} label="Manual" />:""}</div>
                    </ListItem>
                  </Tooltip>
              );
              })}
          </List>
          </Grid>
        </Grid>
      </Card>
    )
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item lg={5}>{customList('Available UTM Content', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item lg={5}>{customList('Mapped UTM Content', right)}</Grid>
    </Grid>
  );
}
