import React, {useEffect, useState} from "react";
import axios from "../../utils/axios";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger, CardGroup, CardColumns,CardDeck

} from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Dropdown from "react-bootstrap/esm/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
const random = (min, max)=>{
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const periods = [
    {name: '15 Minutes' , period : '15i'},
    {name: '30 Minutes' , period : '30i'},
    {name: '1 Hour'     , period : '1h'},
    {name: '3 Hours'    , period : '3h'},
    {name: '6 Hours'    , period : '6h'},
    {name: '8 Hours'    , period : '8h'},
    {name: '12 Hours'    , period : '12h'},
    {name: '1 Day'      , period : '1d'},
    {name: '3 Day'      , period : '3d'},
    {name: '7 Day'      , period : '7d'},
    ];
let elements = 27;
const data1 = [];
const data2 = [];
const data3 = [];
for (let i = 0; i <= elements; i++) {
    data1.push(random(50, 200))
    data2.push(random(80, 100))
    data3.push(65)
}
export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: data1,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: data2,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};
function TechApplication01() {
    const refsecs = 60;
    const defaultTimerSeconds = 60;
    const [currPeriod, setCurrPeriod] = useState(periods[2]);
    const [loading, setLoading] = useState(true);
    const [alertsData, setAlertsData] = useState([]);
    const [alertsTitles, setAlertsTitles] = useState([]);
    const [widgetsData, setWidgetsData] = useState([]);
    const [state, setState] = useState({seconds: 51});
    let timer = false;

    const loadNewPeriod = async (index) => {
        await setCurrPeriod(periods[index]);
    }
    const fetchAlerts = async () => {
        //setLoading(true);
        const response = await axios.get(`/monitor/dashhdw01?period=${currPeriod.period}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        let alArrData = [];
        let alArrTitles = [];
        Object.keys(response.data.data).forEach((i)=>{
            console.log(response.data.data[i].length === undefined)
            if (response.data.data[i].length === undefined) {
                //alArrData.push(response.data.data[i]);
                let alArrDet = [];
                Object.keys(response.data.data[i]).forEach((j)=>{
                    console.log(response.data.data[i][j].length === undefined)
                    if (response.data.data[i][j].length === undefined) {
                        alArrDet.push(response.data.data[i][j]);
                    }
                },[alArrDet]);
                alArrData.push(alArrDet);

                alArrTitles.push(i);
            }
        },[alArrData,alArrTitles]);
        await setAlertsData(alArrData);
        await setAlertsTitles(alArrTitles);
        let wgArrData = [];
        Object.keys(response.data.data2.db.widgets).forEach((i)=>{
            wgArrData.push(response.data.data2.db.widgets[i]);
        },[wgArrData]);
        await setWidgetsData(wgArrData);
        setLoading(false);
        setState({seconds: defaultTimerSeconds});
    };
    const init = async () => {
        setLoading(true);
        await fetchAlerts();
    }
    const doTick = () => {
            setState(prevState => ({
                seconds: prevState.seconds - 1
            }));
    }
    useEffect(() => {
        init().then(
            () => {
                console.log('loaded');
            }
        );
        const intervalId = setInterval((state) => {
            doTick(state); // Fetch data every 2 minutes
        }, 1000);
        /*const intervalId2 = setInterval(() => {
            fetchAlerts(); // Fetch data every 2 minutes
        }, 6200);*/
        return () => {
            clearInterval(intervalId)
            //clearInterval(intervalId2);
        };
    }, [currPeriod]);
    if (! loading) {
        //console.log(alertsData.IVRCheckSuccess.Status);
        //console.log(alertsData);
        if (state.seconds <= 0) {
            console.log('reload here');
            setState({seconds: defaultTimerSeconds});
            fetchAlerts();
        } else {
            console.log('seconds', state.seconds);
        }
    }
    return (
        <> {
            (loading || ! alertsData || alertsData.length <=0 ) ? (
                <>
                    loading
                </>
            ):(
                <>
                    <Container fluid>
                        <Row>
                            {
                                alertsData.map((b, i) => {
                                    return (
                                        <CardGroup
                                            style={{
                                                borderStyle: 'dotted',
                                                borderWidth: 'thin',
                                                padding: 5,
                                                margin: 2,
                                                flexFlow: 'wrap',
                                                minWidth: '99%',
                                                maxWidth: '33%'
                                            }}
                                        >

                                            <div className="numbers h3"
                                                 style={{
                                                     minWidth: '99%',
                                                     maxWidth: '99%',
                                                     maxHeight: '3%'
                                                 }}
                                            >
                                                <p className="card-category">{alertsTitles[i]}</p>
                                            </div>

                                            {((typeof b) === 'object') ?
                                                b.map((a, i) => {
                                                    return (<Col style={{
                                                            minWidth: '25%',
                                                            maxWidth: '25%'
                                                        }}>
                                                            <Card className="card-stats">
                                                                <Card.Body>

                                                                    <div className="numbers">
                                                                        <p className="card-category">{a.Metric}</p>
                                                                        <p className="card-category">{a.Descrption ?? ''}</p>
                                                                        <Card.Title
                                                                            className={a.Class}>{a.Status}
                                                                        </Card.Title>
                                                                    </div>

                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }) : ''
                                            }
                                        </CardGroup>
                                    )
                                })
                            }
                            {/*<Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Enrollments Accepted</p>
                                                    <Card.Title className={alertsData.CreateAccountSuccess.Class}>{alertsData.CreateAccountSuccess.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>*/}
                            {/*(<Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Create Account Fail</p>
                                                    <Card.Title className={alertsData.CreateAccountFail.Class}>{alertsData.CreateAccountFail.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>*/}
                            {/*<Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Enrollment Submissions</p>
                                                    <Card.Title className={alertsData.CreateAccount.Class}>{alertsData.CreateAccount.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">IVR Get Account</p>
                                                    <Card.Title className={alertsData.IVRCheckSuccess.Class}>{alertsData.IVRCheckSuccess.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">SG Web Products Error</p>
                                                    <Card.Title className={alertsData.SGGetProdsError.Class}>{alertsData.SGGetProdsError.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">GC Web Products Error</p>
                                                    <Card.Title className={alertsData.webProdsCountZero.Class}>{alertsData.webProdsCountZero.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">SGDb Login Timeouts</p>
                                                    <Card.Title className={alertsData.SGDb1WebLTO.Class}>{alertsData.SGDb1WebLTO.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>*/}
                            {/*<Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Back-end Errors</p>
                                                    <Card.Title className={alertsData.Cli400.Class}>{alertsData.Cli400.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>*/}

                            {/*<Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Prod Db CPU%</p>
                                                    <Card.Title className={alertsData.DbProdCpu.Class}>{alertsData.DbProdCpu.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Prod Db RO CPU%</p>
                                                    <Card.Title className={alertsData.DbProdROCpu.Class}>{alertsData.DbProdROCpu.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Web Server CPU%</p>
                                                    <Card.Title className={alertsData.EC2WebCpu.Class}>{alertsData.EC2WebCpu.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="2" sm="4">
                                <Card className="card-stats">
                                    <Card.Body>
                                        <Row>
                                            <Col xs="12">
                                                <div className="numbers">
                                                    <p className="card-category">Internal Server CPU%</p>
                                                    <Card.Title className={alertsData.EC2IntCpu.Class}>{alertsData.EC2IntCpu.Status}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>*/}
                        </Row>
                        <Row>
                            {
                                widgetsData.map((a,i) => {
                                    return (<Col lg="2" sm="3">
                                        <Card className="card-stats">
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="12">
                                                        <div className="numbers">
                                                            <p className="card-category">{a.title}</p>
                                                            <Card.Title
                                                                className={alertsData[0].Class}><img
                                                                width={"100%"}
                                                                src={`data:image/png;base64, ${a.object.Image}`}/></Card.Title>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>)
                                })
                            }
                        </Row>
                        {/*<Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <Card.Title as="h4">Users Behavior</Card.Title>
                                        <p className="card-category">24 Hours performance</p>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="ct-chart" id="chartHours">
                                            <Bar options={options} data={data} />
                                        </div>
                                    </Card.Body>
                                    <Card.Footer>
                                        <div className="legend">
                                            <i className="fas fa-circle text-info"></i>
                                            Open <i className="fas fa-circle text-danger"></i>
                                            Click <i className="fas fa-circle text-warning"></i>
                                            Click Second Time
                                        </div>
                                        <hr></hr>
                                        <div className="stats">
                                            <i className="fas fa-history"></i>
                                            Updated 3 minutes ago
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>*/}
                        {/*<Row>
                            <Col md="4">
                                <Card>
                                    <Card.Header>
                                        <Card.Title as="h4">Email Statistics</Card.Title>
                                        <p className="card-category">Last Campaign Performance</p>
                                    </Card.Header>
                                    <Card.Body>
                                        <div
                                            className="ct-chart ct-perfect-fourth"
                                            id="chartPreferences"
                                        >
        ...
                                        </div>
                                        <div className="legend">
                                            <i className="fas fa-circle text-info"></i>
                                            Open <i className="fas fa-circle text-danger"></i>
                                            Bounce <i className="fas fa-circle text-warning"></i>
                                            Unsubscribe
                                        </div>
                                        <hr></hr>
                                        <div className="stats">
                                            <i className="far fa-clock"></i>
                                            Campaign sent 2 days ago
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>*/}

                    </Container>
                    <h1>Period: {currPeriod.name}
                        <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Period">
                            {periods.map((aPeriod, index) => (
                                <Dropdown.Item as="button" key={'v' + index} value={index}
                                               onClick={() => loadNewPeriod(index)}>{aPeriod.name}</Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </h1>
                    <div>Next Refresh: {state.seconds}</div>
                </>
            )}
        </>
    );
}
export default TechApplication01;