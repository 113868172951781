import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";

import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText } from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const ModalCheckBox = styled.input`
        width: 35px;
        height: 40px
      `;
const ModalInput = styled.input`
        min-width: 350px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 350px;
      `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}
const DisableBrokerAPIModal = ({ open, onClose, tableData, loadEnrollments }) => {

    const [formValue, setFormValue] = useState(null)
    const [brokerProducts, setBrokerProducts] = useState({});

    const getBrokerDetails = async () => {
        try {
            let response = await axios.post(`/internal/get/broker/details`, { "broker_id": tableData.id }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            let values = response.data.broker_data[0];

            setFormValue({
                ...values, status: values.status!==null ? Number(values.status) : 0
            })
        } catch (err) {
            console.error(err);
            return err;
        }
        return 0;
    };

    const getAssoProds = async () => {
        try {
            let response = await axios.post(`internal/get/broker/products`, { "broker_id": tableData.id }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            let arrToJsonVal = {}
            response.data.broker_products.map((item) => {
                arrToJsonVal = { ...arrToJsonVal, [item.sg_product_id]: item.sg_product_name }
            })
            setBrokerProducts(arrToJsonVal)
        } catch (err) {
            console.error(err);
            return err;
        }
        return 0;
    }

    useEffect(() => {
        getBrokerDetails();
        getAssoProds()
    }, [])

    const commonClasses = useCommonStyles();
    const classes = useStyles();

    //close the modal
    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {
        let modFormValue = formValue.hasOwnProperty('products') ? formValue : { ...formValue, products: JSON.stringify(brokerProducts) }
        modFormValue = modFormValue.hasOwnProperty('api_token') ? modFormValue : { ...modFormValue, api_token: formValue.api_token }
        if (modFormValue.api_token.trim() === '') {
            modFormValue = { ...modFormValue, deactivation_reason: formValue.deactivation_reason || "n/a", api_token: formValue.api_token }
        }
        modFormValue = {
            ...modFormValue,
            broker_id: tableData.id,
            broker_batch_enabled: "1",
            file_prefix: "n/a",
            submit_deposit_required: parseInt(formValue.submit_deposit_required),
            status: parseInt(formValue.status)
        }
        try {
            const response = await axios.post('/internal/brokers/update', modFormValue, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            loadEnrollments();
            onClose();
        } catch (error) {
            console.log(error)
        }

        // console.log(formValue)
    }

    const handleChange = (e) => {
        if (e.target.name === "status") {
            let reason = e.target.checked === false ? "" : "n/a"
            setFormValue({
                ...formValue,
                status: e.target.checked ? 1 : 0,
                deactivation_reason: reason,
            })
        } else {
            setFormValue({
                ...formValue,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
            });
        }
    }

    if (!formValue || !open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Activate or Deactivate the Broker</Typography>
                        <br />
                        <CheckBox
                            label="Activated"
                            type="checkbox"
                            name="status"
                            defaultChecked={formValue.status}
                            onChange={handleChange}
                        />
                        <br />
                        {!formValue.status && <MuiText
                            label="Deactivation Reason"
                            name="deactivation_reason"
                            defaultValue={formValue.deactivation_reason}
                            multiline={true}
                            row={5}
                            onChange={handleChange}
                        />}
                        <br />

                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Done
                            </ThemedButton>
                        </InputRow>

                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default DisableBrokerAPIModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "44%",
        height: "75vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
            height: "68vh",
        }
    },
}))