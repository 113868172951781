import axios from "../../../utils/axios";

export const getBrokerData = async(start, end, value) =>{
    try{
        const response = await axios.get(`/broker/sales-report?start_date=${start}&end_date=${end}&filter_type=${value === 0 ? "month" : "date"}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        return response?.data;
    }catch(err){
        console.log(err)
    }
}

export const getNetTarget = async(start, end, value) =>{
    try{
        const response = await axios.get(`/broker/net-target-sales-report?start_date=${start}&end_date=${end}&filter_type=${value === 0 ? "month" : "date"}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        return response?.data;
    }catch(err){
        console.log(err)
    }
}


export const getSalesTarget = async(currentMonthFilter, currentYearFilter, value) =>{
    try{
        const response = await axios.get(value === 0 ? `/broker/sales-target?year=${currentYearFilter}` : `/broker/sales-target?year=${currentYearFilter}&month=${currentMonthFilter}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        return response?.data;
    }catch(err){
        console.log(err)
        return {
            "data": [
                {
                    "id": 1,
                    "rpt_sales_category_id": 1,
                    "years": currentYearFilter,
                    "jan": "0",
                    "feb": "0",
                    "mar": "0",
                    "apr": "0",
                    "may": "0",
                    "jun": "0",
                    "jul": "0",
                    "aug": "0",
                    "sep": "0",
                    "oct": "0",
                    "nov": "0",
                    "dec": "0",
                    "created_at": "2023-05-13 22:52:07",
                    "updated_at": null,
                    "rpt_sales_category": {
                        "id": 1,
                        "category_name": "Free Broker",
                        "status": 1,
                        "created_at": "2023-05-10 13:09:07",
                        "updated_at": null
                    }
                },
                {
                    "id": 2,
                    "rpt_sales_category_id": 2,
                    "years": currentYearFilter,
                    "jan": "0",
                    "feb": "0",
                    "mar": "0",
                    "apr": "0",
                    "may": "0",
                    "jun": "0",
                    "jul": "0",
                    "aug": "0",
                    "sep": "0",
                    "oct": "0",
                    "nov": "0",
                    "dec": "0",
                    "created_at": "2023-05-13 22:53:48",
                    "updated_at": null,
                    "rpt_sales_category": {
                        "id": 2,
                        "category_name": "Comparison Broker\r\n",
                        "status": 1,
                        "created_at": "2023-05-10 13:09:08",
                        "updated_at": null
                    }
                },
                {
                    "id": 3,
                    "rpt_sales_category_id": 3,
                    "years": currentYearFilter,
                    "jan": "0",
                    "feb": "0",
                    "mar": "0",
                    "apr": "0",
                    "may": "0",
                    "jun": "0",
                    "jul": "0",
                    "aug": "0",
                    "sep": "0",
                    "oct": "0",
                    "nov": "0",
                    "dec": "0",
                    "created_at": "2023-05-13 22:53:48",
                    "updated_at": null,
                    "rpt_sales_category": {
                        "id": 3,
                        "category_name": "Advertising\r\n",
                        "status": 1,
                        "created_at": "2023-05-10 13:09:08",
                        "updated_at": null
                    }
                },
                {
                    "id": 4,
                    "rpt_sales_category_id": 4,
                    "years": currentYearFilter,
                    "jan": "0",
                    "feb": "0",
                    "mar": "0",
                    "apr": "0",
                    "may": "0",
                    "jun": "0",
                    "jul": "0",
                    "aug": "0",
                    "sep": "0",
                    "oct": "0",
                    "nov": "0",
                    "dec": "0",
                    "created_at": "2023-05-13 22:53:49",
                    "updated_at": null,
                    "rpt_sales_category": {
                        "id": 4,
                        "category_name": "Direct Sales Broker\r\n",
                        "status": 1,
                        "created_at": "2023-05-10 13:09:09",
                        "updated_at": null
                    }
                }
            ],
            "status": "Success"
        }
    }
}


export const uploadSalesTarget = async(payload) =>{
    try{
        const response = await axios.post(`broker/update-sales-target`, payload,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        console.log(response)
        return response?.data?.data;
    }catch(err){
        console.log(err)
        return false
    }
}