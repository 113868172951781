import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import downloadCSV from "utils/downloadCsv";
import {CSVLink} from "react-csv";
import moment from "moment/moment";

const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;
const conditionalRowStyles = [
    {
        when: row => row.account_number === row.refCodeAccountNumber,
        style: {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    }
    // You can also pass a callback to style for additional customization
    // {
    //     when: row => row.calories < 400,
    //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
    //},
];
const tableCustomStyles = {
    headCells: {
        style: {
            fontSize: '12px',
            paddingLeft:'0 0px',
            paddingRight:'0 0px',
            fontWeight: 'bold',
            /*paddingLeft: '0 8px',*/
            justifyContent: 'center',
            /*backgroundColor: '#FFA500'*/
        },
    },
}

export const AccountMissingNTP =  (props) => {
    const [accountsData, setAccountsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const filteredItems = accountsData.filter(
        item => item.AccountNumber && item.LastName.toLowerCase().includes(filterText.toLowerCase()),
    );
    const onClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        //console.log(e);
        e.target.focus();
    };
    useEffect(() => {
        const init = async () => {
            await fetchAccounts(1);
            await setColumns([
                {
                    name: 'AccountNumber',
                    selector: row => row.AccountNumber,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },{
                    name: 'FirstName',
                    selector: row => row.FirstName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },{
                    name: 'LastName',
                    selector: row => row.LastName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },{
                    name: 'AccountStatus',
                    selector: row => row.AccountStatusName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },{
                    name: 'StartDate',
                    selector: row => row.AccountStartDate ? moment(new Date(row.AccountStartDate)).format("YYYY-MM-DD"): '',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: '3 D Before Due Date',
                    selector: row => row.nt_3_Days_Before_Due_Notice === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },{
                    name: 'Cancel Enrollment',
                    selector: row => row.nt_Cancel_Enrollment === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Regular Pmt Received',
                    selector: row => row.nt_Regular_Payment_Received === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Pmt Received Pwr Off',
                    selector: row => row.nt_Payment_Received_Power_Off === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Pmt Argmt. Inst. Due',
                    selector: row => row.nt_Payment_Arrangement_Installment_Due === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Pwr OffPayment NS',
                    selector: row => row.nt_Power_OffPayment_NS === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'RNP SO Sent',
                    selector: row => row.nt_RNP_Service_Order_Sent === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Post Pay AutoPay Success',
                    selector: row => row.nt_Post_Pay_Auto_Pay_Success === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Auto Pay Failed',
                    selector: row => row.nt_Auto_Pay_Failed === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Auto Pmt Account Expire',
                    selector: row => row.nt_Auto_Payment_Account_Expire === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Dunning M. Action Grace',
                    selector: row => row.nt_Dunning_Model_Action_Grace === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },{
                    name: 'Disconnect Reminder',
                    selector: row => row.nt_Disconnection_Reminder === null ? 'No' : 'Yes',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                }
            ]);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, []);
    const fetchAccounts = async page => {
        setLoading(true);
        let isOk = true;
        const response = await axios.get(`/internal/missing_notification_templates?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        ).catch((e)=>{
            console.log(e);
            isOk = false;
        });
        if (isOk) {
            console.log(response.data.data);
            //console.log(response.data.total);
            await setAccountsData(response.data.data);
            //setTotalRows(response.data.total);
            setLoading(false);
        } else {

        }
    };
    const ExpandedComponent = ({ data }) =>
        <Table striped bordered hover responsive="sm">
            <tr>
                <td><strong>Type</strong></td>
                <td><strong>Account</strong></td>
                <td><strong>Status</strong></td>
                <td><strong>Enrollment Date</strong></td>
                <td><strong>Referral Code</strong></td>
            </tr>
            <tr>
                <td>{data.a_type}</td>
                <td>{data.account_number}</td>
                <td>{data.account_status}</td>
                <td>{data.account_start}</td>
                <td>{!data.referral_code ? (!data.preferral_code ? (!data.preferralCode ? '' : data.preferralCode) : data.preferral_code) : data.referral_code}</td>
            </tr>
            <tr>
                <td><strong>TransactionId</strong></td>
                <td><strong>Ref Account</strong></td>
                <td><strong>Status</strong></td>
                <td><strong>Enrollment Date</strong></td>
            </tr>
            <tr>
                <td>{data.account_transaction_id}</td>
                <td>
                    {data.ref_account_number}<br/>{data.ref_account_id ? `(${data.ref_account_id})`:''}
                </td>
                <td>{data.ref_account_status}</td>
                <td>{data.ref_account_start}</td>
            </tr>

            <tr>
                <td><strong>Amount</strong></td>
                <td><strong>Date</strong></td>
                <td><strong>Created By</strong></td>
                <td><strong>Bill Id</strong></td>

            </tr>
            <tr>
                <td>{data.amount_paid}</td>
                <td>{data.transaction_date}</td>
                <td>{data.created_by_username}</td>
                <td>{data.bill_id}</td>

            </tr>
        </Table>
    ;
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        fetchEnrollments(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/missing_notification_templates?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50, 100, 250, 500, 1000, 2000
    ];
    const actionsMemo = React.useMemo(() =>
            <>


            </>,
        []
    );
    return (
        <>
            {
                (loading || ! accountsData || accountsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <SearchBox>
                            <TextField
                                id="search"
                                type="text"
                                placeholder="Filter By First Name"
                                aria-label="Search Input"
                                value={filterText}
                                onInput={onFilter}
                                autoFocus
                            />
                            <ClearButton type="button" onClick={onClear}>
                                X
                            </ClearButton>
                        </SearchBox>
                        <DataTable
                            title="Accounts Missing Notification Preferences"
                            columns={columns}
                            customStyles={tableCustomStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            /*expandableRows
                            expandableRowsComponent={ExpandedComponent}*/
                            fixedHeader
                            fixedHeaderScrollHeight="1000px"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                            paginationPerPage={perPage}
                            paginationDefaultPage={page}
                            paginationServer
                            paginationTotalRows={totalRows}
                            progressPending={loading}
                            striped
                            responsive
                            dense
                            subHeader
                            actions={actionsMemo}
                        >
                        </DataTable>
                    </>
                )
            }

        </>
    );
}