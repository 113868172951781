import React from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";

import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, MuiAutocomplete, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px 50px',
    borderRadius: '5px',
    zIndex: 1000
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const EditEnrollmentDetailsModal = ({ open, onClose,row ,editData}) => {

    const commonClasses = useCommonStyles();

    const [formValue, setFormValue] = React.useState({
       
    });
    const handleCancel = async (e) => {
        setFormValue({
          approvalStatus : '',
        })
        onClose();
    }
    const handleSubmit = async (e) => {
        try {
            const response = await axios.post('/internal/update-approval-status', 
            {
                id:row?.User_id,
                approval_status : formValue?.approvalStatus == "Denied"? 0: formValue?.approvalStatus == "Approved"? 2:
                formValue?.approvalStatus == "Reviewed"?1:""
            }
            , {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
              
            if(response?.status == 200){
                onClose();
                 editData(formValue.approvalStatus)
                }
        } catch(error) {
            console.log(error)
        }
    }

    const handleChange = (event) => {
        if(event.target.name == "send_to_sg"){
            setFormValue({
                ...formValue,
                send_to_sg: event.target.checked ? 1 : 0,
            })
        }
        else{
            setFormValue({
                ...formValue,
                [event.target.name]: event.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Edit Details
                        </Typography>


                        {/* <MuiAutocomplete
                            label="Enrollment Status"
                            name="enrollmentStatus"
                            defaultValue={formValue.enrollmentStatus}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            options={[
                                { title: 'Approved', value: "approved" },
                                { title: 'Pending', value: "pending" },
                                { title: 'Pending Deposit', value: "pendingDeposit" }
                            ]}
                        />  */}
                        <MuiAutocomplete
                            label="Approval Status"
                            name="approvalStatus"
                            // defaultValue={formValue.approvalStatus}
                            defaultValue= {formValue.approvalStatus? formValue.approvalStatus : row?.Approval_Status}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            options={[
                                { title: 'Reviewed', value: "Reviewed" },
                                { title: 'Approved', value: "Approved" },
                                { title: 'Denied', value: "Denied" }
                            ]}
                        />
                        {/* <MuiAutocomplete
                            label="Document Status"
                            name="documentStatus"
                            defaultValue={formValue.documentStatus}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            options={[
                                { title: 'Sent', value: "sent" },
                                { title: 'Received', value: "received" },
                            ]}
                        />
                        <MuiText
                            label="Credit Score"
                            name="creditScore"
                            defaultValue={formValue.creditScore}
                            onChange={handleChange}
                        />
                        <br /> */}

                        <InputRow>
                            <ThemedButton
                                outlined={"true"}
                                onClick={handleCancel}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            } 
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default EditEnrollmentDetailsModal;

