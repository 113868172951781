import arrowRight                                        from "../assets/arrow-right.png";
import { ReactComponent as calendarIcon }                from "../assets/calendarIcon.svg";
import errorIcon                                         from "../assets/Error.png";
import Theme                                             from "../theme/theme";
import { useSwitch }                                     from "@mui/base/SwitchUnstyled";
import { AccessTime }                                    from "@mui/icons-material";
import KeyboardArrowDownIcon                             from "@mui/icons-material/KeyboardArrowDown";
import AdapterDateFns                                    from "@mui/lab/AdapterDateFns";
import DesktopDatePicker                                 from "@mui/lab/DesktopDatePicker";
import LocalizationProvider                              from "@mui/lab/LocalizationProvider";
import PickersDay, {PickersDayProps, pickersDayClasses } from "@mui/lab/PickersDay";
import TimePicker                                        from "@mui/lab/TimePicker";
import { Box, Button, Checkbox, CircularProgress }       from "@mui/material";
import { FormControl, FormHelperText, InputAdornment }   from "@mui/material";
import { MenuItem, OutlinedInput, Radio, Select }        from "@mui/material";
import { Typography }                                    from "@mui/material";
import TextField                                        from "@mui/material/TextField";
import { makeStyles }                                   from "@mui/styles";
import { styled }                                       from "@mui/system";
import clsx                                             from "clsx";
import React, { useState }                              from "react";
import ReactPhoneInput                                  from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";


const renderWeekPickerDay = (
    date,
    selectedDates,
    pickersDayProps
) => {

    return (
        <PickersDay
            {...pickersDayProps}
            sx={{
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: Theme.palette.primary.main
                }
            }}
        />
    );
};


const useStyles = makeStyles((theme) => ({
    InputFields: {
        fontSize: 16,
        height: 48,
        borderRadius: "10px !important",
        width: "338px !important",
        "&::placeholder": {
            color: "rgba(33, 33, 33, 0.52)",
        },
        [theme.breakpoints.up("md")]: {
            width: "350px !important",
        },
    },
    inputTextProps: {
        fontWeight: 600,
        fontSize: 16,
        paddingLeft: 5
    },
    aptUnit: {
        fontSize: 16,
        height: 48,
        borderRadius: "10px !important",
        width: "200px !important",
        "&::placeholder": {
            color: "rgba(33, 33, 33, 0.52)",
        },
        [theme.breakpoints.up("md")]: {
            width: "200px !important",
        },
    },
    reviewField: {
        fontSize: 16,
        height: 48,
        borderRadius: "10px !important",
        width: "170px !important",
        paddingLeft: "0px !important",
        "&::placeholder": {
            color: "rgba(33, 33, 33, 0.52)",
        },
        [theme.breakpoints.up("sm")]: {
            width: "240px !important",
        },
    },
    phoneButton: {
        width: 30,
        height: 30,
        marginTop: 10,
        borderRadius: "10px !important",
    },
    fieldTitle: {
        margin: 8,
        marginTop: 20,
        fontWeight: "bold !important",
        color: "#3A3A3A",
    },
    cardDetCvc: {
        width: 164,
        height: 48,
        [theme.breakpoints.up("md")]: {
            width: 170,
            height: 48,
        },
    },
    submitBtn: {
        height: 48,
        width: 180,
        boxShadow: 3,
        "&.MuiButtonBase-root:hover": {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.up("md")]: {
            height: 60,
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-around",
        zIndex: 3,
    },
    btnShadow: {
        position: "absolute",
        left: "12.43%",
        right: "11.89%",
        top: "25%",
        bottom: "0%",
        background: theme.palette.primary.main,
        opacity: 1,
        filter: "blur(40px)",
        borderRadius: "29px",
        zIndex: 1,
    },
    themedTitleBox: {
        marginTop: 20,
        marginBottom: 0,
        [theme.breakpoints.up("md")]: {
            marginBottom: 20,
            marginTop: 30,
        },
    },
    themedTitle: {
        fontWeight: "bold",
        fontSize: "20px !important",
        lineHeight: "28px !important",
        letterSpacing: "-0.28px !important",
        [theme.breakpoints.up("md")]: {
            fontSize: "34px !important",
            lineHeight: "45px !important",
            letterSpacing: "-1px !important",
        },
    },
    errortext: {
        textTransform: "uppercase",
        fontSize: "9px !important",
        lineHeight: 2,
        fontWeigh: 600,
    },
    selectAdornment: {
        marginRight: theme.spacing(3),
    },
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.3em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: Theme.palette.primary.main,
            outline: "0px solid slategrey",
        },
    },
}));

const grey = {
    400: "#BFC7CF",
    500: "#AAB4BE",
    600: "#6F7E8C",
};

const BasicSwitchRoot = styled("span")(
    ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
  margin: 10px;
  // background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
  background: white;
  border-radius: 16px;
  cursor: pointer;
  border: 1px solid #BFC7CF;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    border: 1px solid ${Theme.palette.primary.main} ;
    background: ${Theme.palette.primary.main};
  }
  `
);

const BasicSwitchInput = styled("input")`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb = styled("span")`
  display: block;
  width: 19px;
  height: 19px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #bfc7cf;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 24px;
    top: 3px;
    background-color: #fff;
  }
`;

function BasicSwitch(props) {
    const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

    const stateClasses = {
        "Switch-checked": checked,
        "Switch-disabled": disabled,
        "Switch-focusVisible": focusVisible,
    };

    const handleChange = (e) => {
        getInputProps().onChange(e);
        props.additional(e);
        // console.log(e)
    }

    return (
        <BasicSwitchRoot className={clsx(stateClasses)}>
            <BasicSwitchThumb className={clsx(stateClasses)} />
            <BasicSwitchInput {...getInputProps()} onChange={handleChange} aria-label="Demo switch" />
        </BasicSwitchRoot>
    );
}


const grey1 = {
    400: "#BFC7CF",
    500: "#AAB4BE",
    600: "#6F7E8C",
};

const BasicSwitchRoot1 = styled('span')(
    ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 25px;
  margin: 10px;
  background:  ${Theme.palette.primary.main};
  border-radius: 16px;
  cursor: pointer;

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: ${Theme.palette.secondary.main};
  }
  `,
);

const BasicSwitchInput1 = styled("input")`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb1 = styled("span")`
  display: block;
  width: 19px;
  height: 19px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: white;
  position: relative;
  transition: all 200ms ease;

  &.Switch-focusVisible {
    background-color: ${grey1[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 24px;
    top: 3px;
    background-color: #fff;
  }
`;

function BasicSwitch1(props) {
    const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

    const stateClasses = {
        "Switch-checked": checked,
        "Switch-disabled": disabled,
        "Switch-focusVisible": focusVisible,
    };

    const handleChange = (e) => {
        getInputProps().onChange(e);
        props.additional(e);
        // console.log(e)
    }

    return (
        <BasicSwitchRoot1 className={clsx(stateClasses)}>
            <BasicSwitchThumb1 className={clsx(stateClasses)} />
            <BasicSwitchInput1 {...getInputProps()} onChange={handleChange} aria-label="Demo switch" />
        </BasicSwitchRoot1>
    );
}

const InputFields = ({
                         type,
                         input,
                         label,
                         meta: { touched, invalid, error },
                         otherType,
                         children,
                         placeholder,
                         fullWidth,
                         customClass,
                         required,
                         additional,
                         ...rest
                     }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    if (errorMsg) {
        setTimeout(() => {
            setErrorMsg(null);
        }, 5000);
    }

    const classes = useStyles();
    const handleChange = (event) => {
        let value = event.target.value;
        input.onChange(value);
    };

    const inputColor = () => {
        if (input.value && input.value.trim() !== "") {
            return "#3A3A3A";
        }
        return "rgba(33, 33, 33, 0.52)";
    };

    const selectProps = {
        sx: { borderRadius: 2, color: inputColor, fontWeight: 600 },
    };

    const textFieldProps = {
        sx: { borderRadius: 2 },

        className: customClass ? classes[customClass] : classes.InputFields,
    };

    const showError = () => {
        if (error && touched) {
            return true;
        } else if (required && !input.value) {
            return true;
        } else if (required && input.value && input.value.trim() === "") {
            return true;
        }
        return false;
    };

    const ShowErrorIcon = () => {
        if (showError) {
            return <img src={errorIcon} alt="error-icon" />;
        } else return null;
    };

    switch (type) {
        case "textField":
            return (
                <FormControl variant="outlined">
                    <OutlinedInput
                        autoComplete="off"
                        error={touched && invalid}
                        placeholder={placeholder}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                {touched && invalid && <img src={errorIcon} alt="error-icon" />}
                            </InputAdornment>
                        }
                        {...textFieldProps}
                        inputProps={{
                            className: classes.inputTextProps,
                        }}
                        {...input}
                        {...rest}
                    />
                    <FormHelperText error>
                        <Typography className={classes.errortext}>
                            {touched && error}
                        </Typography>
                    </FormHelperText>
                </FormControl>
            );
        case "phoneNumber":
            return (
                <FormControl variant="outlined">
                    <ReactPhoneInput
                        autoComplete="off"
                        onlyCountries={["us"]}
                        inputExtraProps={{
                            required: true,
                            autoFocus: true,
                        }}
                        placeholder={placeholder}
                        containerClass={classes.InputFields}
                        buttonClass={classes.phoneButton}
                        dropdownStyle={{ borderRadius: "10px !important" }}
                        country={"us"}
                        {...input}
                        {...rest}
                    />
                    <Box sx={{ position: "absolute", right: 15, top: 15 }}>
                        {touched && invalid && <img src={errorIcon} alt="error-icon" />}
                    </Box>
                    <FormHelperText error>
                        <Typography className={classes.errortext}>
                            {touched && error}
                        </Typography>
                    </FormHelperText>
                </FormControl>
            );
        case "selectField":
            return (
                <Box>
                    <FormControl>
                        {fullWidth ? (
                            <Select
                                size="small"
                                displayEmpty
                                {...input}
                                {...rest}
                                className={classes.InputFields}
                                input={<OutlinedInput />}
                                IconComponent={KeyboardArrowDownIcon}
                                {...selectProps}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            marginTop: 0.5,
                                            borderRadius: 3,
                                            bgcolor: 'white',
                                            color: '#21212185',
                                            '& .MuiMenuItem-root': {
                                                margin: 1,
                                                padding: 1,
                                                borderRadius: 3,
                                            },
                                            '& .Mui-selected':{
                                                color: 'black',
                                                backgroundColor: '#6F6C6C0D'
                                            },
                                            '& .MuiTouchRipple-root': {
                                                color: 'black'
                                            }
                                        },
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            width: 250,
                                        }
                                    },
                                }}
                            >
                                <MenuItem disabled value="">
                                    {placeholder}
                                </MenuItem>
                                {children}
                            </Select>
                        ) : (
                            <Select
                                size="small"
                                displayEmpty
                                {...input}
                                {...rest}
                                input={<OutlinedInput />}
                                IconComponent={KeyboardArrowDownIcon}
                                {...selectProps}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            marginTop: 0.5,
                                            borderRadius: 3,
                                            bgcolor: 'white',
                                            color: '#21212185',
                                            '& .MuiMenuItem-root': {
                                                margin: 1,
                                                padding: 1,
                                                borderRadius: 3,
                                            },
                                            '& .Mui-selected':{
                                                color: 'black',
                                                backgroundColor: '#6F6C6C0D'
                                            },
                                            '& .MuiTouchRipple-root': {
                                                color: 'black'
                                            }
                                        },
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        }
                                    },
                                }}
                            >
                                <MenuItem disabled value="">
                                    {placeholder}
                                </MenuItem>
                                {children}
                            </Select>
                        )}
                        <FormHelperText error>
                            <Typography className={classes.errortext}>
                                {touched && error}
                            </Typography>
                        </FormHelperText>
                    </FormControl>
                </Box>
            );
        case "checkBox":
            return (
                <div>
                    <Box alignItems="center" display="flex" ml={-1}>
                        <Checkbox
                            checked={input.value}
                            onChange={
                                input.value === ""
                                    ? input.onChange(false)
                                    : input.onChange(input.value)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            {...input}
                            {...rest}
                        />
                        <Typography className={classes.fieldTitle} color="textSecondary">
                            {label}
                        </Typography>
                    </Box>
                </div>
            );
        case "switch": {
            return (
                <Box display="flex" sx={{ alignItems: "center" }}>
                    <BasicSwitch sx={{ ml: -1 }} checked={input.value} additional={additional} {...input} {...rest} />
                    <Typography variant="body1"> {placeholder}</Typography>
                </Box>
            );
        }
        case "switch1": {
            return (
                <Box display="flex" sx={{ alignItems: "center" }}>
                    <BasicSwitch1 sx={{ ml: -1 }} checked={input.value} additional={additional} {...input} {...rest} />
                    <Typography variant="body1"> {placeholder}</Typography>
                </Box>
            );
        }
        case "datePicker1":
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        inputFormat="EEE, MMM dd, yyyy"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        InputAdornmentProps={{
                            position: "start",
                            style: { paddingLeft: 11 },
                        }}
                        {...input}
                        {...rest}
                        components={{
                            OpenPickerIcon: calendarIcon,
                        }}
                        renderDay={renderWeekPickerDay}
                        style={{ borderRadius: 5 }}
                        renderInput={(params) => (
                            <TextField
                                className={classes.InputFields}
                                autoComplete="off"
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Select date",
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        case "datePicker2":
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        // error={touched && invalid}
                        // helperText={touched && error}
                        InputAdornmentProps={{
                            position: "start",
                            style: { paddingLeft: 11 },
                        }}
                        {...input}
                        {...rest}
                        components={{
                            OpenPickerIcon: calendarIcon,
                        }}
                        renderDay={renderWeekPickerDay}
                        renderInput={(params) => (
                            <TextField
                                autoComplete="off"
                                className={classes.InputFields}
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Select date",
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        case "radio":
            return (
                <Box>
                    <Radio {...input} {...rest} />
                </Box>
            );
        case "timePicker":
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        fullWidth
                        id="time-picker"
                        format="HH:mm"
                        error={touched && invalid}
                        helperText={touched && error}
                        inputVariant="outlined"
                        {...input}
                        {...rest}
                        onChange={(date) => input.onChange(date)}
                        KeyboardButtonProps={{
                            "aria-label": "change time",
                        }}
                        InputProps={{ readOnly: true }}
                        keyboardIcon={<AccessTime />}
                    />
                </LocalizationProvider>
            );
        default:
            return "Unknown Input Field";
    }
};

export default InputFields;

export function ThemedTitle({ children }) {
    const classes = useStyles();
    return (
        <Box className={classes.themedTitleBox}>
            <Typography className={classes.themedTitle} variant="h4" color="inherit">
                {children}
            </Typography>
        </Box>
    );
}

export function FieldTitle(props) {
    const classes = useStyles();
    return (
        <Box className={classes.fieldTitle}>
            <Typography className={classes.fieldTitle} variant="body2">
                {props.children}{" "}
                {props.required ? (
                    <span style={{ color: Theme.palette.primary.main }}>{" *"}</span>
                ) : null}
            </Typography>
        </Box>
    );
}

export function ThemedButton({ children, disabled, isSubmitting, titleOnly, ...rest }) {
    const classes = useStyles();
    return (
        <Box
            sx={{
                display: "flex",
                mt: 4,
                justifyContent: "center",
                cursor: disabled ? "not-allowed" : "pointer",
            }}
        >
            <Button
                variant="contained"
                disabled={disabled}
                className={classes.submitBtn}
                {...rest}
                sx={{
                    borderRadius: 32,
                }}
            >
                {!isSubmitting ? (
                    <>
                        <Box className={classes.btnContainer}>
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                                {children}
                            </Typography>
                            {!disabled && !titleOnly ? (
                                <img
                                    src={arrowRight}
                                    alt="arrow-left"
                                    style={{ objectFit: "contain", marginLeft: 5 }}
                                />
                            ) : null}
                        </Box>
                        {/* {!disabled && <Box className={classes.btnShadow} />} */}
                    </>
                ) : (
                    <>
                        <Box
                            className={classes.btnContainer}
                            sx={{ color: "#fff", width: "80%", alignItems: "center" }}
                        >
                            <CircularProgress
                                size={18}
                                thickness={6}
                                sx={{
                                    m: 0,
                                    mr: 0.5,
                                    p: 0,
                                }}
                                color="inherit"
                            />
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>{titleOnly ? children : "Submitting"}</Typography>
                            {/* <Box className={classes.btnShadow} /> */}
                        </Box>
                    </>
                )}
            </Button>
        </Box>
    );
}
export function ThemedButton2({ children, disabled, isSubmitting, titleOnly, ...rest }) {
    const classes = useStyles();
    return (
        <Box
            sx={{
                display: "flex",
                mt: '5px',
                justifyContent: "center",
                cursor: disabled ? "not-allowed" : "pointer",
            }}
        >
            <Button
                variant="contained"
                disabled={disabled}
                className={classes.submitBtn}
                {...rest}
                sx={{
                    borderRadius: 32,
                }}
            >
                {!isSubmitting ? (
                    <>
                        <Box className={classes.btnContainer}>
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>
                                {children}
                            </Typography>
                            {!disabled && !titleOnly ? (
                                <img
                                    src={arrowRight}
                                    alt="arrow-left"
                                    style={{ objectFit: "contain", marginLeft: 5 }}
                                />
                            ) : null}
                        </Box>
                        {/* {!disabled && <Box className={classes.btnShadow} />} */}
                    </>
                ) : (
                    <>
                        <Box
                            className={classes.btnContainer}
                            sx={{ color: "#fff", width: "80%", alignItems: "center" }}
                        >
                            <CircularProgress
                                size={18}
                                thickness={6}
                                sx={{
                                    m: 0,
                                    mr: 0.5,
                                    p: 0,
                                }}
                                color="inherit"
                            />
                            <Typography sx={{ fontSize: 16, fontWeight: 600, color: "white" }}>{titleOnly ? children : "Submitting"}</Typography>
                            {/* <Box className={classes.btnShadow} /> */}
                        </Box>
                    </>
                )}
            </Button>
        </Box>
    );
}
