import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import {Nav} from "react-bootstrap";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
    const { instance, accounts } = useMsal();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    console.log(accounts)
    return (
        <DropdownButton variant="danger" className="ml-auto" drop="left" title={`${accounts[0]?.name}`}>
            {/*<Dropdown.Item as="button" onClick={() => handleLogout("popup")}>Sign out using Popup</Dropdown.Item>*/}
            <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>Sign out<br/>{accounts[0]?.name}<br/>from internal application</Dropdown.Item>
        </DropdownButton>
    )
    return (
        <Nav.Link href="/">Home</Nav.Link>
    );
}
