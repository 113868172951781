import React, { lazy } from 'react'
import '../../scss/style.scss';

import {Card} from "react-bootstrap";


const Dashboard00 = () => {
    return (
        <>
            <Card> Please login </Card>
        </>
    )
}

export default Dashboard00
