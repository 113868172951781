import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import {FieldLabel, TextField} from "../../components/FormComponents";
import { useMsal } from "@azure/msal-react";
import {Input} from "@mui/material";
import {breakpoints} from "../../components/BreakPoints";
import DataTable from "react-data-table-component";
import RowSelector from "../../components/RowSelector";
import Table from "react-bootstrap/Table";
import jquery from "jquery"
const Lp = styled.div`
    width: 300px;
  text-align: left;
` ;
const Lspan = styled.div`
  width: 110px;
    text-align: right;
    float:left;
` ;
const Rspan = styled.div`
    text-align: left;
  float: right;
` ;
const Column = styled.div`
  flex: 0 0 calc(50% - 4px);

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    flex: 0 0 100%;

    &:first-of-type {
      
    }
  }
`;
const ColumnTitle = styled.div`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 35px;
  font-size: 2rem;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 320px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;
const Container = styled.div`
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  & .text {
    margin-bottom: 2.5rem;
  }
`;
const Form = styled.form`
  max-width: 450px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0 0;
  & .text {
    margin-bottom: 2.5rem;
  }
`;
const InputGroup = styled.div`
  margin-bottom: 1rem;
`;
const LinkButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 85px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 320px;
        text-align: center;   
        display: inline-block;
        align-items: center;
        justify-content: center;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    `;
const TextInput = styled(Input)`
        width: 20%;
  margin-top: 50px;
        margin-bottom: 12px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.23);
        padding: 5px 15px;
        font-size: 1rem;
        height: 33px;
    `;
const Row = styled.div`
  
  padding: min(4.17vw, 3.75rem);
  color: #3a3a3a;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    padding: 1rem 8vw 4rem;
  }
`;

export const CheckEmailValidity = ({ gcid }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCheckValidity = async () => {
        setLoading(true);
        const response =  await axios.post(`/account/profile/81/email/check`, {email}, {
            headers: {'x-api-key': process.env.REACT_APP_API_KEY},
        });
        return response;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email.trim().length > 0) {
            handleCheckValidity(email)
            .then((response)=>{
                if (response.status === 200) {
                    setMessage(response.data?.message ?? 'Message not available');
                }
            }).catch((reason) => {
                console.log('Email Valid', reason.response); // Log internal_note specifically
                setMessage(reason.response?.data?.internal_note ?? reason.response?.data.message ?? 'Message not available');
            }).finally(()=>{
                setLoading(false);
            })
        }
    };

    return (
         <>
             <main>
                 <Column>
                     <ColumnTitle>Check Email Validity</ColumnTitle>
                     <Form onSubmit={handleSubmit}>
                         <InputGroup>
                             <label htmlFor="email">Email:</label>
                             <TextField
                                 type="text"
                                 id="email"
                                 name="email"
                                 value={email}
                                 onChange={(e) => setEmail(e.target.value)}
                                 placeholder="Enter email"
                             />
                         </InputGroup>
                         <InputGroup>
                             <Button type="submit" disabled={loading}>Submit</Button>
                         </InputGroup>
                         {message && (
                             <InputGroup>
                                 <strong>{message}</strong>
                             </InputGroup>
                         )}
                     </Form>
                 </Column>
             </main>
         </>
     );
};