import { Checkbox, Radio } from "@mui/material";
import { useEffect, useState } from "react";

const checkInArray = (arr, obj, property) => {
    let selected = false;
    for (let i = 0; i < arr?.length; i++) {
        let data = arr[i];
        if (obj[property] === data[property]) {
            return true;
        } else {
            selected = false
        }
    }
    return selected;
}
export const handleUnselect = async (row, tableData) => {
    console.log('handleUnselect')
    console.log(row);
    console.log(tableData);
    let my = document.getElementById(`chk_${row.id}`)
    console.log(my);
    if (my) {
        my.click();
    }
}
const OneRowAdverseLetter = ({ row, tableData, setTableData , handler, setCanSend}) => {
    if (row === null) {
        return <div />
    }
    //let selected = checkInArray(tableData, row, "id");
    const [isSelected, setSelected] = useState(false);

    const handleSelect = async(row,isSel,ltable) => {
        console.log('Id', row.id)
        if (isSel) {
            console.log('C')
            let arr = [];
            console.log(typeof  tableData)
            tableData?.map((data,i)=>{
                console.log(i)
                console.log(data);
                console.log(data.id,row.id)
                if (data.id === row.id) {
                    console.log('pushed');
                    tableData.splice(i,1);
                }
                console.log(arr,'arr')
            });
            console.log(arr)
            setTableData(tableData);
            setSelected(false);
            await handler();
        } else if ( !isSel) {
            console.log('A')
            if (tableData.length >  10000 ) {
                console.log('my',tableData.length);
                //console.log(row,'my');
                let selected = await checkInArray(tableData, row, "ID");
                console.log('my',selected);
                if (selected) {
                    await setSelected(true);
                    await handler();
                }
                //setTableData(tableData);
            } else {
                console.log(tableData.length);
                console.log('B')
                console.log(tableData)
                await tableData.push(row);
                await setTableData(tableData);
                //tableData.push(row);
                await setSelected(true);
                await handler();
            }
        } else {
            console.log('B')
            // setTableData([]);
            // setSelected(false);
        }
        console.log(tableData);
    }

    return (
        <>
            <Checkbox
                id={`chk_${row.id}`}
                type="checkbox"
                style={{ transform: "scale(1)", padding: 0 }}
                checked={isSelected}
                onClick={() => handleSelect(row, isSelected)}
            />
        </>
    )
}

export default OneRowAdverseLetter;
