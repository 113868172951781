const  Dashboard05 = () => {
    const src = process.env.REACT_APP_DASHBOARD_05_URL
    return (

        <iframe
            style={{ width: '90%', height: '1500px' }}
            src={src}>
        </iframe>
    )
}
export default Dashboard05;