import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import RowSelector from "../../components/RowSelector";
import ActionBar from "../../components/ActionBar";
import {Divider, Menu, MenuItem} from "@mui/material";
import {Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon, EditOutlined} from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { makeStyles } from "@mui/styles";
import Theme from "theme/theme";
import downloadCSV from "../../utils/downloadCsv";
import {CSVLink} from "react-csv";
import DroppedEditModal from "../../components/modals/DroppedEditModal";


const openInit = {
    addDrpped: false,
    editDropped: false,
    deactivate: false,
    userPortal: false
}
const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const Dropped =  (props) => {
    const classes = useStyles();
    const [droppedData, setDroppedData] = useState([]);
    const [isOpen, setIsOpen] = useState(openInit);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [anchorAction, setAnchorAction] = useState(null);
    const filteredItems = droppedData.filter(
        item => item.AccountNumber && item.AccountNumber.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <td><b>AccountNumber</b><br/>{data.AccountNumber}</td>
            <td><b>StartDate</b><br/>{data.AgreementStart}</td>
            <td><b>EndDate</b><br/>{data.AgreementEnd}</td>
            <td><b>DropDate</b><br/>{data.InactivationDt}</td>
            <td><b>Account/Location Status</b><br/>{data.AccountStatus}<br/>{data.LocationStatus}</td>
        </tr>
        <tr>
            <td><b>FirstName</b><br/>{data.FirstName}</td>
            <td><b>LastName</b><br/>{data.LastName}</td>
            <td><b>Email</b><br/>{data.EmailAddress}</td>
            <td><b>PhoneNumber</b><br/>{data.PhoneNumber}</td>
            <td><b>PhoneType</b><br/>{data.PhoneType}</td>
        </tr>
        <tr>
            <td><b>Address</b><br/>{data.Address1}<br/>{data.ServiceProviderIdentifier}</td>
            <td><b>City</b><br/>{data.City}</td>
            <td><b>Zipcode</b><br/>{data.ZipCode}</td>
            <td><b>UtilityName</b><br/>{data.UtilityName}</td>
            <td><b>Transaction</b><br/>{data.TransactionName}<br/>({data.TransactionCode})</td>
        </tr>
        <tr>
            <td><b>Product</b><br/>{data.ProductName}</td>
            <td><b>UTM Content</b><br/>{data.UtmContent}</td>
            <td><b>Language</b><br/>{data.CustomerLanguage}</td>
            <td><b>Number of Pets</b><br/>{data.PetNum}</td>
        </tr>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const openAction = Boolean(anchorAction);
    const conditionalRowStyles = [
        /* {
             when: row => row.is_emergency > 0,
             style: {
                 backgroundColor: 'red',
                 color: 'white',
                 '&:hover': {
                     cursor: 'pointer',
                 },
             },
         },*/
        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const handleClose = () => {
        setIsOpen(openInit);
    };
    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };
    const handleEditDropped = () => {
        console.log('handleEditDropped');
        console.log(tableData.length);
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, editDropped: true })
        }
    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };
    const handleExport = () =>{
        downloadCSV(droppedData, "dropped")
    }
    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const exportHeaders = [
        { label: 'Account', key: 'AccountNumber' },
        { label: 'AgreementEnd', key: 'EndDate'},
        { label: 'AccountStatus', key: 'SAGStatus'},
        { label: 'LocationStatus', key: 'LocationStatus'},
        { label: 'FirstName', key: 'FirstName'},
        { label: 'LastName', key: 'LastName'},
        { label: 'Email', key: 'EmailAddress'},
        { label: 'PhoneType', key: 'PhoneType'},
        { label: 'PhoneNumber', key: 'PhoneNumber'},
        { label: 'Address', key: 'Address1'},
        { label: 'City', key: 'City'},
        { label: 'ZipCode', key: 'ZipCode'},
        { label: 'UtilityName', key: 'UtilityName'},
        { label: 'ProductName', key: 'ProductName'},
        { label: 'EnergyRate', key: 'GCRate'},
        { label: 'TotalEnergyRate2K', key: 'Kwh2000'},
        { label: 'AnualUsage', key: ''},
    ];
    const actionProps = {
        title: "Saves",
        addTitle: "Add (not)",
        filterTitle: "Filter Saves",
        tableData,
        openAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportHeaders,
        handleExportAction: handleExport,
        handleActionClick,

    };


    const actionsMemo = React.useMemo(() =>
            <>
                <ActionBar actionProps={actionProps} />
                    <CSVLink
                        data={filteredItems}
                        headers={exportHeaders}
                        filename={"goodcharlie_dropped.csv"}
                        className={classes.actionButtons}
                    >
                        <Button className={"MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium css-1oh0714-MuiButtonBase-root-MuiButton-root"}
                                style={{
                                    marginRight: 10,
                                    width: 166,
                                    borderWidth: 2,
                                }}
                                variant="outlined"
                        >
                        Export
                        </Button>
                    </CSVLink>


            </>
        , [tableData, filterText, anchorAction, droppedData]);
    useEffect(() => {
        const init = async () => {
            await fetchDropped(1);
            await setColumns([
                {
                    name: '',
                    selector: row => <RowSelector row={row} tableData={tableData} setTableData={setTableData} />,
                    sortable: false,
                    right: false,
                    style: "padding:1px;margin:1px;",
                    width: "2%",
                },
                {
                    name: 'AccNo',
                    selector: row => row.AccountNumber,
                    sortable: true,
                    right: true,
                    width: "6%",
                },
                {
                    name: 'Start',
                    selector: row => row.AgreementStart,
                    sortable: true,
                    right: true,
                    width : "7%",
                },
                {
                    name: 'End',
                    selector: row => row.AgreementEnd,
                    sortable: true,
                    right: true,
                    width : "7%",
                },
                {
                    name: 'Drop',
                    selector: row => row.InactivationDt,
                    sortable: true,
                    right: true,
                    width : "7%",
                },
                {
                    name: 'FirstName',
                    selector: row => row.FirstName,
                    sortable: true,
                    right: true,
                    width: "6%",
                },
                {
                    name: 'LastName',
                    selector: row => row.LastName,
                    sortable: true,
                    right: true,
                    width: "6%",
                },
                {
                    name: 'PhoneNumber',
                    selector: row => row.PhoneNumber,
                    sortable: true,
                },
                {
                    name: 'Account/Location  Status',
                    selector: row => row.AccountStatus + "/" + row.LocationStatus,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    //width: "12%",
                    right: true,
                    grow: 2
                },
                {
                    name: 'Balance',
                    selector: row => row.AccountBalance,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "6%",
                    right: true,
                },
                {
                    name: 'Due',
                    selector: row => row.AmountDue,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "6%",
                    right: true,
                },
                {
                    name: 'Days',
                    selector: row => row.DaysPastDue,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "5%",
                    right: true,
                },
                {
                    name: 'Trn',
                    selector: row => row.TransactionCode,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "8%",
                    right: true,
                },
                {
                    name: 'TrnDate',
                    selector: row => row.TransactionDate,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "8%",
                    right: true,
                },
                {
                    name: 'UTMContent',
                    selector: row => row.UtmContent,
                    sortable: true,
                    style: "padding:1px;margin:1px;",
                    width: "8%",
                    right: true,
                }
            ]);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, []);
    const fetchDropped = async page => {
        setLoading(true);
        const response = await axios.get(`/internal/saves?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setDroppedData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        fetchDropped(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/saves?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setDroppedData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    return (
        <>
            {
                (loading || ! droppedData || droppedData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <div id="portal"></div>
                        {tableData?.length === 1 && isOpen.editDropped && (
                            <DroppedEditModal open={isOpen.editDropped}
                                             onClose={handleClose} tableData={tableData[0]}
                                             loadEnrollments={fetchDropped}
                            />
                        )}
                        <DataTable
                            title=""
                            columns={columns}
                            conditionalRowStyles={conditionalRowStyles}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="1000px"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                            paginationPerPage={perPage}
                            paginationDefaultPage={page}
                            paginationServer
                            paginationTotalRows={totalRows}
                            progressPending={loading}
                            striped
                            responsive
                            dense
                            subHeader
                            actions={actionsMemo}
                        >
                        </DataTable>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorAction}
                            open={openAction}
                            onClose={handleActionClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            style={{ margin: 0, padding: 0 }}
                        >
                            <MenuItem disabled={tableData?.length !== 1} onClick={handleEditDropped}>
                                <div className={classes.menuItem}>
                                    <EditOutlined color="primary" sx={{ mr: 1 }} />
                                    Edit
                                </div>
                            </MenuItem>
                            <style>{`
                            ul.MuiList-root.MuiList-padding {
                                padding: 0;
                            }
                        `}</style>
                        </Menu>
                    </>
                )
            }

        </>
    );
}
const useStyles = makeStyles(theme => ({
    actionButtons: {
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        color: "#FF5B6A",
    },
}))
