import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Table from "react-bootstrap/Table";
import BrokerAddModal from "../../components/modals/BrokerAddModal";
import { makeStyles } from "@mui/styles";
import { Divider, Menu, MenuItem } from "@mui/material";
import { EditOutlined, Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import RowSelector from "components/RowSelector";
import ActionBar from "components/ActionBar";
import BrokerEditModal from "components/modals/BrokerEditModal";
import { Box } from "@mui/system";
import DisableBrokerAPIModal from "components/modals/DisableBrokerAPIModal";
import downloadCSV from "utils/downloadCsv";
import ModalsForPricingGroup from "components/modals/ModalsForProductPricingGroup";
import ModalAllPricingGroup from "components/modals/ModalAllPricingGroup";

const openInit = {
    openPPModal: false,
    openPGModal: false,
    // deactivate: false
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    table: {
        style: {
            border: `1px solid grey`,
            borderRadius: 10,
            overflow: "hidden"
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#ffedee"
        }
    }
}

export const BrokerProductList = (props) => {
    const classes = useStyles();
    const [brokersData, setBrokersData] = useState([]);
    const [uniqueBrokerData, setUniqueBrokerData] = useState([]);
    const [uniquePricingData, setUniquePricingData] = useState([]);
    const [filteredBrokerData, setFilteredBrokerData] = useState([]);
    const [filteredPricingData, setFilteredPricingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([])
    const [isOpen, setIsOpen] = useState(openInit);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [anchorAction, setAnchorAction] = useState(null);
    const [pgAction, setPGAction] = useState(null);
    const filteredItems = brokersData.filter(
        item =>    ((item.broker_name && item.broker_name.toLowerCase().includes(filterText.toLowerCase())) ||
                        (item.sg_product_name && item.sg_product_name.toLowerCase().includes(filterText.toLowerCase())) ||
                        (item.pricing_group_name && item.pricing_group_name.toLowerCase().includes(filterText.toLowerCase())))
    );

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const handleExport = () =>{
        downloadCSV(brokersData, "brokers")
    }

    const handleOpenPPModal = () => setIsOpen({ ...openInit, openPPModal: true });
    const handleOpenPGModal = () =>  setIsOpen({ ...openInit, openPGModal: true });
    // const handleDeactiveBroker = () => {
    //     if (tableData.length === 1) {
    //         handleActionClose();
    //         setIsOpen({ ...openInit, deactivate: true })
    //     }
    // };
    const handleClose = () => {
        setIsOpen(openInit);
    };

    const exportOpsHeaders = [
        { label: 'Account', key: 'account_number' },
        { label: 'Signup Date', key: 'signup_date' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'ESIID', key: 'esiid' },
        { label: 'IsMove', key: 'moving_switching' },
        { label: 'Requested Date', key: 'requested_date' },
        { label: 'End Date', key: 'end_date' },
        { label: 'Plan Name', key: 'plan_name' },
        { label: 'Address', key: 'Address1' },
        { label: 'Status', key: 'SPStatus' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'IpAddress', key: 'EnrollmentIpAddress' },
        { label: 'Tdu', key: 'TduName' },
        { label: 'Promo code', key: 'promo_code' },
        { label: 'UTM_Source', key: 'utm_source' },
        { label: 'UTM_Content', key: 'utm_content' },
    ];

    const openAction = Boolean(anchorAction);
    const pgOpenAction = Boolean(pgAction);

    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };

    const handlePGClick = (event) => {
        setPGAction(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorAction(null);
        setPGAction(null)
    };

    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        e.target.focus();
    };

    const actionProps = {
        title: "Broker Product List",
        addTitle: "Add Broker",
        filterTitle: "Filter Broker, Product & Pricing Group",
        tableData,
        openAction,
        pgOpenAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportOpsHeaders,
        handleExportAction: handleExport,
        filterByBroker: handleActionClick,
        filterByPricingGroup: handlePGClick,
        pricingGroupMapping: handleOpenPPModal,
        viewPricingGroup:  handleOpenPGModal
    };

    const actionsMemo = React.useMemo(() =>
        <>
            <ActionBar actionProps={actionProps} />
        </>
        , [tableData, filterText, anchorAction, pgOpenAction, brokersData]);

    useEffect(() => {
        setColumns([
            {
                name: 'Broker ID',
                selector: row => row.broker_id,
                sortable: true,
                center: true
            },
            {
                name: 'Broker Name',
                selector: row => row.broker_name,
                sortable: true,
            },
            {
                name: 'UTM_Content',
                selector: row => row.converting_utm_content,
                sortable: true,
            },
            {
                name: 'SG Agent Id',
                selector: row => row.sg_agent_id,
                sortable: true,
                right: true,
            },
            {
                name: 'TFN Number',
                selector: row => row.tfn_number ? row.tfn_number : "-",
                sortable: true,
                center: true,
            },
            {
                name: 'SG Product ID',
                selector: row => row.sg_product_id,
                sortable: true,
                right: true
            },
            {
                name: 'SG Product Name',
                selector: row => row.sg_product_name,
                sortable: true,
            },
            {
                name: 'Charge ID',
                selector: row => row.charge_id,
                sortable: true,
            },
            {
                name: 'Pricing Group ID',
                selector: row => row.pricing_group_id,
                sortable: true,
            },
            {
                name: 'Pricing Group Name',
                selector: row => row.pricing_group_name,
                sortable: true,
            },
            {
                name: 'Pricing Group Description',
                selector: row => row.pricing_group_description,
                sortable: true,
                grow: 2
            },
        ]);
    }, [tableData])

    const filterByBrokerData = (broker) =>{
        handleActionClose()
        let data = brokersData;
        let newData = data.filter(item => (item.broker_name && item.broker_name.toLowerCase().includes(broker.toLowerCase())))
        setFilteredBrokerData(newData)
    }

    const filterByPricingData = (pricing) =>{
        handleActionClose()
        let data = brokersData;
        let newData = data.filter(item => (item.pricing_group_name && item.pricing_group_name.toLowerCase().includes(pricing.toLowerCase())))
        setFilteredPricingData(newData)
    }

    const loadEnrollments = async () => {
        let response = await axios.get('/internal/get/all/products/price_group',
            {
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                params: {
                    inactive: 1
                }
            }
        );
        let sorted = response.data.product_mapping;
        function compare(a, b) {
            if (Number(a.broker_api_enabled) < Number(b.broker_api_enabled)) {
                return 1;
            }
            if (Number(a.broker_api_enabled) > Number(b.broker_api_enabled)) {
                return -1;
            }
            return 0;
        }

        sorted.sort(compare);
        await setBrokersData(sorted);
        getUniqueValues(sorted)
    }

    const getUniqueValues = (data)=>{
        const unique = [...new Set(data.map(item => item.broker_name))];
        setUniqueBrokerData(unique)

        const uniquepg = [...new Set(data.map(item => item.pricing_group_name))];
        setUniquePricingData(uniquepg)
    }

    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                // console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || !brokersData || brokersData.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>
                    <div id="portal"></div>
                    <ModalsForPricingGroup open={isOpen.openPPModal} onClose={handleClose}></ModalsForPricingGroup>
                    <ModalAllPricingGroup open={isOpen.openPGModal} onClose={handleClose}></ModalAllPricingGroup>
                    <Box className={classes.tableContainer}>
                        <Box className={classes.table}>
                            <DataTable
                                columns={columns}
                                data={ filteredBrokerData.length!==0 ? filteredBrokerData : (filteredPricingData.length!==0 ? filteredPricingData : filteredItems)}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                fixedHeader
                                fixedHeaderScrollHeight="2000px"
                                pagination
                                paginationPerPage={30}
                                paginationComponentOptions={paginationComponentOptions}
                                striped
                                dense
                                subHeader
                                customStyles={customStyleForTable}
                                //subHeaderComponent={subHeaderComponentMemo}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </Box>
                    </Box>
                    {
                        uniqueBrokerData.length !== 0 &&
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorAction}
                                open={openAction}
                                onClose={handleActionClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                style={{ margin: 0, padding: 0, maxHeight: '300px' }}
                            >
                                <MenuItem onClick={()=>{setFilteredBrokerData([]); handleActionClose()}}>
                                    <div className={classes.menuItem}>
                                        CLEAR FILTER
                                    </div>
                                </MenuItem>
                            {
                                uniqueBrokerData.map((item)=>(
                                    <MenuItem onClick={()=>{filterByBrokerData(item)}}>
                                        <div className={classes.menuItem}>
                                            {item}
                                        </div>
                                    </MenuItem>
                                ))
                            }
                            <Divider style={{ margin: 0, padding: 0 }} />
                            <style>{`
                                ul.MuiList-root.MuiList-padding {
                                    padding: 0;
                                }
                            `}</style>
                            </Menu>
                    }
                    {
                        uniqueBrokerData.length !== 0 &&
                            <Menu
                                id="basic-menu"
                                anchorEl={pgAction}
                                open={pgOpenAction}
                                onClose={handleActionClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                style={{ margin: 0, padding: 0, maxHeight: '300px' }}
                            >
                                <MenuItem onClick={()=>{setFilteredPricingData([]); handleActionClose()}}>
                                    <div className={classes.menuItem}>
                                        CLEAR FILTER
                                    </div>
                                </MenuItem>
                            {
                                uniquePricingData.map((item)=>(
                                    <MenuItem onClick={()=>{filterByPricingData(item)}}>
                                        <div className={classes.menuItem}>
                                            {item}
                                        </div>
                                    </MenuItem>
                                ))
                            }
                            <Divider style={{ margin: 0, padding: 0 }} />
                            <style>{`
                                ul.MuiList-root.MuiList-padding {
                                    padding: 0;
                                }
                            `}</style>
                            </Menu>
                    }
                </>
                )
            }

        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    expandedTableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    table: {
        // border: "1px solid black"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    }
}))
