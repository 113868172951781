export const docUploadFormatter = (rawData) =>{
    // docCategory, docType, docFile
    if(Object.keys(rawData).length === 3){
        let docTypeArr = []
        let docFileArr = []
        Object.keys(rawData).map((keys)=>{
            docTypeArr.push(keys)
            docFileArr.push(rawData[keys])
        })
        return ["Aged 65+", docTypeArr, docFileArr]
    }else{
        let output = [];
        Object.keys(rawData).map((key)=>{
            switch(key){
                case "loc": output = ["Letter of Credit", ["Letter of Credit"], [rawData[key]]]; break;
                case "certlet": output = ["Victim of Family Violence",[ "Certification Letter"], [rawData[key]]]; break;
                case "cert": output = ["Medically Indigent",["Certification"], [rawData[key]]]; break;
                case "gid": output = ["Aged 65+", ["Government-issued ID"], [rawData[key]]]; break;
                case "mrb": output = ["Aged 65+",[ "Most Recent Bill"], [rawData[key]]]; break;
                case "pobp": output = ["Aged 65+", ["Proof Of Bill Payment"], [rawData[key]]]; break;
                default: output = ["Other", ["other"], [null]]; break;
            }
        })
        return output
    }
}