export const netPlanSourceFormatter = (data) =>{
    let currentData = data?.data;
    if(currentData){
        let result = [];
        currentData.map((item)=>{
            result.push(
                {
                    category_id: item?.rpt_sales_category_id || 0,
                    id: item.id,
                    name: item.rpt_sales_category.category_name,
                    jan: parseInt(item.jan),
                    feb: parseInt(item.feb),
                    mar: parseInt(item.mar),
                    apr: parseInt(item.apr),
                    may: parseInt(item.may),
                    jun: parseInt(item.jun),
                    jul: parseInt(item.jul),
                    aug: parseInt(item.aug),
                    sep: parseInt(item.sep),
                    oct: parseInt(item.oct),
                    nov: parseInt(item.nov),
                    dec: parseInt(item.dec),
                    total: parseInt(item.total) || 0
                }
            )
        })
        return result
    }
    else return []
}

export const responseParserNetPlan = (data, year) =>{
    console.log('Data in data ==>', data);
    let result = {
        "data":[
            {
                "category":data[0]?.category_id,
                "year":year,
                "month" : {
                    "jan":data[0]?.jan,
                    "feb": data[0]?.feb,
                    "mar": data[0]?.mar,
                    "apr": data[0]?.apr,
                    "may": data[0]?.may,
                    "jun": data[0]?.jun,
                    "jul": data[0]?.jul,
                    "aug": data[0]?.aug,
                    "sep": data[0]?.sep,
                    "oct": data[0]?.oct,
                    "nov": data[0]?.nov,
                    "dec": data[0]?.dec
                },
                "total": data[0]?.total
            },
            {
                "category":data[1]?.category_id,
                "year":year,
                "month" : {
                    "jan":data[1]?.jan,
                    "feb": data[1]?.feb,
                    "mar": data[1]?.mar,
                    "apr": data[1]?.apr,
                    "may": data[1]?.may,
                    "jun": data[1]?.jun,
                    "jul": data[1]?.jul,
                    "aug": data[1]?.aug,
                    "sep": data[1]?.sep,
                    "oct": data[1]?.oct,
                    "nov": data[1]?.nov,
                    "dec": data[1]?.dec
                },
                "total": data[1]?.total
            },
            {
                "category":data[2]?.category_id,
                "year":year,
                "month" : {
                    "jan":data[2]?.jan,
                    "feb": data[2]?.feb,
                    "mar": data[2]?.mar,
                    "apr": data[2]?.apr,
                    "may": data[2]?.may,
                    "jun": data[2]?.jun,
                    "jul": data[2]?.jul,
                    "aug": data[2]?.aug,
                    "sep": data[2]?.sep,
                    "oct": data[2]?.oct,
                    "nov": data[2]?.nov,
                    "dec": data[2]?.dec
                },
                "total": data[2]?.total
            },
            {
                "category":data[3]?.category_id,
                "year":year,
                "month" : {
                    "jan":data[3]?.jan,
                    "feb": data[3]?.feb,
                    "mar": data[3]?.mar,
                    "apr": data[3]?.apr,
                    "may": data[3]?.may,
                    "jun": data[3]?.jun,
                    "jul": data[3]?.jul,
                    "aug": data[3]?.aug,
                    "sep": data[3]?.sep,
                    "oct": data[3]?.oct,
                    "nov": data[3]?.nov,
                    "dec": data[3]?.dec
                },
                "total": data[3]?.total
            }
        ]
    }
    return result
}