import axios from "../../utils/axios";
import React, {useEffect, useState} from "react";
import {Bubble} from 'react-chartjs-2';
import "./TicketsReview.css";
import {Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js';
import TicketsModal from "../../components/modals/TicketsModal";
import RowSelector from "../../components/RowSelector";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Title);
export var options = {
    layout : {
        autoPadding: false,
        padding: 50
    },
    plugins: {
        legend: {
            display: true,
        },
        title: {
            display: true,
            text: 'LOE vs BIZ IMPACT'
        },
        tooltip: {
            callbacks: {
                label: function(t) {
                    return t.dataset.label + ':' + t.raw.label ;
                }
            }
        }
    },
    scales: {
        x: {
            title: {
              display: true,
              text: 'LOE'
            },
            ticks: {
                stepSize: 1
            },
            stack: true,
            beginAtZero: true
        },
        y: {
            title: {
                display: true,
                text: 'BIZ IMPACT'
            },
            ticks: {
                stepSize: 1
            },
            stack: true,
            beginAtZero: true
        },
    }
};
export var data = {
    labels : [],
    datasets: []
};
const openInit = {
    tickets: false
}
export const TicketsReview =  (props) => {
    const {filterId, setFilterId} = useState(props.filterId);
    const [isOpen, setIsOpen] = useState(openInit);
    const [loading, setLoading] = useState(true);
    const [ticketsColumns, setTicketsColumns] = useState([]);
    const [ticketsData, setTicketsData] = useState([]);
    const [ticketsList, setTicketsList] = useState([]);
    const filters = {
        10040: "EH Review",
        10046: "QuickWins & Cleanup"
    };
    const fetchTickets = async (filterId) => {
        setLoading(true);
        const response = await axios.get(`/internal/jira/filter/${filterId}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setTicketsData(response.data.data);
        setLoading(false);
    };
    const loadTickets = async (filterId) => {
        setLoading(true);
        const response = await axios.get(`/internal/jira/filter/${filterId}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setTicketsData(response.data.data);
        setLoading(false);
    }
    const handleClose = () => {
        setIsOpen(openInit);
    };
    const setOrAdd = (dArr, dObj) => {
        if (dArr.length ===0) {
            dArr.push(dObj)
        } else {
            let found = false;
            dArr.forEach((v)=>{
               if (v.x===dObj.x && v.y===dObj.y ) {
                   v.r = v.r + 3;
                   v.label = v.label +',' +dObj.label;
                   found=true;
               }
            });
            if (! found) {
                dArr.push(dObj)
            }
        }
    }
    const ticketsClear = () => {
        setTicketsList([]);
    }
    const getTickets = async (list) => {
        const response = await axios.get(`/internal/jira/list/?list="${list}"`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setTicketsList(response.data.data);
        await setTicketsColumns([
            {
                name: 'Tk',
                selector: row => <a target={"_blank"} href={'https://goodcharlie.atlassian.net/issues/?jql=key%20in%20(%22' + row.key + '%22)'}> {row.key}  </a>,
                sortable: true,
                right: false,
                width: "11%",
            },
            {
                name: 'ST',
                selector: row => row.fields.status.name,
                sortable: true,
                right: true,
                width: "10%",
            },
            {
                name: 'Cat',
                selector: row => row.fields.customfield_10056?.value,
                sortable: true,
                right: true,
                width: "11%",
            },
            {
                name: 'Desc',
                selector: row => row.fields.summary,
                sortable: true,
                right: true,
                width: "50%",
            },
            {
                name: 'BI',
                selector: row => row.fields.customfield_10059?.value,
                sortable: true,
                right: true,
                width: "9%",
            },
            {
                name: 'LOE',
                selector: row => row.fields.customfield_10060?.value,
                sortable: true,
                right: true,
                width: "9%",
            },
        ]);
        console.log(list);
    }
    useEffect(() => {
        const init = async () => {
            await fetchTickets(filterId ?? props?.filterId);
        }
        init().then(
            () => {
                console.log('loaded');
            }
        );
    }, []);
    if (ticketsData && ticketsData.length > 0) {
        options.plugins.title.text = filters[filterId ?? props.filterId] + ' LOE vs Biz Impact';
        let dd0 = [];
        let dd1 = [];
        let dd2 = [];
        let dd3 = [];
        let dd9 = [];
        let dd10 = [];

        ticketsData.forEach((v) => {
            const dd = {
                x: v.fields.customfield_10060 ? Number(v.fields.customfield_10060.value) : 0,
                y: v.fields.customfield_10059 ? Number(v.fields.customfield_10059.value) : 0,
                r: 3,
                label:v.key
            }

                switch (dd.y) {
                    case 0:
                        if (dd.x > 0) {
                            if (v.fields.status.id !== "3") {
                                setOrAdd(dd0, dd);
                            } else {
                                setOrAdd(dd0, dd);
                            }
                        } else {
                            setOrAdd(dd3,dd);
                        }
                        break;
                    case 1:
                        if (dd.x > 2) {
                            if (v.fields.status.id !== "3") {
                                setOrAdd(dd0, dd);
                            } else {
                                setOrAdd(dd0, dd);
                            }
                        } else {
                            setOrAdd(dd1,dd);
                        }
                        break;
                    case 2:
                        if (dd.x > 3) {
                            if (v.fields.status.id !== "3") {
                                setOrAdd(dd0, dd);
                            } else {
                                setOrAdd(dd0, dd);
                            }
                        } else {
                            setOrAdd(dd1,dd);
                        }
                        break;
                    case 3:
                        if (dd.x > 4) {
                            if (v.fields.status.id !== "3") {
                                setOrAdd(dd0, dd);
                            } else {
                                setOrAdd(dd0, dd);
                            }
                        } else {
                            if (v.fields.status.id !== "3") {
                                setOrAdd(dd1, dd);
                            } else {
                                setOrAdd(dd1, dd);
                            }
                        }
                        break;
                    case 4:
                        if (dd.x > 4) {
                            setOrAdd(dd0,dd);
                        } else if (dd.x > 2){
                            setOrAdd(dd1,dd);
                        } else {
                            setOrAdd(dd2,dd);
                        }
                        break;
                    case 5:
                        if (dd.x > 4) {
                            setOrAdd(dd1,dd);
                        } else if (dd.x > 2){
                            setOrAdd(dd1,dd);
                        } else {
                            setOrAdd(dd2,dd);
                        }
                        break;
                    default:
                        setOrAdd(dd3,dd);
                        //dd3.push(dd);
                }
        })
        const colors = [
            'rgba(188, 155, 078, 1)',
            'rgba(219, 243, 132, 1)',
            'rgba(143, 243, 132, 1)',
            'rgba(109, 099, 255, 1)',
            'rgba(248, 005, 056, 1)',
            'rgba(248, 114, 005, 1)'
        ];
        const dss = [
            dd0, dd1, dd2, dd3, dd9, dd10
        ];
        const labels = [
            'Don\'t do','Ask','Do','Pending','Why?','Why?'
        ];
        for (let index=0; index< 6; index++) {
            data.datasets[index] =
                {
                    pointStyle: 'circle',
                    label: labels[index],
                    data: dss[index],
                    backgroundColor: colors[index],
                    borderColor: colors[index],
                    borderWidth: 3,
                    order: - index
                }
            ;
        }
        options.onClick = async (e, i) => {
            if (! i[0]) {
                return;
            }
            getTickets(i[0].element.$context.raw.label)
                .then(()=>{
                    setIsOpen({ ...openInit, tickets: true })
                });
            //console.log(i[0].element.$context.raw.label);
        }
    }
    return (
        <> {
            (loading || ! ticketsData || ticketsData.length <=0 ) ? (
                <>
                    loading
                </>
            ):(
                <>
                    <div id="portal"></div>
                    <Bubble options={options} data={data}/>
                    <TicketsModal
                        open={isOpen.tickets}
                        onClose={handleClose}
                        tkCols={ticketsColumns}
                        tkData={ticketsList}
                        tkClear={ticketsClear}
                    ></TicketsModal>
                </>
            )
        }
        </>
    );
}