import { Box, Select, Typography } from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useState } from "react";
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import enrollData from "../../dummydata/enrollData.json"
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from 'react-router-dom';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import axios from "../../utils/axios";
import { docUploadFormatter } from "utils/formatter";
import { useSnackbar } from "notistack";
import moment from "moment";

const dropzone = {
    opacity: 0,
    position: 'absolute',
    height: 100,
    width: "95%",
    cursor: 'pointer'
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
    table: {
        style: {
            borderRadius: 10,
            overflow: "hidden",
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#FF7676",
            color:"white"
        }
    },
    rows:{
        style:{
            '&:nth-child(odd)': {
                backgroundColor: '#FFF1F1', // Set the desired color for striped rows
              },
        }
    }
}

const paginationComponentOptions = {
    rowsPerPageText: 'Rows / Page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
};

const docTypes = {
    "loc": "Letter of Credit | Letter of Credit",
    "gid": "Aged 65+ | Government-issued ID",
    "mrb": "Aged 65+ | Most recent bill",
    "pobp": "Aged 65+ | Proof of bill payment",
    "certlet": "Victim of Family Violence | Certification Letter",
    "cert": "Medically Indigent | Certification"
}

const docCategories = ["Letter of Credit", "Aged 65+", "Victim of Family Violence", "Medically Indigent"]

function trimString(string) {
    if (string.length > 30) {
      var trimmedString = string.substring(0, 30) + '...';
      return trimmedString;
    } else {
      return string;
    }
}
  

const EnrollmentDocuments = () =>{
    const classes = useStyles();
    const [files, setFiles] = useState({})
    const [fileTypes, setFileTypes] = useState([])
    const [columns, setColumns] = useState([])
    const [documentsData, setDocumentsData] = useState([])
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleFileChange = (e) =>{
        let current = e.target.files;
        let tempFileTypes = []
        for(let i=0; i<current.length; i++){
            tempFileTypes.push("loc");
        }
        setFileTypes(tempFileTypes)
        setFiles(current)
    }

    const handleFileSubmit = async() =>{
        let formattedData = docUploadFormatter({[fileTypes[0]]: files[0]})
        let apiRes = await uploadDocuments(formattedData[0], formattedData[1], formattedData[2])
        if(apiRes?.status === 201){
            setFiles({})
            setFileTypes([])
            getDocuments();
            enqueueSnackbar("Document uploaded successfully", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'success'
            });
        }else{
            enqueueSnackbar("Failed to upload document", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }


    //HARD CODED DATA FOR NOW
    const uploadDocuments = async (docCategory, docType, docFile) => {

        const formdata = new FormData();
        formdata.append("doc_category", docCategory);
        // formdata.append("prospect_id", 1);
        // formdata.append("user_id", 62);
        // formdata.append("prospect_uuid", "5fd019e6-b90b-4455-8dc3-3fb7068cf8c2");
        formdata.append("prospect_id", location?.state?.row?.Prospect_id);
        formdata.append("user_id", location?.state?.row?.User_id);
        formdata.append("prospect_uuid", location?.state?.row?.Uuid);
        
        // if(docCategory=="Aged 65+"){
        //     formdata.append("doc_type[]", [docType[0], docType[1], docType[2]]);
        //     formdata.append("doc_file_1", docFile[0]);
        //     formdata.append("doc_file_2", docFile[1]);
        //     formdata.append("doc_file_3", docFile[2]);
        // }else{
            formdata.append("doc_type[]", [docType[0]]);
            formdata.append("doc_file_1", docFile[0]);
        // }

        try{
            const response = await axios.post('/internal/enrollment-document', formdata, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            return response;
        }catch(e){
            console.log(e);
            return null;
        }

        // const response = await fetch(url, {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: formdata
        // });

    }

    const handleDocTypeChange = (e, index) =>{
        let type = e.target.value;
        let tempFileTypes = [...fileTypes]
        tempFileTypes[index] = type;
        setFileTypes(tempFileTypes)
    }

    const getDocuments = async () => {
        try {
            let response = await axios.get(`/internal/enrollment-document`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
                params:{
                    uuid:location?.state?.row?.Uuid
                }
            });
            setDocumentsData(response?.data?.enrollmentDocuments)
        } catch (err) {
            console.error(err);
            return err;
        }
        return 0;
    };

    useEffect(() => {
        (async()=>{await getDocuments()})()
        setColumns([
            {
                name: 'Document Name',
                selector: row =><div style={{whiteSpace:"normal"}}> {row.document_name} </div>,
                left: true,
                width:"20%"
            },
            {
                name: 'Document Type',
                selector: row => row.document_type,
                left: true
            },
            {
                name: 'Document Category',
                selector: row => row.document_category,
                left: true
            },
            {
                name: 'Uploaded On',
                selector: row => row.updated_at? moment(new Date(row.updated_at)).format("YYYY-MM-DD") : "",
                sortable: true,
                center: true,
            },
            {
                name: 'Uploaded By',
                selector: row => row.submitted_by,
                sortable: true,
                center: true,
            },
            {
                name: 'View Document',
                selector: row =><a href={row.document_path} target="_blank" style={{color:"#212529",cursor:"pointer"}}><InsertDriveFileIcon style={{cursor: "pointer"}}/></a>,
                center: true
            }
        ])
    },[])

    return <Box style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box style={{width: "90%", display: "flex", justifyContent: "left", marginLeft: "2%", cursor: "pointer"}} onClick={()=>navigate(-1)}>
            <KeyboardDoubleArrowLeftOutlinedIcon /> Back
        </Box>
        <Typography fontSize={24} fontWeight="bold">Documents</Typography>
        <br/>
        
        <Box style={{ width: "95%", border: "2px solid #ff5b6a", borderRadius: "10px", padding: "20px"}}>
            <Box sx={{display:"flex", flexDirection: "column", padding:"8px 10px", alignItems: "flex-start"}}>
                <Typography fontWeight={"bold"} fontSize="20px">Upload Documents</Typography>
                <br/>
                <input type="file" accept=".pdf" onChange={ e => handleFileChange(e)} style={dropzone}/>
                <Box style={{display: "flex", alignItems: "center"}}>
                    <FileCopyIcon height={100} width={100}/>&nbsp;&nbsp;
                    <Typography fontWeight={"normal"} fontSize="15px">Upload documents in pdf format. Maximum file size (25 MB)</Typography>
                </Box>
                <br />
                {Object.values(files).length !== 0 && 
                    <Box style={{width: "100%"}}>
                        <Box style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            <Typography fontWeight={"bold"} fontSize="15px">Files to be uploaded:</Typography>
                            <br />
                            {
                                Object.values(files)?.map((item, index)=>{
                                    return(
                                        <Box className={classes.containerCard}>
                                            <Typography>{trimString(item.name)}</Typography>
                                            <FormControl 
                                                style={{
                                                    height: "40px"
                                                }}
                                            >
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={fileTypes[index]}
                                                    style={{
                                                        height: "100%",
                                                        width: "80%"
                                                    }}
                                                    onChange={(e)=>handleDocTypeChange(e, index)}
                                                >
                                                    {
                                                        Object.keys(docTypes)?.map(key=><MenuItem value={key}>{docTypes[key]}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        <Box style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                            <Box style={{display: "flex", justifyContent: "flex-end"}}>
                                <ThemedButton contained={false} style={{width:"45%"}} onClick={()=>{setFiles({}); setFileTypes([])}}>Clear</ThemedButton>&nbsp;&nbsp;
                                <ThemedButton contained={true}  style={{width:"45%"}}onClick={handleFileSubmit}>Upload</ThemedButton>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
        <Box className={classes.ownerOfDoc}>
            <Typography fontWeight={"bold"} fontSize="16px">Documents uploaded by {location?.state?.row?.customerName}:</Typography>
        </Box>
        <Box className={classes.tableContainer}>
            <Box className={classes.table}>
                <DataTable
                    columns={columns}
                    data={documentsData}
                    defaultSortAsc={false}
                    defaultSortFieldId={1}
                    fixedHeader
                    fixedHeaderScrollHeight="1000px"
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={paginationComponentOptions}
                    striped
                    dense
                    // subHeader
                    // subHeaderComponent={getSubHeaderComponent}
                    customStyles={customStyleForTable}
                    //subHeaderComponent={subHeaderComponentMemo}
                >
                </DataTable>
            </Box>
        </Box>
        <style>
            {`
            ::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            ::-webkit-scrollbar-thumb {
                border-radius: 3px;
            }
            `}
        </style>
    </Box> 
}

export default EnrollmentDocuments;

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
        width: "98.5%",
        // [theme.breakpoints.down("lg")]: {
        //     padding: 0
        // },
    },
    ownerOfDoc: {
        display: "flex",
        marginTop: "20px",
        justifyContent: "flex-start",
        width: "95%",
        marginBottom: "-10px"
    },
    containerCard:{
        padding: "10px 10px",
        border: "1px solid #ff5a6a",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
        borderRadius: "10px",
        alignItems: "center",
         [theme.breakpoints.down("lg")]: {
            width:"100%"
        },
    }
}));