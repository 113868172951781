import React from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";

import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px 50px',
    borderRadius: '5px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const PromoCodeAddModal = ({ open, onClose }) => {
    const console ={
        log: (a) => {
            if ((process.env.NODE_ENV ?? '') === 'development' ) {
                //window.console.log(a);
            }
        }
    };

    const commonClasses = useCommonStyles();

    const [formValue, setFormValue] = React.useState({
        promocode: '',
        start_date: '',
        end_date: '',
        description: '',
        converting_utm_content: '',
        send_to_sg: 0
    });
    const handleCancel = async (e) => {
        setFormValue({
            promocode: '',
            start_date: '',
            end_date: '',
            description: '',
            converting_utm_content: '',
            send_to_sg: 0
        })
        onClose();
    }
    const handleSubmit = async (e) => {
        let modFormValue = { ...formValue};
        try {
            const response = await axios.post('/internal/promocodes', modFormValue, {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            }).then(() => {
                onClose();
            });
        } catch(error) {
            console.log(error)
        }
    }

    const handleChange = (event) => {
        if(event.target.name == "send_to_sg"){
            setFormValue({
                ...formValue,
                send_to_sg: event.target.checked ? 1 : 0,
            })
        }
        else{
            setFormValue({
                ...formValue,
                [event.target.name]: event.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Add and save your promo code here.
                        </Typography>

                        <MuiText
                            label="Promo Code "
                            name="promocode"
                            defaultValue={formValue.promocode}
                            onChange={handleChange}
                            key={"PromoCode"}
                        />

                        <MuiDateTime
                            label="Start Date"

                            name="start_date"

                            formValue={formValue}

                            value={formValue.start_date}
                            setFormValue={setFormValue}
                            onChange={handleChange}
                            key={"StartDate"}
                        />

                        <MuiDateTime
                            label="End Date"

                            name="end_date"

                            formValue={formValue}

                            value={formValue.end_date}
                            setFormValue={setFormValue}
                            onChange={handleChange}
                            key={"EndDate"}
                        />

                        <MuiText
                            multiline={true}
                            row={3}
                            label="Description"
                            name="description"
                            defaultValue={formValue.description}
                            onChange={handleChange}
                        />

                        <MuiText
                            label="UTM Content"
                            name="converting_utm_content"
                            value={formValue.converting_utm_content}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            onChange={handleChange}
                        />

                        <Switch
                            label="Send to SG (SmartGrid)"
                            type="checkbox"
                            name="send_to_sg"
                            value={formValue.send_to_sg}
                            defaultChecked={formValue.send_to_sg}
                            onChange={handleChange}
                        />
                        <br />

                        <InputRow>
                            <ThemedButton
                                outlined={'true'}
                                onClick={handleCancel}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={'true'}
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            } 
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default PromoCodeAddModal;

