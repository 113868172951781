import { Box, TextField } from '@mui/material'
import { DateRangePicker } from '@mui/lab'
import { useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

export const MuiDateRangePicker = ({label, date, setDate, currentDate}) => {
  return (
    <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label={label}
                value={dayjs(currentDate)}
                disableFuture={true}
                defaultValue={dayjs(currentDate)}
                onChange={(newValue) => {
                    label==="Start Date" ? setDate({...date, start: newValue["$d"]}) : setDate({...date, end: newValue["$d"]})
                }}
            />
        </LocalizationProvider>
        <style>{`
        .css-1lqsjo3-MuiInputBase-root-MuiOutlinedInput-root{
            height: 46px
        }
        `}</style>
    </Box>
  )
}