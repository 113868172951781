import React,{ useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {Col, Row} from "react-bootstrap";

export const BrokerApiDocs=  (props) => {
    const APILocation = 'https://dev-signup.goodcharlie.com/brokers/docs/';
    const [status, setStatus] = useState({copySuccess: ''});
    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(APILocation);

        e.target.focus();
        setStatus({ copySuccess: 'Copied!' });
    };

    return (
        <>
            <h1>GoodCharlie Broker API Documentation</h1>
            <div className="text-center">
                <Row className="align-content-md-center">
                    <Col xs={4} className="mb-4 d-none d-sm-block">
                        &nbsp;
                    </Col>
                    <Col xs={8} className="mb-4 d-none d-sm-block">
                        <div className="main-content text-left">
                            <h2>Getting Started</h2>
                            <p>To begin using GoodCharlie's REST API, follow the steps below.</p>
                            <ul>
                                <li>Obtain a developer API Key</li>
                                <li>Read this documentation</li>
                                <li>Develop your application</li>
                                <li>Test your application</li>
                            </ul>
                            <h2>Enrollment Flow</h2>
                            <p>GoodCharlie's enrollment flow.</p>
                            <ul>
                                <li>Get ZipCode from customer</li>
                                <li>call method <b>/zip</b></li>
                                <li>Get TDU Name from <b>/zip</b> response</li>
                                <li>call method <b>/products</b> with TDU Name</li>
                                <li>show product details including Rate and EFL to customer<br/>
                                    from getProducts response</li>
                                <li>Get Service Address from customer</li>
                                <li>call method <b>/address/lookup</b> to obtain
                                    customer address' ESIID
                                </li>
                                <li>call method <b>/address/validate</b> with ESIID
                                </li>
                                <li>call method <b>/lists</b> to obtain Pet Meta-Data
                                    information
                                </li>
                                <li>Get Customer information</li>
                                <li>call method <b>/account</b> with
                                    Customer Information
                                </li>
                                <li>show Deposit requirements to customer or<br/>
                                    show Account Number to Customer from createAccountEnrollment response
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="align-content-md-center">
                    <Col xs={12} className="mb-4 d-none d-sm-block">
                        <a href={APILocation}>Swagger API Documentation</a> <span onClick={copyToClipboard}>Copy to clipboard</span><br/>
                        <span> {status.copySuccess}</span>
                        <span>{APILocation}</span>
                    </Col>
                </Row>
            </div>
        </>
    );
}
