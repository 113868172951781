import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import NetPlanModal from './NetPlanModal';
import { useEffect } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
const mappingDataMonth=["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec", "total"]

export default function MonthlySalesTable({showHeader, showLabels, tableData, setTargetData=null, tableDataColSum=null, label, currentYearFilter, showColorBg, editModal, tableName, pageRefresh=null, setPageRefresh=null}) {
    const [totalDataRow, setTotalDataRow] = React.useState(Array(13).fill("-"))
    const [openNetPlanModal, isOpenNetPlanModal] = React.useState(false)

    useEffect(()=>{
        if(tableDataColSum){
            let temp = Object.values(tableDataColSum)
            let zeros = temp.length <= 13 ? Array(13-temp.length).fill("-") : Array(13).fill("-")
            let final = [...temp, ...zeros]
            let sum = 0;
            for (var i = 0; i < final.length; i++) {
                if(typeof final[i] === "number"){
                    sum += final[i]
                }            
            }
            // final.push(sum)
            setTotalDataRow(final)
        }
    },[tableDataColSum, currentYearFilter])

    return (
        <>
            {editModal && <NetPlanModal
                open={openNetPlanModal}
                handleOpen={isOpenNetPlanModal}
                year={currentYearFilter}
                targetData={tableData}
                setTargetData={setTargetData}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
            />}
            {
                tableName === "target" ?
                <TableContainer component={Paper} style={{width: "80%"}}>
                    <Table size='small'>
                        <TableHead>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center"}} >{label}&nbsp;&nbsp;<EditIcon fontSize="small" style={{cursor: "pointer"}} onClick={()=>isOpenNetPlanModal(true)}/></StyledTableCell>
                                {
                                    months.map(item=><StyledTableCell style={{fontWeight: "bold"}}>{item}</StyledTableCell>)
                                }
                                <StyledTableCell style={{fontWeight: "bold"}}>TOTAL</StyledTableCell>
                            </TableRow>
                        {tableData.map(row => {
                            return(
                            <TableRow key={row.id}>
                                <TableCell>
                                    {
                                        row.name
                                    }
                                </TableCell>
                                {mappingDataMonth.map((item)=><TableCell>
                                    {
                                        row[item] || "-"
                                    }
                                </TableCell>)}
                            </TableRow>
                        )})}
                            <TableRow>
                                <StyledTableCell style={{fontWeight: "bold"}} >TOTAL</StyledTableCell>
                                {
                                    totalDataRow.map((x, id)=>{
                                        return(
                                            <StyledTableCell align="left">{typeof x === "number" ? x : "-"}</StyledTableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <TableContainer component={Paper} style={{width: "80%"}}>
                    <Table size='small' aria-label="monthly sales table">
                        {showHeader && <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={14} style={{fontWeight: "bold"}}>MONTHLY SALES</StyledTableCell>
                            </TableRow>
                        </TableHead>}
                        <TableBody>

                            {/* HEADING ROW */}
                            {showLabels && <TableRow>
                                <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center", width: "188px"}} >{label}</StyledTableCell>
                                {
                                    months.map(item=><StyledTableCell style={{fontWeight: "bold", width: "120px"}}>{item}</StyledTableCell>)
                                }
                                <StyledTableCell style={{fontWeight: "bold", width: "140px"}}>TOTAL</StyledTableCell>
                            </TableRow>}
                            
                            {/* DATA ROW */}
                            {Object.values(tableData).map((mainItem, index) => {
                                return mainItem && Object.values(mainItem).map((item, index) => {
                                    let blanks = [];
                                    for (let i = 0; i < 13-item.length; i++) {
                                        blanks.push(0);
                                    }
                                    return(
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row">
                                                {Object.keys(mainItem)[index]}
                                            </StyledTableCell>
                                            {
                                                item.map((x, id)=>{
                                                    return(
                                                        x[tableName] === 0 ? 
                                                        <StyledTableCell align="center">-</StyledTableCell> : (
                                                            (showColorBg && typeof x[tableName] === "number") ? (
                                                                label !== "GROSS TO NET %" ?
                                                                <StyledTableCell align="center" style={{backgroundColor: x[tableName] < 50 ? "#fec7ce" : ((x[tableName] >= 50 && x[tableName] < 80)  ? "#fdeb97" : "#c7efca"), color: x[tableName] < 50 ? "#c86570" : ((x[tableName] >= 50 && x[tableName] < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{parseFloat(x[tableName]).toFixed(0)}%</StyledTableCell> :
                                                                <StyledTableCell align="center" style={{backgroundColor: x[tableName] < 75 ? "#fec7ce" : ((x[tableName] >= 75 && x[tableName] < 90)  ? "#fdeb97" : "#c7efca"), color: x[tableName] < 75 ? "#c86570" : ((x[tableName] >= 75 && x[tableName] < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{parseFloat(x[tableName]).toFixed(0)}%</StyledTableCell>
                                                            ) :
                                                            <StyledTableCell align="center">{typeof x[tableName] === "number" ? x[tableName] : "-"}</StyledTableCell>
                                                        )
                                                    )
                                                })
                                            }
                                            {
                                                blanks.map((x,id)=>{
                                                    return(
                                                        id+1!==blanks.length ? 
                                                        <StyledTableCell align="center">-</StyledTableCell> :
                                                        (
                                                            (item[item.length-1][`percentTotal`]?.toFixed(0) == 0 || item[item.length-1][`percentTotal`]?.toFixed(0) === undefined) ?
                                                            // false ?
                                                            <StyledTableCell align="center" >{parseInt(item[item.length-1][`${tableName}_total`]) || parseInt(item[item.length-1][`${tableName}`]?.toFixed(0)) || "-"}</StyledTableCell> :
                                                            (
                                                                label !== "GROSS TO NET %" && showColorBg ?
                                                                <StyledTableCell align="center" style={{backgroundColor: (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 50 ? "#fec7ce" : (((item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 80)  ? "#fdeb97" : "#c7efca"), color: (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 50 ? "#c86570" : (((item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold"}}>{item[item.length-1][`${tableName}_total`] || item[item.length-1][`${"percentTotal"}`]?.toFixed(0) || "-"}%</StyledTableCell> :
                                                                (
                                                                    showColorBg ?
                                                                    <StyledTableCell align="center" style={{backgroundColor: (item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) < 75 ? "#fec7ce" : (((item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) >= 75 && (item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) < 90)  ? "#fdeb97" : "#c7efca"), color: (item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) < 75 ? "#c86570" : (((item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) >= 75 && (item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]) < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold"}}>{item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]?.toFixed(0) || "-"}%</StyledTableCell> :
                                                                    <StyledTableCell align="center" >{parseInt(item[item.length-1][`${tableName}_total`]) || parseInt(item[item.length-1][`${tableName}`]?.toFixed(0)) || "-"}</StyledTableCell>
                                                                )
                                                            )
                                                        )
                                                    )
                                                })
                                            }
                                        </StyledTableRow>
                                    )
                                })
                            })}

                            {/* TOTAL ROW */}
                            <TableRow>
                                <StyledTableCell style={{fontWeight: "bold"}} >TOTAL</StyledTableCell>
                                {
                                    totalDataRow.map((x, id)=>{
                                        return(
                                            parseFloat(x).toFixed(0) == 0 ? <StyledTableCell align="center">-</StyledTableCell> : (
                                                (showColorBg && typeof x === "number") ? (
                                                    label !== "GROSS TO NET %" ?
                                                    <StyledTableCell align="center" style={{backgroundColor: x < 50 ? "#fec7ce" : ((x >= 50 && x < 80)  ? "#fdeb97" : "#c7efca"), color: x < 50 ? "#c86570" : ((x >= 50 && x < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold"}}>{parseFloat(x).toFixed(0)}%</StyledTableCell> :
                                                    <StyledTableCell align="center" style={{backgroundColor: x < 75 ? "#fec7ce" : ((x >= 75 && x < 90)  ? "#fdeb97" : "#c7efca"), color: x < 75 ? "#c86570" : ((x >= 75 && x < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold"}}>{parseFloat(x).toFixed(0)}%</StyledTableCell>
                                                ) :
                                                <StyledTableCell align="center">{typeof x === "number" ? x : "-"}</StyledTableCell>
                                            )
                                        )
                                    })
                                }
                            </TableRow>

                        </TableBody>
                    </Table>
                    <style>
                        {`
                        ::-webkit-scrollbar {
                            width: 6px;
                            height: 6px;
                        }

                        ::-webkit-scrollbar-thumb {
                            border-radius: 3px;
                        }
                        `}
                    </style>
                </TableContainer>
            }
        </>
    );
}
