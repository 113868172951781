import React, { useState, useEffect, useMemo } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Table from "react-bootstrap/Table";
import BrokerAddModal from "../../components/modals/BrokerAddModal";
import { makeStyles } from "@mui/styles";
import { Checkbox, Divider, Menu, MenuItem } from "@mui/material";
import { EditOutlined, Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RowSelector from "components/RowSelector";
import ActionBar from "components/ActionBar";
import BrokerEditModal from "components/modals/BrokerEditModal";
import { Box } from "@mui/system";
import DisableBrokerAPIModal from "components/modals/DisableBrokerAPIModal";
import downloadCSV from "utils/downloadCsv";
import BrokerPortalUsersModal from "components/modals/BrokerPortalUsersModal";
import ComstrModal from "components/modals/ComstrModal";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import enrollData from "../../dummydata/enrollData.json"
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';import Select from '@mui/material/Select';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';



import {getDateForThisMonth, getDateForLastMonth, getDateForYTD, getDateForLastYear, timeRemover, getDateForAll, getDateForToday, getDateForYesterday} from "../../utils/dateFilter";
import { MuiDateRangePicker } from "components/MuiDateRangePicker";
import { ThemedButton, ThemedButton2 } from "components/InputFields";
import { CheckBox } from "components/StyledInputFields";

const TextField = styled.input`
        height: 45px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 2px solid #FF7676;
        font-size: 16px;
        font-color: #FF7676;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: none;
            border-color: #FF7676; /* Change the border color when focused */
            box-shadow: 0 0 2px #FF7676; /* Apply a box shadow when focused */
        }
    `;

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
            padding:"0px 4px 0px 4px"
        },
    },
    // cell:{
    //     style:{
    //                 padding:"0px 4px 0px 4px"
    //     }
    //     },
    table: {
        style: {
            borderRadius: 10,
            overflow: "hidden",
        }
    },
    headRow: {
        style: {
            padding: 5,
            backgroundColor: "#FF7676",
            color:"white"
        }
    },
    rows:{
        style:{
            '&:nth-child(odd)': {
                backgroundColor: '#FFF1F1', // Set the desired color for striped rows
              },
        }
    },
    cells: {
        style: {
          padding:"0px 8px 0px 8px", // Adjust the padding as needed
        },
      },
}

let downloadData = []

export const EnrollmentPortal = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tableLoad,setTableLoad] = useState(true)
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [filterList, setFilterList] = useState(["a", "b", "c"]);
    const navigate = useNavigate();
    const [dateFilter, setDateFilter] = React.useState('all');
    const [filterLabel, setFilterLabel] = useState();
    const [gdateRange,setGdateRange] = useState({
        start_date:  moment(new Date(new Date("01-01-1900"))).format("MM-DD-YYYY"),
        end_date: moment(new Date(new Date())).format("MM-DD-YYYY")
    })
    const [enrollmentData,setEnrollmentData] =useState([]);
    const [totalrows,setTotalrows] = useState();
    const [page,setPage] = useState(1);
    const [perpage,setPerpage] = useState(250);
    const [memoWidth,setMemoWidth] = useState(100);
    const { enqueueSnackbar } = useSnackbar();
    const [bulkActionIds, setBulkActionIds] = useState([]);

    const [spFilters, setSpFilters] = useState([]);


    const [customDate, setCustomDate] = useState({
        start: timeRemover(new Date()),
        end: timeRemover(new Date())
    });

    const [filters,setFilters] = useState({})


    useEffect(()=>{
        (async()=>{
            let response = await axios.get(`get/picklist/is_active_sglocation_enrollment`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            let res = response?.data?.picklist[0]?.picklist_entries[0]?.descriptions;
            if(res){
                let jsonRes = JSON.parse(res)
                setSpFilters(["All", ...jsonRes?.filterSGLocations, "Blank"])
            }else{
                setSpFilters(["All"])
            }
        })()
    },[])

    const handleDateFilterChange = (event) => {
        setMemoWidth(100)
        let values;
        setDateFilter(event?.target?.value)
        if(event.target.value === "all"){
            values = getDateForAll()
        }
        else if(event.target.value === "today"){
            values = getDateForToday()
        }
        else if(event.target.value === "yesterday"){
            values = getDateForYesterday()
        }
        else if(event.target.value === "customDate"){
            setMemoWidth(80)
        }
        (values?.startDate && values?.endDate) && 
        setGdateRange({start_date: moment(new Date(values.startDate)).format("MM-DD-YYYY"),end_date:moment(new Date(values.endDate)).format("MM-DD-YYYY")})
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, arr,colName) => {
        setFilterList(arr)
        setAnchorEl(event.currentTarget);
        setFilterLabel(colName)
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const filterClick = (e) =>{
        const temp = {...filters}
        const final = {...temp, [filterLabel]:e.target.textContent}
        setFilters(final)
        handleClose();
    }

    useEffect(() => {
        setColumns([
            {
                name: "",
                selector: row => <Checkbox
                    type="checkbox"
                    style={{ transform: "scale(1)", padding: 0 }}
                    // checked={}
                    checked={bulkActionIds.includes(row?.Account_Number)?true:false}
                    onClick={() => manageMassIds(row)}
                />,
                sortable: false,
                right: true,
                width: `60px`,
            },
            {
                name: <div style={{whiteSpace: 'normal',wordWrap: "break-word"}}>Enroll Date</div>,
                selector: row => !tableLoad?
                (row?.Enrollment_Date ? moment(new Date(row?.Enrollment_Date)).format("MM-DD-YY")  : "-"):
                <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth:"80px",
                maxWidth:"80px"
                // width: "5%"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Start Date</div>,
                selector: row =>!tableLoad?
                (row?.Start_Date? moment(new Date(row?.Start_Date)).format("MM-DD-YY") : "-"):
                <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth:"80px",
                maxWidth:"80px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Account #</div>,
                selector: row =>!tableLoad?
                 (row.Account_Number|| "-"):
                 <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth: "100px",
                maxWidth: "100px"
            },
            {
                name: <div style={{whiteSpace: 'normal',width:"300px"}}>Name</div>,
                selector: row =>!tableLoad?
                 (<div style={{whiteSpace:"normal"}}>{row.Customer_Name || "-"}</div>):
                 <Skeleton variant="rectangular" width="70px" height={20} />,
                sortable: true,
                left: true,
                center:true,
                minWidth: "110px",
                maxWidth:"110px"
            },
            {
                name: 'Broker',
                selector: row => !tableLoad?
                (<div style={{whiteSpace:"normal"}}>{ row.Broker_Name}</div> || "-"):
                <Skeleton variant="rectangular" width="70px" height={20} />,
                left: true,
                center:true,
                minWidth: "100px",
                maxWidth:"150px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>DF</div>,
                selector: row => !tableLoad?
               (<div style={{whiteSpace:"normal"}}>{(row?.Product_Name?.includes("Deposit") && row?.Product_Name?.includes("Freedom")) ? "Y" : "N"}</div> || "-"):
               <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth:"110px",
                maxWidth:"110px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Deposit Bal</div>,
                selector: row =>!tableLoad?
                 (row.Diposit_Balance || "-"):
                 <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth: "100px",
                maxWidth:"100px"
            },
            {
                name: <div style={{display: "flex", whiteSpace: "normal"}}>
                    SP Status
                    <FilterAltIcon style={{cursor:"pointer"}} onClick={e=>handleClick(e, spFilters,"location_status")} />
                    {/*"All", "Approved", "Pending", "Pending Deposit" */}
                </div>,
                selector: row =>!tableLoad?
                 (<div style={{whiteSpace:"normal"}}>{row.Location_Status}</div> || "-"):
                 <Skeleton variant="rectangular" width="70px" height={20} />,
                left: true,
                center:true,
                minWidth: "120px",
                maxWidth:"120px"
            },
            {
                name: <div style={{display: "flex", whiteSpace: "normal", width:"120px"}}>
                    Document Status
                    <FilterAltIcon style={{cursor:"pointer"}} onClick={e=>handleClick(e, ["All", "Requested", "Received"],"document_status")} />
                </div>, 
                selector: row => !tableLoad?
               ( row.Document_Status || "-"):
               <Skeleton variant="rectangular" width="70px" height={20} />,
                center:true,
                minWidth: "120px",
                maxWidth:"120px"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Trust Status</div>,
                selector: row =>!tableLoad?
                <div style={{backgroundColor:row.Trust_Status == "Approve"?"#73d49f":row.Trust_Status == "Deny"?"#ff7373":row.Trust_Status == "Review"?"#ffdc73":"white",
                minWidth:"90px",maxWidth:"90px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                     {row.Trust_Status == "Approve"?"Approve":row.Trust_Status == "Deny"?"Deny":row.Trust_Status == "Review"?"Review":"-"}
                     </div>:
                     <Skeleton variant="rectangular" width="70px" height={20} />,
                sortable: true,
                center:true,
                minWidth:"120px",
                maxWidth: "auto"
            },
            {
                name:  <div style={{display: "flex", whiteSpace: "normal", width:"130px"}}>
                Approval Status
                {/* <FilterAltIcon style={{cursor:"pointer"}} onClick={e=>handleClick(e, ["Approved", "Reviewed", "Denied"],"approval_status")} /> */}
                </div>,
                selector: row =>!tableLoad?
                <div style={{backgroundColor:row.Approval_Status == "Approved"?"#73d49f":row.Approval_Status == "Denied"?"#ff7373":row.Approval_Status == "Pending Review"?"#ffdc73":"white",
                width:"100px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                     {/* {row.Approval_Status == "Approve"?"Approved":row.Approval_Status == "Deny"?"Denied":row.Approval_Status == "Reviewed"?"Reviewed":"-"} */}
                     {row.Approval_Status}
                     </div>:
                     <Skeleton variant="rectangular" width="70px" height={20} />,
                left: true,
                center:true,
                minWidth:"130px",
                width:"auto"
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Email Sent</div>,
                selector: row =>!tableLoad?
                 (row.Email_Send || "-"):
                 <Skeleton variant="rectangular" width="70px" height={20} />,
                center: true,
                minWidth: "90px",
                width:"90px"
            },
            {
                name: <div style={{alignItems: 'center'}}>Actions</div>,
                selector: row =>!tableLoad?
                 <div style={{display: "flex", justifyContent: "space-between", width: "60px"}}>
                    <InsertDriveFileOutlinedIcon style={{cursor: "pointer"}} color="primary" onClick={()=>navigate("/enrollment-documents", {state:{row}})}/>
                    {/* <EmailOutlinedIcon style={{cursor: "pointer"}} color="primary"/> */}
                    <ArrowForwardOutlinedIcon style={{cursor: "pointer"}} color="primary" onClick={()=>navigate("/enrollment-details", {state:{row}})}/>
                </div>:
                <Skeleton variant="rectangular" width="50px" height={20} />,
                center: true,
                minWidth:"50px",
                width:"auto"
            },
            {
                name: <div style={{alignItems: 'left'}}>SG</div>,
                selector: row =>!tableLoad?
                (row.PROrigin == 'SyncSGToGC' ? 'SG': ' ' || "-"):
                <Skeleton variant="rectangular" width="20px" height={20} />,
                center: true,
                minWidth:"30px",
                width:"55px"
            }
        ]);
    }, [tableData,tableLoad,bulkActionIds])

    const handleExport = () =>{
       downloadData.length>0 ? downloadCSV(downloadData, "enrollments"):
       enqueueSnackbar("No data for Export", {
        anchorOrigin: {
                horizontal: 'right',
                vertical: 'top'
        },
        autoHideDuration: 2000,
        variant: 'error'
    });
    }

    const handleExportAll = async() =>{

        let response = await axios.get('internal/enrollment-portal',
            {
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                params:{...gdateRange,...filters,page:1,per_page:totalrows}
            }
        )
        const downloadAllData = replaceDownloadData(response?.data?.data)
        downloadAllData.length>0 ? downloadCSV(downloadAllData, "allenrollments"):
        enqueueSnackbar("No data for Export", {
            anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
            },
            autoHideDuration: 2000,
            variant: 'error'
        });
    }

    const onClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            // setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        // setFilterText(e.target.value);
        // console.log(e);
        // e.target.focus();
    };

    const manageMassIds = (id) =>{
        let prev = bulkActionIds
        if(prev.includes(id?.Account_Number)){
            const index = prev.indexOf(id?.Account_Number);
            if (index !== -1) {
                prev.splice(index, 1);
            }
            let now = [...prev]
            setBulkActionIds((now)=>[...now])
        }else{
            const c=1;
            let now = [...prev, id?.Account_Number]
            setBulkActionIds([...prev, id?.Account_Number])
        }
    }

    const handleBulkAction = async(key) =>{
        //0 DENY, 2 APPROVE
        try {
            let response = await axios.post(`/internal/bulk-approval-status`, { "account_number": bulkActionIds, "approval_status" : key}, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            response &&  enqueueSnackbar(key==0 ? "Customers denied successfully" : "Customers approved successfully", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'success'
            });
            response && loadEnrollments();
            response && setBulkActionIds([]);
            
        } catch (err) {
            console.error(err);
            enqueueSnackbar("Something went wrong", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
            return err;
        }
        return 0;
    }

    const actionProps = {
        title: "Enrollment Portal",
        addTitle: "Add Broker",
        filterTitle: "Filter List",
        onClear,
        onFilter,
        handleExportAction: handleExport,
        handleExportActionAll: handleExportAll,
        hideSearch: true,
        disableprop: tableLoad,
        handleBulkAction: handleBulkAction,
        bulkActionIds: bulkActionIds
    };

    const actionsMemo = React.useMemo(() =>{
    console.log("memo",bulkActionIds)
     return(   <div style={{width: `${memoWidth}%`}} >
            <ActionBar style={{display:"flex",justifyContent:"flex-start",width:"100%",}} actionProps={actionProps} />
            <Box style={{display:"flex",width:"100%",justifyContent:"flex-end"}}>
                {/* <Box style={{display: "flex",margin:"10px 20px 30px 0px"}}>
                    <Box style={{display:"flex",height:"100%",flexDirection:"column",justifyContent:"flex-end"}}>
                        <TextField
                            id="search"
                            type="text"
                            placeholder="Search by Account #"
                            aria-label="Search Input"
                            // value={filterText}
                            // onChange={onFilter}
                        />
                    </Box>
                    &nbsp;
                    <ThemedButton2 contained style={{width:"10%",borderRadius:"10px",height:"45px",marginTop:"10px"}}
                    onClick={()=>{
                        customDate.start && customDate.end && setGdateRange({start_date:  moment(new Date(customDate.start)).format("MM-DD-YYYY"),end_date: moment(new Date(customDate.end)).format("MM-DD-YYYY")})
                    }}>Ok</ThemedButton2>
                </Box> */}
                <Box style={{display: "flex", flexDirection: "column",alignItems:"flex-end",margin:"20px 0px 20px 0px"}}>
                    <FormControl sx={{ m: 1, minWidth: 80, width: "150px" }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={dateFilter}
                            onChange={handleDateFilterChange}
                            label="Filter"
                            size="small"
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"today"}>Today</MenuItem>
                            <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                            <MenuItem value={"customDate"}>Custom Date</MenuItem>
                        </Select>
                    </FormControl>
                        {dateFilter === "customDate" &&<Box sx={{width: "100%", display: "flex", justifyContent: "right"}}>
                        <Box className={classes.inner2Filters}>
                            <MuiDateRangePicker label={"Start Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.start}/>
                            <Box sx={{display: "flex", alignItems: "center"}}>&nbsp;to&nbsp;</Box>
                            <MuiDateRangePicker label={"End Date"} date={customDate} setDate={setCustomDate} currentDate={customDate.end}/>
                            {/* <CustomButton variant="contained" style={{width: "10px", borderRadius: "10px", height: "42px"}}
                                onClick={()=>{
                                    setGdateRange({startDate:timeRemover(customDate.start),endDate:timeRemover(customDate.end)})
                                }}
                            >
                                Ok
                            </CustomButton >                     */}
                            &nbsp;
                            <ThemedButton2 contained style={{width:"10%",borderRadius:"10px",height:"45px",marginTop:"10px"}}
                            onClick={()=>{
                                customDate.start && customDate.end && setGdateRange({start_date:  moment(new Date(customDate.start)).format("MM-DD-YYYY"),end_date: moment(new Date(customDate.end)).format("MM-DD-YYYY")})
                            }}>Ok</ThemedButton2>

                        </Box>
                    </Box>
                    }
                </Box>
            </Box>
        </div>)}
    , [dateFilter,customDate,tableLoad,bulkActionIds]);

    const replaceDownloadData = (data) => {
        return data.map((item) => {
          const { Start_Date,Account_Number,Customer_Name,Enrollment_Date,Enrollment_Status,Document_Status,Approval_Status,Product_Name,
            Location_Status,So_History,Diposit_Balance,Broker_Name,Converting_Utm_Content,Sales_Channel,Trust_Status,Trust_Reason,
            Prospect_id,User_id,Email_Send, ...rest } = item;
          return {
            "Enroll Date": moment(Enrollment_Date).format("MM-DD-YYYY") || "",
            "Start Date": moment(Start_Date).format("MM-DD-YYYY") || "",
            "Account Number":Account_Number?.replace(/,/g, "") || "",
            "Name":Customer_Name?.replace(/,/g, "") || "",
            "Broker":Broker_Name?.replace(/,/g, "") || "",
            "DF":((Product_Name?.includes("Deposit") && Product_Name?.includes("Freedom")) ? "Y" : "N") || "",
            "Deposit Bal":Diposit_Balance || "",
            "SP Status":Enrollment_Status?.replace(/,/g, "") || "",
            "Document Status": Document_Status?.replace(/,/g, "") || "",
            "Trust Status": Trust_Status?.replace(/,/g, "") || "",
            "Approval Status": Approval_Status?.replace(/,/g, "") || "",
            "Email Sent": Email_Send?.replace(/,/g, "") || ""
        }
    })}

    const loadEnrollments = async () => {
        setTableLoad(true)
        try{
            let response = await axios.get('internal/enrollment-portal',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                    params:{...gdateRange,...filters,page,per_page:perpage}
                }
            );
            setEnrollmentData(response?.data?.data)
            if(response?.data?.data){
                downloadData = replaceDownloadData(response?.data?.data) 
            }
            setTotalrows(response?.data?.total)
            setTableLoad(false)
        }catch(e){
            console.log(e)
            setTableLoad(false)
        }
        // let sorted = response.data.brokers;
        // function compare(a, b) {
        //     if (Number(a.broker_api_enabled) < Number(b.broker_api_enabled)) {
        //         return 1;
        //     }
        //     if (Number(a.broker_api_enabled) > Number(b.broker_api_enabled)) {
        //         return -1;
        //     }
        //     return 0;
        // }

        // sorted.sort(compare);
        // await setBrokersData(sorted);
        // setBrokersData(enrollData);
    }
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                setLoading(false);
                setTableLoad(false);
            }
        );
    }, [gdateRange,filters,perpage,page]);

    // const getSubHeaderComponent = () => {
    //     return (
    //       <FilterComponent
    //         onFilter={(e) => {
    //           let newFilterText = e.target.value;
    //           this.filteredItems = fakeUsers.filter(
    //             (item) =>
    //               item.name &&
    //               item.name.toLowerCase().includes(newFilterText.toLowerCase())
    //           );
    //           this.setState({ filterText: newFilterText });
    //         }}
    //         onClear={this.handleClear}
    //         filterText={this.state.filterText}
    //       />
    //     );
    // };

    const handleChangeRowsPerPage = (newPerPage) => {
        // Handle the change in the number of rows per page
        setPerpage(newPerPage)
      };
    
      const handlePageChange = (page) => {
        setPage(page);
      };

    const paginationOptions = [20,40,60,80,100,150,200,250];


    return (
        <>
            {
                (loading) ? (
                    <>
                        loading
                    </>
                ) : (
                <>
                    <div id="portal"></div>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                    {
                        filterList.map(item=>
                            <MenuItem key={"MI" + item} onClick={(e)=>filterClick(e)}>{item}</MenuItem>
                        )
                    }
                    </Menu>
                    <Box className={classes.tableContainer} style={{padding:"30px"}}>
                        <Box className={classes.table}>
                            <DataTable
                                className="my-data-table"
                                columns={columns}
                                data={enrollmentData}
                                // data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                fixedHeader
                                fixedHeaderScrollHeight="auto"
                                // overflow="scroll"
                                pagination
                                paginationPerPage={perpage} // Set the initial number of rows per page
                                paginationRowsPerPageOptions={paginationOptions} // Specify the options
                                onChangeRowsPerPage={handleChangeRowsPerPage} // Handle the change event
                                paginationComponentOptions={paginationComponentOptions}
                                paginationTotalRows={totalrows}
                                paginationDefaultPage={page}
                                paginationServer
                                onChangePage={handlePageChange}
                                // striped
                                dense
                                // subHeader
                                // subHeaderComponent={getSubHeaderComponent}
                                customStyles={customStyleForTable}
                                //subHeaderComponent={subHeaderComponentMemo}
                                actions={actionsMemo}
                                noDataComponent="No records to display"
                            >
                            </DataTable>
                        </Box>
                        <style>
                            {`
                            ::-webkit-scrollbar {
                                width: 10px;
                                height: 10px;
                            }

                            ::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                            }
                            .my-data-table .rdt_TableHeaderCell {
                                white-space: normal;
                                overflow: visible;
                                text-overflow: unset;
                              }
                            `}
                        </style>
                    </Box>
                </>
                )
            }

        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: "30px",
        width:"100%"
    },
    // '@media (max-width: 1199.95px)': {
    //     tableContainer: {
    //       padding: '30px',
    //     },
    //   },
    table: {
        // border: "1px solid black"
        width:"100%"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    },
    inner2Filters: {
        display: "flex",
        alignItems: "end",
        // width: "50%",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            marginTop:"20px"
         }
    },
}))
