import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Theme from "theme/theme";
import EditIcon from '@mui/icons-material/Edit';
import ReactDom from 'react-dom';
import useCommonStyles from 'components/CommonStyleClasses';
import { InputRow, MuiText, MuiDateTime, MuiDateTimeNew} from 'components/StyledInputFields';
import { ThemedButton } from 'components/GCButtons';
import axios from "../../utils/axios";
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px 50px',
    borderRadius: '5px',
    zIndex: 1000
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            {children}
            </Box>
        )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function modifyObject(obj) {
    if (obj.hasOwnProperty("start") && obj.start?.includes("T")) {
        obj.start = obj.start.replace("T", " ").replace(/:\d{2}$/, ":00");
    }

    if (obj.hasOwnProperty("end") && obj.end?.includes("T")) {
        obj.end = obj.end.replace("T", " ").replace(/:\d{2}$/, ":00");
    }

    return obj;
}

const EditBannerData = ({open, setOpen, bannerData, id, loadMaintenanceData, enqueueSnackbar}) =>{
    const commonClasses = useCommonStyles();
    const [formValue, setFormValue] = React.useState({
        start: bannerData[id]?.start,
        end: bannerData[id]?.end,
        header: bannerData[id]?.header,
        body: bannerData[id]?.body
    });

    React.useEffect(()=>{
        if(open){
            setFormValue({
                start: bannerData[id].start,
                end: bannerData[id].end,
                header: bannerData[id].header,
                body: bannerData[id].body
            })
        }
    },[open, id, bannerData])

    const handleSubmit = async()=>{
        const portal_id = bannerData[id]?.portal_id;
        const newFormValues = modifyObject({...bannerData[id], ...formValue})
        if (!newFormValues?.body || !newFormValues?.header || !newFormValues?.start || !newFormValues?.end){
            enqueueSnackbar("Please fill all the fields", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
            return;
        }
        const stringFormvalue = JSON.stringify(newFormValues)
        try{
            const response = await axios.post('/picklist_entry/update', { 
                id: portal_id,
                descriptions: stringFormvalue
             },{
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
            enqueueSnackbar("Maintenance data updated successfully", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'success'
            });
        }catch(err){
            console.log('Caught while updating maintenance data', err);
        }
        setOpen(false)
        loadMaintenanceData();
    }

    const handleCancel = ()=>{
        setOpen(false)
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    if (!open) {
        return null
    }
    return <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Update Maintenance Data <br/> ({bannerData[id]?.portal_name})
                        </Typography>

                        <MuiDateTimeNew
                            name="start"
                            label="Start Time"
                            value={bannerData[id]?.start}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiDateTimeNew
                            name="end"
                            label="End Time"
                            value={bannerData[id]?.end}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiText
                            name="header"
                            label="Banner Heading"
                            defaultValue={bannerData[id]?.header}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiText
                            name="body"
                            label="Banner Body"
                            defaultValue={bannerData[id]?.body}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            multiline={true}
                            rows={2}
                        />

                        <br />

                        <InputRow>
                            <ThemedButton
                                outlined
                                onClick={handleCancel}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
            <style>{`
                input, select {
                    width: 70%;
                    height: 200%;
                    padding: 5px 5px;
                    margin: 4px 0;
                    display: inline-block;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    box-sizing: border-box;
                    // margin-bottom: 20px
                } 
            `}</style>
        </>
}

const EditMyaccountBannerData = ({open, setOpen, bannerData, id, loadMaintenanceData, enqueueSnackbar}) =>{

    const commonClasses = useCommonStyles();
    const [formValue, setFormValue] = React.useState({
        start: bannerData[id]?.start,
        end: bannerData[id]?.end,
        header: bannerData[id]?.header,
        body: bannerData[id]?.body
    });

    React.useEffect(()=>{
        if(open){
            setFormValue({
                start: bannerData[id].start,
                end: bannerData[id].end,
                header: bannerData[id].header,
                body: bannerData[id].body
            })
        }
    },[open, id, bannerData])

    const handleSubmit = async()=>{
        const portal_id = bannerData[id]?.portal_id;
        const newFormValues = modifyObject({...bannerData[id], ...formValue})
        if (!newFormValues?.body || !newFormValues?.header || !newFormValues?.start || !newFormValues?.end){
            enqueueSnackbar("Please fill all the fields", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
            return;
        }
        const stringFormvalue = JSON.stringify(newFormValues)

        try{
            const response = await axios.post('/picklist_entry/update', {
                id: portal_id,
                descriptions: stringFormvalue
             },{
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
            enqueueSnackbar("Maintenance data updated successfully", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'success'
            });
        }catch(err){
            console.log('Caught while updating maintenance data', err);
        }
        setOpen(false)
        loadMaintenanceData();
    }

    const handleCancel = ()=>{
        setOpen(false)
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }

    if (!open) {
        return null
    }
    return <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Update Maintenance Data <br/> ({bannerData[id]?.portal_name})
                        </Typography>

                        <MuiDateTimeNew
                            name="start"
                            label="Start Time"
                            value={bannerData[id]?.start}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiDateTimeNew
                            name="end"
                            label="End Time"
                            value={bannerData[id]?.end}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiText
                            name="header"
                            label="Banner Heading"
                            defaultValue={bannerData[id]?.header}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />

                        <MuiText
                            name="body"
                            label="Banner Body"
                            defaultValue={bannerData[id]?.body}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            multiline={true}
                            rows={2}
                        />

                        <br />

                        <InputRow>
                            <ThemedButton
                                outlined
                                onClick={handleCancel}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
            <style>{`
                input, select {
                    width: 70%;
                    height: 200%;
                    padding: 5px 5px;
                    margin: 4px 0;
                    display: inline-block;
                    border: 1px solid #ccc;
                    border-radius: 10px;
                    box-sizing: border-box;
                    // margin-bottom: 20px
                } 
            `}</style>
        </>
}

export const MaintenanceBanners = () =>{

    const [ bannerData, setBannerData ] = React.useState([])
    const [ myaccountBannerData, setMyaccountBannerData ] = React.useState([])
    const [ loading, setLoading ] = React.useState(true)
    const [ openModal, isOpenModal ] = React.useState(false)
    const [ openMyaccModal, isOpenMyaccModal ] = React.useState(false)
    const [ tabValue, setTabValue ] = React.useState(0)
    const [ id, setId ] = React.useState(null)
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(()=>{
        (async()=>{
            await loadMaintenanceData();
            await loadMyaccountMaintenanceData();
        })()
        setLoading(false)
    },[])

    const handleChangeTabs = (event, newValue) => {
        setTabValue(newValue);
    };

    const loadMaintenanceData = async() =>{
        try{
            const response = await axios.get('/get/picklist/maintenance_mode', {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
            let resArr = response?.data?.picklist[0]?.picklist_entries;
            let _bannerData = []
            resArr?.map((data)=>{
                let _des = JSON.parse(data?.descriptions)
                _bannerData.push(
                    {
                        "portal_id": data?.id,
                        "portal_key": data?.key,
                        "portal_name": data?.label,
                        "start": _des?.start,
                        "end": _des?.end,
                        "header": _des?.header,
                        "body": _des?.body
                    }
                )
            })
            setBannerData(_bannerData)
        }catch(err){
            console.log("Caught while fetching maintenance data", err)
        }
    }

    const loadMyaccountMaintenanceData = async() =>{
        try{
            const response = await axios.get('/get/picklist/myaccount_banner', {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
            let resArr = response?.data?.picklist[0]?.picklist_entries;
            let _bannerData = []
            resArr?.map((data)=>{
                let _des = JSON.parse(data?.descriptions)
                _bannerData.push(
                    {
                        "portal_id": data?.id,
                        "portal_key": data?.key,
                        "portal_name": data?.label,
                        "start": _des?.start,
                        "end": _des?.end,
                        "header": _des?.header,
                        "body": _des?.body
                    }
                )
            })
            setMyaccountBannerData(_bannerData)
        }catch(err){
            console.log("Caught while fetching myaccount maintenance data", err)
        }
    }

    if(loading) return <>Loading...</>
    return(
        <>
            <EditBannerData open={openModal} setOpen={isOpenModal} bannerData={bannerData} id={id} loadMaintenanceData={loadMaintenanceData} enqueueSnackbar={enqueueSnackbar}/>
            <EditMyaccountBannerData open={openMyaccModal} setOpen={isOpenMyaccModal} bannerData={myaccountBannerData} id={id} loadMaintenanceData={loadMyaccountMaintenanceData} enqueueSnackbar={enqueueSnackbar}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", fontSize: "24px", fontWeight: "bold"}}>
                Maintenance Banners
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between" }}>
                <Tabs value={tabValue} onChange={handleChangeTabs} aria-label="basic tabs example" >
                    <Tab style={{backgroundColor: tabValue === 0 && "#ff5b6a", borderRadius: tabValue === 0 && "10px 10px 0px 0px", color: tabValue === 0 && "white"}} label="Maintenance Banners" {...a11yProps(0)} />
                    <Tab style={{backgroundColor: tabValue === 1 && "#ff5b6a", borderRadius: tabValue === 1 && "10px 10px 0px 0px", color: tabValue === 1 && "white"}} label="MyAccount Bannners" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <div key={"tab-0-div"} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {bannerData.map((item, portalId)=>{
                        return(
                                <Card key={`card0-${item.portal_key}`} sx={{ width: "50%", marginTop: "30px", padding: "10px" }}>
                                    <CardContent>
                                        <div className={"h5"} style={{
                                            textAlign: "left",
                                            fontWeight: "normal",
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                            <div>
                                                {item.portal_name}
                                                <div style={{
                                                    backgroundColor: Theme.palette.primary.main,
                                                    width: "100px",
                                                    height: "6px",
                                                    borderRadius: "50px",
                                                    marginTop: "-10px",
                                                    marginBottom: "20px"
                                                }}/>
                                            </div>
                                            <div>
                                                <EditIcon style={{cursor: "pointer"}} onClick={() => {
                                                    setId(portalId);
                                                    isOpenModal(true);
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{display: "grid", gridTemplateColumns: "1fr 3fr", gap: "10px"}}>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                <b>Start Date and Time: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                {item.start || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                <b>End Date and Time: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                {item.end || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                <b>Banner Heading Text: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{
                                                textAlign: "left",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}>
                                                {item.header || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary"
                                                        style={{textAlign: "left"}}>
                                                <b>Banner Body Text: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{
                                                textAlign: "left",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }} title={item.body}>
                                                {item.body || "-"}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                        )
                    })}
                </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {myaccountBannerData.map((item, portalId)=>{
                        return(
                                <Card  key={`card1-${item.portal_key}`} sx={{ width: "50%", marginTop: "30px", padding: "10px" }}>
                                    <CardContent>
                                        <div  className={"h5"} style={{textAlign: "left", fontWeight: "normal", display: "flex", justifyContent: "space-between"}}>
                                            <div>
                                                {item.portal_name}
                                                <div style={{backgroundColor: Theme.palette.primary.main, width: "100px", height: "6px", borderRadius: "50px", marginTop: "-10px", marginBottom: "20px"}} />
                                            </div>
                                            <div>
                                                <EditIcon style={{cursor: "pointer"}} onClick={()=>{
                                                    setId(portalId);
                                                    isOpenMyaccModal(true);
                                                }}/>
                                            </div>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr", gap: "10px" }}>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                <b>Start Date and Time: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                {item.start || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                <b>End Date and Time: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                {item.end || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                <b>Banner Heading Text: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{textAlign: "left", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                                {item.header || "-"}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{ textAlign: "left" }}>
                                                <b>Banner Body Text: </b>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" style={{textAlign: "left", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} title={item.body}>
                                                {item.body || "-"}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                        )
                    })}
                </div>
            </TabPanel>
        </>
    )
}

export default MaintenanceBanners;