import React,{ useState, useEffect } from "react";
import {axiosSignup} from "../../utils/axios";
import axios from "../../utils/axios";
import styled from 'styled-components';
import {FieldLabel, TextField} from "../../components/FormComponents";
import {breakpoints} from "../../components/BreakPoints";
import Button from "react-bootstrap/Button";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField as MuiTextField } from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {MuiDate} from "../../components/StyledInputFields";
const Row = styled.div`
  display: flex;
  padding: min(4.17vw, 3.75rem);
  color: #3a3a3a;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    padding: 1rem 8vw 4rem;
  }
`;
const Column = styled.div`
  flex: 0 0 calc(50% - 4px);

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    flex: 0 0 100%;

    &:first-of-type {
      margin-bottom: 3rem;
    }
  }
`;
const Container = styled.div`
  max-width: 450px;
  margin: 1rem auto 0 0;

  & .text {
    margin-bottom: 2.5rem;
  }
`;
const InputGroup = styled.div`
  margin-bottom: 1rem;
`;


export const Prices =  (props) => {
    const [pricesData, setPricesData] = useState({});
    const [loading, setLoading] = useState(true);
    function HandleSubmit(event) {
        event.preventDefault();
        console.log(pricesData);
        postPrices(pricesData);
        if (pricesData.clearCache &&
            pricesData.clearCache === true
        ) {
            axiosSignup.post(`/internal/cache/clear`,
                {},
                { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
            ).then((data)=> {
                console.log(data);
            }).catch((error)=>{
                console.log(error);
            });
        }
    }
    const postPrices = async (postData) => {
        setLoading(true);
        const response = await axios.put(`/internal/prices`,
            postData,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        response.data.data.clearCache = true;
        await setPricesData(response.data.data);
        console.log('response.data.data');
        console.log(response.data.data);
        console.log('pricesData');
        console.log(pricesData);
        //setTotalRows(response.data.total);
        setLoading(false);
    }
    function HandleSetStartDateInputChange(data)
    {
        console.log('HandleSetStartDateInputChange');
        console.log(data);
        setPricesData(data);
    }
    function HandleStartDateInputChange(event) {
        console.log('HandleStartDateInputChange');
        setPricesData({
            firstAvailableStartDate: event.target.value,
            clearCache: pricesData.clearCache
        });
    }

    function HandleClearCacheInputChange(event) {
        setPricesData({
            firstAvailableStartDate: pricesData.firstAvailableStartDate,
            clearCache: event.target.checked
        });
    }
    const fetchPrices = async page => {
        setLoading(true);
        const response = await axios.get(`/internal/prices`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        if (response.data.data) {
            response.data.data.clearCache = true;
            await setPricesData(response.data.data);
            //setTotalRows(response.data.total);
        }
        setLoading(false);
    };
    useEffect(() => {
        const init = async () => {
            await fetchPrices(1);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, []);
    if (loading){
        return <>Loading...</>
    }
    return (
        <>
            <main>
                <Row>
                    <Column>
                        <p>Enrollment Settings</p>
                        <Container>
                            <form onSubmit={HandleSubmit}>
                                <InputGroup>
                                    <FieldLabel htmlFor="firstAvailableStartDate">Start Date</FieldLabel>
                                    <MuiDate
                                        name="firstAvailableStartDate"
                                        label="Enroillment First Available Date"
                                        //placeholder="Start Date"
                                        value={pricesData.firstAvailableStartDate}
                                        key={"firstAvailableStartDate"}
                                        onChange={HandleStartDateInputChange}
                                        formValue={pricesData}
                                        setFormValue={HandleSetStartDateInputChange}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <FieldLabel htmlFor="clearCache">Clear Cache</FieldLabel>
                                    <input
                                        type= "checkbox"
                                        name= "clearCache"
                                        id= "clearCache"
                                        checked={pricesData.clearCache}
                                        onChange= {HandleClearCacheInputChange}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Button type="submit"> Update </Button>
                                </InputGroup>
                            </form>
                        </Container>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <Container>

                        </Container>
                    </Column>
                </Row>
            </main>
        </>
    );
}