import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { makeStyles } from "@mui/styles";
import ActionBar from "components/ActionBar";
import { useNavigate, useLocation } from 'react-router-dom';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, Card, Divider, IconButton, Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import salesCategoryData from "../../dummydata/sales_data.json";
import Demo from "../../utils/transferList.js"
import AddSalesCategoryModal from "components/modals/AddSalesCategoryModal";
import {Grid, List, ListItem, ListItemText, TextField} from "@material-ui/core"
import SaveIcon from '@mui/icons-material/Save';
import { ThemedButton , ThemedButtonSimple} from 'components/GCButtons';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from "../../utils/axios";
import { useSnackbar } from 'notistack';
import AddUtmMappingModal from "components/modals/AddUtmMappingModal";

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
    },
    table: {
        style: {
            borderRadius: 10,
            overflow: "hidden",
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#FF7676",
            color:"white"
        }
    },
    rows:{
        style:{
            '&:nth-child(odd)': {
                backgroundColor: '#FFF1F1', // Set the desired color for striped rows
              },
        }
    }
}

export const SalesCategory = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState(null)
    const [data, setData] = useState(salesCategoryData)
    const [openEdit,setOpenEdit] = useState(false)
    const [editingId, setEditingId] = useState(null);
    const [editMapping,setEditMapping] = useState();
    const [left, setLeft] = React.useState([0, 1, 2, 3]);
    const [right, setRight] = React.useState([4, 5, 6, 7]);
    const [fixedLr, setFixedLr] = React.useState({});
    const [columns, setColumns] = useState([]);
    const [categoryId,setCategoryId] = useState();
    const [disableBtn,setDisableBtn] = useState(false);
    const [rrrr, setRrrr] = useState([]);
    const [llll, setLlll] = useState([]);
    const [checked, setChecked] = React.useState([]);
    const [addManual,setAddManual] = useState(false);
    const [notCategorized,setNotCategorised] = useState([]);
    function intersection(a, b) {
        try{
            return a.filter((value) => b.indexOf(value) !== -1)?.length;
        }catch(err){
            return 1
        }
    }

    const handleFieldChange = (event, field, id) => {
        console.log('Data in field ==>', field)
        const newData = data.map(item => {
            if (item.id === id) {
                return { ...item, [field]: event.target.value };
            }
            return item;
        });
        setData(newData);
        if(event.target.value === "" || event.target.value === null) { // Only allow numeric values
            const newData = data.map(item => {
                if (item.id === id) {
                    return { ...item, [field]: "" };
                }
                return item;
            });
            setData(newData);
        }
    };

    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [purpose,setPurpose] = useState("")
    const [openAddutm,setOpenAddUtm] = useState("")
    const { enqueueSnackbar } = useSnackbar();

    const open = Boolean(anchorEl);
    const handleClick = (event, arr) => {
        setFilterList(arr)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const filterClick = (e) =>{
        console.log('Data in e.target.textContent ==>', e.target.textContent)
        handleClose();
    }

    const enableEditing = (id) => {
        setEditingId(id);
    };
    
    const saveEdits = (id) => {
        setEditingId(null);
    };


    const getMappingById = async (id) => {
        try{
            let response = await axios.get('/internal/utm-list-by-sales-category',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                    params:{
                        categoryId: id
                    }
                }
            );
            const temp = [];
            const temp1 = [];
            response && response?.data?.utmList?.map((x)=>{
                temp.push(x?.utm)
                temp1.push(`${x?.utm}=>${x?.manual_utm}`)
            })
            setRight(temp)
            setFixedLr({...fixedLr, right: temp1})
            setRrrr(temp1)
        }catch(error){
            console.log('Data in error ==>', error)
        }
        
    }

    const getAvailableMappings = async () => {
        try{
            let response = await axios.get('/internal/utm-list',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                }
            );
            const temp = [];
            const temp1 = [];
            response && response?.data?.availableUtms?.map((x)=>{
                temp.push(x?.converting_utm_content)
                temp1.push(`${x?.converting_utm_content}=>0`)
            })
            setLeft(temp)
            setLlll(temp1)
            setFixedLr({...fixedLr, left: temp1})
        }catch(error){
            console.log('Data in error ==>', error)
        }
        
    }

    const getMappingForNotCategorized=async ()=>{
        try{
            let response = await axios.get('/internal/uncategorized-utm-list',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                }
            );
            const temp = [];
            response && response?.data?.availableUtms?.map((x)=>{
                temp.push(x?.converting_utm_content)
            })
            setNotCategorised(temp)
        }catch(error){
            console.log('Data in error ==>', error)
        }
    }


    const updateMappings = async () =>{
        setDisableBtn(true)
        try{
            const response = await axios.post('/internal/save-utm-sales-category',
            {
                categoryId,
                utm_name: rrrr
            },
             {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            if(response.status == "201"){
                enqueueSnackbar("UTM content is Mapped successfully", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
                setAddManual(false)
            }
            refetchData()
            setDisableBtn(false)
        }catch(e){
            console.log(e);
            setDisableBtn(false)
            enqueueSnackbar("Something went wrong", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
            getAvailableMappings();
            getMappingForNotCategorized();
            getMappingById(categoryId);
            
            return null;
        }

    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            fontWeight: 500,
            padding:"8px"
        },
    }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
          backgroundColor: "#FFF1F1",
      },
      // hide last border
      // '&:last-child td, &:last-child th': {
      //     border: 0,
      // },
  }));

    useEffect(() => {
        setColumns([
            {
                name: <div style={{whiteSpace: 'normal'}}>Sales Category</div>,
                selector: row => row.sales_category,
                center: true
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Description</div>,
                selector: row =><div style={{whiteSpace: 'normal'}}> {row.description}</div>,
                center: true,
            },
            {
                name: <div style={{whiteSpace: 'normal'}}>Status</div>,
                selector: row => row.status,
                center: true
            },
            {
                name: 'Actions',
                selector: row => <div style={{display: "flex", justifyContent: "space-around", width: "100px"}}>
                    <EditIcon style={{cursor:"pointer"}} onClick={()=>setOpenEdit(true)} />
                </div>,
                center: true,
            },
        ]);

      
    }, [])

    const loadData = async () => {
        try{
            let response = await axios.get('/internal/sales-category',
                {
                    headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                }
            );
            response && setData(response?.data?.salesCategory)
        }catch(error){
            console.log('Data in error ==>', error)
        }
       
    }

    useEffect(() => {
      loadData()
    }, []);


    const refetchData = ()=>{
        getAvailableMappings();
        getMappingForNotCategorized();
        getMappingById(categoryId);
    }


    const actionProps = {
        title: "Sales Channel and Mappings",
        addTitle: "Add Broker",
        filterTitle: "Filter List",
        hideSearch: true
    };

    function convertValues(input) {
        try{
          const parts = input.split('=>');
          return parts[0];
        }catch(err){
          return input;
        }
      }



    return (
        <>
            {
                (loading || !data || data.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>
                    <div id="portal"></div>
                    <Typography style={{fontSize:"28px",fontWeight:"bold",padding:"20px 20px 0px 20px"}}>Sales Channel and UTM Mappings</Typography>
                    <div style={{padding:"0px 20px 10px 20px",display:"flex",justifyContent:"end"}}>
                        {/* <ThemedButton
                            contained={"true"}
                            style={{width:"300px"}}
                            onClick={()=>setOpenEdit(true)}
                        >
                            Add Sales Category
                        </ThemedButton> */}
                        <Tooltip title="Add Sales Channel" arrow placement='left'>
                            <AddCircleIcon style={{cursor:"pointer",color:"#FF7676"}} fontSize="large" onClick={()=>{setOpenEdit(true); setPurpose("Add")}} />
                        </Tooltip>
                    </div>
                    <TableContainer style={{borderRadius:"10px"}}>
                                            <Table size='small' >
                                                <TableHead>
                                                    <StyledTableRow>
                                                        {/* <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center", minWidth: "188px"}} >SALES - TARGET</StyledTableCell> */}
                                                            <StyledTableCell style={{color:"white",marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}} className={classes.tableCell1}>{"Sales Channel"}</StyledTableCell>
                                                            {<StyledTableCell className={classes.tableCell1} style={{color:"white",marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}>{"Description"}</StyledTableCell>}
                                                            {<StyledTableCell className={classes.tableCell1} style={{color:"white",marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}>{"Status"}</StyledTableCell>}
                                                            <StyledTableCell style={{color:"white",marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}>Actions</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                {data?.map((row,index) => (
                                                    <TableRow key={row?.id}
                                                        style={{ backgroundColor: index % 2 !== 0 ? '#FFF1F1' : 'transparent' }}
                                                    >
                                                        {true &&
                                                        <TableCell className={classes.tableCell1} style={{minWidth:"8%"}}>
                                                            {(editingId === row.id ) ? (
                                                            <TextField
                                                                value={row["category_name"]}
                                                                fullWidth
                                                                inputProps={{
                                                                    style: {
                                                                      fontSize: '14px' // Set your desired font size here
                                                                    }
                                                                  }}                                                                onChange={event => handleFieldChange(event, "category_name", row.id)}
                                                            />
                                                            ) : (
                                                            <div style={{cursor:"pointer"}} onClick={()=>{setEditMapping(row["category_name"]); getMappingById(row["id"]);
                                                                if(row["category_name"] === "NotCategorized"){
                                                                    getMappingForNotCategorized();
                                                                }else{
                                                                    getAvailableMappings();
                                                                }
                                                                setCategoryId(row["id"])}}>{row["category_name"]}
                                                            </div>
                                                            )}
                                                        </TableCell>}
                                                        {true &&
                                                        <TableCell className={classes.tableCell1} style={{minWidth:"15%"}}>
                                                            {(editingId === row.id ) ? (
                                                            <TextField
                                                                fullWidth
                                                                inputProps={{
                                                                    style: {
                                                                    fontSize: '14px' // Set your desired font size here
                                                                    }
                                                                }}
                                                                value={row["category_description"]}
                                                                onChange={event => handleFieldChange(event, "category_description", row.id)}
                                                            />
                                                            ) : (
                                                            row["category_description"]
                                                            )}
                                                        </TableCell>}
                                                        {true &&
                                                        <TableCell className={classes.tableCell1} >
                                                            {(editingId === row.id) ? (
                                                            <TextField
                                                                inputProps={{
                                                                    style: {
                                                                    fontSize: '14px'
                                                                    }
                                                                }}
                                                                value={row["status"]}
                                                                onChange={event => handleFieldChange(event, "status", row.id)}
                                                            />
                                                            ) : (
                                                            row["status"] == 1? "Active" : "Inactive"
                                                            )}
                                                        </TableCell>}
                                                        <TableCell className={classes.tableCell1}>
                                                            {/* {editingId === row?.id ? (
                                                                <SaveIcon onClick={() => saveEdits(row?.id)} style={{cursor:"pointer"}}/>
                                                            ) : (
                                                                <EditIcon onClick={() => enableEditing(row?.id)} style={{cursor:"pointer"}}/>
                                                            )} */}
                                                            {row["category_name"] == "NotCategorized"?"":<EditIcon onClick={()=>{setOpenEdit(true); setPurpose("Edit"); setRowData(row)}} style={{cursor:"pointer",color:"#FF7676"}}/>}


                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                    <AddSalesCategoryModal purpose={purpose} rowData={purpose === "Add"?null:rowData} loadData={loadData} open={openEdit} onClose={()=>setOpenEdit(false)}></AddSalesCategoryModal>
                    <AddUtmMappingModal addManual={addManual} setAddManual={setAddManual} refetchData={refetchData} salesCategory={editMapping}  open={openAddutm} onClose={()=>setOpenAddUtm(false)} rightArray={rrrr} setRight={setRrrr} categoryId={categoryId}></AddUtmMappingModal>
                    <br/>
                    {editMapping &&
                        <>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <Typography className={classes.heading}>UTM Mapping For {editMapping}</Typography>
                                {editMapping !=="NotCategorized" && <div style={{padding:"0px 80px 10px 80px",display:"flex",justifyContent:"end"}}>
                                    <Tooltip title={`Add UTM Mapping for ${editMapping}`} arrow placement='left'>
                                        <AddCircleIcon style={{cursor:"pointer",color:"#FF7676"}} fontSize="large" onClick={()=>{setOpenAddUtm(true); setPurpose("Add")}} />
                                    </Tooltip>
                                </div>}
                            </div>
                           {editMapping ==="NotCategorized"?
                            <>
                                    <Card style={{borderRadius:"10px", width:"45%", margin:"0px 30px"}}>
                                        <div style={{  background:"#FF7676",color:"white",fontWeight:"bold",display:"flex",alignItems:"center",padding:"6px 16px 6px 16px"}}>
                                            <div style={{paddingLeft:"20px"}}>{'Available UTMs'}</div>
                                        </div>
                                        <Divider />
                                        <Grid container style={{maxHeight:"250px", overflowY:"scroll"}} >
                                            <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <List
                                                sx={{
                                                width: "100%",
                                                height: 230,
                                                bgcolor: 'background.paper',
                                                overflow: 'auto',
                                                padding:"0px"
                                                }}
                                                dense
                                                component="div"
                                                role="list"
                                            >
                                                {notCategorized?.map((value,index) => {
                                                const labelId = `transfer-list-all-item-${value}-label`;

                                                return (
                                                    <Tooltip title={(value.includes("=>1")) ? "This is a manually added UTM content, it will be removed if transferred to available UTMs." : ""} placement='right' arrow>
                                                        <ListItem
                                                        key={index}
                                                        role="listitem"
                                                        button
                                                        // onClick={handleToggle(value)}
                                                        style={{
                                                            backgroundColor: index % 2 === 0 ? '#FFF1F1' : 'white',
                                                            padding:"6px 16px 6px 16px"
                                                        }}
                                                        >
                                                        <ListItemText id={labelId} primary={convertValues(value)} />
                                                        </ListItem>
                                                    </Tooltip>
                                                );
                                                })}
                                            </List>
                                            </Grid>
                                        </Grid>
                                    </Card>
                            </>:
                            <>
                                <Demo left={llll} right={rrrr} setLeft={setLlll} setRight={setRrrr} checked={checked} setChecked={setChecked}/>
                                <div style={{display:"flex",marginTop:"30px", justifyContent:"center",width:"100%",padding:"0px 100px 0px 100px"}}>
                                    <ThemedButton
                                        outlined
                                        onClick={()=> setEditMapping("")}
                                        style={{width:"20%",margin:"0px 30px 0px 0px"}}
                                    >
                                        Cancel
                                    </ThemedButton>
                                    <ThemedButtonSimple
                                        contained
                                        onClick={()=>{
                                            updateMappings()
                                        }}
                                        style={{margin:"0px 0px 0px 30px",
                                        backgroundColor:(addManual ?!addManual:(disableBtn || (intersection(checked, llll)) || (intersection(checked, rrrr)) || (fixedLr?.left === llll) || (fixedLr?.right === rrrr))) ? "lightgray" : "#FF5A6A", border: (disableBtn || (left === fixedLr?.left) || (right === fixedLr?.right)) ? "lightgray" : "#FF5A6A"}}
                                        width={"20%"}
                                        disabled={addManual ?!addManual: (disableBtn || (intersection(checked, llll)) || (intersection(checked, rrrr)) || (fixedLr?.left === llll) || (fixedLr?.right === rrrr))}
                                    >
                                        Save
                                    </ThemedButtonSimple>
                                </div>
                            </>}
                        </>
                    }
                </>
                )
            }
        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    table: {
        // border: "1px solid black"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    },
    tableCell1:{
        textAlign:"center"
    },
    heading:{
        padding:"30px",
        fontSize:"24px",
        fontWeight:"600",
        display:"flex",
        justifyContent:"flex-start"
    }
}))