import React, { useState } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Box, Grid, TextField, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

export const AccountNumEsiid = () => {
    const [data, setData] = useState([]);
    const [identifiers, setIdentifiers] = useState("");
    const [type, setType] = useState("account_numbers");
    const [loading, setLoading] = useState(false);
    const [allItems, setAllItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchData = async () => {
        setLoading(true);
        setErrorMessage("");
        try {
            const response = await axios.get('/internal/getaccountesiid', {
                params: {
                    identifiers: identifiers.split(',').map(id => id.trim()),
                    type: type
                },
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
            });
            if (response.data.data.length === 0) {
                setErrorMessage("No data found for the provided identifiers.");
            }
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage("An error occurred while fetching data.");
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        setData([]);
        setIdentifiers("");
        setErrorMessage("");
    };

    const columns = [
        { name: "Account Number", selector: "AccountNumber", sortable: true },
        { name: "Esiid", selector: "Esiid", sortable: true },
    ];

    const exportHeaders = columns.map(col => ({ label: col.name, key: col.selector }));

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <h1>Account Esiid Information</h1>
                </Grid>
                <Grid item>
                    {data.length === 0 ? (
                        <Button onClick={fetchData} variant="contained" color="primary" disabled={loading || !identifiers}>
                            {loading ? 'Loading...' : 'Fetch Data'}
                        </Button>
                    ) : (
                        <CSVLink
                            data={data}
                            headers={exportHeaders}
                            filename={"account_esiid_data.csv"}
                            className="btn btn-primary"
                        >
                            Download CSV
                        </CSVLink>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 16 }}>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <MenuItem value="esiids">ESIIDs</MenuItem>
                            <MenuItem value="account_numbers">Account Numbers</MenuItem>
                            <MenuItem value="uuid">UUID</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        label="List of Accounts/ESIIDs/uuid"
                        variant="outlined"
                        fullWidth
                        value={identifiers}
                        onChange={(e) => setIdentifiers(e.target.value)}
                    />
                </Grid>
            </Grid>
            {errorMessage && (
                <Typography variant="body2" color="error" style={{ marginTop: 16 }}>
                    {errorMessage}
                </Typography>
            )}
            {data.length > 0 && (
                <div style={{ marginTop: 16 }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        responsive
                    />
                    <Box mt={2}>
                        <Button onClick={handleRefresh} variant="contained" color="secondary">
                            Refresh
                        </Button>
                    </Box>
                </div>
            )}
        </div>
    );
};
