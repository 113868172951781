import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from 'react-data-table-component';
import Table from 'react-bootstrap/Table';

export const DepositRequired =  (props) => {
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([])
    //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
            <tr>
                <th>Id</th><td>{data.id}</td>
                <th>Accoun Number</th><td>{data.account_number}</td>
            </tr>
            <tr>
                <th>FirstName</th><td>{data.first_name}</td>
                <th>LastName</th><td>{data.last_name}</td>
            </tr>
            <tr>
                <th>Emmail</th><td>{data.email}</td>
                <th>Created</th><td>{data.created_at}</td>
            </tr>
            <tr>
                 <th>StartDate</th><td>{data.start_date}</td>
                <th>PromoCode</th><td>{data.promo_code}</td>

            </tr>
            <tr>
                <th>Address</th><td>{data.address}</td>
                <th>City</th><td>{data.city}</td>
            </tr>
            <tr>
                <th>ZipCode</th><td>{data.zip_code}</td>
                <th>Esiid</th><td>{data.esiid}</td>
            </tr>
            <tr>
                <th>UTM Sources</th><td>{data.utmlookup_code}</td>
                <th>IP Country</th><td>{data.ip_country}</td>
            </tr>
        </Table>
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const loadEnrollments = async () => {
        let response = await axios.get('/internal/enrollments',
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        await setColumns([
            {
                name: 'Enrollment Create Date',
                selector: row => row.created_at,
                sortable: true,
                right: false,
            },
            {
                name: 'Service Start Date',
                selector: row => row.start_date,
                sortable: true,
                right: false,
            },
            {
                name: 'Enrollment Type',
                selector: row => row.so_type,
                sortable: true,
                right: false,
            },
            {
                name: 'Product Name',
                selector: row => row.product_name,
                sortable: true,
                right: false,
            },
            {
                name: 'Enrollment Status',
                selector: row => row.sos_status,
                sortable: true,
                right: false,
            },
            {
                name: 'Deposit Amount',
                selector: row => row.deposit_amount,
                sortable: true,
                right: true,
            },
            {
                name: 'Deposit Status',
                selector: row => row.deposit_status,
                sortable: true,
                right: false,
            },
            {
                name: 'Payment Type',
                selector: row => row.payment_account_type,
                sortable: true,
                right: false,
            },
            {
                name: 'Is Autopay',
                selector: row => row.is_auto_pay,
                sortable: true,
                right: false,
            },
            {
                name: 'Account',
                selector: row => row.account_number,
                sortable: true,
                right: true,
            },
            {
                name: 'First Name',
                selector: row => row.first_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Last Name',
                selector: row => row.last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
                right: false,
            },
            {
                name: 'PromoCode',
                selector: row => row.promo_code,
                sortable: true,
                right: false,
            },
        ]);
    }
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <DataTable
                        title="Deposit Required"
                        columns={columns}
                        data={enrollmentsData}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        defaultSortFieldId={1}
                        striped
                        defaultSortAsc={false}
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                    >
                    </DataTable>
                )
            }

        </>
    );
}
