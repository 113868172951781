export const breakpoints = {
    xs: 0,
    xs_end: "575.98px",
    sm: "576px",
    sm_end: "767.98px",
    md: "768px",
    md_end: "991.98px",
    lg: "992px",
    lg_end: "1199.98px",
    xl: "1200px",
    xl_end: "1399.98px",
    xxl: "1400px",
};
