import React, { useEffect } from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";


import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, MuiAutocomplete, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import { useSnackbar } from 'notistack';



const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px 50px',
    borderRadius: '5px',
    zIndex: 1000,
    width:"40%"
}


const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}


const AddUtmMappingModal = ({ open, onClose, salesCategory, rightArray , categoryId, refetchData,setRight,addManual,setAddManual}) => {


    const commonClasses = useCommonStyles();
    const { enqueueSnackbar } = useSnackbar();


    const [formValue, setformValue] = React.useState({
        utm_mapping_name:  "",
        status:  1
    });
    const [disableBtn,setDisableBtn] = React.useState(false)



    const handleCancel = async (e) => {
        setformValue({
            utm_mapping_name:"",
            status:1
        })
        onClose();
    }


    // useEffect(()=>{
    //     setformValue({
    //         utm_mapping_name: "",
    //         status: 1
    //     })
    // },[rowData])

    const updateMappings=()=>{
        try{
            let newr= [...rightArray,`${formValue?.utm_mapping_name}=>1`]
            setRight(newr)
            setAddManual(true)
            onClose();
        }catch(err){
            console.log('Data in err ==>', err)
        }
    }

  


    const handleChange = (event) => {
        setformValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }
   
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Add UTM Mapping for {salesCategory}
                        </Typography>
                        <MuiText
                            label="UTM mapping name"
                            name="utm_mapping_name"
                            // defaultValue={rowData?.category_description}
                            onChange={handleChange}
                        />
                        {/* <MuiAutocomplete
                            label="Status"
                            name="status"
                            defaultValue={rowData?.status==0?"Inactive":"Active"}
                            onChange={handleChange}
                            formValue={formValue}
                            setformValue={setformValue}
                            options={[
                                { title: 'Active' },
                                { title: 'Inactive' },
                            ]}
                        /> */}
                        <div style={{display:"flex",marginTop:"30px", justifyContent:"space-between",width:"100%",padding:"0px 10% 0px 10%"}}>
                            <ThemedButton
                                outlined
                                onClick={handleCancel}
                                style={{width:"150px"}}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained
                                onClick={updateMappings}
                                style={{width:"150px"}}
                            >
                                Add
                            </ThemedButton>
                        </div>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            }
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default AddUtmMappingModal;

