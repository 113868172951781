import { Checkbox, Radio } from "@mui/material";
import { useEffect, useState } from "react";

export const handleUnselect = (row, tableData) => {
    console.log('handleUnselect:row_id',tableData.a_id)
    console.log(tableData);
    let my = document.getElementById(`chk_${tableData.a_id}`)
    console.log('my',my);
    if (my) {
        my.click();
    }
}
const OneRowReferralSelector = ({ row, tableData, setTableData , handler}) => {
    if (row === null) {
        return <div />
    }
    const [isSelected, setSelected] = useState(false);
    const handleSelect = async (row,isSel,ltable) => {
        console.log('handleSelect:issel',isSel);
        console.log('handleSelect:row',row);
        //console.log('handleSelect:ltbl',ltable);
        console.log('handleSelect:tbld',tableData);
        console.log('handleSelect:row-a_id',row.a_id);
        console.log('handleSelect:tbl-a_id',tableData.a_id);
            //handleUnselect(row, tableData);
        if (! isSel) {
            await setTableData(tableData);
            await setSelected(true);
            handler(row, tableData);
        } else {
            await setSelected(false);
        }
    }

    return (
        <>
            <Checkbox
                id={`chk_${row.a_id}`}
                type="checkbox"
                style={{ transform: "scale(1)", padding: 0 }}
                checked={isSelected}
                onClick={() => handleSelect(row, isSelected)}
            />
        </>
    )
}

export default OneRowReferralSelector;
