import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";
import CloseIcon from '@mui/icons-material/Close';
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch, InputRow1 } from 'components/StyledInputFields';
import { Box, Checkbox as CBX } from '@mui/material';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography, Tooltip, Alert, AlertTitle } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';
import { EditOutlined, Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const BrokerPortalUsersModal = ({ open, onClose, loadEnrollments,tableData }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [assoProductsModal, setAssoProductsModal] = useState(false)
    const [assoTokenModal, setAssoTokenModal] = useState(false)
    const [allProductsData, setAllProductsData] = useState([])
    const [forBtnCB, setForBtnCB] = useState({
        assoProds: false,
        assoToken: false
    })
    const [addRow,setAddRow] = useState([]);
    const [alter,setAlter] = useState([])
    const [totalRows, setTotalRows] = useState([])
    const [totalRowsUpdate, setTotalRowsUpdate] = useState([])
    const [totalRowsAdd, setTotalRowsAdd] = useState([])
    const [newUuid, setNewUuid] = useState(null)
    const [iconChange, setIconChange] = useState(true)
    const [formValue, setFormValue] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        uuid: '',
    });
    const [refetchData,setRefetchData] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()

    useEffect(()=>{
        const fetchEnrollments = async page => {
            try{
                const response = await axios.get(`broker/users`, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}, params:{broker_id:tableData?.id}});
                setTotalRows(response?.data?.data);
                setTotalRowsUpdate(response?.data?.data);
                const count = new Array(response?.data?.data?.length).fill(true)
                setAlter(count)
            }catch(err){
                console.log('Data in err ==>', err);
            }
        };
        fetchEnrollments();
    },[iconChange,refetchData])

    const updateUserData = async(payload) =>{
        try{
            const response = await axios.post(`broker/update-user`, payload, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}})
            if(response?.status === 200){
                enqueueSnackbar("User updated successfully", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
            }
        }catch(err){
            console.log('Caught while updating', err);
            enqueueSnackbar("Failed to update details", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }
    
    const addUserData = async(payload) =>{
        const finalPayload ={...payload,uuid:newUuid,broker_id:tableData?.id,status:1}
        try{
            const response = await axios.post(`broker/add-user`, finalPayload, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}})
            if(response?.status === 200 || response?.status === 201){
                setRefetchData(!refetchData)
                handleRemove(0)
                enqueueSnackbar("User added successfully", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
            }
        }catch(err){
            console.log('Caught while updating', err?.response?.data?.email);
            enqueueSnackbar(err?.response?.data?.email ? err?.response?.data?.email[0]  :  "Failed to add user", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }

    const handleActDeactUser = async(index, type) =>{
        try{
            let payload = {...totalRowsUpdate[index], status: type}
            const response = await axios.post(`broker/update-user-status`, payload, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}})
            if(response?.status === 200){
                setIconChange(!iconChange)
                enqueueSnackbar(type === 1 ? "User activated successfully" : "User deactivated successfully", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
            }
        }catch(err){
            console.log('Data in err ==>', err);
            enqueueSnackbar(type === 1 ? "Failed to activate user" : "Failed to deactivate user", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }

    const getUuid = async() => {
        // setNewUuid("uuid-1010-gcinternal-1933")
        try{
            const response = await axios.post(`broker/generate-uuid`,{}, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}});
            if(response?.data?.status == 200){
                setNewUuid(response?.data?.data)
            }
        }catch(err){
            // console.log('Data in err ==>', err);
        }
    };

    const handleShadowAccess = async(data) =>{
        let uuid = data?.uuid;
        let name = data?.first_name + " " + data?.last_name;
        let email = data?.email;
        let api_enabled = data?.api_enabled
        let accessToken;

        try{
            const response = await axios.post(`broker/shadow-access`, {password: "VGVjaEA5MDAw"}, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}});
            if(response?.data?.status == 200){
                accessToken = response?.data?.accessToken;
                // window.open(`http://localhost:3100?uuid=${uuid}&name=${name}&email=${email}&accessToken=${accessToken}&enabled=${api_enabled}`, '_blank', 'noreferrer')
                window.open(`${process.env.REACT_APP_BROKER_PORTAL}?uuid=${uuid}&name=${name}&email=${email}&accessToken=${accessToken}&enabled=${api_enabled}`, '_blank', 'noreferrer')
            }
        }catch(err){
            enqueueSnackbar( err?.response?.data?.Message || "Failed to open shadow access", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }

    const handleCancel = (e) => {
        setFormValue({
            broker_name: '',
            sg_agent_id: '',
            sg_user_id: '',
            submit_deposit_required: 0,
            broker_api_enabled: 1,
            converting_utm_content: '',
            converting_utm_campaign: '',
            converting_utm_source: '',
            converting_utm_medium: '',
            broker_batch_enabled: "1",
            file_prefix: "n/a",
            deactivation_reason: "n/a",
            api_token: '',
            products: '',
            tfn_number: ""
        })
        setForBtnCB({
            assoProds: false,
            assoToken: false
        })
        onClose();
    }

    const handleUpdate = (e, index) => {
        let tempArrUpdate = [...totalRowsUpdate]
        const jsonToUpdate = tempArrUpdate.find((json, index1) => index1 === index);
        let finalJson = {...jsonToUpdate, [e.target.name]:e.target.value}
        tempArrUpdate[index] = finalJson
        setTotalRowsUpdate(tempArrUpdate)
    }

    const handleAdd = (e) =>{
        let finalJson = {...totalRowsAdd, [e.target.name]:e.target.value}
        setTotalRowsAdd(finalJson)
    }

    if (!open) {
        // console.log('not opened...');
        return null
    }

    const handleRemove = (index)=>{
        let arr =[...addRow]
        arr.splice(index, 1)
        setAddRow(arr)
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Box key={"close"} style={{display: "flex", justifyContent: "right", width: "100%"}}>
                            <CloseIcon style={{cursor: "pointer"}} onClick={handleCancel}/>
                        </Box>
                            <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Broker Portal Users</Typography>
                        {totalRows.length>0 && totalRows?.map((x,index) =>
                        <Box key={"user" + index} style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                            <Box style={{width:"12%"}}>
                                <MuiText
                                    label="First Name"
                                    name="first_name"
                                    defaultValue={x.first_name}
                                    onChange={(e)=>handleUpdate(e, index)}
                                    disabled = {alter[index]}
                                />
                            </Box>
                            <Box style={{width:"12%"}}>
                                <MuiText
                                    label="Last Name"
                                    name="last_name"
                                    defaultValue={x.last_name}
                                    onChange={(e)=>handleUpdate(e, index)}
                                    disabled = {alter[index]}
                                />
                            </Box>
                            <Box style={{width:"24%"}}>
                                <MuiText
                                    label="Email"
                                    name="email"
                                    defaultValue={x.email}
                                    onChange={(e)=>handleUpdate(e, index)}
                                    disabled = {true}
                                />
                            </Box>
                            <Box style={{width:"36%"}}>
                                <MuiText
                                    label="UUID"
                                    name="uuid"
                                    defaultValue={x.uuid}
                                    onChange={(e)=>handleUpdate(e, index)}
                                    disabled = {true}
                                />
                            </Box>
                            <div style={{display:"flex",marginBottom:"25px",width:"10%",justifyContent:"space-between",alignItems:"center"}}>
                                {alter[index]?
                                    <Tooltip title="Edit" arrow placement='bottom'>
                                        <EditIcon  color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize='medium'
                                            onClick={()=>{
                                                let temp = [...alter]
                                                temp[index] = !temp[index]
                                                setAlter(temp)
                                                if(alter[index]===false){
                                                    updateUserData(totalRowsUpdate[index])
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                        :
                                    <Tooltip title="Submit" arrow placement='bottom'>
                                        <ArrowCircleRightIcon color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize='medium' 
                                            onClick={()=>{
                                                let temp = [...alter]
                                                temp[index] = !temp[index]
                                                setAlter(temp)
                                                if(alter[index]===false){
                                                    updateUserData(totalRowsUpdate[index])
                                                }
                                            }}/>
                                    </Tooltip>
                                }
                                <Tooltip title="Shadow Access" arrow placement='bottom'>
                                    <VisibilityIcon color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize='medium' onClick={()=>handleShadowAccess(x)}/>
                                </Tooltip>
                                <div style={{display:"flex",alignItems:"center",marginLeft:"5px"}}>
                                    {
                                        x.status ?
                                        <Tooltip title="Deactivate User" arrow placement='right'>
                                            <BlockIcon color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize="medium" onClick={()=>handleActDeactUser(index, 0)}/>
                                        </Tooltip> : 
                                        <Tooltip title="Activate User" arrow placement='right'>
                                            <CheckCircleOutlineIcon color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize='medium' onClick={()=>handleActDeactUser(index, 1)}/>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        </Box>)}

                        {  Array.from({ length: addRow.length }).map((_, index) => (
                        <Box style={{width:"100%",display:"flex",justifyContent:"space-between"}}>
                        <Box style={{width:"12%"}}>
                                <MuiText
                                    label="First Name"
                                    name="first_name"
                                    defaultValue={formValue.first_name}
                                    onChange={(e)=>handleAdd(e)}
                                />
                            </Box>
                            <Box style={{width:"12%"}}>
                                <MuiText
                                    label="Last Name"
                                    name="last_name"
                                    defaultValue={formValue.last_name}
                                    onChange={(e)=>handleAdd(e)}
                                />
                            </Box>
                            <Box style={{width:"24%"}}>
                                <MuiText
                                    label="Email"
                                    name="email"
                                    defaultValue={formValue.email}
                                    onChange={(e)=>handleAdd(e)}
                                />
                            </Box>
                            <Box style={{width:"36%"}}>
                                {newUuid && <MuiText
                                    label="UUID"
                                    name="uuid"
                                    defaultValue={newUuid}
                                    onChange={(e)=>handleAdd(e)}
                                    disabled={true}
                                />}
                            </Box>
                            <div style={{display:"flex",marginBottom:"25px",width:"10%",justifyContent:"space-evenly",alignItems:"center"}}>
                                <Tooltip title="Submit" arrow placement='bottom'>
                                    <ArrowCircleRightIcon color="primary" sx={{ mr: 1, cursor: "pointer" }} fontSize='medium' 
                                        onClick={()=>addUserData(totalRowsAdd)}
                                    />
                                </Tooltip>
                                <div style={{display:"flex",alignItems:"center",marginLeft:"10px"}}>
                                    <Tooltip title="Remove" arrow placement='bottom'>
                                        {<CancelIcon color="primary" sx={{ mr: 1, cursor:"pointer" }} fontSize="medium" onClick={()=> handleRemove(index)}/>}
                                    </Tooltip>
                                </div>
                            </div>
                        </Box>))}

                        {totalRows.length<=0 ?<Box style={{display:"flex",justifyContent:"center",width:"100%"}}>
                                <Typography>You haven't added any Broker Portal User. <span style={{cursor:"pointer",textDecoration:"underline",color:"blue"}} 
                                onClick={()=>{
                                    getUuid()
                                    const arr = [...addRow]
                                    arr.push(addRow.length+1)
                                    setAddRow(arr)
                                }}
                                >Add</span></Typography>
                            </Box>:
                            <ThemedButton
                            onClick={()=>{
                                getUuid()
                                const arr = [...addRow]
                                arr.push(addRow.length+1)
                                setAddRow(arr)
                            }}
                            contained={"true"}
                            width="25%"
                            disabled = {addRow?.length>0 ? true : false}
                        >
                                Add User
                        </ThemedButton>}
                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default BrokerPortalUsersModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "75%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 2vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    forCheckButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'none',
        padding: '0px 0px 20px 0px',
        cursor: 'pointer',
        position: 'absolute',
    },
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))