import React from "react";
import styled from "styled-components";

export const FieldLabel = styled.label`
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  display: block;
  margin: 0 0 0.5rem 1rem;

  &::after {
    content: "*";
    display: ${(props) => (props.requiredAsterisk ? "inline" : "none")};
    color: #ff5a6a;
    margin-left: 0.25rem;
  }
  &.disabled {
    color: rgba(33, 33, 33, 0.52);
  }
`;

export const TextField = styled.input`
  background-color: #ffffff;
  color: #3a3a3a;
  border: 1px solid #e6e6e6;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(224, 224, 224, 0.1);
  border-radius: 8px;
  padding: 0.6875rem;
  font-size: 1rem;
  line-height: 26px;
  letter-spacing: -0.1px;
  font-weight: 600;
  outline: none;
  transition: all 0.25s;

  &::placeholder {
    color: rgba(33, 33, 33, 0.52);
  }
  &:hover {
    border: 1px solid #ff5a6a;
  }
  &:focus {
    background-color: #fff7f8;
    border: 1px solid #ff5a6a;
  }
  /* &:disabled {
    color: #e6e6e6;
  } */
  &.error {
    border: 2px solid #ff3448;
    padding-right: calc(1rem + 18px + 0.6875rem);
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.71758 2.895L1.36508 13.5C1.2341 13.7268 1.1648 13.984 1.16407 14.2459C1.16334 14.5078 1.23119 14.7653 1.3609 14.9929C1.4906 15.2204 1.67762 15.4101 1.90336 15.5429C2.12909 15.6757 2.38568 15.7471 2.64758 15.75H15.3526C15.6145 15.7471 15.8711 15.6757 16.0968 15.5429C16.3225 15.4101 16.5096 15.2204 16.6393 14.9929C16.769 14.7653 16.8368 14.5078 16.8361 14.2459C16.8354 13.984 16.766 13.7268 16.6351 13.5L10.2826 2.895C10.1489 2.67458 9.96062 2.49234 9.73597 2.36586C9.51133 2.23939 9.25788 2.17294 9.00008 2.17294C8.74227 2.17294 8.48882 2.23939 8.26418 2.36586C8.03953 2.49234 7.85128 2.67458 7.71758 2.895V2.895Z' stroke='%23FF3448' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 6.75V9.75' stroke='%23FF3448' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 12V12.375' stroke='%23FF3448' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 18px 18px;
  }

  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-inner-spin-button {
    // Hide default calendar icon on Webkit browsers
    opacity: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: pointer;
  }
`;

// Custom checkbox input field
const CheckboxFieldLabelContainer = styled.label`
  display: block;
  position: relative;
  padding-left: calc(18px + 1rem);
  cursor: pointer;
  color: #3a3a3a;
  font-size: 0.875rem;
  user-select: none;

  & input[type="checkbox"] {
    position: absolute;
    /* opacity: 0; */
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    /* cursor: pointer;
    height: 0;
    width: 0; */
  }
  & span.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 4px;
    border: 1px solid rgba(9, 15, 71, 0.22);
    background-color: inherit;
    transition: all 0.25s;
  }
  & input[type="checkbox"]:focus ~ .checkmark {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  & .checkmark::after {
    content: "";
    position: absolute;
    display: none;
  }
  & input:checked ~ .checkmark::after {
    display: block;
  }
  & .checkmark::after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #ffffff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
  }
`;

export function CustomCheckboxField({
                                        labelText,
                                        inputName,
                                        inputID,
                                        field,
                                        form,
                                        ...props
                                    }) {
    return (
        <CheckboxFieldLabelContainer>
            {labelText}
            <input
                type="checkbox"
                name={inputName}
                id={inputID}
                {...field}
                {...props}
            />
            <span className="checkmark"></span>
        </CheckboxFieldLabelContainer>
    );
}

export function AcceptTermsLabelText() {
    return (
        <>
            By checking this box, I acknowledge that I have read the{" "}
            <a
                href="/GoodCharlie-free-electricity-sweepstakes-official-rules-terms-and-conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#0bcebb", textDecoration: "none" }}
            >
                Sweepstakes Rules
            </a>{" "}
            and the information entered above is accurate.
        </>
    );
}
