import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";
import CloseIcon from '@mui/icons-material/Close';
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch, InputRow1, MuiDateTime, MuiDate } from 'components/StyledInputFields';
import { Box, Checkbox as CBX, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import useCommonStyles from 'components/CommonStyleClasses';
import {TextField} from "@material-ui/core"
import { makeStyles } from '@mui/styles';
import { Typography, Tooltip, Alert, AlertTitle } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';
import { EditOutlined, Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import  { tableCellClasses } from '@mui/material/TableCell';
import { MuiAutocomplete } from 'components/StyledInputFields';
import dummyData from "../../dummydata/commissions_data.json"
import { commissionDataFormatter, commissionProductFormatter, tableFlagFormatter } from 'utils/commissionPostFormatter';


const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const ComstrModal = ({ open, onClose, loadEnrollments,tableData }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [allProductsData, setAllProductsData] = useState([])
    const [forBtnCB, setForBtnCB] = useState({
        assoProds: false,
        assoToken: false
    })
    const [totalRowsAdd, setTotalRowsAdd] = useState([])
    const [edit,setEdit] = useState(false)
    const [addData,setAddData] = useState(false)
    const [formValue, setFormValue] = useState({
        frequency: "",
        clawback: "",
        date: "",
        status: "",
        utmContent:"",
    });
    const tableData1 = [{
        "name":"Good Charlie",
        "plan_term": "45m",
        "plan_id": "2060",
        "upfront": "p47",
        "residual": "6mills",
        "payment": "Monthly",
        "clawback": "19",
        "date": "07/08/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "96m",
        "plan_id": "2049",
        "upfront": "g85",
        "residual": "1mills",
        "payment": "Monthly",
        "clawback": "82",
        "date": "04/17/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "76m",
        "plan_id": "2027",
        "upfront": "l17",
        "residual": "2mills",
        "payment": "Monthly",
        "clawback": "90",
        "date": "07/10/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "56m",
        "plan_id": "2089",
        "upfront": "h36",
        "residual": "1mills",
        "payment": "Monthly",
        "clawback": "05",
        "date": "12/09/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "56m",
        "plan_id": "2056",
        "upfront": "p39",
        "residual": "4mills",
        "payment": "Monthly",
        "clawback": "50",
        "date": "03/26/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "55m",
        "plan_id": "2011",
        "upfront": "986",
        "residual": "3mills",
        "payment": "Monthly",
        "clawback": "38",
        "date": "07/04/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "91m",
        "plan_id": "2056",
        "upfront": "o66",
        "residual": "5mills",
        "payment": "Monthly",
        "clawback": "12",
        "date": "12/16/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "63m",
        "plan_id": "2013",
        "upfront": "l73",
        "residual": "6mills",
        "payment": "Monthly",
        "clawback": "16",
        "date": "08/19/2022",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "77m",
        "plan_id": "2075",
        "upfront": "k57",
        "residual": "5mills",
        "payment": "Monthly",
        "clawback": "54",
        "date": "01/12/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }, {
        "plan_term": "18m",
        "plan_id": "2075",
        "upfront": "k57",
        "residual": "7mills",
        "payment": "Monthly",
        "clawback": "08",
        "date": "03/25/2023",
        "utm_content": "GOOD",
        "status": "Pending"
      }]
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    // const heading2 = ["Plan Term","Plan Id","Upfront","Residual","Upfront","Residual"]
    const [tableFlags, setTableFlags] = useState({
        acqComp: false,
        acqUpfront: false,
        acqResidual: false,
        renComp: false,
        renUpfront: false,
        renResidual: false,
    })

    const [dataAvail, setDataAvail] = useState(false)
    const [data, setData] = useState();
    const [editingId, setEditingId] = useState(null);
    const heading1 = ["Product Details","Acquisition Compensation","Residual Compensation"];
    const heading2 = ['Plan ID', 'Plan Name', 'Plan Term', "Upfront", "Residual", "Upfront", "Residual"];
    const [tableColumns,setTableColumns]=useState(["plan_id","plan_name","plan_term","acq_upfront","acq_residual","ren_upfront","ren_residual"])
    const dontEditColumns = ["sg_product_id", "sg_product_name"]
    const [dontShowColumns,setDontShowColumns] = useState(["acq_upfront","acq_residual","ren_upfront","ren_residual"])
    const [commissionsData,setCommissionData] = useState();
    const [productArray,setProductArray] = useState();
    const [updateId,setUpdateId] = useState()
    const [refetch,setRefetch] = useState(false)
    const [trueCount, setTrueCount] = useState(4)

    // useEffect(()=>{
    //     const count = Object.values(tableFlags).reduce((count, value) => count + (value === true ? 1 : 0), 0);

    // },[tableFlags])

    const handleFieldChange = (event, field, id) => {
        console.log('Data in field ==>', field)
        const newData = data.map(item => {
            if (item.sg_product_id === id) {
                return { ...item, [field]: event.target.value };
            }
            return item;
        });
        setData(newData);
        if(event.target.value === "" || event.target.value === null) { // Only allow numeric values
            const newData = data.map(item => {
                if (item.sg_product_id === id) {
                    return { ...item, [field]: "" };
                }
                return item;
            });
            setData(newData);
        }
    };

    const enableEditing = (id) => {
        setEditingId(id);
    };
    
    const saveEdits = (id) => {
        setEditingId(null);
    };

    const handleTableSubmit = async() =>{
        let finalValues={...formValue}
        if(formValue.clawback <=0 ){
             finalValues = {...formValue,clawback : 0}
        }

        let cmd = commissionDataFormatter(finalValues)
        let tf = tableFlagFormatter(tableFlags)
        let cpf = commissionProductFormatter(data, tableData.id, tableFlags)
        let url = `internal/brokers/broker-commision`
        let finalPayload = {
            broker_id: tableData.id,
            ...cmd,
            ...tf,
            brokerProduct: cpf,
        }
        if(edit){
            finalPayload = {...finalPayload, id:updateId}
            url = `internal/brokers/update-broker-commision`
        }
        try{
            const response = await axios.post(url, finalPayload, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}})
            if(response?.status === 200 || response?.status === 201){
                // setEdit(false)
                // setAddData(false)
                enqueueSnackbar(response?.status == 201 ? "Commission Updated" : "Commission Added", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
                setEdit(false)
                setAddData(false)
                setRefetch(!refetch)
            }
        }catch(err){
            console.log('Data in err ==>', Object.values(err?.response?.data).length)
            if( Object.values(err?.response?.data).length>0){
                enqueueSnackbar("Please fill all the fields", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'error'
                })
            }
            else{
                enqueueSnackbar("Error Occured", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });}
        }
    }

    useEffect(()=>{
        const fetchData = async page => {
            try{
                const response = await axios.get(`internal/brokers/broker-commision`, { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}, params:{broker_id:tableData.id}});
                console.log('Data in response ==>', response.data)
                if(response.data.status === 200){
                    response?.data?.brokerCommision ? setDataAvail(true) : null
                    setCommissionData(response?.data?.brokerCommision)
                    setAllProductsData(response?.data?.brokerProduct)
                    setTableFlags({
                        acqComp: response?.data?.brokerCommision?.acq_comp ? true : false,
                        acqUpfront: response?.data?.brokerCommision?.acq_comp_upfront ? true : false,
                        acqResidual: response?.data?.brokerCommision?.acq_comp_residual ? true : false,
                        renComp: response?.data?.brokerCommision?.renewal_comp ? true : false,
                        renUpfront: response?.data?.brokerCommision?.renewal_comp_upfront ? true : false,
                        renResidual: response?.data?.brokerCommision?.renewal_comp_residual ? true : false
                    })
                    setFormValue({
                        frequency: response?.data?.brokerCommision?.payment_frequency,
                        clawback: response?.data?.brokerCommision?.clawback_period,
                        date: response?.data?.brokerCommision?.effective_date,
                        status: response?.data?.brokerCommision?.broker_status === 1 ? "Active" :response?.data?.brokerCommision?.broker_status === 0 ? "Inactive":"Pending",
                        // utmContent: response?.data?.brokerCommision?.converting_utm_content,
                    })
                    setData(response?.data?.brokerProduct)
                    setUpdateId(response?.data?.brokerCommision?.id)
                }
            }catch(err){
                console.log('Data in err ==>', err)
            }
        };
        fetchData();
    },[refetch])



    const handleCancel = (e) => {
        setFormValue({
            broker_name: '',
            sg_agent_id: '',
            sg_user_id: '',
            submit_deposit_required: 0,
            broker_api_enabled: 1,
            converting_utm_content: '',
            converting_utm_campaign: '',
            converting_utm_source: '',
            converting_utm_medium: '',
            broker_batch_enabled: "1",
            file_prefix: "n/a",
            deactivation_reason: "n/a",
            api_token: '',
            products: '',
            tfn_number: ""
        })
        setForBtnCB({
            assoProds: false,
            assoToken: false
        })
        onClose();
    }

    const handleChange = (e) => {
        // if (e.target.name === "broker_api_enabled") {
        //     let reason = e.target.checked === false ? "" : "n/a"
        //     setFormValue({
        //         ...formValue,
        //         broker_api_enabled: e.target.checked ? 1 : 0,
        //         deactivation_reason: reason,
        //     })
        // } else {
            setFormValue({
                ...formValue,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 
                : e.target.value
            });
            if(e.target.name === "clawback" && e.target.value<=0){
                e.target.value = 0;
            }
        // }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
            fontWeight:"bold",
            background:"#FFF1F1"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            fontWeight: 500,
            // background:"#FFF7F8"
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
          '&:nth-of-type(even)': {
              backgroundColor: "#FFF1F1",
              background:"#FFF1F1"
          },
          // hide last border
          // '&:last-child td, &:last-child th': {
          //     border: 0,
          // },
      }));

    const handleAdd = (e) =>{
        let finalJson = {...totalRowsAdd, [e.target.name]:e.target.value}
        setTotalRowsAdd(finalJson)
    }

    if (!open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Box style={{display: "flex", justifyContent: "right", width: "100%"}}>
                            <CloseIcon style={{cursor: "pointer"}} onClick={handleCancel}/>
                        </Box>
                            {((dataAvail && edit) || addData) && <Button onClick={()=>{setEdit(false);setAddData(false)}}>BACK</Button>}
                            <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Commissions
                            {(dataAvail && !edit && !addData) && <Tooltip title="Edit" arrow placement='bottom'>
                                <EditIcon sx={{ ml: 2, cursor: "pointer",alignSelf:"center" }} onClick={()=>setEdit(true)} fontSize='medium'/>
                            </Tooltip>}
                            </Typography>
                            {(dataAvail && !edit && !addData)?
                            <>
                                <div style={{display:"flex",width:"100%"}}>
                                    <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                                        <Box style={{width: "50%"}}>
                                            <Typography className={classes.cardSubheading} align='left'>Payment Details</Typography>
                                            <Box className={classes.headingUnderline}></Box>
                                            <Box>
                                                <Box style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                                                    <Typography className={classes.contentHeaing}>Payment Frequency</Typography>
                                                    <Typography className={classes.contentText} >{commissionsData?.payment_frequency}</Typography>
                                                </Box>
                                                <Box style={{display: "flex", justifyContent: "space-between", marginTop: "5px"}}>
                                                    <Typography className={classes.contentHeaing}>Clawback Period (days)</Typography>
                                                    <Typography className={classes.contentText} >{commissionsData?.clawback_period}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                                        <Box style={{width: "40%"}}>
                                            <Typography className={classes.cardSubheading} align='left'>Contractual Details</Typography>
                                            <Box className={classes.headingUnderline}></Box>
                                            <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                <Typography className={classes.contentHeaing}>Effective Date</Typography>
                                                <Typography className={classes.contentText} >{commissionsData?.effective_date}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                                <div style={{display:"flex", width:"100%", marginTop: "20px"}}>
                                    <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                                        <Box style={{width: "40%"}}>
                                            <Typography className={classes.cardSubheading} align='left'>Status</Typography>
                                            <Box className={classes.headingUnderline}></Box>
                                            <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                <Typography className={classes.contentHeaing}>Status</Typography>
                                                <Typography className={classes.contentText} >{commissionsData?.commision_status == 1 ? "Active" : commissionsData?.commision_status == 0 ? "Inactive":"Pending"}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                                        <Box style={{width: "40%"}}>
                                            <Typography className={classes.cardSubheading} align='left'>Tracking</Typography>
                                            <Box className={classes.headingUnderline}></Box>
                                            <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                <Typography className={classes.contentHeaing}>UTM Content</Typography>
                                                <Typography className={classes.contentText} >{commissionsData?.converting_utm_content}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </div>
                                <Box className={classes.agentTable} >
                                    <TableContainer className="tableContainer">
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}} colSpan={2}>Plan & Product</StyledTableCell>
                                                    {commissionsData?.acq_comp && (commissionsData?.acq_comp_residual || commissionsData?.acq_comp_upfront) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}} colSpan={(commissionsData.acq_comp && commissionsData.acq_comp_upfront && commissionsData.acq_comp_residual) ? 2 : 1}>Acq Compensation</StyledTableCell>:null}
                                                    {commissionsData?.renewal_comp && (commissionsData?.renewal_comp_residual || commissionsData?.renewal_comp_upfront) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}} colSpan={(commissionsData.renewal_comp && commissionsData.renewal_comp_upfront && commissionsData.renewal_comp_residual) ? 2 : 1}>Renewal Compensation</StyledTableCell>:''}
                                                </TableRow>
                                                <TableRow>
                                                    <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Plan Term</StyledTableCell>
                                                    <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Plan Id</StyledTableCell>

                                                    {(commissionsData?.acq_comp && commissionsData?.acq_comp_upfront) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Upfront</StyledTableCell>:null}
                                                    {(commissionsData?.acq_comp && commissionsData?.acq_comp_residual) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Residual</StyledTableCell>:null}
                                                    {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_upfront) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Upfront</StyledTableCell>:null}
                                                    {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_residual) ? <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FFF1F1",whiteSpace:"nowrap",textAlign:"center"}}>Residual</StyledTableCell>:null}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={classes.providerTableBody}>
                                                {allProductsData?.map((row, index) => {
                                                    return(
                                                    <StyledTableRow key={index}>
                                                        {<StyledTableCell style={{textAlign:"center"}}><div>{row?.sg_product_name}</div></StyledTableCell>}
                                                        {<StyledTableCell style={{textAlign:"center"}}><div>{row?.sg_product_id}</div></StyledTableCell>}
                                                        {(commissionsData?.acq_comp && commissionsData?.acq_comp_upfront) ?<StyledTableCell style={{textAlign:"center"}}><div>$&nbsp;{row.acq_comp_upfront}</div></StyledTableCell>:null}
                                                        {(commissionsData?.acq_comp && commissionsData?.acq_comp_residual) ? <StyledTableCell style={{textAlign:"center"}}><div>{row.acq_comp_residual}&nbsp;mils</div></StyledTableCell>:null}
                                                        {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_upfront) ? <StyledTableCell style={{textAlign:"center"}}><div>$&nbsp;{row.renewal_comp_upfront}</div></StyledTableCell>:null}
                                                        {(commissionsData?.renewal_comp && commissionsData?.renewal_comp_residual) ? <StyledTableCell style={{textAlign:"center"}}><div>{row.renewal_comp_residual}&nbsp;mils</div></StyledTableCell>:null}
                                                    </StyledTableRow>
                                                )})}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </>
                            :
                            (((dataAvail && edit) || addData)?
                                <>
                                    <div style={{display:"flex",width:"100%"}}>
                                        <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                                            <Box style={{width: "50%"}}>
                                                <Typography className={classes.cardSubheading} align='left'>Payment Details</Typography>
                                                <Box className={classes.headingUnderline}></Box>
                                                <Box>
                                                    <Box style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                                                        <MuiAutocomplete
                                                            label="Payment Frequency"
                                                            name="frequency"
                                                            defaultValue={formValue?.frequency}
                                                            onChange={handleChange}
                                                            formValue={formValue}
                                                            setFormValue={setFormValue}
                                                            options={[
                                                                { title: 'Monthly', value: "monthly" },
                                                                { title: 'Quaterly', value: "quaterly" },
                                                                { title: 'Yearly', value: "yearly" }
                                                            ]}
                                                        /> 
                                                    </Box>
                                                    <Box style={{display: "flex", justifyContent: "space-between", marginTop: "5px"}}>
                                                        <MuiText
                                                            label="Clawback Period"
                                                            name="clawback"
                                                            defaultValue={formValue?.clawback}
                                                            onChange={handleChange}
                                                            type= "number"
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                                pattern: '[0-9]*',
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                                            <Box style={{width: "50%"}}>
                                                <Typography className={classes.cardSubheading} align='left'>Contractual Details</Typography>
                                                <Box className={classes.headingUnderline}></Box>
                                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                    <MuiDate
                                                        name="date"
                                                        label="Effective Date"
                                                        defaultValue={formValue?.date}
                                                        // onChange={handleChange}
                                                        formValue={formValue}
                                                        setFormValue={setFormValue}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box> 
                                    </div> 
                                    <div style={{display:"flex", width:"100%", marginTop: "20px"}}>
                                        <Box style={{display: "flex", justifyContent: "left", width: "50%", paddingLeft: "10px"}}>
                                            <Box style={{width: "50%"}}>
                                                <Typography className={classes.cardSubheading} align='left'>Status</Typography>
                                                <Box className={classes.headingUnderline}></Box>
                                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                    <MuiAutocomplete
                                                        label="Status"
                                                        name="status"
                                                        defaultValue={commissionsData?.commision_status == 0?"Inactive":commissionsData?.commision_status == 1?"Active":commissionsData?.commision_status == 2?"Pending":""}
                                                        onChange={handleChange}
                                                        options={[
                                                            { title: 'Inactive', value: "0" },
                                                            { title: 'Active', value: "1" },
                                                            { title: 'Pending', value: "2" }
                                                        ]}
                                                        formValue={formValue}
                                                        setFormValue={setFormValue}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{display: "flex", justifyContent: "left", width: "50%"}}>
                                            <Box style={{width: "50%"}}>
                                                <Typography className={classes.cardSubheading} align='left'>Tracking</Typography>
                                                <Box className={classes.headingUnderline}></Box>
                                                <Box style={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: "10px"}}>
                                                    <MuiText
                                                        label="UTM Content"
                                                        name="utmContent"
                                                        defaultValue={ tableData?.converting_utm_content}
                                                        disabled
                                                        onChange={handleChange}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </div> 
                                    {/* table */}
                                    <Box style={{width:"100%",display:"flex"}}>
                                        <div style={{paddingLeft:"10px",width:"50%",marginTop:"20px"}}>
                                            <Switch
                                                label="Acquisition Compensation"
                                                type="checkbox"
                                                defaultChecked={commissionsData?.acq_comp}
                                                onChange={()=>{
                                                    setTableFlags({...tableFlags, acqComp: !tableFlags.acqComp})
                                                }}
                                                />
                                            <div style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                                                <Switch
                                                    label="Upfront"
                                                    type="checkbox"
                                                    defaultChecked={commissionsData?.acq_comp_upfront}
                                                    onChange={()=>{setTableFlags({...tableFlags, acqUpfront: !tableFlags.acqUpfront})
                                                    // tableFlags.acqComp && tableFlags.acqUpfront && removeElement("acq_upfront")
                                                }}
                                                />
                                                <Switch
                                                    label="Residual"
                                                    type="checkbox"
                                                    defaultChecked={commissionsData?.acq_comp_residual}
                                                    onChange={()=>setTableFlags({...tableFlags, acqResidual: !tableFlags.acqResidual})}
                                                />
                                            </div>
                                        </div>
                                        <div style={{width:"50%",marginTop:"20px"}}>
                                            <Box>
                                                <Switch
                                                    label="Renewal Compensation"
                                                    type="checkbox"
                                                    defaultChecked={commissionsData?.renewal_comp}
                                                    onChange={()=>setTableFlags({...tableFlags, renComp: !tableFlags.renComp})}
                                                />
                                            </Box>
                                            <div style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                                                <Switch
                                                    label="Upfront"
                                                    type="checkbox"
                                                    defaultChecked={commissionsData?.renewal_comp_upfront}
                                                    onChange={()=>setTableFlags({...tableFlags, renUpfront: !tableFlags.renUpfront})}
                                                />
                                                <Switch
                                                    label="Residual"
                                                    type="checkbox"
                                                    defaultChecked={commissionsData?.renewal_comp_residual}
                                                    onChange={()=>setTableFlags({...tableFlags, renResidual: !tableFlags.renResidual})}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                    <Box width={"100%"}>
                                        <Box style={{display: "flex", width: "100%", justifyContent: "space-between", padding: "20px 0px 0px 15px"}}>
                                            <p>{edit ? "EDIT" : "ADD"} &nbsp;<b>COMMISSIONS</b></p>
                                        </Box>
                                        <TableContainer>
                                            <Table size='small'>
                                                <TableHead>
                                                    <StyledTableRow>
                                                        {/* <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center", minWidth: "188px"}} >SALES - TARGET</StyledTableCell> */}
                                                            <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}} colSpan={3} className={classes.tableCell1}>{"Plan Details"}</StyledTableCell>
                                                            {(tableFlags?.acqComp && (tableFlags?.acqUpfront || tableFlags?.acqResidual)) && <StyledTableCell className={classes.tableCell1} colSpan={tableFlags?.acqUpfront && tableFlags?.acqResidual?2:1} style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}>{"Acquisition Compensation"}</StyledTableCell>}
                                                            {(tableFlags?.renComp && (tableFlags?.renUpfront || tableFlags?.renResidual)) && <StyledTableCell className={classes.tableCell1} colSpan={tableFlags?.renUpfront && tableFlags?.renResidual?2:1} style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}>{"Renewal Compensation"}</StyledTableCell>}
                                                            <StyledTableCell style={{marginLeft:"20px",fontWeight:"800",fontSize:"15px",background:"#FF7676",whiteSpace:"nowrap",textAlign:"center"}}></StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableHead>
                                                    <TableRow>
                                                        {/* <StyledTableCell style={{fontWeight: "bold", display: "flex", alignItems:"center", minWidth: "188px"}} >SALES - TARGET</StyledTableCell> */}
                                                        
                                                            <StyledTableCell className={classes.tableCell1} >Plan Id</StyledTableCell>
                                                            <StyledTableCell className={classes.tableCell1} style={{width:"15%"}}>Plan Name</StyledTableCell>
                                                            <StyledTableCell className={classes.tableCell1} >Plan Term</StyledTableCell>
                                                            {tableFlags?.acqComp && tableFlags?.acqUpfront && <StyledTableCell className={classes.tableCell1} >Upfront</StyledTableCell>}
                                                            {tableFlags?.acqComp && tableFlags?.acqResidual && <StyledTableCell className={classes.tableCell1}  >Residual</StyledTableCell>}
                                                            {tableFlags?.renComp && tableFlags?.renUpfront && <StyledTableCell className={classes.tableCell1} >Upfront</StyledTableCell>}
                                                            {tableFlags?.renComp && tableFlags?.renResidual && <StyledTableCell className={classes.tableCell1} >Residual</StyledTableCell>}
                                                            <StyledTableCell className={classes.tableCell1}  >EDIT</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {data?.map((row,index) => (
                                                    <TableRow key={row?.sg_product_id} 
                                                        style={{ backgroundColor: index % 2 !== 0 ? '#FFF1F1' : 'transparent' }}
                                                    >
                                                        {true && 
                                                        <TableCell className={classes.tableCell1} style={{minWidth:"8%"}}>
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("sg_product_id")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                variant="outlined"
                                                                value={row["sg_product_id"]}
                                                                onChange={event => handleFieldChange(event, "sg_product_id", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                            row["sg_product_id"]
                                                            )}
                                                        </TableCell>}
                                                        {true && 
                                                        <TableCell className={classes.tableCell1} style={{minWidth:"15%"}}>
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("sg_product_name")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["sg_product_name"]}
                                                                onChange={event => handleFieldChange(event, "sg_product_name", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                            row["sg_product_name"]
                                                            )}
                                                        </TableCell>}
                                                        {true && 
                                                        <TableCell className={classes.tableCell1} >
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("plan_term")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["plan_term"]}
                                                                onChange={event => handleFieldChange(event, "plan_term", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                            row["sg_product_term"]
                                                            )}
                                                        </TableCell>}
                                                        {tableFlags?.acqComp && tableFlags?.acqUpfront && 
                                                        <TableCell className={classes.tableCell1}>
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("acq_comp_upfront")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["acq_comp_upfront"]}
                                                                onChange={event => handleFieldChange(event, "acq_comp_upfront", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                                row["acq_comp_upfront"]?"$ "+row["acq_comp_upfront"] : "$ 0"
                                                            )}
                                                        </TableCell>}
                                                        {tableFlags?.acqComp && tableFlags?.acqResidual && 
                                                        <TableCell className={classes.tableCell1}>
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("acq_comp_residual")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["acq_comp_residual"]}
                                                                onChange={event => handleFieldChange(event, "acq_comp_residual", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                                row["acq_comp_residual"]?row["acq_comp_residual"]+" mils": "0 mils"
                                                            )}
                                                        </TableCell>}
                                                        {tableFlags?.renComp && tableFlags?.renUpfront && 
                                                        <TableCell className={classes.tableCell1}>
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("renewal_comp_upfront")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["renewal_comp_upfront"]}
                                                                onChange={event => handleFieldChange(event, "renewal_comp_upfront", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                                row["renewal_comp_upfront"]?"$ "+row["renewal_comp_upfront"]: "$ 0"
                                                            )}
                                                        </TableCell>}
                                                        {tableFlags?.renComp && tableFlags?.renResidual && 
                                                        <TableCell className={classes.tableCell1} >
                                                            {(editingId === row.sg_product_id && !dontEditColumns.includes("renewal_comp_residual")) ? (
                                                            <TextField
                                                                style={{heigth:"14px",marginRight:"20px",marginLeft:'20px'}}
                                                                value={row["renewal_comp_residual"]}
                                                                onChange={event => handleFieldChange(event, "renewal_comp_residual", row.sg_product_id)}
                                                            />
                                                            ) : (
                                                                row["renewal_comp_residual"]?row["renewal_comp_residual"]+" mils":"0 mils"
                                                            )}
                                                        </TableCell>}
                                                        <TableCell className={classes.tableCell1}>
                                                            {editingId === row?.sg_product_id ? (
                                                                <SaveIcon onClick={() => saveEdits(row?.sg_product_id)} style={{cursor:"pointer"}}/>
                                                            ) : (
                                                                <EditIcon onClick={() => enableEditing(row?.sg_product_id)} style={{cursor:"pointer"}}/>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box style={{marginTop: 20, display: "flex", justifyContent: "right"}}>
                                            <Button variant='contained' onClick={handleTableSubmit}>Submit</Button>
                                        </Box>
                                    </Box>
                                </>:
                            <Box style={{display:"flex",justifyContent:"center",width:"100%"}}>
                                <Typography>You haven't added Commission Structure. <span style={{cursor:"pointer",textDecoration:"underline",color:"blue"}} onClick={()=>setAddData(true)}>Add</span></Typography>
                            </Box>)}
                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default ComstrModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "75%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '10px 2vw 10px 2vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    agentTable: {
        marginTop: "30px",
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    forCheckButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'none',
        padding: '0px 0px 20px 0px',
        cursor: 'pointer',
        position: 'absolute',
    },
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    },
    cardSubheading:{
        font: "normal normal normal 24px Roboto",
        letterSpacing: "0.12px",
        color: "#033333",
        margin:"20px 0px 0px 0px",
        fontWeight:"600 !important"
    },
    headingUnderline:{
        background: "#FF7676",
        border: "1px solid #FF7676",
        borderRadius: "20px",
        width: "80px",
        height: "4px"
    },
    contentHeaing: {
        font: "normal normal normal 16px/35px Roboto",
        letterSpacing: "0.12px",
        color: "#033333",
        fontWeight: "600"
    },
    contentText: {
        font: 'normal normal normal 16px/35px Roboto',
        letterSpacing: "0.11px",
        color: '#707070'
    },
    tableCell1:{
        textAlign:'center !important'
    }
}))