import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const MyRichTextEditor = ({emailBody,setEmailBody,mailType}) => {
    const [text, setText] = useState('');       
    useEffect(()=>{
        // const initialContent = '<p>This is the pre-populated HTML content.</p>'
        // setText(initialContent)
        // console.log("eb",emailBody)
        setText(emailBody)
    },[mailType])
    const handleChange = (value) => {
        setText(value);
        setEmailBody(`<html><body>${value}</body></html>`)
    };
    return <ReactQuill value={text} onChange={handleChange} style={{width:"100%",marginBottom:"50px",height:"200px"}} />;
};

export default MyRichTextEditor;