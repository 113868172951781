import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import {default as OneRowReferralSelector, handleUnselect} from "../../components/OneRowReferralSelector";
import {Menu, MenuItem, Table} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";
import moment from "moment";
import ReferralModal from "../../components/modals/ReferralModal";
import Button from "react-bootstrap/Button";
import {CSVLink} from "react-csv";
const openInit = {
    createReferral: false,
    editReferral: false,
    editType: 'update',
    deactivate: false,
    userPortal: false
}

export const Referrals =  (props) => {
    const [allItems, setAllItems] =  useState([]);
    const [referralsData, setReferralsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [anchorAction, setAnchorAction] = useState(null);
    const [referralData, setReferralData] = useState([]);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    let filteredItems = referralsData.filter(
        item => !item.account_number || (item.account_number && item.account_number.toLowerCase().includes(filterText.toLowerCase())),
    );
    const disableDownload = false;
    const [isOpen, setIsOpen] = useState(openInit);
    const handleClose = async (newRecord, doUpdate = false) => {

        setIsOpen(openInit);

        //console.log('handleClose',referralData);
        handleUnselect(referralData,referralData);
        if (doUpdate) {
            //console.log('newRecord', newRecord);
            newRecord.a_id = referralData.a_id;
            setReferralsData(prevData => prevData.map(row =>
                row.a_id === newRecord.a_id ? { ...row, ...newRecord } : row
            ));
        }

    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };
    const handleExpandReferral = (row,tableData) => {
        const data = row;
        //console.log('handleExpandReferral:row',row);
        //console.log('handleExpandReferral:tdl',tableData.length);
        //console.log('handleExpandReferral:td',tableData);

            if (data.needs_create) {
                //console.log('handle create');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'create' })
            } else if (data.needs_update) {
                //console.log('handle edit');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'update' })
            } else {
                //console.log('already updated');
                handleActionClose();
                setReferralData(data);
                setIsOpen({ ...openInit, editReferral: true, editType: 'updated' })
            }

    }

    const ExpandedComponent = ({ data }) =>
        <Table striped bordered hover responsive="sm">
            <tr>
                <td><strong>Type</strong></td>
                <td><strong>Account</strong></td>
                <td><strong>Status</strong></td>
                <td><strong>Enrollment Date</strong></td>
                <td><strong>Referral Code</strong></td>
            </tr>
            <tr>
                <td>{data.a_type}</td>
                <td>{data.account_number}</td>
                <td>{data.account_status}</td>
                <td>{data.account_start}</td>
                <td>{!data.referral_code ? (!data.preferral_code ? (!data.preferralCode ? '' : data.preferralCode) : data.preferral_code) : data.referral_code}</td>
            </tr>
            <tr>
                <td><strong>TransactionId</strong></td>
                <td><strong>Ref Account</strong></td>
                <td><strong>Status</strong></td>
                <td><strong>Enrollment Date</strong></td>
            </tr>
            <tr>
                <td>{data.account_transaction_id}</td>
                <td>
                    {data.ref_account_number}<br/>{data.ref_account_id ? `(${data.ref_account_id})`:''}
                </td>
                <td>{data.ref_account_status}</td>
                <td>{data.ref_account_start}</td>
            </tr>

            <tr>
                <td><strong>Amount</strong></td>
                <td><strong>Date</strong></td>
                <td><strong>Created By</strong></td>
                <td><strong>Bill Id</strong></td>

            </tr>
            <tr>
                <td>{data.amount_paid}</td>
                <td>{data.transaction_date}</td>
                <td>{data.created_by_username}</td>
                <td>{data.bill_id}</td>

            </tr>
        </Table>
    ;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText:
            'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50, 100, 250, 500, 1000, 2000
    ];
    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: '12px',
                paddingLeft:'0 0px',
                paddingRight:'0 0px',
                fontWeight: 'bold',
                /*paddingLeft: '0 8px',*/
                justifyContent: 'center',
                /*backgroundColor: '#FFA500'*/
            },
        },
    }
    const conditionalRowStyles = [

        {
            when: row => row.needs_update,
            style: {
                backgroundColor: 'lightblue',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.needs_create,
            style: {
                backgroundColor: 'lightyellow',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.a_type === 'invalid',
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.account_id  === row.raf_account_id,
            style: {
                backgroundColor: 'orange',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }

        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const handleSelectType = (event) => {
        //console.log(event.target);
        //console.log(event.target.selectedOptions[0].value);
        const raf_id = event.target.getAttribute('raf_id')
        //console.log(event.target.getAttribute('raf_id'));
        if (event.target.selectedOptions[0].value === '1') {
            //console.log('in')
            document.getElementById('refacc_' + raf_id).value = document.getElementById('acc_' + raf_id).value;
            document.getElementById('acc_' + raf_id).value = null;
        } else {
            //console.log('not');
            //console.log(event.target.selectedOptions[0].value);
        }
    }
    const onFilter = (e) => {
        setFilterText(e.target.value);
        //console.log(e);
        e.target.focus();
    };
    const now = new Date();
    const fileDate= now.getFullYear() + '_' + now.getMonth() + '_' +now.getDate();
    const exportHeaders = [
        { label: 'NewAccount', key: 'account_number' },
        { label: 'CodeUsed', key: 'referral_code' },
        { label: 'NewAccountAmount', key: 'amount_paid' },
        { label: 'NewAccountStart', key: 'account_start' },
        { label: 'NewAccountStatus', key: 'account_status' },
        { label: 'OldAccount', key: 'ref_account_number' },
        { label: 'OldAccountStart', key: 'ref_account_start' },
        { label: 'OldAccountStatus', key: 'ref_account_status' },
        { label: 'OldAccountAmount', key: 'rc_amount_paid' },
        { label: 'ReferralStatus', key: 'raf_status_name' },
        { label: 'ValidRecord', key: 'raf_is_valid' },
    ];
    useEffect(() => {
        const init = async () => {
            await fetchReferrals(1);
            await setColumns([
                {
                    name: '',
                    selector: row => row.raf_status === 4 ? '' :<OneRowReferralSelector row={row} tableData={tableData} setTableData={setTableData} handler={handleExpandReferral}/>,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "1.9%",
                },
                {
                    name: '#',
                    selector: row => row.a_id,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'Valid',
                    selector: row => row.a_type === 'invalid' ? 'N' : 'Y',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'Acc#',
                    selector: row => row.account_number,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'AccStart',
                    selector: row => row.account_start ? moment(new Date(row.account_start)).format("YYYY-MM-DD"): '',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "7%",
                },
                {
                    name: 'AccStatus',
                    selector: row => row.account_status,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'ReferralCode',
                    selector: row => row.referral_code,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'ByUser',
                    selector: row => row.created_by_username,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Amount',
                    selector: row => row.amount_paid,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Date',
                    selector: row => row.transaction_date ? moment(new Date(row.transaction_date)).format("YYYY-MM-DD") : '',
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "7%",
                },
                {
                    name: 'RafStatus',
                    selector: row => row.raf_status_name  ? row.raf_status_name : 'new',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'RefAcc#',
                    selector: row => row.raf_account_number ? row.raf_account_number : (row.ref_account_number ? row.ref_account_number : null),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'AccStatus',
                    selector: row => row.raf_account_status  ? row.raf_account_status : (row.ref_account_status  ? row.ref_account_status : null),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'AccStart',
                    selector: row => row.raf_account_start ? moment(new Date(row.raf_account_start)).format("YYYY-MM-DD") : (row.ref_account_start ? moment(new Date(row.ref_account_start)).format("YYYY-MM-DD") : null),
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "7%",
                },
                {
                    name: 'RafId',
                    selector: row => row.raf_id,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'Action',
                    selector: row => row.a_type==='invalid' ? '' :(row.needs_create ? 'create' : (row.needs_update ? 'update' : '')),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                }

            ]);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, [tableData, totalRows]);
    const fetchReferral = async raf_id => {
        setLoading(true);
        let isOk = true;
        const response = await axios.get(`/internal/referral/${raf_id}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        ).catch((e)=>{
            //console.log(e);
            isOk = false;
        });
        if (isOk) {
            //console.log(response.data.data);
            //console.log(response.data.total);
            console.log(response.data.data);
            //await setReferralsData(response.data.data);
            //setTotalRows(response.data.total);
            setLoading(false);
        } else {

        }
    };
    const fetchReferrals = async page => {
        setLoading(true);
        let isOk = true;
        const response = await axios.get(`/internal/referrals?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        ).catch((e)=>{
            //console.log(e);
            isOk = false;
        });
        if (isOk) {
            //console.log(response.data.data);
            //console.log(response.data.total);
            await setTotalRows(response.data.total);
            await setReferralsData(response.data.data);

            setLoading(false);
        } else {

        }
    };
    const getAllItems = async (e) => {
        //console.log(e);
        setLoading(true);
        const response = await axios.get(`/internal/referrals?page=1&per_page=${totalRows}`,
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        setLoading(false);
        let rafItems= [];
        await response.data.data.map((item) => {
            //console.log(item.raf_status_name?.length);
            if (item.raf_status_name?.length === undefined) {
                item.raf_status_name = 'new';
            }
            if (item.a_type === 'invalid') {
                item.raf_is_valid = false;
            } else {
                item.raf_is_valid = true;
            }
            rafItems.push(item);
        });
        //console.log(rafItems);
        await setAllItems(rafItems);

    }
    const actionsMemo = React.useMemo(() =>
            <>
                {! disableDownload && allItems.length <= 0 ?<Button onClick={getAllItems}>Prepare Download  ({totalRows})</Button>: undefined}
                {! disableDownload && allItems.length > 0 ?
                    <CSVLink
                        data={allItems}
                        headers={exportHeaders}
                        filename={`gc_raf_${fileDate}.csv`}
                        className="btn btn-primary"
                    >
                        Export Referral Data
                    </CSVLink>
                    : undefined}

            </>,
        [allItems, totalRows]
    );

    return (
        <>
            {
                (loading || ! referralsData || referralsData.length <=0 || ! totalRows || totalRows <=0) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <div id="portal"></div>
                        {isOpen.editReferral && (
                            <ReferralModal open={isOpen.editReferral}
                                           onClose={handleClose}
                                           tableData={referralData}
                                           isOpen={isOpen}

                            />
                        )}
                        <>
                            <DataTable
                                title="Referrals"
                                columns={columns}
                                customStyles={tableCustomStyles}
                                conditionalRowStyles={conditionalRowStyles}
                                data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="1000px"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                paginationPerPage={perPage}
                                paginationDefaultPage={page}
                                progressPending={loading}
                                striped
                                responsive
                                dense
                                subHeader
                                autoResetSortBy={false}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </>
                    </>
                )
            }
        </>
    );
}