import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import DataTable from "react-data-table-component";
import {CSVLink} from "react-csv";
import {default as OneRowAdverseLetter, handleUnselect} from "../../components/OneRowAdverseLetter";
import AdverseLetterSendModal from "../../components/modals/AdverseLetterSendModal";
const openInit = {
    sendAdverseLetter: false
}
const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;
const SendAdverseLetter = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #dc3545!important;
        border-color: #dc3545!important;
        /*height: 34px;*/
        /*width: 32px;*/
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const AdverseLetters =  (props) => {
        const [enrollmentsData, setEnrollmentsData] = useState([]);
        const [loading, setLoading] = useState(true);
        const [canSendAdverseLetter, setCanSendAdverseLetter] = useState(false);
        const [columns, setColumns] =  useState([]);
        const [filterText, setFilterText] = useState('');
        const [isOpen, setIsOpen] = useState(openInit);
        const [totalRows, setTotalRows] = useState(0);
        const [perPage, setPerPage] = useState(25);
        const [page, setPage] = useState(1);
        const [tableData, setTableData] = useState([]);
        const filteredItems = enrollmentsData.filter(
            item => ! item.first_name || (item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase())),
        );
        const ExpandedComponent = ({ data }) => <Table striped bordered hover responsive="sm">
            <tr>
                <td><b>Id</b><br/>{data.id}</td>
                <td><b>Accoun Number</b><br/>{data.account_number}</td>
                <td><b>Created</b><br/>{data.started_date.substring(0, 10)}</td>
                <td><b>FirstName</b><br/>{data.first_name}</td>
                <td><b>LastName</b><br/>{data.last_name}</td>
                <td><b>Email</b><br/>{data.email}</td>
                <td><b>Product</b><br/>{data.plan_name}</td>
                <td><b>UTM Content</b><br/>{data.utm_content}</td>
                <td><b>Address</b><br/>{data.address}</td>
                <td><b>City</b><br/>{data.city}</td>
                <td><b>ZipCode</b><br/>{data.zip_code}</td>
            </tr>
            <tr>
                <td><b>Esiid</b><br/>{data.esiid}</td>
                <td><b>Credit Score</b><br/>{data.creditScore}</td>
                <td><b>Address Mismatch</b><br/>{data.addressMismatch}</td>
                <td><b>Credit D. Amount</b><br/>{data.depositAmount}</td>
                <td><b>Ercot 6m SO</b><br/>{data.ercot_so6m}</td>
                <td><b>Deposit
                    Required</b><br/>{data.verify_deposit_required === null ? 'n/a' : (data.verify_deposit_required ? 'Y' : 'N')}
                </td>
                <td><b>Deposit Amount</b><br/>{data.verify_deposit_amount}</td>
                <td><b>Deposit Freedom</b><br/>{data.verify_show_df ? 'Y' : 'N'}</td>
                <td><b>Reason</b><br/>{data.verify_reason}</td>
                <td><b>HotJar</b><br/><a target={"_blank"}
                                         href={`https://insights.hotjar.com/sites/2977112/playbacks?sort_by=-created&date=%7B%22DAYS_AGO%22:%7B%22created%22:365%7D%7D&filters=%7B%22EQUAL%22:%7B%22user_attributes.str.prospect_id%22:%22${data.uuid}%22%7D%7D`}>Recording</a>
                </td>
                <td><b>Decision</b><br/><b><big>{data.gc_decision}</big></b></td>



            </tr>
            <tr>
                <td><b>SN Trust</b><br/>{data.SSNTrust}</td>
                <td><b>SN Reason</b><br/>{data.SSNInsightsReason}</td>
                <td><b>Address Affiliation</b><br/>{data.addressAffiliation}</td>
                <td><b>Reason</b><br/>{data.addressAffiliationReason}</td>
                <td><b>DOB Trust</b><br/>{data.dobTrust}</td>
                <td><b>DOB Reason</b><br/>{data.dobVerificationReason}</td>
                <td><b>Email Trust</b><br/>{data.emailTrust}</td>
                <td><b>Email reason</b><br/>{data.emailVerificationReason}</td>
                <td><b>Phone Trust</b><br/>{data.phoneTrust}</td>
                <td><b>Phone Reason</b><br/>{data.phoneVerificationReason}</td>



            </tr>
            <tr>
                <td><b>Identity Trust</b><br/>{data.identityTrust}</td>
                <td><b>Identity Reason</b><br/>{data.identityVerificationReason}</td>
                <td><b>Identity Risk</b><br/>{data.identityRisk}</td>
                <td><b>Reason</b><br/>{data.identityRiskReason}</td>
                <td><b>Credit Score</b><br/>{data.credit_score}</td>
                <td><b>Broker/UTM-content</b><br/>{data.broker_name}/{data.utm_content}</td>
                <td><b>MIS SO 6 Months</b><br/>{data.ercot_so6m === null ? 'n/a' : data.ercot_so6m}</td>
                <td><b>MIS SO 12 Months</b><br/>{data.ercot_so12m === null ? 'n/a' : data.ercot_so12m}</td>
                {(data.ivrId) ?
                    <>
                        <td><>
                            <b>Id Validation
                                Status: </b><br/>{data.ivrResponse.status} : {data.ivrResponse.match_type === 'DevAPIAddressID'
                            ? 'TPS Address'
                            : (data.ivrResponse.match_type === 'DevAPICallerID'
                                ? 'TPS Phone'
                                : (data.ivrResponse.match_type === 'LandGlide' ? 'LandGlide' : '(no matches)'))}
                        </>
                        </td>
                        <td>
                            {(data.ivrId)
                                ? <>
                                    <b>Id Validation Type: </b><br/>
                                    {data.ivrResponse.match_type_first_name
                                        ? data.ivrResponse.match_type_first_name
                                        : data.ivrResponse.match_type_name ? data.ivrResponse.match_type_name : 'n/a'}
                                </>
                                : <>
                                </>}
                        </td>
                        <td>
                            {(data.ivrId)
                                ? <>
                                    <b>Id Validation Address: </b><br/>
                                    {data.ivrResponse.match_address ?? 'n/a'}
                                </>
                                : <>
                                </>}
                        </td>
                        <td>
                            {(data.ivrId)
                                ? <>
                                    <b>Id Validation Name: </b><br/>
                                    {data.ivrResponse.match_name ?? 'n/a'}
                                </>
                                : <>
                                </>}
                        </td>

                    </>
                    : <></>
                }
            </tr>
        </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50, 100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        {label: 'Enrollment Start', key: 'started_date'},
        {label: 'First Name', key: 'first_name'},
        {label: 'Last Name', key: 'last_name'},
        {label: 'Email', key: 'email'},
        {label: 'Phone', key: 'phone'},
        {label: 'Credit Score', key: 'creditScore'},
        {label: 'Address Mismatch', key: 'addressMismatch'},
        {label: 'Deposit Amount', key: 'depositAmount'},
        {label: 'Zipcode', key: 'zip_code'},
        {label: 'ESIID', key: 'esiid'},
        {label: 'IsMove', key: 'moving_switching'},
        {label: 'Requested Date', key: 'start_date'},
        {label: 'Plan Name', key: 'plan_name'},
        {label: 'Address', key: 'address'},
        {label: 'Deposit Amount', key: 'deposit_amount'},
        {label: 'Promo code', key: 'promo_code'},
        {label: 'UTM_Source', key: 'utm_source'},
        {label: 'UTM_Content', key: 'utm_content'},
        {label: 'Last Step', key: 'screen_title'},
    ];
    const exportOpsHeaders = [];
    const onClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: '12px',
                paddingLeft: '0 0px',
                paddingRight: '0 0px',
                fontWeight: 'bold',
                /*paddingLeft: '0 8px',*/
                justifyContent: 'center',
                /*backgroundColor: '#FFA500'*/
            },
        },
    }
    const conditionalRowStyles = [
        {
            when: row => row.is_completed === 'Yes',
            style: {
                backgroundColor: 'lightgray',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.verify_deposit_amount === 0,
            style: {
                backgroundColor: 'pink',
                color: 'black',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        /*{
            when: row => (row.verify_reason &&
                row.verify_reason.toLowerCase() !== 'identity check failed' &&
                row.verify_deposit_amount !== 400 &&
                (row.credit_score === 0 || row.credit_score === 99999)
            ),
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        /*{
            when: row => row.verify_deposit_amount === 400,
            style: {
                backgroundColor: 'orange',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.credit_score === 0,
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.verify_reason.toLowerCase() === 'identity check failed',
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },*/
            // You can also pass a callback to style for additional customization
            // {
            //     when: row => row.calories < 400,
            //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
            //},
        ];
        const handleCheckAdverseLetter = async () => {
            console.log('handleCheckAdverseLetter', tableData.length);
            if (tableData.length === 1) {
                handleActionClose();
                //setIsOpen({ ...openInit, editRenewalEligible: true })
            }
            await setCanSendAdverseLetter(tableData.length > 0)
        };
        const handleActionClose = () => {
            console.log('handleActionClose', tableData.length);

            //setAnchorAction(null);
        };
        const fetchEnrollments = async page => {
            setLoading(true);
            const response = await axios.get(`/internal/adverse_letters?page=${page}&per_page=${perPage}&search=${filterText}`,
                { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
            );
            await setEnrollmentsData(response.data.data);
            await setColumns([
                {
                    name: '',
                    selector: row => <OneRowAdverseLetter row={row} tableData={tableData} setTableData={setTableData} handler={handleCheckAdverseLetter}/>,
                    sortable: false,
                    right: false,
                    style: "padding:0px;margin:0px;",
                    width: "1.5%",
                },
                {
                    name: 'Id',
                    selector: row => row.id,
                    sortable: true,
                    right: false,
                    style: "font-weight:bold;padding:0px;margin:0px;",
                    width: "3%",
                },
                {
                    name: 'Enrolled',
                    selector: row => row.is_completed,
                    sortable: true,
                    right: false,
                    //className: "text-break",
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },
                {
                    name: 'Created',
                    selector: row => row.started_date.substring(0,10),
                    sortable: true,
                    right: false,
                    //className: "text-break",
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },
                {
                    name: 'F.Name',
                    selector: row => row.first_name,
                    sortable: true,
                    right: false,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'L.Name',
                    selector: row => row.last_name,
                    sortable: true,
                    right: false,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Account',
                    selector: row => row.account_number,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:1px;margin:1px;",
                    width: "4%",
                },
                {
                    name: 'Status',
                    selector: row => row.account_status,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:1px;margin:1px;",
                    width: "5%",
                },
                /*{
                    name: 'Email',
                    selector: row => row.email,
                    sortable: true,
                    right: false,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "9%",
                },*/
                /*{
                    name: 'Plan',
                    selector: row => row.plan_name,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:1px;margin:1px;",
                    width: "10%",
                },*/
                {
                    name: 'Address',
                    selector: row => row.address,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "11%",
                },
                {
                    name: 'Esiid',
                    selector: row => row.esiid,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:0px;margin:0px;font-size:9pt;",
                    width: "10%",
                },
                {
                    name: '#',
                    selector: row => row.samesiid ?? 0,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "1%",
                },
               /* {
                    name: 'City',
                    selector: row => row.city,
                    sortable: true,
                    right: false,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },*/
                /*{
                    name: 'Zip',
                    selector: row => row.zip_code,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },*/
                {
                    name: 'M/S',
                    selector: row => row.moving_switching === 1 ? 'M' : 'S',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },
                /*{
                    name: 'SameDay',
                    selector: row => (row.moving_switching !== 1 || row.start_date === null ? '' : (row.started_date.toString().substring(0,10) === row.start_date.toString().substring(0,10) ? 'Y' : 'N')),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3.8%",
                },*/
                {
                    name: '6mSO',
                    selector: row => row.ercot_so6m === null ? 'n/a' : row.ercot_so6m,
                    sortable: false,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2%",
                },
                {
                    name: 'Score',
                    selector: row => row.credit_score === null ? 'n/a' : row.credit_score ,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },
                /*{
                    name: 'C.D.Amt',
                    selector: row => row.depositAmount === null ? 'n/a' : row.depositAmount ,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3.5%",
                },*/
                /*{
                    name: '  D.Req',
                    selector: row => row.verify_deposit_required === null  ? '' : (row.verify_deposit_required ? 'Y' : 'N'),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },*/
                {
                    name: '  D.Amt',
                    selector: row => row.verify_deposit_amount,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },
               /* {
                    name: 'DFShown',
                    selector: row => row.verify_show_df ? 'Y': 'N',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },*/
                {
                    name: 'IdVerif',
                    selector: row => row.verify_is_verified ? 'Y' : 'N',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2.5%",
                },
                {
                    name: 'D.Reason',
                    selector: row => row.verify_reason,
                    sortable: true,
                    right: true,
                    style: "text-transform: lowercase;padding:0px;margin:0px;",
                    width: "13.5%",
                },

                /*{
                    name: 'UTM',
                    selector: row => row.utm_content ,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "6%",
                },*/
                {
                    name: 'Src',
                    selector: row => row.created_by_method ?
                        (row.created_by_method === 'createProspect' ? 'ac' :
                            (row.created_by_method === 'createAccount:1' ? 'en1' :
                                (row.created_by_method === 'createAccount:2' ? 'en2' :
                                    (row.created_by_method === 'SyncSGToGC' ? 'sg' :
                                        row.created_by_method
                                    )
                                )
                            )
                        ) : 'n/a',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Type',
                    selector: row => row.verify_deposit_amount >= 400 ? 'Other' : 'Credit',
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                }
            ]);
            setTotalRows(response.data.total);
            setLoading(false);
        };
        const handlePageChange = page => {
            console.log('Page change: ' + page);
            fetchEnrollments(page);
            setPage(page);
        };
        const handlePerRowsChange = async (newPerPage, page) => {
            setLoading(true);
            const response = await axios.get(`/internal/adverse_letters?page=${page}&per_page=${newPerPage}&search=${filterText}`,
                { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
            );
            await setEnrollmentsData(response.data.data);
            setPerPage(newPerPage);
            setLoading(false);
        };
        useEffect(() => {
            const init = async () => {
                await fetchEnrollments(1);
            }
            init().then(
                () => {
                    //console.log('loaded');
                }
            );
        }, [tableData]);
        const handleSort = (column, sortDirection) => {
            console.log(column);
            setLoading(true); // Set loading to true before sorting

            // Perform sorting logic here (if not using built-in sorting)
            // ...

            // After sorting is complete, set loading to false
            setLoading(false);
        };
    const handleClose = async (doUpdate = false) => {

        setIsOpen(openInit);

        console.log('handleClose',tableData);


    };
    const handleSendAdverseLetter = async () => {
        setIsOpen({ ...openInit, sendAdverseLetter: true })
    }
    const actionsMemo = React.useMemo(() =>
            <>
                <SearchBox>
                    <TextField
                        id="search"
                        type="text"
                        placeholder="Filter By First Name"
                        aria-label="Search Input"
                        value={filterText}
                        onInput={onFilter}
                        autoFocus
                    />
                    <ClearButton type="button" onClick={onClear}>
                        X
                    </ClearButton>
                </SearchBox>
                <CSVLink
                    data={filteredItems}
                    headers={exportHeaders}
                    filename={"goodcharlie_incomplete_enrollments.csv"}
                    className="btn btn-primary"
                >
                    Export
                </CSVLink>
                <SendAdverseLetter
                    className={"btn-danger"}
                    type={"button"}
                    disabled={!canSendAdverseLetter}
                    onClick={handleSendAdverseLetter}
                >
                    Send Adverse Letter to selected customers
                </SendAdverseLetter>
            </>,
        [filteredItems]
    );
        return (
            <>
                {

                    (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                        <>
                            <>
                                loading
                            </>
                        </>
                    ) : (
                        <>
                            <div id="portal"></div>
                            {isOpen.sendAdverseLetter && (
                                <AdverseLetterSendModal open={isOpen.sendAdverseLetter}
                                               onClose={handleClose}
                                               tableData={tableData}
                                               isOpen={isOpen}
                                />
                            )}
                            <>
                                <DataTable
                                    title="Enrollments - Adverse Letters"
                                    columns={columns}
                                    customStyles={tableCustomStyles}
                                    conditionalRowStyles={conditionalRowStyles}
                                    data={filteredItems}

                                    expandableRows
                                    onSort={handleSort}
                                    expandableRowsComponent={ExpandedComponent}
                                    fixedHeader
                                    fixedHeaderScrollHeight="1000px"
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    paginationPerPage={perPage}
                                    paginationDefaultPage={page}
                                    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    progressPending={loading}
                                    striped
                                    responsive
                                    dense
                                    subHeader
                                    actions={actionsMemo}
                                >
                                </DataTable>
                            </>
                        </>
                    )
                }

            </>
        );
}