import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import Table from "react-bootstrap/Table";
import BrokerAddModal from "../../components/modals/BrokerAddModal";
import { makeStyles } from "@mui/styles";
import { Divider, Menu, MenuItem } from "@mui/material";
import { EditOutlined, Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RowSelector from "components/RowSelector";
import ActionBar from "components/ActionBar";
import BrokerEditModal from "components/modals/BrokerEditModal";
import { Box } from "@mui/system";
import DisableBrokerAPIModal from "components/modals/DisableBrokerAPIModal";
import downloadCSV from "utils/downloadCsv";
import BrokerPortalUsersModal from "components/modals/BrokerPortalUsersModal";
import ComstrModal from "components/modals/ComstrModal";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const openInit = {
    addBroker: false,
    editBroker: false,
    deactivate: false,
    userPortal: false,
    comstr: false
}

const ExtendedItem = ({ title, value, style, valueComponent, defaultValue }) => {
    const classes = useStyles();
    let haveValue = value || valueComponent || defaultValue;
    if (haveValue) {
        return (
            <div style={style?.itemStyle} className={classes.ex_item}>
                {title && <div style={style?.titleStyle} className={classes.ex_title}>{title}</div>}
                {valueComponent || (value && <div style={style?.valueStyle} className={classes.ex_value}>{value}</div>) || defaultValue}
            </div>
        )
    } else {
        return null;
    }
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    table: {
        style: {
            border: `1px solid grey`,
            borderRadius: 10,
            overflow: "hidden"
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#ffedee"
        }
    }
}

export const BrokerManagement = (props) => {
    const classes = useStyles();
    const [brokersData, setBrokersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([])
    const [isOpen, setIsOpen] = useState(openInit);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [anchorAction, setAnchorAction] = useState(null);
    const filteredItems = brokersData.filter(
        item => (
            (item.broker_name && item.broker_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.sg_agent_id && item.sg_agent_id.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.converting_utm_content && item.converting_utm_content.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.IntacctID && item.IntacctID.toLowerCase().includes(filterText.toLowerCase())) || // Include Intacct ID field
            (item._aiid && item._aiid.toLowerCase().includes(filterText.toLowerCase())) // Include _aiid field
        )
    );

    const ExpandedComponent = ({ data }) => {
        return(
            <Box className={classes.ec_main}>
                <Box className={classes.ec_container}>
                    <ExtendedItem title="Id" value={data.id} />
                    <ExtendedItem title="Broker Name" value={data.broker_name} />
                    <ExtendedItem title="Created at" value={data.created_at_raw} />
                    <ExtendedItem title="API Enabled" value={parseInt(data.broker_api_enabled) ? "Enabled" : "Disabled"} />
                    <ExtendedItem title="UTM Content" value={data.converting_utm_content} />
                    <ExtendedItem title="UTM Campaign" value={data.converting_utm_campaign} />
                    <ExtendedItem title="UTM Medium" value={data.converting_utm_medium} />
                    <ExtendedItem title="UTM Source" value={data.converting_utm_source} />
                    <ExtendedItem title="SG Agent Id" value={data.sg_agent_id} />
                    <ExtendedItem title="SG User Id" value={data.sg_user_id} />
                    <ExtendedItem title="Submit Deposit Required" value={data.submit_deposit_required === 1 ? "Yes" : "No"} />
                    <ExtendedItem title="TFN Number" value={data.tfn_number ? data.tfn_number : "-" } />
                    <ExtendedItem title="IntacctID" value={data.IntacctID} />
                    <ExtendedItem title="_aiid" value={data._aiid} />
                </Box>
            </Box>
    )}

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const handleExport = () =>{
        downloadCSV(brokersData, "brokers")
    }

    const handleAddBroker = () => setIsOpen({ ...openInit, addBroker: true });
    const handleEditBroker = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, editBroker: true })
        }
    };
    const handleDeactiveBroker = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, deactivate: true })
        }
    };
    const handleUserPortal = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, userPortal: true })
        }
    };
    const handleComstr = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, comstr: true })
        }
    };
    const handleClose = () => {
        setIsOpen(openInit);
    };

    const exportHeaders = [
        { label: 'Signup Date', key: 'signup_date' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'ESIID', key: 'esiid' },
        { label: 'IsMove', key: 'moving_switching' },
        { label: 'Requested Date', key: 'requested_date' },
        { label: 'End Date', key: 'end_date' },
        { label: 'Plan Name', key: 'plan_name' },
        { label: 'Address', key: 'Address1' },
        { label: 'Status', key: 'SPStatus' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'IpAddress', key: 'EnrollmentIpAddress' },
        { label: 'Tdu', key: 'TduName' },
        { label: 'Promo code', key: 'promo_code' },
        // { label: 'UTM_Source', key: 'utm_source'},
        //{ label: 'UTM_Content', key: 'utm_content'},
    ];
    const exportOpsHeaders = [
        { label: 'Account', key: 'account_number' },
        { label: 'Signup Date', key: 'signup_date' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'ESIID', key: 'esiid' },
        { label: 'IsMove', key: 'moving_switching' },
        { label: 'Requested Date', key: 'requested_date' },
        { label: 'End Date', key: 'end_date' },
        { label: 'Plan Name', key: 'plan_name' },
        { label: 'Address', key: 'Address1' },
        { label: 'Status', key: 'SPStatus' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'IpAddress', key: 'EnrollmentIpAddress' },
        { label: 'Tdu', key: 'TduName' },
        { label: 'Promo code', key: 'promo_code' },
        { label: 'UTM_Source', key: 'utm_source' },
        { label: 'UTM_Content', key: 'utm_content' },
    ];

    const openAction = Boolean(anchorAction);

    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };

    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };

    const actionProps = {
        title: "Brokers",
        addTitle: "Add Broker",
        filterTitle: "Filter Brokers",
        tableData,
        openAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportOpsHeaders,
        handleExportAction: handleExport,
        handleActionClick,
        handleAddAction: handleAddBroker
    };

    const actionsMemo = React.useMemo(() =>
        <>
            <ActionBar actionProps={actionProps} />
        </>
        , [tableData, filterText, anchorAction, brokersData]);

    useEffect(() => {
        setColumns([
            {
                name: '',
                selector: row => <RowSelector row={row} tableData={tableData} setTableData={setTableData} />,
                sortable: false,
                right: true,
                width: "60px",
            },
            {
                name: 'Broker Name',
                selector: row => row.broker_name,
                sortable: true,
            },
            {
                name: 'Create Date',
                selector: row => row.created_at_raw.substring(0, 10),
                sortable: true,
                right: true,
            },
            {
                name: 'Status',
                selector: row => Number(row.status) ? 'Yes' : 'No',
                sortable: true,
                center: true
            },
            {
                name: 'ApiEnabled',
                selector: row => Number(row.broker_api_enabled) ? 'Yes' : 'No',
                sortable: true,
            },
            {
                name: 'UTM_Content',
                selector: row => row.converting_utm_content,
                sortable: true,
                right: true,
            },
            {
                name: 'SG Agent Id',
                selector: row => row.sg_agent_id,
                sortable: true,
                right: true,
            },
            {
                name: 'TFN Number',
                selector: row => row.tfn_number ? row.tfn_number : "-",
                sortable: true,
                right: true,
            },
            {
                name: 'Intacct ID',
                selector: row => row.IntacctID || "-",
                sortable: true,
                right: true,
            },
            {
                name: '_aiid',
                selector: row => row._aiid || "-",
                sortable: true,
                right: true,
            }
        ]);
    }, [tableData])

    const loadEnrollments = async () => {
        let response = await axios.get('/internal/brokers',
            {
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
                params: {
                    inactive: 1
                }
            }
        );
        let sorted = response.data.brokers;
        function compare(a, b) {
            if (Number(a.broker_api_enabled) < Number(b.broker_api_enabled)) {
                return 1;
            }
            if (Number(a.broker_api_enabled) > Number(b.broker_api_enabled)) {
                return -1;
            }
            return 0;
        }

        sorted.sort(compare);
        await setBrokersData(sorted);
    }
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                // console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || !brokersData || brokersData.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>
                    <div id="portal"></div>
                    <BrokerAddModal open={isOpen.addBroker} onClose={handleClose} loadEnrollments={loadEnrollments}></BrokerAddModal>
                    {tableData?.length === 1 && isOpen.editBroker && (
                        <BrokerEditModal open={isOpen.editBroker}
                            onClose={handleClose} tableData={tableData[0]}
                            loadEnrollments={loadEnrollments}
                        />
                    )}
                    {tableData?.length === 1 && isOpen.userPortal && (
                        <BrokerPortalUsersModal open={isOpen.userPortal}
                            onClose={handleClose} tableData={tableData[0]}
                            loadEnrollments={loadEnrollments}
                        />
                    )}
                    {tableData?.length === 1 && isOpen.deactivate && (
                        <DisableBrokerAPIModal open={isOpen.deactivate}
                            onClose={handleClose} tableData={tableData[0]}
                            loadEnrollments={loadEnrollments}
                        />
                    )}
                    {tableData?.length === 1 && isOpen.comstr && (
                        <ComstrModal
                            open={isOpen.comstr}
                            onClose={handleClose}
                            tableData={tableData[0]}
                            loadEnrollments={loadEnrollments}
                        />
                    )}
                    <Box className={classes.tableContainer}>
                        <Box className={classes.table}>
                            <DataTable
                                // title="Broker"
                                columns={columns}
                                data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                expandableRows
                                expandableRowsComponent={ExpandedComponent}
                                fixedHeader
                                fixedHeaderScrollHeight="1000px"
                                pagination
                                paginationPerPage={30}
                                paginationComponentOptions={paginationComponentOptions}
                                striped
                                dense
                                subHeader
                                customStyles={customStyleForTable}
                                //subHeaderComponent={subHeaderComponentMemo}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </Box>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorAction}
                        open={openAction}
                        onClose={handleActionClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        style={{ margin: 0, padding: 0 }}
                    >
                        <MenuItem disabled={tableData?.length !== 1} onClick={handleEditBroker}>
                            <div className={classes.menuItem}>
                                <EditOutlined color="primary" sx={{ mr: 1 }} />
                                Edit
                            </div>
                        </MenuItem>
                        <Divider style={{ margin: 0, padding: 0 }} />
                        <MenuItem disabled={tableData?.length !== 1} onClick={handleDeactiveBroker}>
                            <div className={classes.menuItem}>
                                {(tableData?.length === 1 && parseInt(tableData[0].status)) ? <BlockIcon color="primary" sx={{ mr: 1 }} /> : <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />}
                                {(tableData?.length === 1 && parseInt(tableData[0].status)) ? "Deactivate" : "Activate"}
                            </div>
                        </MenuItem>
                        <Divider style={{ margin: 0, padding: 0 }} />
                        <MenuItem disabled={tableData?.length !== 1} onClick={handleUserPortal}>
                            <div className={classes.menuItem}>
                                <PeopleAltIcon color="primary" sx={{ mr: 1 }} />
                                Portal Users
                            </div>
                        </MenuItem>
                        <MenuItem disabled={tableData?.length !== 1} onClick={handleComstr}>
                            <div className={classes.menuItem}>
                                <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />
                                Commissions
                            </div>
                        </MenuItem>
                        <style>{`
                            ul.MuiList-root.MuiList-padding {
                                padding: 0;
                            }
                        `}</style>
                    </Menu>
                </>
                )
            }

        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    table: {
        // border: "1px solid black"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    ec_main: {
        width: "100%",
        backgroundColor: "#DCDCDC",
        padding: "14px 5vw",
    },
    ec_container: {
        padding: "15px",
        backgroundColor: "#fff",
        display: "grid",
        borderRadius: 10,
        columnGap: 10,
        rowGap: 10,
        gridTemplateColumns: "repeat(4, 1fr)",
        [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        border: `1px dashed black`
    },
    ex_item: {
        width: "100%",
        textAlign: "start",
        padding: "8px 15px",
    },
    ex_title: {
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 16
    },
    ex_value: {
        fontSize: 14
    }
}))