import React, { useState } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Grid, TextField } from '@mui/material';

export const TduforAccount = () => {
    const [accountNumbers, setAccountNumbers] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allItems, setAllItems] = useState([]);

    const handleInputChange = (event) => {
        setAccountNumbers(event.target.value);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/internal/tduforaccount', {
                params: {
                    account_numbers: accountNumbers.split(',').map(num => num.trim())
                },
                headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
            });
            setData(response.data.data);
            setAllItems(response.data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { name: "ID", selector: "id", sortable: true },
        { name: "Account Number", selector: "account_number", sortable: true },
        { name: "Address", selector: "address", sortable: true },
        { name: "City", selector: "city", sortable: true },
        { name: "Distribution Point Response", selector: "distribution_point_response", sortable: true }
    ];

    const exportHeaders = columns.map(col => ({ label: col.name, key: col.selector }));

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <h1>TDU for Account Number</h1>
                </Grid>
                <Grid item>
                    <TextField
                        label="Account Numbers"
                        variant="outlined"
                        fullWidth
                        value={accountNumbers}
                        onChange={handleInputChange}
                        placeholder="Enter account numbers separated by commas"
                    />
                </Grid>
                <Grid item>
                    <Button onClick={fetchData} variant="contained" color="primary">
                        Fetch Data
                    </Button>
                </Grid>
                <Grid item>
                    {allItems.length > 0 && (
                        <CSVLink
                            data={allItems}
                            headers={exportHeaders}
                            filename={"account_data.csv"}
                            className="btn btn-primary"
                        >
                            Download CSV
                        </CSVLink>
                    )}
                </Grid>
            </Grid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        responsive
                    />
                </div>
            )}
        </div>
    );
};
