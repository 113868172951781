import React, { useEffect } from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";

import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, MuiAutocomplete, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import { useSnackbar } from 'notistack';


const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px 50px',
    borderRadius: '5px',
    zIndex: 1000,
    width:"40%"
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const AddSalesCategoryModal = ({ open, onClose, purpose, rowData, loadData }) => {

    const commonClasses = useCommonStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [formValue, setFormValue] = React.useState({
        category_name: rowData?.category_name ? rowData?.category_name : "",
        category_description: rowData?.category_description ? rowData?.category_description : "",
        status: rowData?.status ? rowData?.status : 1
    });

    const handleCancel = async (e) => {
        setFormValue({
            category_name:"",
            category_description:"",
            status:1
        })
        onClose();
    }

    useEffect(()=>{
        setFormValue({
            category_name: rowData?.category_name ? rowData?.category_name : "",
            category_description: rowData?.category_description ? rowData?.category_description : "",
            status: rowData?.status==0 ? 0 : 1
        })
    },[rowData])

    const handleSubmit = async (e) => {

        let temp;
        let msgSuccess = rowData ? "User updated successfully" : "User added successfully";
        let msgFail = rowData ? "Failed to update user" : "Failed to add user";

        if(purpose === "Add"){
            temp = {...formValue, status: (formValue.status=="Inactive" || formValue.status=="0")?0:1};
        }
        if(purpose === "Edit"){
            temp = {...formValue, id: rowData.id, status: (formValue.status=="Inactive" || formValue.status=="0")?0:1};
        }
        
        try {
            const response = await axios.post('/internal/sales-category',
            temp,
            {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                }
            })
            if(response){
                loadData()
                onClose()
                setFormValue({
                    category_name:"",
                    category_description:"",
                    status:1
                })
            }
            if(response?.status === 200 || response?.status === 201){
                enqueueSnackbar(msgSuccess, {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
            }
        } catch(error) {
            console.log(error)
            enqueueSnackbar(msgFail, {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }

    const handleChange = (event) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value
        });
    }
    
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            {purpose} Sales Category
                        </Typography>
                        <MuiText
                            label="Sales Category"
                            name="category_name"
                            defaultValue={rowData?.category_name}
                            onChange={handleChange}
                        />
                        <MuiText
                            label="Description"
                            name="category_description"
                            defaultValue={rowData?.category_description}
                            onChange={handleChange}
                        />
                        <MuiAutocomplete
                            label="Status"
                            name="status"
                            defaultValue={rowData?.status==0?"Inactive":"Active"}
                            onChange={handleChange}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            options={[
                                { title: 'Active' },
                                { title: 'Inactive' },
                            ]}
                        />
                        <div style={{display:"flex",marginTop:"30px", justifyContent:"space-between",width:"100%",padding:"0px 10% 0px 10%"}}>
                            <ThemedButton
                                outlined={"true"}
                                onClick={handleCancel}
                                style={{width:"150px"}}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                                style={{width:"150px"}}

                            >
                                Save
                            </ThemedButton>
                        </div>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            } 
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default AddSalesCategoryModal;

