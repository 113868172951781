import { Checkbox, TextField as MuiTextField, Typography, useMediaQuery } from "@mui/material";
import MaterialSwitch from "@material-ui/core/Switch"
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Theme from "theme/theme";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from "@mui/x-date-pickers"
import Autocomplete from '@mui/material/Autocomplete';
import moment from "moment/moment";

const StyledInput = styled.input`
        width: 100%;
        margin-bottom: 12px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 5px 15px;
        font-size: 1rem;
        height: 33px;
      `;
const StyledTextarea = styled.textarea`
        width: 100%;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 5px 15px;
        font-size: 1rem;
        margin-bottom: 12px;
      `;

export const MuiText = (props) => {
    const classes = useInputStyles({ smallInput: props.small, width: props.width, multiline: props.multiline });
    return (
        <div className={classes.flexColumn} style={{ ...props.style, alignSelf: "start", }}>
            <div style={{ width: "100%" }}>
                <MuiTextField
                    style={{
                        width: "100%",
                        marginBottom: "25px",
                        fontSize: "1rem",
                    }}
                    size={props.multiline ? "medium" : "small"}
                    {...props}

                    InputLabelProps={{
                        ...props.inputLabelProps,
                        style: { marginTop: props.defaultValue ? 0 : 6 },
                        classes: { focused: classes.focused }
                    }}
                    className={classes.textField}
                />
            </div>
            <style>{`
          textarea{
            min-height: 70px !important;
            overflow-y: auto !important;
          }
      `}</style>
        </div>
    )
}

export const MuiDateTime = (props) => {
    const classes = useInputStyles({ smallInput: props.small, width: props.width });
    const [value, setValue] = React.useState(new Date(props.defaultValue));

    useEffect(() => {
        props.setFormValue({
            ...props.formValue
        })
    }, [])

    const handleChange = (newValue) => {
        setValue(newValue)
        props.setFormValue({
            ...props.formValue,
            [props.name]: moment(new Date(newValue ? newValue : value)).format("YYYY-MM-DD h:mm:ss")
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                renderInput={(params) =>
                    <div className={classes.flexColumn} style={{ ...props.style, alignSelf: "start" }}>
                        <div style={{ width: "100%" }}>
                            <MuiTextField
                                style={{
                                    width: "100%",
                                    marginBottom: "25px",
                                    fontSize: "1rem",
                                }}
                                size={props.multiline ? "medium" : "small"}
                                {...props}
                                {...params}
                                InputLabelProps={{
                                    ...props.inputLabelProps,
                                    style: { marginTop: props.defaultValue ? 0 : 6 },
                                    classes: { focused: classes.focused }
                                }}
                            />
                        </div>
                    </div>
                }
                value={value}
                onChange={handleChange}

            />
        </LocalizationProvider>
    )
}

export const MuiDate = (props) => {
    const classes = useInputStyles({ smallInput: props.small, width: props.width });
    const [value, setValue] = React.useState(new Date(props.defaultValue));

    useEffect(() => {
        props.setFormValue({
            ...props.formValue
        })
    }, [])

    const handleChange = (newValue) => {
        setValue(newValue)
        props.setFormValue({
            ...props.formValue,
            [props.name]: moment(new Date(newValue ? newValue : value)).format("YYYY-MM-DD")
        });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                renderInput={(params) =>
                    <div className={classes.flexColumn} style={{ ...props.style, alignSelf: "start" }}>
                        <div style={{ width: "100%" }}>
                            <MuiTextField
                                style={{
                                    width: "100%",
                                    marginBottom: "25px",
                                    fontSize: "1rem",
                                }}
                                size={props.multiline ? "medium" : "small"}
                                {...props}
                                {...params}
                                InputLabelProps={{
                                    ...props.inputLabelProps,
                                    style: { marginTop: props.defaultValue ? 0 : 6 },
                                    classes: { focused: classes.focused }
                                }}
                            />
                        </div>
                    </div>
                }
                value={value}
                onChange={handleChange}

            />
        </LocalizationProvider>
    )
}

export const
    MuiAutocomplete = ({formValue, setFormValue, ...props}) => {
        const classes = useInputStyles({ smallInput: props.small, width: props.width });
        const [value, setValue] = React.useState(props.value ? props.value : "");
        const option = props.options ? props.options : [
            { title: 'NOVA', year: 1994 },
            { title: '4 Angry Men', year: 1972 },
            { title: 'SouL', year: 1974 },
            { title: 'Entity', year: 2008 },
            { title: 'BTR', year: 1957 },
            { title: "Orange Rock", year: 1993 }
        ]

        /*useEffect(() => {
           // console.log(props)
          props.setformvalue({
            ...props.formvalue
          })
        }, [])*/

        const handleChange = async(newValue,e) => {
            setValue(newValue)
            setFormValue({
              ...formValue,
              [props.name]: newValue
            });
            props.name == "subject" && await props?.getMails(newValue)
            props.name == "subject" && props?.setMailType(newValue)
        };

        return (
            <div style={{ width: '100%' }}>
                <Autocomplete
                    // freeSolo={true}
                    options={option.map((item) => item.title)}
                    renderInput={(params) =>
                        <div className={classes.flexColumn} style={{ ...props.style, alignSelf: "start" }}>
                            <MuiTextField
                                style={{
                                    width: "100%",
                                    marginBottom: "25px",
                                    fontSize: "1rem",
                                    height: "48px",
                                    // padding: "0px 2px 20px 2px",
                                }}
                                size={props.multiline ? "medium" : "small"}
                                {...props}
                                {...params}
                                InputLabelProps={{
                                    ...props.inputLabelProps,
                                    style: { marginTop: props.defaultValue ? 0 : -3 },
                                    classes: { focused: classes.focused }
                                }}
                                className={classes.textField}

                            />
                        </div>
                    }
                    autoHighlight
                    value={value}
                    onChange={(e, val) => handleChange(val,e)}

                />
            </div>
        )
    }

export const TextField = (props) => {
    const classes = useInputStyles({ smallInput: props.small, width: props.width });
    return (
        <div className={classes.flexColumn} style={{ ...props.style, alignSelf: "start" }}>
            <div>{props.label}</div>
            <div style={{ width: "100%" }}>
                <StyledInput
                    type="text"
                    {...props}
                    className={classes.textField}
                />
            </div>
        </div>
    )
}

export const CheckBox = (props) => {
    const classes = useInputStyles();
    return (
        <div className={classes.flexBox} style={{ width: "auto", marginBottom: 10 }}>
            <Checkbox
                className={classes.checkBox}
                // checked={Boolean(props.defaultChecked)}
                {...props}
            />
            &nbsp;
            <Typography>{props.label}</Typography>
        </div>
    )
}

export const Switch = (props) => {
    const classes = useInputStyles();
    return (
        <div className={classes.switchContainer} style={{ width: "auto", marginBottom: 10 }}>
            <input class="toggle" type="checkbox" {...props}/>
            &nbsp;&nbsp;
            <Typography>{props.label}</Typography>
            <style>{`
            .toggle {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 40px;
                height: 20px;
                display: inline-block;
                position: relative;
                border-radius: 50px;
                overflow: hidden;
                outline: none;
                border: none;
                cursor: pointer;
                background-color: #9f9f9f;
                transition: background-color ease 0.3s;
            }
              
            .toggle:before {
                content: "";
                display: block;
                position: absolute;
                z-index: 2;
                width: 17px;
                height: 17px;
                background: #fff;
                left: 2px;
                top: 2px;
                border-radius: 50%;
                font: 10px/28px Helvetica;
                text-transform: uppercase;
                font-weight: bold;
                text-indent: -22px;
                word-spacing: 37px;
                color: #fff;
                text-shadow: -1px -1px rgba(0,0,0,0.15);
                white-space: nowrap;
                box-shadow: 0 1px 2px rgba(0,0,0,0.2);
                transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
            }
              
            .toggle:checked {
                background-color: #F50057;
            }
              
            .toggle:checked:before {
                left: 21px;
            }
        `}</style>
        </div>
    )
}

export const InputRow = (props) => {
    const classes = useInputStyles();
    const smallScr = useMediaQuery('(max-width: 900px)');
    return (
        <div className={classes.inputRow}>
            {props.children.map((item, index) => {
                let lastChild = index + 1 === props.children.length;
                return (
                    <div key={item.key} style={{ marginRight: smallScr ? 0 : lastChild ? 0 : 20, width: "100%" }}>
                        {item}
                    </div>
                )
            })}
        </div>
    )
}

export const useInputStyles = makeStyles((theme) => ({
    flexBox: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputRow: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        }
    },
    flexColumn: {
        width: (props) => props["smallInput"] ? props["width"] || "50%" : "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        [theme.breakpoints.down("md")]: {
            width: "100% !important"
        }
    },
    checkBox: {
        transform: "scale(1.2)",
        padding: 0,
        margin: 0
    },
    '@global': {
        '.MuiCheckbox-root': {
            padding: "0px !important",
        }
    },
    textField: {
    },
    input: {
        padding: "10px 14px !important",
        height: props => props["multiline"] ? "auto" : "28px !important"
    },
    focused: {
        marginTop: "1px !important",
        color: `${theme.palette.primary.main} !important`,
    },
    switchContainer:{
        width: "100%",
        display: "flex",
        alignItems: "center",
    }
}))