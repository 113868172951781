import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import DataTable from 'react-data-table-component';
import PromoCodeAddModal from '../../components/modals/PromoCodeAddModal';
import PromoCodeEditModal from '../../components/modals/PromoCodeEditModal';
import { DeleteOutlined, EditOutlined, Block as BlockIcon } from "@mui/icons-material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Divider, Menu, MenuItem } from "@mui/material";
import RowSelector from "components/RowSelector";
import ActionBar from "components/ActionBar";
import { makeStyles } from "@mui/styles";
import downloadCSV from "utils/downloadCsv";

const AddButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

const BUTTON_WRAPPER_STYLES = {
    position: 'relative',
    zIndex: 1
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    table: {
        style: {
            border: `1px solid grey`,
            borderRadius: 10,
            overflow: "hidden"
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#ffedee"
        }
    }
}

export const PromoCodes = (props) => {
    const console ={
        log: (a) => {
            window.console.log(a);
        }
    };
    const classes = useStyles();
    const [promoCodesData, setPromoCodesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([])
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [anchorAction, setAnchorAction] = useState(null);
    const [page, setPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const filteredItems = promoCodesData.filter(
        item =>   ((item.promocode && item.promocode.toLowerCase().includes(filterText.toLowerCase())) || 
                        (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) ||
                        (item.converting_utm_content && item.converting_utm_content.toLowerCase().includes(filterText.toLowerCase())))
    );

    const addPromoCode = () => {
        setIsOpenAdd(true);
    }
    const paginationRowsPerPageOptions = [
        25, 50, 100, 250, 500, 1000, 2000
    ];
    const handleOnCloseAdd = async () => {
        setIsOpenAdd(false);
        await loadPromocodes();
    }
    const handleOnCloseUpdate = async () => {
        setIsOpenEdit(false);
        await loadPromocodes();
    }


    const exportOpsHeaders = [
        { label: 'Account', key: 'account_number' },
        { label: 'Signup Date', key: 'signup_date' },
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
        { label: 'ESIID', key: 'esiid' },
        { label: 'IsMove', key: 'moving_switching' },
        { label: 'Requested Date', key: 'requested_date' },
        { label: 'End Date', key: 'end_date' },
        { label: 'Plan Name', key: 'plan_name' },
        { label: 'Address', key: 'Address1' },
        { label: 'Status', key: 'SPStatus' },
        { label: 'Deposit Amount', key: 'deposit_amount' },
        { label: 'IpAddress', key: 'EnrollmentIpAddress' },
        { label: 'Tdu', key: 'TduName' },
        { label: 'Promo code', key: 'promo_code' },
        { label: 'UTM_Source', key: 'utm_source' },
        { label: 'UTM_Content', key: 'utm_content' },
    ];

    const openAction = Boolean(anchorAction);

    const handleExport = () =>{
        downloadCSV(promoCodesData, "promo_codes")
    }

    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };

    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };

    const onFilter = (e) => {
        setFilterText(e.target.value);
        e.target.focus();
    };

    const actionProps = {
        title: "Promocodes",
        addTitle: "Add Promocodes",
        filterTitle: "Filter Promocodes",
        tableData,
        openAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportOpsHeaders,
        handleExportAction: handleExport,
        handleActionClick,
        handleAddAction: addPromoCode
    };

    const actionsMemo = React.useMemo(() =>
        <>
            <ActionBar actionProps={actionProps} />
        </>
        , [tableData, filterText, anchorAction, promoCodesData]
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

    }, [filterText, resetPaginationToggle]);
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const today = new Date();
    const inTwoWeeks = new Date(new Date().setDate(today.getDate() + 45));
    const conditionalRowStyles = [
        /*{
            when: row =>   ( new Date(row.end_date) > new Date() && new Date(row.end_date) < inTwoWeeks),
            style: {
                backgroundColor: 'orange',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row =>   new Date(row.end_date) < new Date(),
            style: {
                backgroundColor: 'gray',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        }*/

        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];

    useEffect(() => {
        setColumns([
            {
                name: '',
                selector: row => <RowSelector row={row} tableData={tableData} setTableData={setTableData} />,
                sortable: false,
                right: true,
                width: "60px",
            },
            {
                name: 'PromoCode',
                selector: row => row.promocode,
                sortable: true,
            },
            {
                name: 'StartDate',
                selector: row => row.start_date,
                sortable: true,
                right: true,
            },
            {
                name: 'EndDate',
                selector: row => row.end_date,
                sortable: true,
                right: true,
            },
            {
                name: 'Expired',
                selector: row => new Date(row.end_date) >= new Date() ? 'No' : 'Yes',
                sortable: true,
                right: true,
            },
            {
                name: 'Description',
                selector: row => (new Date(row.end_date) - new Date(-90)),
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'UTM Content',
                selector: row => row.converting_utm_content,
                sortable: true,
                right: true,
            },
            {
                name: 'Send to SG',
                selector: row => row.send_to_sg ? "Yes" : "No",
                sortable: true,
                right: true,
            },
        ]);
    }, [tableData])

    const loadPromocodes = async () => {
        let response = await axios.get('/internal/promocodes',
            { headers: { 'x-api-key': process.env.REACT_APP_API_KEY } }
        );
        let sorted = response.data.data;
        function compare(a, b) {
            if (new Date(a.start_date) < new Date(b.start_date)) {
                return 1;
            }
            if (new Date(a.start_date) > new Date(b.start_date)) {
                return -1;
            }
            return 0;
        }
        sorted.sort(compare);
        setPromoCodesData(sorted);
    }
    useEffect(() => {
        const init = async () => {
            await loadPromocodes();
        }
        init().then(
            () => {
                // console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || !promoCodesData || promoCodesData.length <= 0) ? (
                    <>
                        loading
                    </>
                ) : (<>
                    <div id="portal"></div>
                    <PromoCodeAddModal open={isOpenAdd} onClose={handleOnCloseAdd}></PromoCodeAddModal>
                    {
                        tableData?.length === 1 && isOpenEdit && (
                            <PromoCodeEditModal
                                open={isOpenEdit}
                                onClose={handleOnCloseUpdate}
                                rowData={tableData[0]}
                            ></PromoCodeEditModal>
                        )}
                    <Box className={classes.tableContainer}>
                        <Box className={classes.table}>
                            <DataTable
                                columns={columns}
                                customStyles={customStyleForTable}
                                conditionalRowStyles={conditionalRowStyles}
                                data={filteredItems}
                                defaultSortAsc={false}
                                defaultSortFieldId={1}
                                fixedHeader
                                fixedHeaderScrollHeight="800px"
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={perPage}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                striped
                                responsive
                                dense
                                subHeader

                                subHeaderComponent={subHeaderComponentMemo}
                                actions={actionsMemo}
                            >
                            </DataTable>
                        </Box>
                    </Box>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorAction}
                        open={openAction}
                        onClose={handleActionClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        style={{ margin: 0, padding: 0 }}
                    >
                        <MenuItem disabled={tableData?.length !== 1} onClick={() => { handleActionClose(); setIsOpenEdit(true) }}>
                            <div className={classes.menuItem}>
                                <EditOutlined color="primary" sx={{ mr: 1 }} />
                                Edit
                            </div>
                        </MenuItem>
                        {/* <Divider style={{ margin: 0, padding: 0 }} /> */}
                        {/* <MenuItem onClick={handleActionClose}>
                            <div className={classes.menuItem}>
                                <BlockIcon color="primary" sx={{ mr: 1 }} />
                                Deactivate
                            </div>
                        </MenuItem> */}
                        <style>{`
                            ul.MuiList-root.MuiList-padding {
                                padding: 0;
                            }
                        `}</style>
                    </Menu>
                </>
                )
            }
        </>
    );
}


const useStyles = makeStyles(theme => ({
    menuItem: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: "14px !important",
        minWidth: "135px",
        // filter: "grayscale(100%)",
        paddingTop: 6,
        paddingBottom: 6,
        color: theme.palette.primary.main
    },
    tableContainer: {
        padding: 30,
        [theme.breakpoints.down("lg")]: {
            padding: 0
        },
    },
    table: {
        // border: "1px solid black"
    },
}))


