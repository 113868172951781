import styled from "styled-components";
import React from "react";
import { FieldLabel, TextField } from "../../components/FormComponents";
import { useState } from "react";
import { breakpoints } from "../../components/BreakPoints";

const Row = styled.div`
  display: flex;
  padding: min(4.17vw, 3.75rem);
  color: #3a3a3a;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    padding: 1rem 8vw 4rem;
  }
`;

const Column = styled.div`
  flex: 0 0 calc(50% - 4px);

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    flex: 0 0 100%;

    &:first-of-type {
      margin-bottom: 3rem;
    }
  }
`;

const SectionHeading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
`;

const Container = styled.div`
  max-width: 450px;
  margin: 1rem auto 0 0;

  & .text {
    margin-bottom: 2.5rem;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
`;
const Head = styled.div`
  margin-bottom: 1rem;
`;

export default function GenerateEmailSignaturePage() {
    const [fullName, setFullName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    function HandleSubmit(event) {
        event.preventDefault();
    }

    function HandleNameInputChange(event) {
        setFullName(event.target.value);
    }

    function HandleJobTitleInputChange(event) {
        setJobTitle(event.target.value);
    }

    function HandlePhoneNumberInputChange(event) {
        setPhoneNumber(event.target.value);
    }

    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 9)}`;
    }

    function HandlePhoneNumberFormat(event) {
        const formattedInputValue = formatPhoneNumber(event.target.value);
        event.target.value = formattedInputValue;
    }

    return (
        <>
            <Head>
                <title>GoodCharlie</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="robots" content="noindex" />
                <meta name="theme-color" content="#FF5A6A" />
            </Head>
            <main>
                <Row>
                    <Column>
                        <p>Fill in the fields below to generate your email signature.</p>
                        <Container>
                            <form onSubmit={HandleSubmit}>
                                <InputGroup>
                                    <FieldLabel htmlFor="fullname">Full name</FieldLabel>
                                    <TextField
                                        type="text"
                                        name="fullname"
                                        id="fullname"
                                        placeholder="Full name"
                                        onChange={HandleNameInputChange}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <FieldLabel htmlFor="jobTitle">Job title</FieldLabel>
                                    <TextField
                                        type="text"
                                        name="jobTitle"
                                        id="jobTitle"
                                        placeholder="Job title"
                                        onChange={HandleJobTitleInputChange}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <FieldLabel htmlFor="phone">Phone number</FieldLabel>
                                    <TextField
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        placeholder="Phone number"
                                        onChange={HandlePhoneNumberInputChange}
                                        onKeyDown={HandlePhoneNumberFormat}
                                    />
                                </InputGroup>
                            </form>
                        </Container>
                    </Column>
                    <Column>
                        <SectionHeading>Preview</SectionHeading>
                        <Container>
                            <p className="text">
                                Copy the whole of the generated preview below and paste in the
                                signature field of your email account.
                            </p>
                        </Container>
                        {/* Generated email to be copied. Styles intentionally made inline because it's to be used in an email. */}
                        <div
                            style={{
                                textAlign: "left",
                                fontFamily: "Arial, Helvetica, sans-serif",
                            }}
                        >
                            <p
                                style={{
                                    fontSize: "16px",
                                    color: "#ff5a6a",
                                    fontWeight: "bold",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    margin: "0",
                                    textDecoration: "none",
                                    lineHeight: "normal",
                                }}
                            >
                                {fullName ? fullName : "Full name"}
                            </p>
                            <p
                                style={{
                                    fontSize: "12px",
                                    color: "#202020",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    margin: "0 0 13px",
                                    textDecoration: "none",
                                    lineHeight: "normal",
                                }}
                            >
                                {jobTitle ? jobTitle : "Job title"}
                            </p>

                            <p
                                style={{
                                    fontSize: "12px",
                                    color: "#202020",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    textDecoration: "none",
                                    lineHeight: "normal",
                                }}
                            >
                                5373 W. Alabama Street <br />
                                Suite 505, Houston, TX 77056.
                            </p>

                            <p
                                style={{
                                    fontSize: "12px",
                                    color: "#202020",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    margin: "13px 0 0",
                                    textDecoration: "none",
                                    lineHeight: "normal",
                                }}
                            >
                                {phoneNumber ? phoneNumber : ""}
                            </p>
                            <a
                                href="https://goodcharlie.com"
                                style={{
                                    fontSize: "12px",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    textDecoration: "underline",
                                    display: "block",
                                    color: "rgb(0, 0, 258)",
                                    lineHeight: "normal",
                                }}
                            >
                                www.goodcharlie.com
                            </a>

                            <div style={{ width: "200px", marginTop: "18px" }}>
                                <img
                                    src="/public/email-signature-logo.png"
                                    alt="GoodCharlie logo and tagline"
                                    width={200}
                                    style={{ height: "auto", width: "200px" }}
                                />

                                <div style={{ marginTop: "8px" }}>
                                    <a href="https://instagram.com/mygoodcharlie">
                                        <img
                                            src="/public/IG.png"
                                            alt="Instagram logo"
                                        />
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a href="https://facebook.com/mygoodcharlie">
                                        <img
                                            src="/public/FB.png"
                                            alt="Facebook logo"
                                        />
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a href="https://www.tiktok.com/@mygoodcharlie">
                                        <img
                                            src="/public/TK.png"
                                            alt="TikTok logo"
                                        />
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a href="https://www.linkedin.com/company/goodcharlie">
                                        <img
                                            src="/public/IN.png"
                                            alt="LinkedIn logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Column>
                </Row>
            </main>
        </>
    );
}
