import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";

import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, MuiAutocomplete, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import MyRichTextEditor from 'components/RichTextEditor';
import { useSnackbar } from 'notistack';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '2% 2%',
    borderRadius: '5px',
    zIndex: 1000,
    width:"70%"
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const SendEmailModal = ({ open, onClose, mailTo, handleTyping, user_id, utilityName,messageTo}) => {

    const commonClasses = useCommonStyles();
    const [emailBody,setEmailBody] = useState("");
    const [mailType,setMailType] = useState("Enrollment Rejected");
    const {enqueueSnackbar} = useSnackbar();

    const [formValue, setFormValue] = React.useState({
      mailTo:mailTo,
      subject:""
    });

    useEffect(()=>{
        setFormValue({...formValue, mailTo: mailTo})
    },[mailTo])

    const handleCancel = async (e) => {
        setFormValue({
            mailTo:"",
            subject:""
        })
        onClose();
    }
    
    useEffect(()=>{
        setMailType("Enrollment Rejected")
        getMails("Enrollment Rejected")
    },[open, utilityName])

    useEffect(()=>{
        setMailType(mailType)
    },[formValue.subject])
    
    const getMails = async(type) =>{
        // setMailType(type)
        try{
            let arr = ["Enrollment Rejected","Deposit Waiver Rejected","Pending Q/A"]
            const response = await axios.get(`/internal/email-templates`,
                { headers: {'x-api-key' : process.env.REACT_APP_API_KEY},
            params:{
                id:(arr.indexOf(type)+1)
            }}
                );
                setEmailBody(response.data.email_templates?.replace("(insert utility name)", utilityName));
        }
        catch(error){
            console.log(error)
            setEmailBody(null)
        }
    }

    const handleSubmit = async (e) => {
        try {
            let arr = ["Enrollment Rejected","Deposit Waiver Rejected","Pending Q/A","Other"]
            const response = await axios.post('/internal/send-email', {
                user_id:user_id,
                to_email: formValue.mailTo ? formValue.mailTo : mailTo,
                to_name: formValue.mailTo ? formValue.mailTo : mailTo,
                subject: mailType!="Other"? mailType:formValue.subject,
                body: emailBody,
                template_id:(arr.indexOf(mailType)+1)
            }, {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                },
            })
            response &&  enqueueSnackbar(`Mail sent successfully : ${mailType!="Other"? mailType:formValue.subject}`, {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'success'
            });
            if(response){
                handleTyping({type: "click", msg: `Successfully sent email - ${mailType!="Other"? mailType:formValue.subject}`});
            }
            
            try {
                const response = await axios.post('/internal/send-messages', 
                {
                    phone_number : messageTo,
                    message: "We have an update regarding your GoodCharlie energy account. Please check your email (including spam folder)."
                },
                {
                    headers: {
                        'x-api-key' : process.env.REACT_APP_API_KEY
                    }
                })
            } catch(error) {
                console.log(error)
            }

            response && onClose()
        } catch(error) {
            console.log(error)
            enqueueSnackbar("Something went wrong", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }
    }

    const handleChange = (event) => {
        if(event.target.name == "send_to_sg"){
            setFormValue({
                ...formValue,
                send_to_sg: event.target.checked ? 1 : 0,
            })
        }
        else{
            setFormValue({
                ...formValue,
                [event.target.name]: event.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Send Email
                        </Typography>
                        <MuiText
                            label="Mail To"
                            name="mailTo"
                            defaultValue={mailTo}
                            onChange={handleChange}
                            key={"MailTo"}
                        />
                           <MuiAutocomplete
                            label="Subject"

                            name="subject"

                            formValue={formValue}

                            value={'Enrollment Rejected'}
                            setFormValue={setFormValue}
                            onChange={handleChange}
                            //defaultValue={'Enrollment Rejected'}
                            getMails={getMails}
                            setMailType={setMailType}
                            options={[
                                { title: 'Enrollment Rejected', value: "1" },
                                { title: 'Deposit Waiver Rejected', value: "2" },
                                { title: 'Pending Q/A', value: "3" },
                                { title: 'Other', value: "4" },
                            ]}
                        />
                        { mailType == "Other"&&
                        <MuiText
                            label="Subject"
                            name="subject"
                            onChange={handleChange}
                            key={"Subject"}
                        />}

                        {/* <MuiText
                            label="Message"
                            multiline
                            name="Message"
                            defaultValue={formValue.description}
                            onChange={handleChange}
                        /> */}
                        <MyRichTextEditor mailType={mailType} emailBody={emailBody} setEmailBody={setEmailBody}/>
                        <br />

                        <div style={{display:"flex",justifyContent:"space-evenly", width:"100%"}}>
                            <ThemedButton
                                outlined={"true"}
                                onClick={handleCancel}
                                width="25%"
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                                width="25%"
                            >
                                Send
                            </ThemedButton>
                        </div>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            } 
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default SendEmailModal;

