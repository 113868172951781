import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import MonthlySalesTable from "./salestables/MonthlySalesTable";
import DailySalesTable from "./salestables/DailySalesTable";

// import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { formatForAPIFilter } from "../../utils/getLabelsForDailySales" 
import { getBrokerData, getNetTarget, getSalesTarget } from "./salestables/salesApis";
import { CircularProgress } from "@mui/material";
import { netPlanSourceFormatter } from "utils/apiDataFormatterSalesReport";
import { useNavigate } from 'react-router-dom';
import { SalesCategory } from "./SalesCategory";


const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
    
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
    
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

      
export const SalesReport =  (props) => {
    const navigate = useNavigate()
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [currentYearFilter, setCurrentYearFilter] = React.useState(new Date().getFullYear())
    const filteredItems = enrollmentsData.filter(
        item => item.first_name && item.first_name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const [pageRefresh, setPageRefresh] = useState(false)
    
    const [popColSum, setPopColSum] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataColSum, setTableDataColSum] = useState([])
    const [targetData, setTargetData] = useState([])
    const [targetDataDaily, setTargetDataDaily] = useState([])
    const [gtnPercent, setGtnPercent] = useState([])

    const [targetColSum, setTargetColSum] = useState([])

    const [value, setValue] = React.useState(0);
    const [currentMonthFilter, setCurrentMonthFilter] = useState(new Date().getMonth()+1)

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    useEffect(() => {
        (async()=>{
            setLoading(true)

            //net gross netgross%
            let res = formatForAPIFilter(currentMonthFilter, currentYearFilter, value)
            let apiRes = await getBrokerData(res[0],res[1], value)
            if(apiRes){
                setTableData(apiRes?.data)
                setTableDataColSum(apiRes?.column_sum[0]?.total)
            }
            else{
                setTableData([{
                    "Advertising" : [{}],
                    "Comparison Broker" : [{}],
                    "Direct Sales Broker" : [{}],
                    "Free Broker": [{}]
                }])
                setTableDataColSum([])
            }

            //target
            let resTarget = await getSalesTarget(currentMonthFilter, currentYearFilter, value)
            if(resTarget){
                let formattedSourceData = netPlanSourceFormatter(resTarget)
                setTargetData(formattedSourceData)
                setTargetDataDaily(resTarget?.data)
                setTargetColSum(resTarget?.sumOfDateOrMonthWise)
            }
            else{
                setTargetData([{}])
                setTargetDataDaily([{}])
            }

            //percent of plan
            let resGtnPercent = await getNetTarget(res[0],res[1], value)
            if(resGtnPercent){
                setGtnPercent([resGtnPercent?.data])
                setPopColSum(resGtnPercent?.totalPercentOfPlan)
            }
            else{
                setGtnPercent([
                    {
                        "Free Broker": [{}],
                        "Comparison Broker": [{}],
                        "Advertising": [{}],
                        "Direct Sales Broker": [{}]
                    }
                ])
                setPopColSum([{}])
            }
            setLoading(false)
        })()
    }, [currentMonthFilter, currentYearFilter, value, pageRefresh]);

    

    return (
        <>
            {
                (loading) ? (
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between" }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                    <Tab style={{backgroundColor: value === 0 && "#ff5b6a", borderRadius: value === 0 && "10px 10px 0px 0px", color: value === 0 && "white"}} label="Monthly" {...a11yProps(0)} />
                                    <Tab style={{backgroundColor: value === 1 && "#ff5b6a", borderRadius: value === 1 && "10px 10px 0px 0px", color: value === 1 && "white"}} label="Daily" {...a11yProps(1)} />
                                    <Tab style={{backgroundColor: value === 2 && "#ff5b6a", borderRadius: value === 2 && "10px 10px 0px 0px", color: value === 2 && "white"}} label="Mappings" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                    <CircularProgress />
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                    <CircularProgress />
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                    <CircularProgress />
                                </Box>
                            </TabPanel>
                        </Box>
                    </>
                ):(
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between" }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                    <Tab style={{backgroundColor: value === 0 && "#ff5b6a", borderRadius: value === 0 && "10px 10px 0px 0px", color: value === 0 && "white"}} label="Monthly" {...a11yProps(0)} />
                                    <Tab style={{backgroundColor: value === 1 && "#ff5b6a", borderRadius: value === 1 && "10px 10px 0px 0px", color: value === 1 && "white"}} label="Daily" {...a11yProps(1)} />
                                    <Tab style={{backgroundColor: value === 2 && "#ff5b6a", borderRadius: value === 2 && "10px 10px 0px 0px", color: value === 2 && "white"}} label="Mappings" {...a11yProps(2)} />
                                </Tabs>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        {value === 1 && <Box style={{marginRight: "20px"}}>
                                            <FormControl sx={{ m: 1, minWidth: 120, margin: 0 }} size="small">
                                                <InputLabel>Month Filter</InputLabel>
                                                <Select
                                                    value={currentMonthFilter}
                                                    displayEmpty
                                                    onChange={(e, val)=>{
                                                        e.target.value && setCurrentMonthFilter(e.target.value)
                                                    }}
                                                    label="Month Filter"
                                                    // inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value={1}>January</MenuItem>
                                                    <MenuItem value={2}>February</MenuItem>
                                                    <MenuItem value={3}>March</MenuItem>
                                                    <MenuItem value={4}>April</MenuItem>
                                                    <MenuItem value={5}>May</MenuItem>
                                                    <MenuItem value={6}>June</MenuItem>
                                                    <MenuItem value={7}>July</MenuItem>
                                                    <MenuItem value={8}>August</MenuItem>
                                                    <MenuItem value={9}>September</MenuItem>
                                                    <MenuItem value={10}>October</MenuItem>
                                                    <MenuItem value={11}>November</MenuItem>
                                                    <MenuItem value={12}>December</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>}
                                        {!(value == 2) && <Box style={{marginRight: "20px"}}>
                                            <Button onClick={()=>{
                                                if(currentYearFilter-1 > 2021){
                                                    setCurrentYearFilter(currentYearFilter-1)
                                                }
                                            }}>-</Button> &nbsp;
                                                {currentYearFilter}&nbsp;&nbsp;
                                            <Button onClick={()=>{
                                                if(currentYearFilter <= new Date().getFullYear()){
                                                    setCurrentYearFilter(currentYearFilter+1)
                                                }
                                            }}>+</Button>
                                        </Box>}
                                    </Box>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                    <b>SALES REPORT - MONTHLY</b>
                                    <br/>
                                    <br/>
                                    <MonthlySalesTable showLabels={true} tableData={targetData} tableDataColSum={targetColSum} setTargetData={setTargetData} label={"SALES - TARGET"} currentYearFilter={currentYearFilter} showColorBg={false} editModal={true} tableName={"target"} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh}/>
                                    <br/>
                                    <MonthlySalesTable showLabels={true} tableData={tableData} tableDataColSum={tableDataColSum?.grossSum} label={"GROSS - SALES"} currentYearFilter={currentYearFilter} showColorBg={false} editModal={true} tableName={"gross"}/>
                                    <br/>
                                    <MonthlySalesTable showLabels={true} tableData={tableData} tableDataColSum={tableDataColSum?.netSum} label={"NET - SALES"} currentYearFilter={currentYearFilter} showColorBg={false} editModal={false} tableName={"net"}/>
                                    <br/>
                                    <MonthlySalesTable showLabels={true} tableData={gtnPercent} tableDataColSum={popColSum} label={"% OF PLAN"} currentYearFilter={currentYearFilter} showColorBg={true} editModal={false} tableName={"percent"}/>
                                    <br/>
                                    <MonthlySalesTable showLabels={true} tableData={tableData} tableDataColSum={tableDataColSum?.grossNetSum} label={"GROSS TO NET %"} currentYearFilter={currentYearFilter} showColorBg={true} editModal={false} tableName={"percent"}/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                    <b>SALES REPORT - DAILY</b>
                                    <br/>
                                    <br/>
                                    <DailySalesTable showLabels={true} tableData={targetDataDaily} tableDataColSum={targetColSum} label={"SALES - TARGET"} currentYearFilter={currentYearFilter} currentMonthFilter={currentMonthFilter} showColorBg={false} tableName={"target"}/>
                                    <br/>
                                    <DailySalesTable showLabels={true} tableData={tableData[0]} tableDataColSum={tableDataColSum?.grossSum} label={"GROSS - SALES"} currentYearFilter={currentYearFilter} currentMonthFilter={currentMonthFilter} showColorBg={false} tableName={"gross"}/>
                                    <br/>
                                    <DailySalesTable showLabels={true} tableData={tableData[0]} tableDataColSum={tableDataColSum?.netSum} label={"NET - SALES"} currentYearFilter={currentYearFilter} currentMonthFilter={currentMonthFilter} showColorBg={false} tableName={"net"}/>
                                    <br/>
                                    <DailySalesTable showLabels={true} tableData={gtnPercent[0]} tableDataColSum={popColSum} label={"% OF PLAN"} currentYearFilter={currentYearFilter} currentMonthFilter={currentMonthFilter} showColorBg={true} tableName={"percent"}/>
                                    <br/>
                                    <DailySalesTable showLabels={true} tableData={tableData[0]} tableDataColSum={tableDataColSum?.grossNetSum} label={"GROSS-TO-NET%"} currentYearFilter={currentYearFilter} currentMonthFilter={currentMonthFilter} showColorBg={true} tableName={"percent"}/>
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <SalesCategory />
                            </TabPanel>
                        </Box>
                    </>
                )
            }

        </>
    );
}
