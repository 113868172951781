import React, { useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";

import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText } from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const ModalCheckBox = styled.input`
        width: 35px;
        height: 40px
      `;
const ModalInput = styled.input`
        min-width: 350px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 350px;
      `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const AssoProductsEditModal = ({ open, onClose, allProductsData, formValue, setFormValue, brokerProducts }) => {

    const commonClasses = useCommonStyles();
    const classes = useStyles();
    let products = formValue.products ? formValue.products : (brokerProducts ? JSON.stringify(brokerProducts) : {})
    let unvProds = []

    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {
        setFormValue({
            ...formValue,
            products: products
        })
        onClose();
    }

    if (!open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Add Associated Products for Broker</Typography>

                        {
                            allProductsData ? allProductsData.map((item) => {
                                const addRemoveProducts = (data) => {
                                    products = JSON.parse(products)
                                    data.target.checked ? products = { ...products, [data.target.name]: item.name } : delete products[data.target.name];
                                    products = JSON.stringify(products)
                                }
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <CheckBox
                                            type="checkbox"
                                            name={item.productID}
                                            defaultChecked={(formValue.products ? formValue.products !== '' : brokerProducts !== '' && formValue.products ? typeof (formValue.products) !== 'undefined' : typeof (brokerProducts) !== 'undefined') ? (formValue.products ? JSON.parse(formValue.products).hasOwnProperty(item.productID) : brokerProducts.hasOwnProperty(item.productID)) : false}
                                            onChange={addRemoveProducts}
                                        />
                                        <Typography variant="h6" sx={{ ml: '10px', mb: '10px', alignSelf: "center" }} >{item.productID} : {item.name}</Typography>
                                    </div>
                                )
                            }) : "No Products Available"
                        }
                        {
                            brokerProducts && Object.keys(brokerProducts).map((item) => {
                                let allPJSON = {}
                                allProductsData.map((item)=>{
                                    allPJSON = {...allPJSON, [item.productID]:item.name}
                                })
                                if(!Object.keys(allPJSON).includes(item)){
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckBox
                                                type="checkbox"
                                                name={item.productID}
                                                defaultChecked={false}
                                                disabled={true}
                                            />
                                            <Typography variant="h6" sx={{ ml: '10px', mb: '10px', alignSelf: "center", color:'#c1c1c1' }} >{item} : {brokerProducts[item]}</Typography>
                                        </div>
                                    )
                                }
                            })
                        }
                        <br />

                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Done
                            </ThemedButton>
                        </InputRow>

                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default AssoProductsEditModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
}))