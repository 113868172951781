import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";

import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch } from 'components/StyledInputFields';
import { Checkbox as CBX } from '@mui/material';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

import AssoProductsAddModal from 'components/modals/AssoProductsAddModal';
import AssoTokenAddModal from "components/modals/AssoTokenAddModal";

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const ModalCheckBox = styled.input`
        width: 35px;
        height: 40px
      `;
const ModalInput = styled.input`
        min-width: 350px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 350px;
      `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}
const BrokerAddModal = ({ open, onClose, loadEnrollments }) => {

    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [assoProductsModal, setAssoProductsModal] = useState(false)
    const [assoTokenModal, setAssoTokenModal] = useState(false)
    const [allProductsData, setAllProductsData] = useState([])
    const [forBtnCB, setForBtnCB] = useState({
        assoProds: false,
        assoToken: false
    })

    const [formValue, setFormValue] = React.useState({
        broker_name: '',
        sg_agent_id: '',
        sg_user_id: '',
        submit_deposit_required: 0,
        broker_api_enabled: 1,
        converting_utm_content: '',
        converting_utm_campaign: '',
        converting_utm_source: '',
        converting_utm_medium: '',
        broker_batch_enabled: "1",
        file_prefix: "n/a",
        deactivation_reason: "n/a",
        api_token: '',
        products: '',
        tfn_number: ""
    });

    const handleCancel = (e) => {
        setFormValue({
            broker_name: '',
            sg_agent_id: '',
            sg_user_id: '',
            submit_deposit_required: 0,
            broker_api_enabled: 1,
            converting_utm_content: '',
            converting_utm_campaign: '',
            converting_utm_source: '',
            converting_utm_medium: '',
            broker_batch_enabled: "1",
            file_prefix: "n/a",
            deactivation_reason: "n/a",
            api_token: '',
            products: '',
            tfn_number: ""
        })
        setForBtnCB({
            assoProds: false,
            assoToken: false
        })
        onClose();
    }

    const handleSubmit = async (e) => {
        try {
            const response = await axios.post('/internal/brokers/create', formValue, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
            loadEnrollments();
            onClose();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getPlans(75001); //hard coded zip for now
    }, [])

    useEffect(() => {
        formValue.api_token.trim() !== '' ? setForBtnCB({ ...forBtnCB, assoToken: true }) : setForBtnCB({ ...forBtnCB, assoToken: false })
    }, [formValue.api_token])

    useEffect(() => {
        (formValue.products.trim() !== '' && formValue.products !== '{}') ? setForBtnCB({ ...forBtnCB, assoProds: true }) : setForBtnCB({ ...forBtnCB, assoProds: false })
    }, [formValue.products])

    const getPlans = async (zipCode) => {
        try {
            let response = await axios.get(`/internal/get/products`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            setAllProductsData(response.data.webProducts)
        } catch (err) {
            console.error(err);
            return err;
        }
        return 0;
    };

    const handleChange = (e) => {
        if (e.target.name === "broker_api_enabled") {
            let reason = e.target.checked === false ? "" : "n/a"
            setFormValue({
                ...formValue,
                broker_api_enabled: e.target.checked ? 1 : 0,
                deactivation_reason: reason,
            })
        } else {
            setFormValue({
                ...formValue,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
            });
        }
    }

    if (!open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <AssoProductsAddModal
                open={assoProductsModal}
                onClose={() => setAssoProductsModal(false)}
                allProductsData={allProductsData}
                formValue={formValue}
                setFormValue={setFormValue}
            >
            </AssoProductsAddModal>
            <AssoTokenAddModal
                open={assoTokenModal}
                onClose={() => setAssoTokenModal(false)}
                formValue={formValue}
                setFormValue={setFormValue}
            >
            </AssoTokenAddModal>

            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Add and save Broker here.</Typography>

                        <InputRow>
                            <MuiText
                                label="Broker Name"
                                name="broker_name"
                                defaultValue={formValue.broker_name}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="SG Agent ID"
                                name="sg_agent_id"
                                defaultValue={formValue.sg_agent_id}
                                onChange={handleChange}
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                // style={{ width: "98%" }}
                                label="UTM Campaign"
                                name="converting_utm_campaign"
                                defaultValue={formValue.converting_utm_campaign}
                                onChange={handleChange}
                            />
                            <MuiText
                                // style={{ width: "98%" }}
                                label="UTM Source"
                                name="converting_utm_source"
                                defaultValue={formValue.converting_utm_source}
                                onChange={handleChange}
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                label="UTM Medium"
                                name="converting_utm_medium"
                                defaultValue={formValue.converting_utm_medium}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="UTM Content"
                                name="converting_utm_content"
                                defaultValue={formValue.converting_utm_content}
                                onChange={handleChange}
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                label="SG User ID"
                                name="sg_user_id"
                                defaultValue={formValue.sg_user_id}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="TFN Number"
                                name="tfn_number"
                                defaultValue={formValue.tfn_number}
                                onChange={handleChange}
                            />
                        </InputRow>

                        <InputRow>
                            <MuiText
                                label="Intacct ID"
                                name="IntacctID"
                                defaultValue={formValue.IntacctID}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="_aiid"
                                name="_aiid"
                                defaultValue={formValue._aiid}
                                onChange={handleChange}
                            />
                        </InputRow>
                        <br />

                        <InputRow>
                            <Switch
                                label="Submit Deposit Required"
                                type="checkbox"
                                name="submit_deposit_required"
                                defaultChecked={formValue.submit_deposit_required}
                                onChange={handleChange}
                            />
                            <Switch
                                label="Enable Broker API"
                                type="checkbox"
                                name="broker_api_enabled"
                                defaultChecked={formValue.broker_api_enabled}
                                onChange={handleChange}
                            />
                        </InputRow>

                        <br />
                        {!formValue.broker_api_enabled && <MuiText
                            label="Deactivation Reason"
                            name="deactivation_reason"
                            defaultValue={formValue.deactivation_reason}
                            multiline={true}
                            row={5}
                            onChange={handleChange}
                        />}

                        <InputRow>
                            <div
                                contained={"true"}
                                onClick={() => setAssoProductsModal(true)}
                                className={classes.forCheckButton}
                            >
                                <CBX
                                    type="checkbox"
                                    checked={forBtnCB.assoProds}
                                    style={{ pointerEvents: 'none', marginRight: '10px' }}
                                />
                                <p className={classes.buttonLinks}>
                                    Associate Products
                                </p>
                            </div>
                            <div
                                contained={"true"}
                                onClick={() => setAssoTokenModal(true)}
                                className={classes.forCheckButton}
                            >
                                <CBX
                                    type="checkbox"
                                    checked={forBtnCB.assoToken}
                                    style={{ pointerEvents: 'none', marginRight: '10px' }}
                                />
                                <p className={classes.buttonLinks}>
                                    Associate Token
                                </p>
                            </div>
                        </InputRow>
                        <br />
                        <br />
                        <br />

                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>

                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default BrokerAddModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    forCheckButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'none',
        padding: '0px 0px 20px 0px',
        cursor: 'pointer',
        position: 'absolute',
    },
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))