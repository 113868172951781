import React, { useState, useEffect } from "react";
import axios from "../../utils/axios";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

export const AccountBilling = () => {
    const [data, setData] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [serviceStatus, setServiceStatus] = useState("");

    const fetchData = async (perPage = 10, page = 1, loadAll = false) => {
        setLoading(true);
        try {
            const response = await axios.get("/internal/getbilling", {
                params: {
                    per_page: loadAll ? totalRows : perPage,
                    page: loadAll ? 1 : page,
                    service_status: serviceStatus,
                },
                headers: { "x-api-key": process.env.REACT_APP_API_KEY },
            });
            if (loadAll) {
                setAllItems(response.data.data);
            } else {
                setData(response.data.data);
                setTotalRows(response.data.total);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(perPage, currentPage);
    }, [perPage, currentPage, serviceStatus]);

    const handleServiceStatusChange = (event) => {
        setServiceStatus(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const loadAllItems = async () => {
        await fetchData(perPage, currentPage, true);
    };

    const columns = [
        { name: "Account Number", selector: "AccountNumber", sortable: true },
        { name: "Service Start Date", selector: "ServiceStartDate", sortable: true },
        { name: "Service Status", selector: "Name", sortable: true },
        { name: "Last Bill Date", selector: "lastBillDate", sortable: true },
        { name: "Inactivation Date", selector: "InactivationDate", sortable: true },
        { name: "Days Since Last Bill", selector: "DaysSinceLastBill", sortable: true },
    ];

    const exportHeaders = columns.map((col) => ({ label: col.name, key: col.selector }));

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <h1>Billing Information</h1>
                </Grid>
                <Grid item>
                    {allItems.length === 0 ? (
                        <Button onClick={loadAllItems} variant="contained" color="primary">
                            Load all for download
                        </Button>
                    ) : (
                        <CSVLink
                            data={allItems}
                            headers={exportHeaders}
                            filename={"billing_info_data.csv"}
                            className="btn btn-primary"
                        >
                            Download CSV
                        </CSVLink>
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={2} style={{ marginTop: 16 }}>
                <Grid item>
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                        <InputLabel id="service-status-label">Service Status</InputLabel>
                        <Select
                            labelId="service-status-label"
                            value={serviceStatus}
                            onChange={handleServiceStatusChange}
                            label="Service Status"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Switched Away">Switched Away</MenuItem>
                            <MenuItem value="Move Out Complete">Move Out Complete</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            {/* Add more status options as needed */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        responsive
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={setPerPage}
                    />
                    <div>
                        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </Button>
                        <span>{currentPage}</span>
                        <Button onClick={() => handlePageChange(currentPage + 1)} disabled={data.length < perPage}>
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
