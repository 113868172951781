
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { ThemedButton } from "components/GCButtons";
import { makeStyles } from "@mui/styles";
import UploadIcon from '@mui/icons-material/Upload';

const ModalFileUploader = (props) => {
    const classes = useStyles()
    const hiddenFileInput = React.useRef(null);
    const [file, setFile] = useState(null);
    const [currentImage, setCurrentImage] = useState(props.previousLogo)

    const handleClick = (e) => {
        e.preventDefault()
        console.log('handleClick');
        hiddenFileInput.current.click();
    };

    const handleChange = (e) => {
        setCurrentImage(URL.createObjectURL(e.target.files[0]))
        try {
            let name = e?.target?.value?.split('\\').pop().split('/').pop()
            setFile(name)
        } catch (error) {
            console.log("event", e)
        }
        props.onChange(e)
    }

    return (
        <div className={classes.mainDiv}>
            <ThemedButton
                src={file ? false : UploadIcon}
                width={"50%"}
                onClick={handleClick}
            >
                {file || (props.label || "Upload a file")}
            </ThemedButton>
            <input
                id="imagePreview"
                type={props.type}
                name={props.name}
                defaultValue={props.defaultValue}
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                accept="image/*"
            />
            {currentImage && <img src={currentImage} className={classes.logo} alt="logo"/>}
        </div>
    );
};
export default ModalFileUploader;

const useStyles = makeStyles(theme => ({
    mainDiv: {
        // height: '150px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column-reverse',
            alignItems: 'space-around',
            justifyContent: 'center',
        },
    },
    logo: {
        maxHeight: "100px",
        maxWidth: "100px",
        padding: 15,
        borderRadius: 6,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
        marginBottom: '0px',
        [theme.breakpoints.down("lg")]: {
            marginBottom: '30px',
        },
    }
}))
