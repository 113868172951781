import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import DataTable from "react-data-table-component";

import { CSVLink, CSVDownload } from "react-csv";

const SearchBox = styled.div`
      float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const BrokerApiIncomplete =  (props) => {
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const filteredItems = enrollmentsData.filter(
        item => item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <th>Id</th><td>{data.id}</td>
            <th>Created</th><td>{data.started_at_raw}</td>
        </tr>
        <tr>
            <th>FirstName</th><td>{data.first_name}</td>
            <th>LastName</th><td>{data.last_name}</td>
        </tr>
        <tr>
            <th>Email</th><td>{data.email}</td>
            <th>PromoCode</th><td>{data.promo_code}</td>
        </tr>
        <tr>
            <th>Address</th><td>{data.address}</td>
            <th>City</th><td>{data.city}</td>
        </tr>
        <tr>
            <th>ZipCode</th><td>{data.zip_code}</td>
            <th>Esiid</th><td>{data.esiid}</td>
        </tr>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        { label: 'Enrollment Start', key: 'started_date'},
        { label: 'First Name', key: 'first_name'},
        { label: 'Last Name', key: 'last_name'},
        { label: 'Email', key: 'email'},
        { label: 'Phone', key: 'phone'},
        { label: 'Credit Score', key: 'creditScore'},
        { label: 'Address Mismatch', key: 'addressMismatch'},
        { label: 'Deposit Amount', key: 'depositAmount'},
        { label: 'Zipcode', key: 'zip_code'},
        { label: 'ESIID', key: 'esiid'},
        { label: 'IsMove', key: 'moving_switching'},
        { label: 'Requested Date', key: 'start_date'},
        { label: 'Plan Name', key: 'plan_name'},
        { label: 'Address', key: 'address'},
        { label: 'Deposit Amount', key: 'deposit_amount'},
        { label: 'Promo code', key: 'promo_code'},
        { label: 'UTM_Source', key: 'utm_source'},
        { label: 'UTM_Content', key: 'utm_content'},
        { label: 'Last Step', key: 'screen_title'},
    ];
    const exportOpsHeaders = [

    ];
    const actionsMemo = React.useMemo(() =>
        <>
            <CSVLink
                data={filteredItems}
                headers={exportHeaders}
                filename={"goodcharlie_incomplete_enrollments.csv"}
                className="btn btn-primary"
            >
                Export
            </CSVLink>
        </>,
        [filteredItems]
    );
    const onClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const conditionalRowStyles = [
       /* {
            when: row => row.is_emergency > 0,
            style: {
                backgroundColor: 'red',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },*/
        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const fetchEnrollments = async page => {
        setLoading(true);
        const response = await axios.get(`/internal/broker_api_incomplete?page=${page}&per_page=${perPage}&search=${filterText}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        await setColumns([
            {
                name: 'Id',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Date',
                selector: row => row.created_at_raw,
                sortable: true,
                right: true,
            },
            {
                name: 'Broker',
                selector: row => row.broker_name,
                sortable: true,
                right: true,
            },
            {
                name: 'FirstName',
                selector: row => row.firstName,
                sortable: true,
                right: true,
            },
            {
                name: 'LastName',
                selector: row => row.lastName,
                sortable: true,
                right: true,
            },
            {
                name: 'ZipCode',
                selector: row => row.zipCode,
                sortable: true,
                right: true,
            },
            {
                name: 'Email',
                selector: row => row.emailAddress,
                sortable: true,
                right: true,
            },
            {
                name: 'Plan',
                selector: row => row.plan_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Result',
                selector: row => row.deposit_amount ? 'Deposit Required' : row.response,
                sortable: true,
                right: true,
            },
            {
                name: 'Info',
                selector: row => row.deposit_message ? row.deposit_amount : row.message,
                sortable: true,
                right: true,
                grow: 2
            }
        ]);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        fetchEnrollments(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/broker_api_incomplete?page=${page}&per_page=${newPerPage}&search=${filterText}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        const init = async () => {
            await fetchEnrollments(1);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <SearchBox>
                            <TextField
                                id="search"
                                type="text"
                                placeholder="Filter By First Name"
                                aria-label="Search Input"
                                value={filterText}
                                onInput={onFilter}
                                autoFocus
                            />
                            <ClearButton type="button" onClick={onClear}>
                                X
                            </ClearButton>
                        </SearchBox>
                        <DataTable
                            title="Incomplete Enrollments"
                            columns={columns}
                            conditionalRowStyles={conditionalRowStyles}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="1000px"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationPerPage={perPage}
                            paginationDefaultPage={page}
                            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                            paginationServer
                            paginationTotalRows={totalRows}
                            progressPending={loading}
                            striped
                            responsive
                            dense
                            subHeader
                            actions={actionsMemo}
                        >
                        </DataTable>
                    </>
                )
            }

        </>
    );
}
