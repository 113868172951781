import React, { useState, useEffect }  from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";
import CloseIcon from '@mui/icons-material/Close';
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch } from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import { Box } from '@mui/system';
import DataTable from "react-data-table-component";

const OVERLAY_STYLES = {
    position: 'fixed',
    display: "flex",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
}

const customStyleForTable = {
    headCells: {
        style: {
            fontSize: '16px',
            fontWeight: 'bold'
        },
    },
    table: {
        style: {
            border: `1px solid grey`,
            borderRadius: 10,
            overflow: "hidden",
        }
    },
    headRow: {
        style: {
            padding: 10,
            backgroundColor: "#ffedee"
        }
    }
}

const ModalsForProductPricingGroup = ({ open, onClose }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const handleCancel = () => {
        onClose();
    }

    useEffect(()=>{
        getProductsPricingData();
    },[])

    useEffect(()=>{
        setColumns([
            {
                name: 'ID',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Product ID',
                selector: row => row.product_id,
                sortable: true,
                left: true,
            },
            {
                name: 'Product Name',
                selector: row => row.product_name,
                sortable: true,
                left: true,
            },
            {
                name: 'Charge ID',
                selector: row => row.charge_id,
                sortable: true,
                left: true,
            },
            {
                name: 'Pricing Group ID',
                selector: row => row.pricing_group_id,
                sortable: true,
                center: true,
            },
            {
                name: 'Pricing Group Name',
                selector: row => row.pricing_group_name,
                sortable: true,
                left: true,
            },
            {
                name: 'Pricing Group Description',
                selector: row => row.pricing_group_description,
                sortable: true,
                left: true,
            }
        ]);
    },[tableData])

    const getProductsPricingData = async () => {
        try {
            let response = await axios.get(`/internal/get/product/price_group`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });
            setTableData(response.data.product_price_group)
        } catch (err) {
            console.error(err);
            return err;
        }
        return 0;
    };

    if (!open) {
        // console.log('not opened...');
        return null
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <CloseIcon onClick={handleCancel} className={classes.crossIcon}/>
                <Typography variant="h5" sx={{ mb: 3, alignSelf: "center", color:"#ff8d8a"}}>Product / Pricing Group Mappings</Typography>
                <Box className={classes.expandedTableContainer}>
                    <Box className={classes.table}>
                        <DataTable
                            columns={columns}
                            data={tableData}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            striped
                            dense
                            customStyles={customStyleForTable}
                        >
                        </DataTable>
                    </Box>
                </Box>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default ModalsForProductPricingGroup;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "80%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 20px',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    crossIcon: {
        position: 'absolute',
        marginLeft: '500px',
        cursor: 'pointer'
    },
}))

