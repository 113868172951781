import { makeStyles } from "@mui/styles";

const useCommonStyles = makeStyles((theme) => ({
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }
}));

export default useCommonStyles;