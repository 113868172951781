import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";
import {Input} from "@mui/material";

const LinkButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 85px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 320px;
        text-align: center;   
        display: inline-block;
        align-items: center;
        justify-content: center;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    `;
const TextInput = styled(Input)`
        width: 20%;
  margin-top: 50px;
        margin-bottom: 12px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.23);
        padding: 5px 15px;
        font-size: 1rem;
        height: 33px,;
    `;

export const NewEnrollment =  (props) => {
    const { instance, accounts } = useMsal();
    const [brokersData, setBrokersData] = useState([]);
    const [tfnsData, setTfnsData] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('18002055230');
    const [enrollmentLink, setEnrollmentLink] = useState()
    const [selectedBroker, setSelectedBroker] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedTfn, setSelectedTfn] = useState({});
    const baseEnrollmentLink = 'https://enrollment.goodcharlie.com/';
    const intUsers = [
        {id: '3d36a6cd-aa80-4676-b022-e9c8d011a73c',utm_term: 'EHernandez'},
        {id: '450010d1-8939-4290-9332-a5b6ffdd6f73',utm_term: 'SSimecek'},
        {id: 'da9f749c-c39e-46c3-8512-f072faee5a63',utm_term: 'DLawrence'}
    ];
    let tfn = phoneNumber;
    let defaultTfn = {tfn_number: "1-800-205-5230", id: 27};
    const findUser = (userId)=>{
        return {utm_term:accounts[0].username.replace(/@goodcharlie.com/i,'')};
        /*return intUsers.find((user) => {
            return user.id===userId;
        })*/
    }
    const  brokerSelected = async (e) => {
        let aSelect = e.target;
        let aSelectedData = aSelect.selectedOptions;
        let selectedBroker = aSelectedData[0] ?? null;
        if (selectedBroker !== null) {
            let aBroker = brokersData.find((bk)=>{return bk.id==selectedBroker.id});
            if (aBroker) {
                setSelectedBroker(aBroker);
                if (aBroker.tfn_number && aBroker.tfn_number.length) {
                    setPhoneNumber(aBroker.tfn_number.replaceAll('-',''));
                    //document.getElementById("pSelect").selectedIndex = 1;
                } else {
                    setPhoneNumber(tfn);
                }
                genIBUrl();
                genOBUrl();
            }
            let aTfn = tfnsData.find((pn)=>{return pn.id==selectedBroker.id});
            let tIx = tfnsData.findIndex((pn)=>{return pn.id==selectedBroker.id});
            if (aTfn) {
                setSelectedTfn(aTfn);
                document.getElementById("pSelect").selectedIndex = tIx+1;

            }

        }
    }
    const  tfnSelected = async (e) => {
        let aSelect = e.target;
        let aSelectedData = aSelect.selectedOptions;
        let selectedTfn = aSelectedData[0] ?? null;
        if (selectedTfn !== null) {
            let aTfn = tfnsData.find((pn)=>{return pn.id==selectedTfn.id});
            let tIx = tfnsData.findIndex((pn)=>{return pn.id==selectedTfn.id});
            if (aTfn) {
                setSelectedTfn(aTfn);
            }
            let aBroker = brokersData.find((bk)=>{return bk.id==aTfn.id});
            let bIx = brokersData.findIndex((bk)=>{return bk.id==aTfn.id});
            if (aBroker) {
                setSelectedBroker(aBroker);
                if (aBroker.tfn_number && aBroker.tfn_number.length) {
                    setPhoneNumber(aBroker.tfn_number.replaceAll('-',''));
                    document.getElementById("bSelect").selectedIndex = bIx+1;
                } else {
                    setPhoneNumber(tfn);
                }
                genIBUrl();
                genOBUrl();
            }
        }
    }
    const  genIBUrl = async (e) => {
        const handleEvent = ( typeof e === 'object');
        if (handleEvent) {
            e.preventDefault();
        }
        if (selectedBroker.id > 0 ) {
            const iUser = findUser(accounts[0].localAccountId);
            await setEnrollmentLink(baseEnrollmentLink
                + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                + '&utm_source=IBTM'
                + '&utm_medium=TFN'
                + '&utm_content=' + selectedBroker.converting_utm_content
                + '&utm_term=' + iUser.utm_term
                + '&tfn=' + phoneNumber.replaceAll('-', '')
            );
            if (handleEvent) {
               // e.target.click();
                console.log( e.target);
                console.log( baseEnrollmentLink
                    + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                    + '&utm_source=IBTM'
                    + '&utm_medium=TFN'
                    + '&utm_content=' + selectedBroker.converting_utm_content
                    + '&utm_term=' + iUser.utm_term
                    + '&tfn=' + phoneNumber.replaceAll('-', ''));
                window.location.href = baseEnrollmentLink
                                 + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                                 + '&utm_source=IBTM'
                                 + '&utm_medium=TFN'
                                 + '&utm_content=' + selectedBroker.converting_utm_content
                                 + '&utm_term=' + iUser.utm_term
                                 + '&tfn=' + phoneNumber.replaceAll('-', '');
            }
        }
    }
    const  genOBUrl = async (e) => {
        const handleEvent = ( typeof e === 'object');
        if (handleEvent) {
            e.preventDefault();
        }
        if (selectedBroker.id > 0 ) {
            const iUser = findUser(accounts[0].localAccountId);
            await setEnrollmentLink(baseEnrollmentLink
                + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                + '&utm_source=OBTM'
                + '&utm_medium=TFN'
                + '&utm_content=' + selectedBroker.converting_utm_content
                + '&utm_term=' + iUser.utm_term
                + '&tfn=' + phoneNumber.replaceAll('-', '')
            );
            if (handleEvent) {
                // e.target.click();
                console.log( e.target);
                console.log( baseEnrollmentLink
                    + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                    + '&utm_source=OBTM'
                    + '&utm_medium=TFN'
                    + '&utm_content=' + selectedBroker.converting_utm_content
                    + '&utm_term=' + iUser.utm_term
                    + '&tfn=' + phoneNumber.replaceAll('-', ''));
                window.location.href = baseEnrollmentLink
                    + '?utm_campaign=' + selectedBroker.converting_utm_campaign
                    + '&utm_source=OBTM'
                    + '&utm_medium=TFN'
                    + '&utm_content=' + selectedBroker.converting_utm_content
                    + '&utm_term=' + iUser.utm_term
                    + '&tfn=' + phoneNumber.replaceAll('-', '');
            }
        }
    }
    const tfnEntered = (e) => {
        let aText = e.target;
        console.log('tfnEntered');
        console.log(aText.value);
    }
    const loadBrokers = async () => {
        let response = await axios.get('/internal/brokers',
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        let cleanedBrokers = [];
        let cleanedTfns = [{tfn_number: "1-800-205-5230", id: 27}];
        let bks = response.data.brokers;
        let bkss = bks.sort((a,b)=>{
            return (a.broker_name>b.broker_name) ? 1: -1;
        });
        bkss.forEach((bk)=>{
            switch (bk.id) {
                case 1:
                case 2:
                case 13:
                case 22:
                case 40:
                    break;
                case 14:
                    bk.broker_name = 'Energy Procurement Services - EPS';
                    cleanedBrokers.push(bk);
                    break;
                case 18:
                    bk.broker_name = 'Texas Electricity Ratings – TER';
                    cleanedBrokers.push(bk);
                    break;
                case 27:
                    bk.tfn_number = "1-800-205-5230";
                    cleanedBrokers.push(bk);
                    break;
                default:
                    if (bk.tfn_number ) {
                        bk.tfn_number = bk.tfn_number.toString().trimEnd();
                    }
                    cleanedBrokers.push(bk);
            }
        });
        cleanedBrokers.forEach((bk)=>{
            if (bk.tfn_number) {
                const found = cleanedTfns.find((tf)=>{
                    return tf.tfn_number.toString().trimEnd() == bk.tfn_number.toString().trimEnd()
                },bk.tfn_number);
                if (! found) {
                    cleanedTfns.push( {tfn_number: bk.tfn_number, id: bk.id})
                }
            }
        });
        await setTfnsData(cleanedTfns);
        await setBrokersData(cleanedBrokers);
        let bObj = cleanedBrokers.find((bk)=>{return bk.id==27});
        setSelectedBroker(bObj);
    }
    useEffect(() => {
        const init = async () => {
            await loadBrokers();
        }
        init().then(() => {
                //await brokerSelected({target : document.getElementById("bSelect")})
                setLoading(false);
                console.log('loaded');
            }
        );
    }, []);
    return  (loading) ? (
                        <>loading</>
                ):(
                    <>
                        <br/>
                            <h4>Select Broker</h4>
                            <select id={"bSelect"}  defaultValue={27} onChange={brokerSelected} className={"enrollmentSelect"}>
                                <option key={0} id={0} value="0">Select Broker</option>
                                {
                                    brokersData.map( (a) => {
                                            return <option key={a.id} id={a.id} value={a.id}>{a.broker_name}</option>
                                        }
                                    )
                                }

                            </select>

                        <br/>
                        OR<br/><br/>
                        <h4>Select Phone Number</h4>
                        <select id={"pSelect"} defaultValue={27} onChange={tfnSelected} className={"enrollmentSelect"}>
                            <option key={998} id={998} value="0">Select Phone Number</option>

                            {
                                tfnsData.map( (a) => {
                                        return <option key={"p" + a.id} id={a.id} value={a.id}>{a.tfn_number}</option>
                                    }
                                )
                            }

                        </select>
                        <br/>

                        {/*} TFN
                        <TextInput key={999} type="text" disabled={false} value={phoneNumber} onChange={tfnEntered} />*/}
                        <br/>
                        <h4>Inbound Call</h4>
                        <LinkButton id="IBTM" variant="danger" disabled={selectedBroker.id <= 0} onClick={genIBUrl} >Start new enrollment for:<br/>
                            <big><strong>{selectedBroker.broker_name}</strong></big><br/>
                            (<strong>{phoneNumber}</strong>)
                        </LinkButton>
                        <br/><br/>
                        OR<br/><br/>
                       <h4>Outbound Call</h4>
                        <LinkButton id="OBTM" variant="danger" disabled={selectedBroker.id <= 0} onClick={genOBUrl} >Start new enrollment for:<br/>
                            <big><strong>{selectedBroker.broker_name}</strong></big><br/>
                            (<strong>{phoneNumber}</strong>)
                        </LinkButton>
                    </>
                )
}