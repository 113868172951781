import React, { useEffect } from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";

import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, MuiDateTime, MuiAutocomplete, Switch} from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import { useSnackbar } from 'notistack';


const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const SaveButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px !important
    `;
const ModalInput = styled.input`
        min-width: 192px;
      `;
const ModalTextarea = styled.textarea`
        min-width: 192px;
      `;
const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '2% 2%',
    borderRadius: '5px',
    zIndex: 1000,
    width:"40%"
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const MessagesModal = ({ open, onClose, messageTo, handleTyping}) => {

    const commonClasses = useCommonStyles();
    const { enqueueSnackbar } = useSnackbar();


    const [formValue, setFormValue] = React.useState({
      receiver:messageTo,
      message:""
    });

    useEffect(()=>{
        setFormValue({...formValue, receiver: messageTo})
    },[messageTo])

    const handleCancel = async (e) => {
        setFormValue({
            receiver:"",
            message:""
        })
        onClose();
    }
    const handleSubmit = async (e) => {

        try {
            const response = await axios.post('/internal/send-messages', 
            {
                phone_number : formValue.receiver ? formValue.receiver : messageTo,
                message: formValue.message
            },
            {
                headers: {
                    'x-api-key' : process.env.REACT_APP_API_KEY
                }
            })
            if(response?.status === 200){
                onClose();
                enqueueSnackbar("Message Sent Successfully", {
                    anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                    },
                    autoHideDuration: 2000,
                    variant: 'success'
                });
                handleTyping({type: "click", msg: `Successfully sent message`});
            }
        } catch(error) {
            console.log(error)
            enqueueSnackbar("Something Went Wrong", {
                anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                },
                autoHideDuration: 2000,
                variant: 'error'
            });
        }

        console.log('Data in formValue ==>', formValue)
    }

    const handleChange = (event) => {
        if(event.target.name == "send_to_sg"){
            setFormValue({
                ...formValue,
                send_to_sg: event.target.checked ? 1 : 0,
            })
        }
        else{
            setFormValue({
                ...formValue,
                [event.target.name]: event.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div style={MODAL_STYLES}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >
                            Send Message
                        </Typography>

                        {/* <MuiText
                            label="Sender"
                            name="sender"
                            defaultValue={formValue.promocode}
                            onChange={handleChange}
                        /> */}
                        <MuiText
                            label="Receiver"
                            name="receiver"
                            defaultValue={messageTo}
                            onChange={handleChange}
                            // disabled
                        />
                        <MuiText
                            label="Message"
                            multiline
                            name="message"
                            defaultValue={formValue.message}
                            onChange={handleChange}
                        />
                        <br />

                        <InputRow>
                            <ThemedButton
                                outlined={"true"}
                                onClick={handleCancel}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Send
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
            <style>{`
            input, select {
                width: 70%;
                height: 200%;
                padding: 5px 5px;
                margin: 4px 0;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-sizing: border-box;
                // margin-bottom: 20px
            } 
            `}</style>
        </>,
        document.getElementById('portal')
    )
};
export default MessagesModal;

