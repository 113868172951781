import { Checkbox, Radio } from "@mui/material";
import { useEffect, useState } from "react";

const checkInArray = (arr, obj, property) => {
    let selected = false;
    for (let i = 0; i < arr?.length; i++) {
        let data = arr[i];
        if (obj[property] === data[property]) {
            return true;
        } else {
            selected = false
        }
    }
    return selected;
}

const RowSelector = ({ row, tableData, setTableData }) => {
    if (row === null) {
        return <div />
    }
    let selected = checkInArray(tableData, row, "id");
    const [isSelected, setSelected] = useState(selected);

    const handleSelect = (row) => {
        if (tableData?.length > 0 && isSelected) {
            let arr = [];
            for (let i = 0; i < tableData?.length; i++) {
                let data = tableData[i]
                if (data.id !== row.id) {
                    arr.push(data);
                }
            }
            setTableData(arr);
            setSelected(false);
        } else if (tableData?.length > 0 || !isSelected) {
            let arr = [...tableData];
            arr.push(row);
            setSelected(true);
            setTableData(arr);
        } else {
            setTableData([]);
            setSelected(false);
        }
    }

    return (
        <>
            <Checkbox
                type="checkbox"
                style={{ transform: "scale(1)", padding: 0 }}
                checked={isSelected}
                onClick={() => handleSelect(row)}
            />
        </>
    )
}

export default RowSelector;