import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState, useRef } from 'react';
import getLabelsForDailySales from "../../../utils/getLabelsForDailySales"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DailySalesTable({showHeader, showLabels, tableData, tableDataColSum=null, label, currentYearFilter, currentMonthFilter, showColorBg, tableName}) {
    const [dayLabels, setDayLabels] = useState([]);
    const [totalDataRow, setTotalDataRow] = React.useState([0])

    const[forEmptyTargetData, setForEmptyTargetData] = useState([]);

    useEffect(()=>{
        let tempArr = [];
        if(!tableDataColSum){
                tableData.map(item=>{
                    let times = new Date(currentYearFilter, currentMonthFilter, 0).getDate();
                    let singleJson = {}
                    while(times+1){
                        let keyName =  `${times}-`
                        let temp2 = {...singleJson, [keyName]: "-"}
                        singleJson = temp2
                        times = times -1;
                    }
                    let final = {...singleJson, "rpt_sales_category":item.rpt_sales_category}
                    tempArr.push(final)
                })
                // let tempArr = Array(4).fill(singleJson)
                setForEmptyTargetData(tempArr)
        }
        let _dayLabels = getLabelsForDailySales(parseInt(currentMonthFilter),parseInt(currentYearFilter));
        setTotalDataRow(Array(_dayLabels.length+1).fill("-"))
        setDayLabels(_dayLabels);
        if(tableDataColSum){
            let temp = Object.values(tableDataColSum)
            let zeros = _dayLabels.length+1>=temp.length ? Array(_dayLabels.length+1-temp.length).fill("-") : Array(_dayLabels.length+1).fill("-")
            let final = [...temp, ...zeros]
            let sum = 0;
            for (var i = 0; i < final.length; i++) {
                if(typeof final[i] === "number"){
                    sum += final[i]
                }
            }
            // final.push(sum)
            setTotalDataRow(final)
        }
    },[currentMonthFilter, currentYearFilter, tableDataColSum])

    const tableRef = useRef(null);
    const handleScroll = (event) => {
        const scrollLeft = event.target.scrollLeft;
        const tables = document.querySelectorAll('.custom-table-scrolling-functionality');
        tables.forEach((table) => {
            if (table !== tableRef.current) {
                table.scrollLeft = scrollLeft;
            }
        });
    };

    return (
            tableName === "target" ?
            <TableContainer component={Paper} style={{width: "100%"}} ref={tableRef} onScroll={handleScroll} className="custom-table-scrolling-functionality">
                <Table size='small' aria-label="monthly sales table">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                        {showLabels && <TableRow>
                            <StyledTableCell style={{fontWeight: "bold", minWidth: "188px"}} >{label}</StyledTableCell>
                            {
                                dayLabels.map(item=><StyledTableCell style={{fontWeight: "bold"}}>{item.day}<br/>{item.date}</StyledTableCell>)
                            }
                            <StyledTableCell style={{fontWeight: "bold"}}>TOTAL</StyledTableCell>
                        </TableRow>}
                        
                        {tableData && Object.values(tableDataColSum ? tableData : forEmptyTargetData ).map((item, index) => {
                            let blanks = [];
                            console.log('Data in item ==>', item);
                            let displayValues = Object.keys(item).map((x)=>{if(x.includes("-")){
                                return item[x]
                            }})
                            displayValues.push(item["month_total"])

                            displayValues = displayValues.filter(function( element ) {
                                return element !== undefined;
                            })

                            for (let i = 0; i < dayLabels.length+1-item.length; i++) {
                                blanks.push(0);
                            }
                            return(
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        {item?.rpt_sales_category?.category_name}
                                    </StyledTableCell>
                                    {
                                        // Object.values(item).map((x, id)=>{
                                            displayValues.map((x, id)=>{
                                            return(
                                                <StyledTableCell align="center">{typeof x === "number" ? x?.toFixed(0) : (x?x:"-")}</StyledTableCell>
                                            )
                                        })
                                    }
                                    {
                                        blanks.map(()=><StyledTableCell align="center">-</StyledTableCell>)
                                    }
                                    {/* {
                                        <StyledTableCell align="center">{item["total"] || "-"}</StyledTableCell>
                                    } */}
                                </StyledTableRow>
                            )
                        })}

                            <TableRow>
                                <StyledTableCell style={{fontWeight: "bold"}} >TOTAL</StyledTableCell>
                                {
                                    totalDataRow.map((x, id)=>{
                                        return(
                                            <StyledTableCell align="center">{typeof x === "number" ? x.toFixed(0) : "-"}</StyledTableCell>
                                        )
                                    })
                                }
                            </TableRow>

                        {/* <TableRow>
                            <StyledTableCell style={{fontWeight: "bold"}} >TOTAL</StyledTableCell>
                            {
                                totalDataRow.map(item=><StyledTableCell style={{fontWeight: "bold"}}>{item}</StyledTableCell>)
                            }
                        </TableRow> */}

                    </TableBody>
                </Table>
            </TableContainer> 
            :
            <TableContainer component={Paper} style={{width: "100%"}} ref={tableRef} onScroll={handleScroll} className="custom-table-scrolling-functionality">
                <Table size='small' aria-label="monthly sales table">
                    {showHeader && <TableHead>
                        <TableRow>
                            <StyledTableCell colSpan={dayLabels.length+2} style={{fontWeight: "bold"}}>DAILY SALES</StyledTableCell>
                        </TableRow>
                    </TableHead>}
                    <TableBody>

                        {showLabels && <TableRow>
                            <StyledTableCell style={{fontWeight: "bold", minWidth: "188px"}} >{label}</StyledTableCell>
                            {
                                dayLabels.map(item=><StyledTableCell style={{fontWeight: "bold"}}>{item.day}<br/>{item.date}</StyledTableCell>)
                            }
                            <StyledTableCell style={{fontWeight: "bold"}}>TOTAL</StyledTableCell>
                        </TableRow>}
                        
                        {tableData && Object.values(tableData).map((item, index) => {
                            let blanks = [];
                            for (let i = 0; i < dayLabels.length+1-item.length; i++) {
                                blanks.push(0);
                            }
                            return(
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    {Object.keys(tableData)[index]}
                                </StyledTableCell>
                                {
                                    item.map((x, id)=>{
                                        return(
                                            x[tableName] == 0 ? 
                                                <StyledTableCell align="center">-</StyledTableCell>
                                            : (
                                                (showColorBg && typeof x[tableName] === "number") ?  (
                                                    label !== "GROSS TO NET %" ?
                                                    <StyledTableCell align="center" style={{backgroundColor: x[tableName] < 50 ? "#fec7ce" : ((x[tableName] >= 50 && x[tableName] < 80)  ? "#fdeb97" : "#c7efca"), color: x[tableName] < 50 ? "#c86570" : ((x[tableName] >= 50 && x[tableName] < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>
                                                        {parseFloat(x[tableName]).toFixed(0)}%
                                                    </StyledTableCell> :
                                                    <StyledTableCell align="center" style={{backgroundColor: x[tableName] < 75 ? "#fec7ce" : ((x[tableName] >= 75 && x[tableName] < 90)  ? "#fdeb97" : "#c7efca"), color: x[tableName] < 75 ? "#c86570" : ((x[tableName] >= 75 && x[tableName] < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>
                                                        {parseFloat(x[tableName]).toFixed(0)}%
                                                    </StyledTableCell>
                                                ) :
                                                <StyledTableCell align="center">{(typeof x[tableName] === "number" && x[tableName] !== 0) ? x[tableName] : "-"}</StyledTableCell>
                                            )
                                        )
                                    })
                                }
                                {
                                    blanks.map((x,id)=>{
                                        return(
                                            id+1!==blanks.length ? 
                                            <StyledTableCell align="center">-</StyledTableCell> :
                                            (
                                                ((item[item.length-1][`${tableName}_total`] ==0 || item[item.length-1][`${tableName}_total`] ==undefined) && (item[item.length-1][`${tableName}`]?.toFixed(0) == 0 || item[item.length-1][`${tableName}`]?.toFixed(0) == undefined)) ? (item[item.length-1]["showPercentTotal"] === "true" ? <StyledTableCell align="center" style={{backgroundColor: (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 50 ? "#fec7ce" : (((item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 80)  ? "#fdeb97" : "#c7efca"), color: (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 50 ? "#c86570" : (((item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{item[item.length-1]["percentTotal"]?.toFixed(0)}%</StyledTableCell> :<StyledTableCell align="center">-</StyledTableCell>) : 
                                                (
                                                    label !== "GROSS TO NET %" && showColorBg ?
                                                    <StyledTableCell align="center" style={{backgroundColor: (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 50 ? "#fec7ce" : (((item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 80)  ? "#fdeb97" : "#c7efca"), color: (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 50 ? "#c86570" : (((item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) >= 50 && (item[item.length-1][`percentTotal`] || item[item.length-1][`${tableName}`]) < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{item[item.length-1][`percentTotal`]?.toFixed(0) || item[item.length-1][`${tableName}`]?.toFixed(0) || "-"}%</StyledTableCell> :
                                                    (
                                                        showColorBg ?
                                                        <StyledTableCell align="center" style={{backgroundColor: (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 75 ? "#fec7ce" : (((item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) >= 75 && (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 90)  ? "#fdeb97" : "#c7efca"), color: (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 75 ? "#c86570" : (((item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) >= 75 && (item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]) < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]?.toFixed(0) || "-"}%</StyledTableCell> :
                                                        <StyledTableCell align="center" >{item[item.length-1][`${tableName}_total`] || item[item.length-1][`${tableName}`]?.toFixed(0) || "-"}</StyledTableCell>
                                                    )
                                                )
                                            )
                                        )
                                    })
                                }
                            </StyledTableRow>
                        )})}

                        <TableRow>
                            <StyledTableCell style={{fontWeight: "bold"}} >TOTAL</StyledTableCell>
                                {
                                    totalDataRow.map((x, id)=>{
                                        return(
                                            parseFloat(x).toFixed(0) == 0 ? 
                                                <StyledTableCell align="center">-</StyledTableCell>
                                            :
                                            (showColorBg && typeof x === "number") ? (
                                                label !== "GROSS TO NET %" ?
                                                <StyledTableCell align="center" style={{backgroundColor: x < 50 ? "#fec7ce" : ((x >= 50 && x < 80)  ? "#fdeb97" : "#c7efca"), color: x < 50 ? "#c86570" : ((x >= 50 && x < 80) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{parseFloat(x).toFixed(0)}%</StyledTableCell> :
                                                <StyledTableCell align="center" style={{backgroundColor: x < 75 ? "#fec7ce" : ((x >= 75 && x < 90)  ? "#fdeb97" : "#c7efca"), color: x < 75 ? "#c86570" : ((x >= 75 && x < 90) ? "#ceb565" : "#72a779"), fontWeight: "bold", padding: 0}}>{parseFloat(x).toFixed(0)}%</StyledTableCell>
                                            ) :
                                            <StyledTableCell align="center">{typeof x === "number" ? x : "-"}</StyledTableCell>
                                        )
                                    })
                                }
                        </TableRow>

                    </TableBody>
                </Table>
                <style>
                {`
                ::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                ::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                }
                `}
            </style>
            </TableContainer>
    );
}
