import React from 'react'
import styled from 'styled-components';
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch } from 'components/StyledInputFields';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';

const OVERLAY_STYLES = {
    position: 'fixed',
    display: "flex",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
}
const TemplateAddModal = ({ open, onClose, loadEnrollments }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [formValue, setFormValue] = React.useState({
        name: '',
        message: '',
        description: '',
        disabled: false,
        media_file: ''
    });
    const handleCancel = (e) => {
        setFormValue({
            name: '',
            message: '',
            description: '',
            disabled: false,
            media_file: ''
        })
        onClose();
    }
    const handleSubmit = async (e) => {

        const TemplateFormData = new FormData();
        TemplateFormData.append("name", formValue.name);
        TemplateFormData.append("message", formValue.message);
        TemplateFormData.append("description", formValue.description);
        formValue.media_file && TemplateFormData.append("media_file", formValue.media_file);
        TemplateFormData.append("disabled", formValue.disabled ? 1 : 0);

        console.log('Data in TemplateFormData ==>', TemplateFormData);
        try {
            const response = await axios.post('/internal/sms_template/create', TemplateFormData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
        } catch (error) {
            console.log(error)
        }

        setFormValue({
            name: '',
            message: '',
            description: '',
            disabled: false,
            media_file: ''
        })
        loadEnrollments();
        onClose();
    }

    const handleChange = (e) => {
        if (e.target.name === "active") {
            setFormValue({
                ...formValue,
                "disabled": e.target.checked ? 0 : 1
            });
        } else {
            setFormValue({
                ...formValue,
                [e.target.name]: (e.target.files && e.target.files[0]) ? e.target.files[0] : e.target.checked ? e.target.checked : e.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Add and save your Templates here.</Typography>
                        <MuiText
                            label="Template Name"
                            name="name"
                            defaultValue={formValue.name}
                            onChange={handleChange}
                        />
                        <MuiText
                            label="Description"
                            name="description"
                            defaultValue={formValue.description}
                            onChange={handleChange}
                        />
                        <MuiText
                            // style={{ width: "98%" }}
                            label="Message"
                            name="message"
                            multiline={true}
                            row={5}
                            defaultValue={formValue.message}
                            onChange={handleChange}
                        />
                        <Box className={classes.tooltipBox}>
                            <Tooltip
                                title="Tokenize the sms message with the hubspot property name like {{firstname}} ,{{email}} etc"
                                style={{color: 'inherit', width: '20px'}}
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Box>
                        <ModalFileButton
                            type="file"
                            name="media_file"
                            defaultValue={formValue.logo}
                            onChange={handleChange}
                        />
                        <br />
                        <Switch
                            label="Active"
                            type="checkbox"
                            name="active"
                            defaultChecked={!formValue.disabled}
                            onChange={handleChange}
                        />
                        <br />
                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default TemplateAddModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
    tooltipBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        marginTop: '-20px'
    }
}))

