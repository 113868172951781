import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {default as OneRowSelector, handleUnselect} from "../../components/OneRowSelector";
import ActionBar from "../../components/ActionBar";
import {Divider, Menu, MenuItem} from "@mui/material";
import {Block as BlockIcon, CheckCircleOutline as CheckCircleOutlineIcon, EditOutlined} from "@mui/icons-material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { makeStyles } from "@mui/styles";
import Theme from "theme/theme";
import RenewalEligibleEditModal from "../../components/modals/RenewalEligibleEditModal";
import downloadCSV from "../../utils/downloadCsv";
import {CSVLink} from "react-csv";

const openInit = {
    addRenewalEligible: false,
    editRenewalEligible: false,
    deactivate: false,
    userPortal: false
}
const SearchBox = styled.div`
      float: right;
    `;

const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const RenewEligible =  (props) => {
    const classes = useStyles();
    const [RenewEligibleData, setRenewEligibleData] = useState([]);
    const [isOpen, setIsOpen] = useState(openInit);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [anchorAction, setAnchorAction] = useState(null);
    const [isReload, setIsReload] = useState(false);
    let filteredItems = RenewEligibleData.filter(
        item => item.AccountNumber && item.AccountNumber.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <td><b>AccountNumber</b><br/>{data.AccountNumber}</td>
            <td><b>AgreementEnd</b><br/>{data.EndDate}</td>
            <td><b>Account/Location Status</b><br/>{data.AccountStatus}/{data.LocationStatus}</td>
            <td><b>UTM Content</b><br/>{data.UtmContent}</td>
            <td><b>Language</b><br/>{data.CustomerLanguage}</td>
        </tr>
        <tr>
            <td><b>FirstName</b><br/>{data.FirstName}</td>
            <td><b>LastName</b><br/>{data.LastName}</td>
            <td><b>Email</b><br/>{data.EmailAddress}</td>
            <td><b>PhoneNumber</b><br/>{data.PhoneNumber}</td>
            <td><b>PhoneType</b><br/>{data.PhoneType}</td>
        </tr>
        <tr>
            <td><b>Address</b><br/>{data.Address1}</td>
            <td><b>City</b><br/>{data.City}</td>
            <td><b>Zipcode</b><br/>{data.ZipCode}</td>
            <td><b>UtilityName</b><br/>{data.UtilityName}</td>
            <td><b>Flags</b><br/>{data.AccountFlags}</td>
        </tr>
        <tr>
            <td><b>Product</b><br/>{data.ProductName}</td>
            <td><b>GC Energy Rate</b><br/>{data.GCRate}</td>
            <td><b>Total Energy Rate (2k)</b><br/>{data.Kwh2000}</td>
            <td><b>Anual Usage</b><br/></td>
        </tr>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const openAction = Boolean(anchorAction);
    const conditionalRowStyles = [
        /* {
             when: row => row.is_emergency > 0,
             style: {
                 backgroundColor: 'red',
                 color: 'white',
                 '&:hover': {
                     cursor: 'pointer',
                 },
             },
         },*/
        // You can also pass a callback to style for additional customization
        // {
        //     when: row => row.calories < 400,
        //    style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        //},
    ];
    const handleClose = () => {
        setIsOpen(openInit);
        handleUnselect(tableData[0],tableData);
    };
    const handleActionClick = (event) => {
        setAnchorAction(event.currentTarget);
    };
    const handleEditRenewalEligible = () => {
        if (tableData.length === 1) {
            handleActionClose();
            setIsOpen({ ...openInit, editRenewalEligible: true })
        }
    };
    const handleActionClose = () => {
        setAnchorAction(null);
    };
    const handleExport = () =>{
        downloadCSV(RenewEligibleData, "renewals")
    }
    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const exportHeaders = [
        { label: 'Account', key: 'AccountNumber' },
        { label: 'AgreementEnd', key: 'EndDate'},
        { label: 'AccountStatus', key: 'SAGStatus'},
        { label: 'LocationStatus', key: 'LocationStatus'},
        { label: 'FirstName', key: 'FirstName'},
        { label: 'LastName', key: 'LastName'},
        { label: 'Email', key: 'EmailAddress'},
        { label: 'PhoneType', key: 'PhoneType'},
        { label: 'PhoneNumber', key: 'PhoneNumber'},
        { label: 'Address', key: 'Address1'},
        { label: 'City', key: 'City'},
        { label: 'ZipCode', key: 'ZipCode'},
        { label: 'UtilityName', key: 'UtilityName'},
        { label: 'ProductName', key: 'ProductName'},
        { label: 'EnergyRate', key: 'GCRate'},
        { label: 'TotalEnergyRate2K', key: 'Kwh2000'},
        { label: 'UTMContent', key: 'UtmContent'},
        { label: 'Disposition', key: 'GCRenewStatus'},
        { label: 'Flags', key: 'AccountFlags'},
    ];
    const actionProps = {
        title: "Rewewals",
        addTitle: "Add (not)",
        filterTitle: "Filter Renewals",
        tableData,
        openAction,
        anchorAction,
        onClear,
        onFilter,
        filterText,
        exportHeaders,
        handleExportAction: handleExport,
        handleActionClick,

    };
    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: '12px',
                paddingLeft:'0 0px',
                paddingRight:'0 0px',
                fontWeight: 'bold',
                /*paddingLeft: '0 8px',*/
                justifyContent: 'center',
                /*backgroundColor: '#FFA500'*/
            },
        },
    }
    const updateEnrollment = async (data1,form1) => {
        setLoading(true);
        const aRow = findRow(RenewEligibleData,data1, 'SAGId');
        console.log(aRow);
        if (! (aRow === false)) {
            RenewEligibleData[aRow].GCRenewStatus = form1.GCRenewStatus;
            RenewEligibleData[aRow].GCRenewNotes = form1.GCRenewNotes;
            await setRenewEligibleData(RenewEligibleData);
            filteredItems = RenewEligibleData.filter(
                item => item.AccountNumber && item.AccountNumber.toLowerCase().includes(filterText.toLowerCase()),
            );
            await setIsReload(! isReload);
        }
        setLoading(false);
    }
    const findRow = (arr, obj, property) => {
        let selected = false;
        for (let i = 0; i < arr?.length; i++) {
            let data = arr[i];
            if (obj[property] === data[property]) {
                return i;
            } else {
                selected = false
            }
        }
        return selected;
    }
    const actionsMemo = React.useMemo(() =>
            <>
                <ActionBar actionProps={actionProps} />
                    <CSVLink
                        data={filteredItems}
                        headers={exportHeaders}
                        filename={"goodcharlie_renewals.csv"}
                        className={classes.actionButtons}
                    >
                        <Button className={"MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium css-1oh0714-MuiButtonBase-root-MuiButton-root"}
                                style={{
                                    marginRight: 10,
                                    width: 166,
                                    borderWidth: 2,
                                }}
                                variant="outlined"
                        >
                        Export Data
                        </Button>
                    </CSVLink>

            </>
        , [tableData, filterText, anchorAction, RenewEligibleData]);
    useEffect(() => {
        const init = async () => {
            await fetchRenewEligible(1);
            await setColumns([
                {
                    name: '',
                    selector: row => <OneRowSelector row={row} tableData={tableData} setTableData={setTableData} handler={handleEditRenewalEligible}/>,
                    sortable: false,
                    right: false,
                    style: "padding:0px;margin:0px;",
                    width: "1.5%",
                },
                {
                    name: 'AccNo',
                    selector: row => row.AccountNumber,
                    sortable: true,
                    right: false,
                    style: "font-weight:bold;padding:0px;margin:0px;",
                    width: "3%",
                },
                {
                    name: 'EndDate',
                    selector: row => row.EndDate,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'FirstName',
                    selector: row => row.FirstName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "4%",
                },
                {
                    name: 'LastName',
                    selector: row => row.LastName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'PhoneNumber',
                    selector: row => row.PhoneNumber,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                },
                {
                    name: 'Email',
                    selector: row => row.EmailAddress,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "10%",

                },
                {
                    name: 'Zip',
                    selector: row => row.ZipCode,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "3%",
                },
                {
                    name: 'Plan',
                    selector: row => row.ProductName,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "12%",
                },
                {
                    name: 'Lang',
                    selector: row => row.CustomerLanguage.substring(0,3),
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "2%",
                },
                {
                    name: 'Flags',
                    selector: row => row.AccountFlags,
                    sortable: true,
                    right: true,
                    style: "padding:0px;margin:0px;",
                    width: "7%",
                },
                {
                    name: 'Account/Location  Status',
                    selector: row => row.AccountStatus + "/" + row.LocationStatus,
                    sortable: true,
                    style: "padding:0px;margin:0px;",
                    width: "14%",
                    right: true,
                },
                {
                    name: 'UTMContent',
                    selector: row => row.UtmContent,
                    sortable: true,
                    style: "padding:0px;margin:0px;",
                    width: "7%",
                    right: true,
                },
                {
                    name: 'Disposition',
                    selector: row => row.GCRenewStatus,
                    sortable: true,
                    style: "padding:0px;margin:0px;",
                    width: "5%",
                    right: true,
                },
                {
                    name: 'Notes',
                    selector: row => row.GCRenewNotes,
                    sortable: true,
                    style: "padding:0px;margin:0px;overflow:ellipsis;",
                    width: "14%",
                    right: true,
                }
            ]);
        }
        init().then(
            () => {
                //console.log('loaded');
            }
        );
    }, [tableData]);
    useEffect(()=>{
        console.log(isReload)
    },[isReload])
    const fetchRenewEligible = async page => {
        setLoading(true);
        const response = await axios.get(`/internal/renew_eligible?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setRenewEligibleData(response.data.data);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        fetchRenewEligible(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/renew_eligible?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setRenewEligibleData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    return (
        <>
            {
                (loading || ! RenewEligibleData || RenewEligibleData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(
                    <>
                        <div id="portal"></div>
                        {tableData?.length === 1 && isOpen.editRenewalEligible && (
                            <RenewalEligibleEditModal open={isOpen.editRenewalEligible}
                                             onClose={handleClose} tableData={tableData[0]}
                                             loadEnrollment={updateEnrollment}
                            />
                        )}
                        <DataTable
                            title="Eligible for Renewal"
                            columns={columns}
                            customStyles={tableCustomStyles}
                            conditionalRowStyles={conditionalRowStyles}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="1000px"

                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                            paginationPerPage={perPage}
                            paginationDefaultPage={page}

                            progressPending={loading}
                            striped
                            responsive
                            dense
                            subHeader
                            actions={actionsMemo}
                        >
                        </DataTable>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorAction}
                            open={openAction}
                            onClose={handleActionClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            style={{ margin: 0, padding: 0 }}
                        >
                            <MenuItem disabled={tableData?.length !== 1} onClick={handleEditRenewalEligible}>
                                <div className={classes.menuItem}>
                                    <EditOutlined color="primary" sx={{ mr: 1 }} />
                                    Edit
                                </div>
                            </MenuItem>
                            <style>{`
                            ul.MuiList-root.MuiList-padding {
                                padding: 0;
                            }
                        `}</style>
                        </Menu>
                    </>
                )
            }

        </>
    );
}
const useStyles = makeStyles(theme => ({
    actionButtons: {
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        color: "#FF5B6A",
    },
}))
