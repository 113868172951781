import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import DataTable from 'react-data-table-component';
import Table from 'react-bootstrap/Table';
function convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });
    return result;
}

function downloadCSV(array) {
    console.log(array);
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
    const filename = 'export.csv';
    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}
const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;

const SearchBox = styled.div`
        float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;       
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;

const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const BrokerSummary=  (props) => {
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([])
    //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <th>Id</th><td>{data.id}</td>
            <th>Created</th><td>{data.created_date}</td>
        </tr>
        <tr>
            <th>FirstName</th><td>{data.first_name}</td>
            <th>LastName</th><td>{data.last_name}</td>
        </tr>
        <tr>
            <th>Email</th><td>{data.email}</td>
            <th>PromoCode</th><td>{data.promo_code}</td>
        </tr>
        <tr>
            <th>Address</th><td>{data.address}</td>
            <th>City</th><td>{data.city}</td>
        </tr>
        <tr>
            <th>ZipCode</th><td>{data.zip_code}</td>
            <th>Esiid</th><td>{data.esiid}</td>
        </tr>
        <tr>
            <th>PetType</th><td>{data.pet_type}</td>
            <th>PetBreed</th><td>{data.pet_breed}</td>
        </tr>
        <tr>
            <th>UTMSources</th><td>{data.utmlookup_code}</td>
            <th> </th><td></td>
        </tr>
    </Table>
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = enrollmentsData.filter(
        item => item.broker && item.broker.toLowerCase().includes(filterText.toLowerCase()),
    );


    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField
                id="search"
                type="text"
                placeholder="Filter By Broker"
                aria-label="Search Input"
                value={filterText}
                onInput={onFilter}
                autoFocus
            />
            <ClearButton type="button" onClick={onClear}>
                X
            </ClearButton>
        </>
    );
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
            console.log('filter cleared');
        };
        const handleOnFilter = (e) => {
            setFilterText(e.target.value);
            console.log(e);
            e.target.focus();
        };

        return (
            <FilterComponent onFilter={handleOnFilter} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV({enrollmentsData})} />, []);

    const loadEnrollments = async () => {
        let response = await axios.get('/internal/broker_summary',
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        await setColumns([
            {
                name: 'Broker',
                selector: row => row.broker,
                sortable: true,
            },
            {
                name: 'Views',
                selector: row => row.visits,
                sortable: true,
                right: true,
            },

            {
                name: 'Contacts',
                selector: row => row.contacts,
                sortable: true,
                right: true,
            },
            {
                name: 'Prospects',
                selector: row => row.prospects,
                sortable: true,
                right: true,
            },
            {
                name: 'Sales',
                selector: row => row.sales,
                sortable: true,
                right: true,
            }
        ]);
    }
    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    useEffect(() => {
        const init = async () => {
            await loadEnrollments();
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(<>
                        <SearchBox>
                            <TextField
                                id="search"
                                type="text"
                                placeholder="Filter By Broker"
                                aria-label="Search Input"
                                value={filterText}
                                onInput={onFilter}
                                autoFocus
                            />
                            <ClearButton type="button" onClick={onClear}>
                                X
                            </ClearButton>
                        </SearchBox>
                        <DataTable
                            title="Enrollments"
                            columns={columns}
                            data={filteredItems}
                            defaultSortAsc={false}
                            defaultSortFieldId={1}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            fixedHeader
                            fixedHeaderScrollHeight="1000px"
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            striped
                            dense
                            subHeader
                            //subHeaderComponent={subHeaderComponentMemo}
                            //actions={actionsMemo}
                        >
                        </DataTable>
                    </>
                )
            }

        </>
    );
}
