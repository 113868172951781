import React from 'react'
import styled from 'styled-components';
// import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import ModalFileButton from "./ModalFileButton";
import { CheckBox, InputRow, StyledCheckBox, StyledInput, StyledTextarea, TextArea, MuiText, Switch } from 'components/StyledInputFields';
import useCommonStyles from 'components/CommonStyleClasses';
import Theme from 'theme/theme';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { ThemedButton } from 'components/GCButtons';
import { useEffect } from 'react';

const OVERLAY_STYLES = {
    position: 'fixed',
    display: "flex",
    justifyContent: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
}
const PartnerEditModal = ({ open, onClose, tableData, setTableData, partnersData, loadEnrollments }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const [formValue, setFormValue] = React.useState({
        ...tableData,
        logo: ''
        // partner_name: '',
        // utm_campaign: '',
        // utm_source: '',
        // utm_medium: '',
        // utm_content: '',
        // sg_account_notes: '',
        // descriptions: '',
        // active: false,
        // external_adoption_link: '',
        // external_foster_link: '',
        // external_volunteer_link: '',
        // external_donation_link: ''
    });

    useEffect(()=>{
        for(let i=0; i<partnersData.length; i++){
            if(i.id === tableData.id){
                setFormValue(i)
                break;
            }
        }
    },[partnersData])

    const handleCancel = (e) => {
        onClose();
    }

    const handleSubmit = async (e) => {
        const partnerFormData = new FormData();
        partnerFormData.append("id", formValue.id);
        partnerFormData.append("active", formValue.active ? 1 : 0);
        partnerFormData.append("descriptions", formValue.descriptions);
        partnerFormData.append("external_adoption_link", formValue.external_adoption_link);
        partnerFormData.append("external_donation_link", formValue.external_donation_link);
        partnerFormData.append("external_foster_link", formValue.external_foster_link);
        partnerFormData.append("external_volunteer_link", formValue.external_volunteer_link);
        partnerFormData.append("external_logo_url", formValue.external_logo_url);
        partnerFormData.append("logo", formValue.logo);
        partnerFormData.append("partner_name", formValue.partner_name);
        partnerFormData.append("sg_account_notes", formValue.sg_account_notes);
        partnerFormData.append("utm_campaign", formValue.utm_campaign);
        partnerFormData.append("utm_content", formValue.utm_content);
        partnerFormData.append("utm_medium", formValue.utm_medium);
        partnerFormData.append("utm_source", formValue.utm_source);

        // console.log(formValue);
        try {
            const response = await axios.post('/internal/partners/update', partnerFormData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
            });
        } catch (error) {
            console.log(error)
        }

        setTableData([])
        loadEnrollments();
        onClose();
    }

    const handleChange = (e) => {
        if (e.target.name === "active") {
            setFormValue({
                ...formValue,
                [e.target.name]: e.target.checked
            });
        } else {
            setFormValue({
                ...formValue,
                [e.target.name]: (e.target.files && e.target.files[0]) ? e.target.files[0] : e.target.checked ? e.target.checked : e.target.value
            });
        }
    }
    if (!open) {
        // console.log('not opened...');
        return null
    }
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{ alignItems: "start" }}>
                        <Typography variant="h5" sx={{ mb: 3, alignSelf: "center" }} >Edit and save your partner here.</Typography>
                        <InputRow>
                            <MuiText
                                label="Partner Name"
                                name="partner_name"
                                defaultValue={formValue.partner_name}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="SG Key"
                                name="sg_account_notes"
                                defaultValue={formValue.sg_account_notes}
                                onChange={handleChange}
                            />
                        </InputRow>
                        <InputRow>
                            <MuiText
                                // style={{ width: "98%" }}
                                label="UTM Campaign"
                                name="utm_campaign"
                                defaultValue={formValue.utm_campaign}
                                onChange={handleChange}
                            />
                            <MuiText
                                // style={{ width: "98%" }}
                                label="UTM Source"
                                name="utm_source"
                                defaultValue={formValue.utm_source}
                                onChange={handleChange}
                            />
                        </InputRow>
                        <InputRow>
                            <MuiText
                                label="UTM Medium"
                                name="utm_medium"
                                defaultValue={formValue.utm_medium}
                                onChange={handleChange}
                            />
                            <MuiText
                                label="UTM Content"
                                name="utm_content"
                                defaultValue={formValue.utm_content}
                                onChange={handleChange}
                            />
                        </InputRow>
                        <MuiText
                            label="Description"
                            name="descriptions"
                            defaultValue={formValue.descriptions}
                            multiline={true}
                            row={3}
                            onChange={handleChange}
                        />
                        <ModalFileButton
                            label="Upload a New File"
                            type="file"
                            name="logo"
                            defaultValue={formValue.logo}
                            previousLogo={formValue.external_logo_url}
                            onChange={handleChange}
                        />
                        <br />
                        <MuiText
                            label="Adoption Link"
                            name="external_adoption_link"
                            defaultValue={formValue.external_adoption_link}
                            onChange={handleChange} />
                        <MuiText
                            label="Foster Link"
                            name="external_foster_link"
                            defaultValue={formValue.external_foster_link}
                            onChange={handleChange} />
                        <MuiText
                            label="Volunteer Link"
                            name="external_volunteer_link"
                            defaultValue={formValue.external_volunteer_link}
                            onChange={handleChange} />
                        <MuiText
                            label="Donation Link"
                            name="external_donation_link"
                            defaultValue={formValue.external_donation_link}
                            onChange={handleChange} />
                        <Switch
                            label="Active"
                            type="checkbox"
                            name="active"
                            defaultChecked={formValue.active}
                            onChange={handleChange}
                        />
                        <br />
                        <InputRow>
                            <ThemedButton
                                onClick={handleCancel}
                                outlined={"true"}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                contained={"true"}
                                onClick={handleSubmit}
                            >
                                Save
                            </ThemedButton>
                        </InputRow>
                    </div>
                </form>
            </div>
        </>,
        document.getElementById('portal')
    )
};
export default PartnerEditModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width: "55%",
        height: "96vh",
        overflowX: "hidden",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        borderRadius: 10,
        padding: '60px 8vw',
        zIndex: 1000,
        [theme.breakpoints.down("lg")]: {
            width: "70%",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        }
    },
}))

