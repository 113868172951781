import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { CSVLink, CSVDownload } from "react-csv";

const SearchBox = styled.div`
        float: right;
    `;
const TextField = styled.input`
        height: 32px;
        width: 200px;       
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
        &:hover {
            cursor: pointer;
        }
    `;
const ClearButton = styled(Button)`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        float: right;
        align-items: center;
        justify-content: center;
    `;

export const BrokerEnrollments =  (props) => {
    const disableDownload = false;
    const [allItems, setAllItems] =  useState([]);
    const [enrollmentsData, setEnrollmentsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([])
    const [filterText, setFilterText] = React.useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = enrollmentsData.filter(
        item => item.utm_content && item.utm_content.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponent = ({ data }) => <Table striped bordered hover  responsive="sm">
        <tr>
            <th>Id</th><td>{data.id}</td>
            <th>Created</th><td>{data.created_date}</td>
        </tr>
        <tr>
            <th>FirstName</th><td>{data.first_name}</td>
            <th>LastName</th><td>{data.last_name}</td>
        </tr>
        <tr>
            <th>Email</th><td>{data.email}</td>
            <th>PromoCode</th><td>{data.promo_code}</td>
        </tr>
        <tr>
            <th>Address</th><td>{data.address}</td>
            <th>City</th><td>{data.city}</td>
        </tr>
        <tr>
            <th>ZipCode</th><td>{data.zip_code}</td>
            <th>Esiid</th><td>{data.esiid}</td>
        </tr>
        <tr>
            <th>PetType</th><td>{data.pet_type}</td>
            <th>PetBreed</th><td>{data.pet_breed}</td>
        </tr>
        <tr>
            <th>UTMSources</th><td>{data.utmlookup_code}</td>
            <th> </th><td></td>
        </tr>
    </Table>;
    const paginationComponentOptions = {
        rowsPerPageText: 'Rows / Page',
        rangeSeparatorText: 'of',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'All',
    };
    const paginationRowsPerPageOptions = [
        25, 50,100, 250, 500, 1000, 2000
    ];
    const exportHeaders = [
        { label: 'Signup Date', key: 'signup_date'},
        { label: 'First Name', key: 'first_name'},
        { label: 'Last Name', key: 'last_name'},
        { label: 'Email', key: 'email'},
        { label: 'Phone', key: 'phone'},
        { label: 'ESIID', key: 'esiid'},
        { label: 'IsMove', key: 'moving_switching'},
        { label: 'Requested Date', key: 'requested_date'},
        { label: 'End Date', key: 'end_date'},
        { label: 'Plan Name', key: 'plan_name'},
        { label: 'Address', key: 'Address1'},
        { label: 'Status', key: 'SPStatus'},
        { label: 'Deposit Amount', key: 'deposit_amount'},
        { label: 'IpAddress', key: 'EnrollmentIpAddress'},
        { label: 'Tdu', key: 'TduName'},
        { label: 'Promo code', key: 'promo_code'},
        // { label: 'UTM_Source', key: 'utm_source'},
        //{ label: 'UTM_Content', key: 'utm_content'},
    ];
    const exportOpsHeaders = [
        { label: 'Account', key: 'account_number'},
        { label: 'Signup Date', key: 'signup_date'},
        { label: 'First Name', key: 'first_name'},
        { label: 'Last Name', key: 'last_name'},
        { label: 'Email', key: 'email'},
        { label: 'Phone', key: 'phone'},
        { label: 'ESIID', key: 'esiid'},
        { label: 'IsMove', key: 'moving_switching'},
        { label: 'Requested Date', key: 'requested_date'},
        { label: 'End Date', key: 'end_date'},
        { label: 'Plan Id', key: 'plan_id'},
        { label: 'Plan Name', key: 'plan_name'},
        { label: 'Address', key: 'Address1'},
        { label: 'Status', key: 'SPStatus'},
        { label: 'Deposit Amount', key: 'deposit_amount'},
        { label: 'IpAddress', key: 'EnrollmentIpAddress'},
        { label: 'Tdu', key: 'TduName'},
        { label: 'Promo code', key: 'promo_code'},
        { label: 'UTM_Source', key: 'utm_source'},
        { label: 'UTM_Content', key: 'utm_content'},
    ];
    const onClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        console.log('filter cleared');
    };
    const onFilter = (e) => {
        setFilterText(e.target.value);
        console.log(e);
        e.target.focus();
    };
    const loadAllItems = async (e) => {
        console.log(e);
        setLoading(true);
        const response = await axios.get(`/internal/broker_enrollments?page=1&per_page=${totalRows}`,
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        setLoading(false);
        setAllItems(response.data.data);

    }
    const actionsMemo = React.useMemo(() =>
        <>
            {! disableDownload && allItems.length <= 0 ?<Button onClick={loadAllItems}>Load all for download</Button>: undefined}
            {! disableDownload && allItems.length > 0 ?
                <>
                    <CSVLink
                    data={allItems}
                    headers={exportHeaders}
                    filename={"goodcharlie_broker_enrollments.csv"}
                    className="btn btn-primary"
                    >
                        Brokers
                    </CSVLink>
                    <CSVLink
                        data={allItems}
                        headers={exportOpsHeaders}
                        filename={"goodcharlie_ops_enrollments.csv"}
                        className="btn btn-primary"
                    >
                        Operations
                    </CSVLink>
                </>
                : undefined}

        </>,
        [filteredItems]
    );
    const loadEnrollments = async page => {
        setLoading(true);
        let response = await axios.get(`/internal/broker_enrollments?page=${page}&per_page=${perPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        setTotalRows(response.data.total);
        await setColumns([
            {
                name: 'Account',
                selector: row => row.account_number,
                sortable: true,
            },
            {
                name: 'SignupDate',
                selector: row => row.signup_date,
                sortable: true,
            },
            {
                name: 'PromoCode',
                selector: row => row.promo_code,
                sortable: true,
                right: true,
            },
            {
                name: 'FirstName',
                selector: row => row.first_name,
                sortable: true,
                right: true,
            },
            {
                name: 'LastName',
                selector: row => row.last_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Status',
                selector: row => row.SPStatus,
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
                right: true,
            },
            {
                name: 'Phone',
                selector: row => row.phone,
                sortable: true,
                right: true,
            },
            {
                name: 'ESIID',
                selector: row => row.esiid,
                sortable: true,
                right: true,
                grow: 2
            },
            {
                name: 'TDSP',
                selector: row => row.TduName,
                sortable: true,
                right: true,
            },
            {
                name: 'RequestedStartDate',
                selector: row => row.requested_date,
                sortable: true,
                right: true,
            },
            {
                name: 'EnergyRare',
                selector: row => row.plan_amount,
                sortable: true,
                right: true,
            },
            {
                name: 'Plan',
                selector: row => row.plan_name,
                sortable: true,
                right: true,
            },
            {
                name: 'Source',
                selector: row => row.utm_source,
                sortable: true,
                right: true,
            },
            {
                name: 'Broker',
                selector: row => row.utm_content,
                sortable: true,
                right: true,
                grow: 2,
            }
        ]);
        setLoading(false);
    }
    const handlePageChange = page => {
        console.log('Page change: ' + page);
        loadEnrollments(page);
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await axios.get(`/internal/broker_enrollments?page=${page}&per_page=${newPerPage}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        await setEnrollmentsData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        const init = async () => {
            await loadEnrollments(1);
        }
        init().then(
            () => {
                console.log('loaded');
                setLoading(false);
            }
        );
    }, []);
    return (
        <>
            {
                (loading || ! enrollmentsData || enrollmentsData.length <=0 ) ? (
                    <>
                        loading
                    </>
                ):(<>
                    <SearchBox>
                        <TextField
                            id="search"
                            type="text"
                            placeholder="Filter By Broker"
                            aria-label="Search Input"
                            value={filterText}
                            onInput={onFilter}
                            autoFocus
                        />
                        <ClearButton type="button" onClick={onClear}>
                        X
                        </ClearButton>
                    </SearchBox>
                    <DataTable
                        title="Broker Signup Enrollments Complete"
                        columns={columns}
                        data={filteredItems}
                        defaultSortAsc={false}
                        defaultSortFieldId={1}
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        fixedHeader
                        fixedHeaderScrollHeight="1000px"
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        paginationPerPage={perPage}
                        paginationDefaultPage={page}
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={loading}
                        striped
                        responsive
                        dense
                        subHeader
                        actions={actionsMemo}
                    >
                    </DataTable>
                  </>
                )
            }
        </>
    );
}
